import React, {useState} from 'react';
import { View, Text, useWindowDimensions, TouchableOpacity, StyleSheet, Image, Platform} from 'react-native';
import { Box, Heading } from 'native-base';
import translate from '../i18n/translate';
import { useNavigation } from '@react-navigation/native';

// Import components
import SessionCard from './SessionCard.js';

//get Image
const resultsHighIcon = require('../images/png/results_high_icon.png');

const SessionList = ({ sessionList, access_token, runner, refId, maxDisplay, sessionPosition }) => {

  const width = useWindowDimensions().width;
  const isWideEnough = width>600;
  const navigation = useNavigation();
  
  /** findRefSession
   *
   *  This functions is a filter on `sessionList` to find the reference results.
   *
   *  There may be several sport sessions, but only one can be the reference session.
   *  This session has the attribute `isref` set to 1.
   *  Session and reference session are then passed to SessionResults.js, where charts
   *  are traced, comparing one session to the reference session.
   */

   const [displayCommentInfo, setDisplayCommentInfo] = useState(false);
   const [commentInfo, setCommentInfo] = useState("");
   const [checked, setChecked] = useState({});
   const [idChecked, setIdChecked] = useState([]);
   const [idCheckedweb, setIdCheckedweb] = useState([]);

   const getSessionListChronological = () => {
    var chronologicalSessionList = sessionList.sort(function(a,b){
      return new Date(Date.parse(b.date)) - new Date(Date.parse(a.date));
    });
    return chronologicalSessionList;
  }

  const findRefSession = () => {
    if (refId !== -1){
      if(getSessionListChronological()[refId]) return getSessionListChronological()[refId]
    }
    return null;
  }
  const refSession = findRefSession();
 
  // For some reason, the web version needs a fixed height to render correctly.
  // Note that this fixed height is ignored in the Android version.
    const flatListWebHeight = "250px";
    
    const findPreviousSession = (sessionIndex) => {
        if (sessionList.length > 1) {
            return getSessionListChronological()[sessionIndex]
        }
        return getSessionListChronological()[0];
    }

    const displayComment = (comment)=>{
      setCommentInfo(comment);
      displayCommentInfo === true ? setDisplayCommentInfo(false) : setDisplayCommentInfo(true);
    }

    const asArrayResults = [];
    
    const addOneSessionId = ()=>{
     
    const asArray = Object.entries(checked);
    const filtered = asArray.filter(([key, value]) => value === true);
    
    getSessionListChronological().forEach((val)=>{
      filtered.forEach(([key, valeur]) => {
        if(val.public_id === key){
          asArrayResults.push(val);
        }
      
      });
    });
    }
    addOneSessionId();

    const addOneSessionWebAndroid = (elt)=>{
      getSessionListChronological().forEach((val)=>{
        if(val.public_id === elt && !idChecked.includes(elt)){
          setIdChecked([...idChecked,elt]);
          setIdCheckedweb([...idCheckedweb, val]); 
        }else if(val.public_id === elt && idChecked.includes(elt)){
          idCheckedweb.splice(idCheckedweb.indexOf(val), 1);
          idChecked.splice(idChecked.indexOf(elt), 1);
        }
      });  
    }
  return(
    <Box>
      
      <View h={flatListWebHeight}>
        {getSessionListChronological().slice(sessionPosition, sessionPosition + maxDisplay).map((item, index) => {
            return (
                <SessionCard
                key={item.public_id}
                session={item}
                sessionList={getSessionListChronological()}
                access_token={access_token}
                runner={runner}
                displayComment = {displayComment}
                // We choose the previous session as reference if reference is not selected
                refSession={(refSession != null) ? refSession : findPreviousSession(1)}
                checked={checked}
                setChecked={setChecked}
                addOneSessionWebAndroid={addOneSessionWebAndroid}
                idChecked={idCheckedweb}
                />
            )
        })}
        {displayCommentInfo &&<View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.8)},{borderRadius: 15}]}>
            <Text style={styles.information_text}>
            {commentInfo}
            </Text>
            <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  setDisplayCommentInfo(false);
                }}>
              <Text style={styles.button_text}>{translate('go_Back')}</Text>
            </TouchableOpacity>
        </View>}
      </View>
      {(asArrayResults[1] || idCheckedweb[1]) && <TouchableOpacity
          style={[styles.button_result_icon]}
          onPress={() => {
            navigation.navigate('BiomechaParamsReport', {asArrayResults:(Platform.OS === 'android')? asArrayResults : idCheckedweb, runner:runner });
          }}>
            <Image alt="High icon results logo" style={(Platform.OS === 'android') ? {} : {width: 30, height:30}}   source={resultsHighIcon}/>
      </TouchableOpacity>}
    </Box>
  );
}

export default SessionList;


const styles = StyleSheet.create({
  information_container:{ 
    borderWidth:1,
    alignSelf:"center",
    flex: 1,
    borderColor: '#00d5f7',
    borderRadius: 20,
    backgroundColor: '#FFF',
    position: 'absolute',
    top :40,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, 
  },
  button:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:200,
    marginBottom: 10,
    marginTop: 20
  },
  button_result_icon:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:15,
  },
  button_text:{
    fontSize: 14, 
    alignSelf:"center", 
    marginHorizontal:20, 
    marginVertical:2,
    color:"#000",
    fontWeight:"bold"
  },
  information_text:{
    textAlign:"justify", 
    padding: 5, 
    paddingHorizontal: 15,
    color:"black"
  },
  session_circle:{
    width: 20,
    height: 20,
    borderRadius:10,
    backgroundColor:"#707070",
    alignSelf:"center"
  },
  reference_circle:{
    width: 20,
    height: 20,
    borderRadius:10,
    backgroundColor:"#00d5f7",
    alignSelf:"center"
  }
});