import React, {useState} from 'react';
import { useWindowDimensions, TouchableOpacity, View, StyleSheet, Text, Platform } from 'react-native';
import { Box, Center, Heading } from 'native-base';
import { Grid, AreaChart, LineChart, XAxis } from 'react-native-svg-charts';
import { Circle, Path, Rect, Use } from 'react-native-svg';
import translate from '../i18n/translate';

const SessionGraph = ({sessionList, idReferenceSession, maxDisplay, sessionPosition}) => {

  const [results, setResults] = useState([]);
  const [isRef, setIsRef] = useState();
  const [displayRefInfo, setDisplayRefInfo] = useState(false);

  const getSessionListChronological = () => {
    var chronologicalSessionList = sessionList.sort(function(a,b){
      return new Date(Date.parse(a.date)) - new Date(Date.parse(b.date));
    });
    return chronologicalSessionList;
  }

  const width = useWindowDimensions().width;
  const isWideEnough = width>600;
  let filteredWalkArray = (getSessionListChronological() || []).filter( elt => elt.activity === "walk");
  let filteredRunArray = (getSessionListChronological() || []).filter( elt => elt.activity === "run");
  let isrefArray = (getSessionListChronological() || []).map((element) => element.feeling);
  let activityArray = (getSessionListChronological() || []).map((element) => element.activity);
  const dataLegend = (getSessionListChronological() || []).map((element) => new Date(Date.parse(element.date)))
  const data = isrefArray.length !== 0 ? isrefArray : [null];
  const getDate = (index) => {
    return new Intl.DateTimeFormat(translate('country_option'), {month: 'short', day: '2-digit'}).format(dataLegend[index]);
  }
  

  const Decorator = ({x, y, data}) => {
    return data.map((value, index) => (
      <Circle
        key={index}   r={11}
        cx={x(index)} cy={y(value)}
        stroke={data.length+index-sessionPosition == isRef? '#00D5F7': '#707070'}
        fill={data.length+index-sessionPosition == isRef? '#00D5F7': '#707070'}
        onPress={() => {
          setIsRef(data.length+index-sessionPosition);
          idReferenceSession(data.length+sessionPosition-index-1);
        }}
      />
    ));
  };

  const Line = ({line}) => (
    <Path
      d={line} stroke={'#707070'} fill={'none'}
    />
  );

  const DecoratorWeb = ({x, y, data}) => {
    return data.map((value, index) => (
      <Circle
        key={index}   r={11}
        cx={x(index)} cy={y(value)}
        stroke={data.length+index-sessionPosition == isRef? '#00D5F7': '#707070'}
        fill={data.length+index-sessionPosition == isRef? '#00D5F7': '#707070'}
        onClick={() => {
          console.log('appui :: ::')
          setIsRef(data.length+index-sessionPosition);
          idReferenceSession(data.length+sessionPosition-index-1);
        }}
      />
    ));
  };

  return (
    <Box>
      <Center mt="5">
        <View style={{flexDirection:"row", width: "100%"}}>
          <Heading size="md">
          {translate('evolution_functional_level')}
          </Heading>
          <TouchableOpacity style={{marginLeft: "2%", width: 30, height: 30, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
            onPress={() => setDisplayRefInfo(!displayRefInfo)}>
            <Heading fontSize={18} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
          </TouchableOpacity>
        </View>

        {!displayRefInfo && <View> 
          <LineChart
            style={{height: 200, width: isWideEnough? 500 : (width*0.8)}}
            data={data.slice(data.length - maxDisplay - sessionPosition, data.length - sessionPosition)}
            gridMin={0}
            gridMax={10}
            contentInset={{top: 20, bottom: 20, left: 20, right:20}}
            svg={{stroke: '#707070'}}
          >
          <Grid/>
          <Line/>
          {Platform.OS === 'android' ? <Decorator/> : <DecoratorWeb/>}
          </LineChart>
          <XAxis
            style={{width: isWideEnough? 500 : (width*0.8)}}
            data={data.slice(data.length - maxDisplay - sessionPosition, data.length - sessionPosition)}
            formatLabel={(value, index) => getDate(data.length-maxDisplay+index-sessionPosition)}
            contentInset={{top: 20, bottom: 20, left: 20, right:20}}
            svg={{ fontSize: 14, fill: 'black', fontWeight:"bold" }}
          />
        </View>}
      </Center>
      {displayRefInfo && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.8)},{borderRadius: 15}]}>
        <Text style={styles.information_text}>
        {translate('evolution_functional_level_message')}
        </Text>
        <View style={{flexDirection: isWideEnough? "row" : "column", justifyContent:"space-around"}}>
          <View style={{flexDirection: "row"}}>
            <Text style={styles.information_text}>{translate('session_message')}</Text>
            <View style={styles.session_circle}/>
          </View>
          <View style={{flexDirection: "row"}}>
            <Text style={styles.information_text}>{translate('reference_session_message')}</Text>
            <View style={styles.reference_circle}/>
          </View>
        </View>
        <TouchableOpacity
            style={styles.button}
            onPress={() => {
              setDisplayRefInfo(false);
            }}>
          <Text style={styles.button_text}>{translate('ok')}</Text>
        </TouchableOpacity>
      </View>}
    </Box>
  );
}

export default SessionGraph;

const styles = StyleSheet.create({
  information_container:{
    // backgroundColor:"#F2F2F2",
    backgroundColor: '#FFF', 
    borderWidth:1,
    alignSelf:"center",
    borderColor: '#00d5f7', 
  },
  button:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:200,
    marginBottom: 10
  },
  button_text:{
    fontSize: 14, 
    alignSelf:"center", 
    marginHorizontal:20, 
    marginVertical:2
  },
  information_text:{
    textAlign:"justify", 
    padding: 5, 
    paddingHorizontal: 15,
    color:"black"
  },
  session_circle:{
    width: 20,
    height: 20,
    borderRadius:10,
    backgroundColor:"#707070",
    alignSelf:"center"
  },
  reference_circle:{
    width: 20,
    height: 20,
    borderRadius:10,
    backgroundColor:"#00d5f7",
    alignSelf:"center"
  }
});
