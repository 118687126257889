import React, { useState } from 'react';
import { NativeBaseProvider, Stack, ScrollView} from 'native-base';

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';

// components
import Logo          from '../components/Logo.js';
import LoginFields   from '../components/LoginFields.js';


const Login = ({route, navigation}) => {
  const [locale, setLocale]= useState(LOCALES.FRENCH)
  const setEnglishlanguages = (lang) =>{
    lang == 'Français' ? setLocale(LOCALES.ENGLISH): setLocale(LOCALES.FRENCH)  
  }

  return(
    <I18nProvider locale={locale} defaultLocale="fr-FR">
      <NativeBaseProvider>
        <ScrollView style={{fontFamily:"sans-serif"}}>
          <Stack
            flex={1}
            direction={{ base: 'column', lg: 'row' }}
            alignItems="center"
          >
            <Logo/>
            <LoginFields navigation={navigation} setEnglishlanguages={setEnglishlanguages}/>
          </Stack>
        </ScrollView>
      </NativeBaseProvider>
    </I18nProvider>
  );
}

export default Login;
