import React, { useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity, useWindowDimensions } from "react-native";
import { FormControl, Input, Select, Center} from "native-base";
import { RadioButton } from 'react-native-paper';
import translate from '../i18n/translate';

// utils functions
import { createPathologies  } from '../src/fetch.js';

export default function FormAddPathology({ public_id, access_token, navigation }) {

    const [onChangeText, setOnChangeText] = useState('');
    const [textError, setTextError] = useState('');

    const largeBreakpoint = 600;
    const width = useWindowDimensions().width;
    const isWideEnough = (width > largeBreakpoint);

    const onSubmit = () =>{

        const data = {
            "pathologie": onChangeText,
        }

        const route = `/api/runners/${public_id}/pathologies`;
        if(onChangeText !== ""){       
            createPathologies(route, access_token, data)
                .then((response) => {
                    if(response.status === 200){
                        navigation.navigate("RunnersList", { public_id, access_token }); 
                    }else{
                        setTextError("Erreur d'enrégistrement");  
                    }
                })
                .catch( err => console.error(err));                                                                     
        }else{
            setTextError("Veuillez Renseigner le champ");
        }        
    } 

    const cancelForm = () =>{ 
        navigation.navigate("RunnersList", { public_id, access_token });
    } 
    
    return (
        <Center style={[styles.add_patient, {width:isWideEnough? 590 : '90%', height:isWideEnough? 343 : 'auto'}]}>
            <View  style={styles.formContainer}>
                <Text style={styles.textTitle}>{translate('add_pathologie')}</Text>
                <View style={styles.form}>
                    <View style={[styles.flexInputText, {flexDirection:isWideEnough? 'row' : 'column'}]}>
                        <FormControl style={[styles.InputTextControl, {padding: isWideEnough? 10 : 0}]}>
                            <Input
                                style={styles.input}
                                height="50%"
                                value={onChangeText}
                                onChangeText={(text) => setOnChangeText(text)}
                            />
                            {textError&&<Text style={styles.errorMsg}>{textError}</Text>}
                        </FormControl>     
                    </View>
                    <View style={[styles.flexButton, {width:isWideEnough? 550 : "95%"}]}>
                        <TouchableOpacity
                            style={[styles.customBtnBG, {width:isWideEnough ? 150 : 100}]}
                            onPress={cancelForm}>
                            <Text style={styles.customBtnText}>{translate('cancel')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.customBtnBG, {width:isWideEnough ? 150 : 100}]}
                            onPress={onSubmit} >
                            <Text style={styles.customBtnText}>{translate('add')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>    
        </Center>
    );
}

const styles = StyleSheet.create({
    add_patient: {
        flex: 1,
        borderColor: '#00d5f7',
        margin: 5,
        borderRadius: 20,
        backgroundColor: '#FFF',
        position: 'absolute',
        top :40,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 2,
    },
    input:{
        marginTop: 5,
        borderWidth: 1,
        borderRadius: 4,
        borderColor:"#00d5f7"
    },
    
    customBtnBG: {
        backgroundColor: "#00d5f7",
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 30,
        margin: 12,
    },
    customBtnText: {
        fontSize: 18,
        fontWeight: '400',
        color: "#fff",
        textAlign: 'center'
    },
    form: {
        flex: 1,
        height: 'auto',
        justifyContent: 'center',
        padding: 5,
        marginTop: 5,
        marginBottom: 10
    },
    formContainer:{
        justifyContent: 'center',
        marginTop: 5,
    },
    flexInputText:{
        flex: 1,
        marginBottom: 30,
        alignItems: 'center'
    },
    InputTextControl:{
        flex: 1,
        padding: 10,  
        margin: -14        
    },
    flexButton:{
        flex: 1,  
        flexDirection: 'row',  
        justifyContent: 'space-around',
        alignItems: 'center', 
        marginTop: 15,
    },
    textTitle:{
        fontSize: 19,
        marginBottom: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#00d5f7'
    },
    errorMsg:{
        fontSize: 12,
        width: '100%', 
        color: 'red',
        padding: 0,
        margin: 0,
    }
})