import React from 'react';
import { Heading, Text, Divider, ArrowDownIcon } from 'native-base';
import { View, TouchableOpacity, Image, Platform, useWindowDimensions } from 'react-native';
import translate from '../i18n/translate';
import { useNavigation } from '@react-navigation/native';
import CheckBox from '@react-native-community/checkbox';

// utils functions
import { deleteOneSession } from '../src/fetch.js';

const SessionCard = ({ access_token, session, runner, displayComment, refSession, sessionList, checked, setChecked, addOneSessionWebAndroid, idChecked }) => {
  let activity;
  if (session.activity == "walk") {
    activity = translate('Walk_balance');
  } else {
    activity = translate('run_balance');
  }
  const date = new Date(Date.parse(session.date));
  let dateTime = session.date.split(' ');
  const options = { month: 'short', day: '2-digit' };
  const dateString = new Intl.DateTimeFormat(translate('country_option'), options).format(date);
  const navigation = useNavigation();


  const largeBreakpoint = 992;
  const { width, height } = useWindowDimensions();
  const isTablet = (width > largeBreakpoint);
  const isWideEnough = (width >= 600);

  const deleteSession = () => {
    const route = `/api/session/${session.public_id}`;
    deleteOneSession(route, access_token)
      .then((response) => {
        if (response.status === 200) {
          console.log("session has been Successfully deleted")
        } else {
          console.log("session error delete")
        }

      })
      .catch(err => console.error(err));
  }

  const getPdf = async () => {
    const route = "https://app.mysmartmove.fr" + `/api/sessions/${runner.public_id}/${session.public_id}/pdf`;
    const details = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token,
      },
    };

    const response = await fetch(route, details);
    if (!response.ok) {
      return response;
    } else {

      console.log(response)
      // Assuming the response contains the PDF blob
      const blob = await response.blob();

      // Create a URL for the blob
      const pdfUrl = window.URL.createObjectURL(blob);

      // Create an anchor (<a>) element to facilitate downloading
      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = `${runner.first_name + "_" + runner.last_name + "_" + new Date(session.date).toLocaleDateString('fr-Fr')}.pdf`;

      // Append the anchor to the document body and trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up by revoking the blob URL and removing the anchor element
      window.URL.revokeObjectURL(pdfUrl);
      downloadLink.parentNode.removeChild(downloadLink);

      return "PDF downloaded successfully.";
    }
  }



  return (
    <View key={session.public_id} style={{ flexDirection: 'row' }}>
      {(Platform.OS == 'android') ? <CheckBox
        value={checked[session.public_id]}
        onValueChange={(newValue) => {
          setChecked({ ...checked, [session.public_id]: newValue });
        }}
      /> : <input type="checkbox" id={session.public_id} name={session.public_id} value={idChecked[idChecked.indexOf(session.public_id)]} onChange={(e) => {
        addOneSessionWebAndroid(session.public_id);
      }}
      ></input>}
      <TouchableOpacity
        style={{ width: isWideEnough ? width * 0.35 : width * 0.8 }}
        onPress={() => {
          navigation.navigate('SessionResults', { runner, session, refSession, activity: sessionList[0].activity, access_token: access_token, sessionList: sessionList, choiceActivity: sessionList[0].activity });
        }}>
        <Heading size="sm">{dateString}, {dateTime[4]} - {activity}</Heading>
        <Text fontWeight={400}>
          {runner.diagnosis || 'Diagnostic non-renseigné'}
          {'   '}
          ({runner.body_region || 'région corporelle non-renseignée'})
        </Text>
        <Divider my="2" />
      </TouchableOpacity>
      <TouchableOpacity
        style={{ marginLeft: isWideEnough ? 15 : 0 }}
        onPress={() => displayComment(session.comment)}
      >
        <Image alt="logo pour commentaire" style={(Platform.OS === 'android') ? {} : { width: 20, height: 20 }} source={require('../images/png/comment_line32x32.png')} />
      </TouchableOpacity>
      <TouchableOpacity
        style={{ marginLeft: 15 }}
        onPress={() => getPdf()}
      >
        <ArrowDownIcon />
      </TouchableOpacity>
      {/* <TouchableOpacity
        style={{ marginLeft:15}}
        onPress={() => deleteSession()}
        >
        <Text>DEL</Text>
    </TouchableOpacity> */}
    </View>
  );
}
//  style={{ alignSelf:'center'}} style={{width: 30, height:16}} style = Platform.OS === 'android'? {} : {width: 30, height:16}
export default SessionCard;

