import React from 'react';
import { View, Text, NativeBaseProvider, Stack, FlatList, Button } from 'native-base';
import { Center, Heading } from 'native-base';
import { Grid, AreaChart, XAxis, BarChart, YAxis, Path } from 'react-native-svg-charts';
import { Circle, Svg, G, Rect,Line } from 'react-native-svg';
import {BackHandler, Alert, StyleSheet, useWindowDimensions} from 'react-native';
import * as shape from 'd3-shape'
import {LineChart,} from "react-native-chart-kit";


const RunnerSessionBehavior = ({route, navigation})=>{

    const {pelvis_inc, param_data_right, param_data_left, motion_param, curve_name_right, curve_name_left, phase } = route.params;
    const midBreakpoint = 688;
    const { height, width } = useWindowDimensions();
    const fontSize = (width<=midBreakpoint)? 18 : 18;
    const graphWidth = Math.min(width*0.9, 1000);
    const graphHeight = Math.min(height*0.45, 300);

    const data5 = {
        datasets: [
          {
            data: param_data_right,
            color: (opacity = 1) => `rgba(0, 213, 247, 1)`, 
            strokeWidth: 2,
            withDots:false,
            withShadow:false,
            withInnerLines:false, 
            withOuterLines:false,
          },
          {
            data: param_data_left,
            color: (opacity = 0.8) => `rgba(245, 127, 23, 1)`, 
            strokeWidth: 2,
            withDots:false,
            withShadow:false, 
            withInnerLines:false,
            withOuterLines:false, 
          }
        ],
        legend: [curve_name_right, curve_name_left],
      };

      const data0 = {
        datasets: [
          {
            data: pelvis_inc,
            color: (opacity = 1) => `rgba(139, 195, 74, 1)`,
            strokeWidth: 2,
            withDots:false,
            withShadow:false, 
            withInnerLines:false,
            withOuterLines:false,
          }
        ],
        legend: [curve_name_right],
      };

    const chartConfig = {
      propsForLabels:{fontFamily:"sans-serif",fontSize: 17},
      backgroundGradientFrom: "#000",
      backgroundGradientFromOpacity: 1,
      backgroundGradientTo: "#000",
      backgroundGradientToOpacity: 1,
      decimalPlaces: 1,
      color: (opacity = 1) => `rgba(255, 255, 255, 1)`,
      labelColor: (opacity = 1) => `rgba(255, 255, 255, 1)`,
      strokeWidth: 2,
      barPercentage: 0.5,
      fillShadowGradientOpacity: 0,   
    };
 
    return(
      <NativeBaseProvider>
          <Stack alignItems="center" style={{fontFamily:"sans-serif"}}>
              {(param_data_right != undefined && param_data_left != undefined) &&
              <View style={styles.container_component}>
          
                  <View style={styles.container_component}>
                  <Text style={styles.container_text} fontSize={fontSize}>{motion_param} ({phase})</Text>
                      <LineChart
                          data={data5}
                          width={graphWidth}
                          height={graphHeight}
                          yAxisSuffix="°"
                          yAxisInterval={11}
                          chartConfig={chartConfig}
                          //bezier
                          style={{
                              // marginLeft: 10,
                              fontFamily:"Montserrat-Regular"
                          }}
                      />      
                  </View>
              </View>
              }{pelvis_inc != undefined &&
                  <View style={styles.container_component}>
          
                  <View style={styles.container_component}>
                  <Text style={styles.container_text}  fontSize={fontSize}>{motion_param} ({phase})</Text>
                      <LineChart
                          data={data0}
                          width={graphWidth}
                          height={graphHeight}
                          yAxisInterval={11}
                          chartConfig={chartConfig}
                          //bezier
                      />      
                  </View>
              </View>
                  }
          </Stack>
      </NativeBaseProvider>
    )
}

export default RunnerSessionBehavior

const styles = StyleSheet.create({
    container_component:{
      justifyContent:"center",
      marginTop:10,
    },
    container_text:{
      textAlign: "center",
      fontWeight: "bold",
      marginBottom: 20,
      color: "#000",
    }
  })