// This component is used to render the navigation adapted on exercise pages.
// It takes the place of the classical return button native of React Navigation
// If pressed, a message is displayed before navigating back, allowing the Master to Reset cleanly

import React, {useEffect, useState } from 'react';
import { StyleSheet, View, Text, NativeModules, Alert, Platform, TouchableOpacity, useWindowDimensions} from 'react-native';

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import translate from '../i18n/translate';

// Native Module
const { CollectData } = NativeModules;

const NavigationConstraintButton = ({navigation, route}) => {

    const { height, width } = useWindowDimensions();

    // If the back button is pressed while on the DeviceMesure screen, the following is executed
    // It may be interesting to add this peculiar navigation button to the RetrieveSession screen as well
    const goBackFromExercise = () => {
        console.log("Navigation attemptend from Exercise Screen...");
        // Changing the Alert to a display message would be prefered for the i18n but may be complex to implement
        // Since this component is not linked to the screen
        if(route.name === "UnitySession"){
            navigation.navigate("Runner", {"runner": route.params.runner, "access_token": route.params.access_token});
        }else{
            Alert.alert(
                "Attention !",
                "Voulez-vous réellement quitter cet écran ? Vérifiez qu'aucune mesure n'est en cours.",
                [
                    {
                        text: "Annuler",
                        style: "cancel"
                    },
                    {
                        text: "Quitter quand même",
                        onPress: () => {
                            CollectData.Reset().then(resolve => {       // Resetting Master to avoid any issue for the next Measure
                                                                        // IMPROVEMENT : Reset the Master only if it is in a state requiring this action.
                                                                        // => Requires to obtain information from the screen DeviceMesure.
                                console.log(resolve);
                                if(resolve === "OK"){
                                console.log("Master has been successfully reset.");
                                }else{
                                console.log("An error occured while trying to reset the Master.");
                                }
                                navigation.navigate("Runner", {"runner": route.params.runner, "access_token": route.params.access_token});
                            }).catch(error => console.error(error));
                        }
                    },
                ]
            )
        }
    }

    return(
        Platform.OS === 'android' && <TouchableOpacity onPress={()=>goBackFromExercise()}>
            <Text style={styles.whiteText}>←</Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    whiteText:{
        color: "white",
        fontSize: 24,
        paddingRight:24,
        fontWeight:"bold"
    },
});

export default NavigationConstraintButton;