import { decode } from 'base-64';
import { Platform, ToastAndroid } from 'react-native';

const loginOrRegister = async (route, email, password) => {
  route = "https://app.mysmartmove.fr" + route;
  if (Platform.OS === 'android') ToastAndroid.show(
    `MYSMARTMOVE_API = ${process.env['MYSMARTMOVE_API']}`,
    ToastAndroid.LONG
  );
  
  const details = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email: email, password: password}),
  };

  const response = await fetch(route, details);
  if (!response.ok){
    return response;
  }
  const {access_token} = await response.json();
  const {public_id} = JSON.parse(decode(access_token.split('.')[1]));
  const res = {
    public_id    : public_id,
    access_token :access_token,
    status       : response.status
  }
  return res;
}

const login = async (email, password) => {
  return loginOrRegister('/auth/login/trainer', email, password);
}

const register = async (route, email, password, firstName, lastName) => {
  route = "https://app.mysmartmove.fr" + route;
  const details = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email: email, password: password, first_name: firstName, last_name: lastName}),
  };
  console.groupCollapsed('fetch.create::');
  console.log(`POST ${route}`);
  if (Platform.OS === 'web') console.dir(details);

  const response = await fetch(route, details);
  if (!response.ok) throw new Error(`Fetch failed (status ${response.status})`);
  console.log(`→ OK (status ${response.status})`);
  const {access_token} = await response.json();
  const {public_id} = JSON.parse(decode(access_token.split('.')[1]));

  console.log(`→ public_id: ${public_id}`);
  console.log(`→ access_token: ${access_token}`);
  console.groupEnd();

  return {public_id, access_token};
}

const get = async(route, access_token) => {
  route = "https://app.mysmartmove.fr" + route;

  const details = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+access_token,
    },
  };

  console.groupCollapsed('fetch.get::');
  console.log(`GET ${route}`);
  if (Platform.OS === 'web') console.dir(details);

  const response = await fetch(route, details);
  if (!response.ok) throw new Error(`Fetch failed (status ${response.status})`);
  console.log(`→ OK (status ${response.status})`);
  const json = await response.json();
  if (Platform.OS === 'web') console.dir(json);
  console.groupEnd();

  return { json };
}

const getPathologies = async(route, access_token) => {
  route = "https://app.mysmartmove.fr" + route;

  const details = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+access_token,
    },
  };

  const response = await fetch(route, details);
  if (!response.ok){
    return response;
  }
  const json = await response.json();
  const res = {
    status       : response.status,
    data         : json
  }
  return res;
}

const createPathologies = async(route, access_token, data) => {
  route = "https://app.mysmartmove.fr" + route;
  const details = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+access_token,
    },
    body: JSON.stringify(data),
  };
  
  const response = await fetch(route, details);
  if (!response.ok){
    return response;
  }
  const json = await response.json();
  const res = {
    status       : response.status,
    data         : json
  }
  return res;
}

const createRunner = async(route, access_token, data) => {
  route = "https://app.mysmartmove.fr" + route;
  const details = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+access_token,
    },
    body: JSON.stringify(data),
  };
  console.groupCollapsed('fetch.create::');
  console.log(`POST ${route}`);
  if (Platform.OS === 'web') console.dir(details);

  const response = await fetch(route, details);
  if (!response.ok) throw new Error(`Fetch failed (status ${response.status})`);
  console.log(`→ OK (status ${response.status})`);
  const json = await response.json();
  if (Platform.OS === 'web') console.dir(json);
  console.groupEnd();

  return { json };
}

const updateRunner = async(route, access_token, data) => {
  route = "https://app.mysmartmove.fr" + route;
  const details = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+access_token,
    },
    body: JSON.stringify(data),
  };
  console.groupCollapsed('fetch.put::');
  console.log(`PUT ${route}`);
  if (Platform.OS === 'web') console.dir(details);

  const response = await fetch(route, details);
  if (!response.ok) throw new Error(`Fetch failed (status ${response.status})`);
  console.log(`→ OK (status ${response.status})`);
  const json = await response.json();
  if (Platform.OS === 'web') console.dir(json);
  console.groupEnd();

  return { json };
}

const updateComment = async(route, access_token, comment) => {
  route = "https://app.mysmartmove.fr" + route;
  const details = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+access_token,
    },
    body: JSON.stringify(comment),
  };
  console.groupCollapsed('fetch.put::');
  console.log(`PUT ${route}`);
  if (Platform.OS === 'web') console.dir(details);

  const response = await fetch(route, details);
  if (!response.ok) throw new Error(`Fetch failed (status ${response.status})`);
  console.log(`→ OK (status ${response.status})`);
  const json = await response.json();
  if (Platform.OS === 'web') console.dir(json);
  console.groupEnd();

  return { json };
}

const deleteOneRunner = async(route, access_token) => {
  route = "https://app.mysmartmove.fr" + route;

  const details = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+access_token,
    },
  };

  console.groupCollapsed('fetch.delete::');
  console.log(`DELETE ${route}`);
  if (Platform.OS === 'web') console.dir(details);

  const response = await fetch(route, details);
  if (!response.ok) throw new Error(`Fetch failed (status ${response.status})`);
  console.log(`→ OK (status ${response.status})`);
  // const json = await response.json();
  if (Platform.OS === 'web') console.dir(json);
  console.groupEnd();

  return response.status;
}

const deleteOneSession = async(route, access_token) => {
  route = "https://app.mysmartmove.fr" + route;
  const details = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+access_token,
    },
  };
  
  const response = await fetch(route, details);
  if (!response.ok){
    return response;
  }
  return response;
}

const sendToDataProcessing = async(route, access_token, data) => {
  route = "https://app.mysmartmove.fr" + route;
  const details = {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization' : 'Bearer '+access_token,
    },
    body: data,
  };
  console.groupCollapsed('fetch.sendToDataprocessing::');
  console.log(`POST ${route}`);
  if (Platform.OS === 'web') console.dir(details);

  const response = await fetch(route, details);
  if (!response.ok) throw new Error(`Fetch failed (status ${response.status})`);
  console.log(`→ OK (status ${response.status})`);
  const json = await response.json();
  if (Platform.OS === 'web') console.dir(json);
  console.groupEnd();

  return { json };
}

export { login, register, get, createRunner, deleteOneRunner, updateRunner, sendToDataProcessing, updateComment, getPathologies, createPathologies, deleteOneSession};

