import React, {useEffect, useState} from 'react';
import { NativeBaseProvider, Box, Text, Button, Icon, Center, VStack, Heading } from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from "@react-native-community/netinfo";

// intl components
import 'intl';
import translate from '../i18n/translate';
import { I18nProvider, LOCALES } from '../i18n';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import {Picker} from '@react-native-picker/picker';

// Import the native module
import { NativeModules, useWindowDimensions } from 'react-native';
const { CollectData } = NativeModules;

//Import Zeroconf
import { MdnsBattery } from '../src/zeroconf_mdns';

const SessionCalibration = ({ route, navigation }) => {

  const { activity, sessionList, access_token, runner, choiceActivity, responseSpeed} = route.params;
  const [selectedLanguage, setSelectedLanguage] = useState("Français");
  const [displayCalibrationState, setDisplayCalibrationState] = useState("0");
  const [displayDataprocessValue, setDisplayDataprocessValue] = useState("");
  const [offline, setOffline] = useState('');
  const [mdnsservice, setMdnsservice] = useState('');
  const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}];

  const { height, width } = useWindowDimensions();
  const largeBreakpoint   = 992;
  const midBreakpoint = 688;
  const isWideEnough = (width >= 600);
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
  const execCreateFile = "run";
  let isInternetReachable = false;

  useEffect(() => {
  let mounted = true;
   const unsubscribeHandler = NetInfo.addEventListener(state => {
    if(state.isInternetReachable === false){
      isInternetReachable = true;
    }
    if(state.details.ssid != undefined && state.isInternetReachable === true){
      console.log(state.details.ssid)
      setOffline("");
      if(state.details.ssid.substring(0, 11) === "MYSMARTMOVE"){
        CollectData.CloseAll()
        .then((res)=>{
          if(res === "Success"){
            setOffline(translate('disconnect_box_message'));
            console.log("The socket closed successfully");
          }
        })
        .catch((res)=>{
          if(res === "BAD CLOSE"){
            console.log("socket close failed");
          }
        }); 
  
      }else{    
        if(isInternetReachable && state.isInternetReachable === true){
          navigation.navigate('Runner', {access_token, runner });
        } 
      } 
    }else{
      CollectData.CloseAll()
      .then((res)=>{
        if(res === "Success"){
          setOffline(translate('disconnect_box_message'));
          console.log("The socket closed successfully");
        }
      })
      .catch((res)=>{
        if(res === "BAD CLOSE"){
          console.log("socket close failed");
        }
      });
      
    }    
  });
  return ()=>{
    console.log("exec unsubscribeHandle");
    if(mounted){
      unsubscribeHandler();
      mounted = false;
    } 
  }
  },[]);

  const getCalibrationState = async () => {
    try {
      const value = await AsyncStorage.getItem('displayCalibrationButton')
      if(value !== null) {
        setDisplayCalibrationState(value);
      }
    } catch(error) {
      console.error(error)
    }
  }
  getCalibrationState();

  const getDataprocessValue = async () => {
    try {
      const value = await AsyncStorage.getItem('dataprocessRecalibration')
      if(value !== null) {
        setDisplayDataprocessValue(value);
      }
    } catch(error) {
      console.error(error)
    }
  }
  getDataprocessValue();

  const getMdnsIdentity = async () => {
    try {
      const value = await AsyncStorage.getItem('cfgmsm');
      if(value !== null) {
        MdnsBattery(value)
        .then((res)=>{
          let response = JSON.parse(res);
          if(mdnsservice === ''){
            setMdnsservice(response);
            console.log("Service batterie:: :: ",response['txt']['battery'])
          }
        });
      }
    } catch(error) {
      console.error(error)
    }
  }
  getMdnsIdentity();

  

  const testPingMasterManager = ()=>{
    // return new Promise((resolve, reject)=> {
      
      var pingIntervall = setInterval(() => {
      
        try {
          
          // console.log("Master is how how how ");
          if(pingIntervall !== undefined){
            // console.log("pin ha ha ha :: "+ intPing);
          }else{
            console.log("Sorry pin hoooo hoooo hoooo:: ");
          }
          CollectData.GetAcknowledgePingMaster().then((pingResponse)=>{
            console.log("Rest yes yes oui:: :: " + pingResponse);
            if(pingResponse === 1 || pingResponse === 2){
              console.log("Master is OK");
            }else if(pingResponse === 0 || pingResponse === 3){
              console.log("Master is not OK");
              clearInterval(pingIntervall);
              CollectData.CloseAll()
                .then((res)=>{
                  if(res === "Success"){
                    setOffline(translate('disconnect_box_message'));
                    console.log("The socket closed successfully");
                  }
                })
                .catch((res)=>{
                  if(res === "BAD CLOSE"){
                    console.log("socket close failed");
                  }
                });
            }
          }).catch((err)=>console.log(err))
        } catch (error) {
          console.log("il y a eut une erreur:: :: "+err)
        }
      }, 8000);
      // if(pingIntervall !== undefined){
      //   resolve(pingIntervall);
      // }else{
      //   clearInterval(inter);
      //   reject(401); // ignored
      // }
      
    // });
  
  }
  
  // let inter = setInterval(() => {
  //   testPingMasterManager()
    // testPingMasterManager(inter).then((response)=>{
    //   console.log("réponse :: :: " + response)
    // }).catch((err)=>{
    //   console.log("error :: :: "+err)
    // })
  // }, 8000);
  

  const testPingMaster = ()=>{
    CollectData.GetAcknowledgePingMaster().then((rest)=>{
      console.log("Rest yes yes oui:: :: " + rest);
    }).catch((err)=>console.log(err))
  }
  

  const recalibrationActivity = async (value) => {
    try {
        await AsyncStorage.setItem('recalibrationActivity', value);
    } catch (error) {
        console.error(error)
    }
  }
  choiceActivity ? recalibrationActivity(choiceActivity) : console.log("not choiceActivity");


  return(
    <NativeBaseProvider flex={1} style={{fontFamily:"sans-serif"}}>
      <View style={styles.picker_container}>
          <Picker style={styles.picker_component}
              selectedValue={selectedLanguage}
              onValueChange={(itemValue, itemIndex) =>{
                setSelectedLanguage(itemValue)
                route.params.translationFunc(selectedLanguage)
              }}>
              {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
          </Picker>
        </View>
      <Box style={{justifyContent: "space-evenly"}}>
        {mdnsservice !== "" &&<Text style={{marginLeft: 20, marginTop: 10}}>{translate('master_battery_level')} : {mdnsservice['txt']['battery']} % </Text>}
        <Center>
          {displayCalibrationState === "0" ?<Heading style={{textAlign:"center", marginHorizontal:"2%", marginTop:height*0.2, color:"#00d5f7", fontSize:isTablet? 32 : 18}}>
            {translate('session_calibration_message')}
          </Heading>:
          <Heading style={{textAlign:"center", marginHorizontal:"2%", marginTop:height*0.2, color:"#00d5f7", fontSize:isTablet? 32 : 18}}>
            {translate('session_start_message')}
          </Heading>}
          { offline !== "" && <Text textAlign="center" mt="20px" mb="15px" color="#32a852" fontSize="xl">
            {offline}           
          </Text>}
        </Center>
        { (responseSpeed && responseSpeed < 60000) && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.97)},{borderRadius: 15}]}>
          <Text style={styles.information_text}>
          {translate('medium_wifi_network_speed')}
          </Text>
        </View>}
        <Center flexDirection={(width>midBreakpoint)? "row" : "column"} justifyContent={"space-around"}>
        {displayCalibrationState === "0" ? <TouchableOpacity
            style={styles.button}
            onPress={() => {
              navigation.navigate('DeviceMesure', {activity, sessionList, access_token, runner, execCreateFile, displayDataprocessValue, choiceActivity});
            }}
            >
            <Text style={{fontSize: isTablet? 18 : 15}}>{translate('calibration_name')}</Text>
          </TouchableOpacity>:
          <Center flexDirection={(width>midBreakpoint)? "row" : "column"} justifyContent={"space-around"}>
            <TouchableOpacity
              style={[styles.button,{marginHorizontal:isTablet? 28 : 0}]}
              onPress={() => {
                  navigation.navigate('RetrieveSession', {activity, sessionList, access_token, runner, choiceActivity});
              }}
            >
              <Text style={{fontSize: isTablet? 18 : 15}}>{translate('session_start')}</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
              style={[styles.button, {marginHorizontal:isTablet? 28 : 0}]}
              onPress={() => {
                  navigation.navigate('RetrieveSession', {activity, sessionList, access_token, runner, choiceActivity, label:"Quaternions"});
              }}
            >
              <Text style={{fontSize: isTablet? 18 : 15}}>Session de quaternions</Text>
            </TouchableOpacity> */}
          </Center>}
        </Center>
      </Box>
    </NativeBaseProvider>
  );
}


export default SessionCalibration;

const styles = StyleSheet.create({
  picker_component:{
    flex:1,
    borderRadius: 4,
    width: 150,
    marginRight: 30,
    justifyContent:'flex-end'
  },
  picker_container:{
    width: "100%",
    alignItems: "flex-end",
    height: 30,
  },
  button:{
    marginTop: 100,
    width: 250,
    height: 45,
    borderRadius: 30,
    backgroundColor:"#00d5f7",
    justifyContent: "center",
    alignItems: "center"
  },
  information_container:{ 
    borderWidth:1,
    alignSelf:"center",
    flex: 1,
    borderColor: '#00d5f7',
    borderRadius: 20,
    backgroundColor: '#FFF',
    position: 'absolute',
    top :0,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, 
  },
  information_text:{
    textAlign:"justify", 
    padding: 5, 
    paddingHorizontal: 15,
    color:"black"
  },
})
