import React, {useEffect, useState } from 'react';
import { StyleSheet, View, Text, Image, NativeModules, ToastAndroid, Alert, Platform, TouchableOpacity, useWindowDimensions, Linking} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from "@react-native-community/netinfo";

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import translate from '../i18n/translate';

const { CollectData } = NativeModules;

// Paramaters Button and display when clicked, needs to take all the width of the screen.
// POSSIBLE IMPROVEMENT : when navigation is launched from the page itself, displayParameters should be set back to "false".
const Parameters = ({navigation, route}) => {
    const [displayParameters, setDisplayParameters] = useState(false);

    const { height, width } = useWindowDimensions();

    // Function checking whether we can go to the configuration screen
    // ISSUE : Alerts may interfere with the i18n and translation for the messages, it may be interesting to modify them.
    //         For example, displaying a message above the parameters with the necessary information.
    const canGoToConfig = () => {
        // Avoid "self-navigation"
        if(route.name === "DeviceConfig"){
            Alert.alert(
                "Attention !",
                "Vous êtes déjà sur l'écran de configuration.",
                [
                    {
                        text: "OK",
                        style: "cancel"
                    },
                ]
            )
        // We cannot perform a configuration if we are not logged in
        }else if(route.name === "Login" || route.name === "Signup"){
            Alert.alert(
                "Attention !",
                "Vous devez être connecté afin de réaliser une configuration.",
                [
                    {
                        text: "OK",
                        style: "cancel"
                    },
                ]
            )
        // We cannot perform a configuration in the midst of an exercise
        // (It would not be logical since the Master must be connected for an exercise to be performed)
        }else if(route.name === "DeviceMesure" || route.name === "RetrieveSession" || route.name === "SessionCalibration" || route.name === "UnitySession"){
            Alert.alert(
                "Attention !",
                "Vous ne pouvez pas réaliser de configuration lors d'un exercice ou d'une calibration.",
                [
                    {
                        text: "OK",
                        style: "cancel"
                    },
                ]
            )
        }else{
            Alert.alert(
            "Confirmation Nécessaire",
            "Voulez-vous effectuer une nouvelle configuration du Master ?",
            [
                {
                    text: "Annuler",
                    style: "cancel"
                },
                { 
                    text: "Poursuivre", 
                    onPress: () => {
                        const unsubscribeHandler = NetInfo.addEventListener(state => {
                            NetInfo.fetch("wifi").then(state => {
                                if(+(state.details.frequency.toString().substring(0, 1)) === 5){
                                    ToastAndroid.showWithGravity(
                                        "Votre Wifi fonctionne actuellement à 5 GHz: configurez le à 2,4 GHz. Merci.",
                                        ToastAndroid.LONG,
                                        ToastAndroid.CENTER
                                    );
                                    setDisplayParameters(false);
                                    unsubscribeHandler();
                                }else{
                                    setDisplayParameters(false);
                                    unsubscribeHandler();
                                    navigation.navigate("DeviceConfig", {"previous_params": route.params, "previous_page": route.name});
                                }
                                
                            });
                        });
                    }
                }
            ])
        }
    }

    const storeCalibrationState = async (value) => {
        try {
          await AsyncStorage.setItem('displayCalibrationButton', value)
        } catch (error) {
          console.error(error)
        }
    }
    
    const dataprocessRecalibrationValue = async (value) => {
        try {
            await AsyncStorage.setItem('dataprocessRecalibration', value)
        } catch (error) {
            console.error(error)
        }
    }

    const recalibrationDevice = ()=>{
        CollectData.ConnectionStatus()
        .then(response => {
            if(response === 1){
                storeCalibrationState("0");
                dataprocessRecalibrationValue("dataprocessingAgain");
                const {activity, sessionList, access_token, runner, choiceActivity} = route.params;
                navigation.navigate('SessionCalibration', {activity, sessionList, access_token, runner});
            }else{
                ToastAndroid.showWithGravity(
                    "Vous êtes déconnecté, veuillez appuyer sur le bouton marche ou course sur la page précédente.",
                    ToastAndroid.LONG,
                    ToastAndroid.CENTER
                );
            }   
        })
        .catch(error => console.error(error));
    }
    const closeSocket = ()=>{
        CollectData.CloseAll()
        .then(response => {
            if(response === "Success"){
                console.log("Socket has been successfully closed.");
            }else{
                console.log("An error occured while trying to close socket.", response);
              }
        })
        .catch(error => console.error(error));
    }

    const editPathology = ()=>{
        const { public_id, access_token } = route.params;
        setDisplayParameters(false);
        navigation.navigate("RunnersList", {public_id, access_token,"addPathology": "addPathology"});
    }

    return(
        Platform.OS === 'android' && <View style={displayParameters? {width: "100%", height: 200, backgroundColor: "#00d5f7"} : {}}>
            {!displayParameters && <TouchableOpacity
                style={{marginTop:displayParameters? 12 : 0, alignSelf:"flex-end"}}
                onPress={() => setDisplayParameters(true)}
                >
                <Image style={{height:32, width: 32, alignSelf:'center'}} source={require('../images/png/logo_param.png')}/>
            </TouchableOpacity>}

            {displayParameters && <View style={{paddingLeft:"5%"}}>
                <View style={styles.title}>
                    <Text style={{fontSize:18, fontWeight: "bold", color:"white"}}>{translate('settings')}</Text>
                </View>
                <View style={styles.window}>
                    <Text style={styles.whiteText}>{translate('app_version')}</Text>
                    <TouchableOpacity onPress={() => Linking.openURL("https://mysmartmove.fr/")}>
                        <Text style={styles.whiteUnderlinedText}>{translate('web_site')}</Text>
                    </TouchableOpacity>
                    {(route.name !== "Login" && route.name != "Signup") && <TouchableOpacity onPress={() => canGoToConfig()}>
                        <Text style={styles.whiteUnderlinedText}>{translate('start_new_config')}</Text>
                    </TouchableOpacity>}
                    {((route.name === "SessionResults")) && <TouchableOpacity onPress={() => {recalibrationDevice()}}>
                        <Text style={styles.whiteUnderlinedText}>{translate('start_new_calibration')}</Text>
                    </TouchableOpacity>}
                    {((route.name === "Runner")) && <TouchableOpacity onPress={() => {closeSocket()}}>
                        <Text style={styles.whiteUnderlinedText}>{translate('reactivate_master_communication')}</Text>
                    </TouchableOpacity>}
                    {((route.name === "RunnersList")) && <TouchableOpacity onPress={() => editPathology()}>
                        <Text style={styles.whiteUnderlinedText}>{translate('add_pathologie')}</Text>
                    </TouchableOpacity>}
                </View>
                <TouchableOpacity style={{position:"absolute", right:0, top: "8%"}} onPress={() => setDisplayParameters(false)} >
                    <Text style={{color:"white", fontWeight:"bold", fontSize:18}}>⤶ {translate('go_Back')}</Text>
                </TouchableOpacity>
            </View>}
        </View>
    );
}

const styles = StyleSheet.create({
    window:{
        position: "relative",
        alignSelf:"center",
        alignItems:"center",
        width:"100%",
        height:"85%",
    },
    title:{
        position:"relative",
        marginTop:14,
        marginBottom: 5
    },
    whiteText:{
        color: "white",
        paddingBottom: 8,
        fontSize: 18
    },
    whiteUnderlinedText:{
        color: "white",
        borderBottomColor: "white",
        borderBottomWidth: 1,
        marginBottom: 10,
        fontSize: 18.5
    }
});

export default Parameters;