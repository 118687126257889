import React, { useState,useEffect } from 'react';
import { NativeBaseProvider, Stack, ScrollView, Center, Heading} from 'native-base';
import { View, StyleSheet, useWindowDimensions, Platform, TouchableOpacity, Alert, Image, Text, TextInput} from 'react-native';
// import Pdf from 'react-native-pdf';
// import * as Print from 'expo-print';
// import Share from 'react-native-share';


// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import translate from '../i18n/translate';
import { titleCase } from '../src/utils.js';


const PdfComparedSessionsParams = ({route, navigation}) => {
   
    const {asArrayResults, checkedChoose, runner } = route.params;

    const [uriCall, setUriCall] = useState(null);
    const [affiche, setAffiche] = useState(false);
    const [addComment, setAddComment] = useState('');
    const [textComment, onChangeText] = useState("");

    const [locale, setLocale]= useState(LOCALES.FRENCH)
    const setEnglishlanguages = (lang) =>{
      lang == 'Français' ? setLocale(LOCALES.ENGLISH): setLocale(LOCALES.FRENCH)  
    }

    const date       = new Date();
    const options    = {year: "numeric", month: "numeric", day: "numeric"};
    const dateString = new Intl.DateTimeFormat("fr-fr",options).format(date);
    const yeardateString = new Intl.DateTimeFormat("fr-fr",{year: "numeric"}).format(date);

    const largeBreakpoint = 992;
    const midBreakpoint = 688;
    const {width, height} = useWindowDimensions();
    const isLandscape = width > height;
    const landscape = width < height;
    const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
    const isHorizontalDisplay = isLandscape && height<midBreakpoint;
    const graphHeight = isHorizontalDisplay? height*0.30 : (height >= midBreakpoint)? 116 : height/7;
    const fontSizeText = (width>= midBreakpoint)? (width >= largeBreakpoint)? "24.5px" : "22.2px" : "22.4px";
    const fontProfile = (width>= midBreakpoint)? (width >= largeBreakpoint)? "29px" : "32px" : "52px";
    const fontName = (width>= midBreakpoint)? (width >= largeBreakpoint)? "25px" : (landscape ? "24" : "30") : "39.7px";
    const fontTitle = (width>= midBreakpoint)? (width >= largeBreakpoint)? "28px" : "28px" : "40px";
    const fontLabel = (width>= midBreakpoint)? (width >= largeBreakpoint)? "35px" : "35px" : "40px";
    const cardWidth = (width>= midBreakpoint)? (width >= largeBreakpoint)? width : width : 690;
    const marginWidth = (width>= midBreakpoint)? (width >= largeBreakpoint)? "0.5%" : "4%" : "0.5%";
    const isWideEnough = (width>= midBreakpoint)? (width >= largeBreakpoint)? "40%" : "50%" : "50%";
    const incContainerStyle_hip = isTablet ? "display: inline-block; display: flex;height: 100%; justify-content: space-between; width: 100%;": "display: inline-block; height: 100%; width: 100%;";



    const spatioTemporalFirstBar = (data)=>{
        let recHtml='';
        let x= (isTablet ? (landscape ? 5 : 20) : 20);
        data.forEach((L, index)=>{
            console.log("index1:: :: ", index);
            recHtml += `<rect x="${x}" y="${(isTablet ? (landscape ? 612 : 723) : 923) - (L<0? 200: (L>400? 200: L))}" width="${isTablet? (landscape ? 90 : 140) : 140}" height="${(L<0? 150: (L>400? 200: L))}" rx="12" ry="12" id="${index===0 ? 'firstRectHtml':'secondRectHtml'}"/>`
            x += x + (isTablet ? (landscape ? 110 : 173) : 180);
        }
        )
        return recHtml;
    }
    const spatioTemporalSecondBar = (data)=>{
        let recHtml='';
        let x= isTablet ? (landscape ? 260 : 430) : 478;
        data.forEach((L, index)=>{
            console.log("index2:: :: ", index);
            recHtml += `<rect x="${x}" y="${(isTablet ? (landscape ? 612 : 723) : 923) - (L<0? 200: (L>400? 200: L))}" width="${isTablet? (landscape ? 90 : 140) : 140}" height="${(L<0? 150: (L>400? 200: L))}" rx="12" ry="12" id="${index===0 ? 'firstRectHtml':'secondRectHtml'}"/>`
            x += x - (isTablet ? (landscape ? 145 : 240) : 280);
        }
        )
        return recHtml;
    }

    const svgBar = (checkedChoosing)=>{
        let svgHtml='';
        asArrayResults.forEach(el=>{
            svgHtml += `<svg width="${isTablet? (landscape ? "48.5%" : 780) : '99%'}" height="${isTablet? (landscape? '40%' : '80%') : '95%'}" style=" border: 3px solid #dbdad5; margin:  0px ${isTablet? (landscape? '5px' : '10px') : '0px'} ${isTablet? (landscape? '170px' : '200px') : '44px'} 0px;">
                ${spatioTemporalFirstBar([2*Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*((checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))),2*Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'?((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))]) + spatioTemporalSecondBar([2*Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr))),2*Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.right_vlr)))])}
                <text x="${(Platform.OS === 'android') ? (isTablet ? (landscape? '10' : '50') : "37"):'50'}" y="${(isTablet ? (landscape? 590 : 700) : 900) - 2*Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" id="textHtml" style="font-size: ${fontName};"> ${Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))} ${checkedChoosing === 'spatioTemporal'? '%':'BW'} </text>
                <text x="${(Platform.OS === 'android') ? ((checkedChoosing === 'spatioTemporal') ? (isTablet ? (landscape? '130' : '250') : "250"):(isTablet ? (landscape? '120' : '240') : "240")):'250'}" y="${(isTablet ? (landscape? 590 : 700) : 900) - 2*Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))}" id="textHtml" style="font-size: ${fontName};"> ${Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))} ${checkedChoosing === 'spatioTemporal'? '%':'BW'} </text>
                <text x="${(Platform.OS === 'android') ? ((checkedChoosing === 'spatioTemporal') ? (isTablet ? (landscape? '265' : '465') : "500"):(isTablet ? (landscape? '245' : '450') : "480")):'480'}" y="${((checkedChoosing === 'spatioTemporal') ? (el.stride_parameters.stride_length < 0 ? (isTablet ? (landscape? 295 : 395) : 90): (isTablet ? (landscape? 590 : 700) : 900)):(isTablet ? (landscape? 590 : 700) : 900)) - 2*Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_length <0? 0 : el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr)))}" id="textHtml" style="font-size: ${fontName};"> ${Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr)))} ${checkedChoosing === 'spatioTemporal'? '%':'BW/s'} </text>
                <text x="${(Platform.OS === 'android') ? ((checkedChoosing === 'spatioTemporal') ? (isTablet ? (landscape? '380' : '655') : "707"):(isTablet ? (landscape? '360' : '650') : "670")):"740"}" y="${(isTablet ? (landscape? 590 : 700) : 900) - 2*Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.right_step_length <0 ? 0 : el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.right_vlr)))}" id="textHtml" style="font-size: ${fontName};"> ${Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? (100*((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)) >100 ? 1 : ((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length))):(el.stride_parameters.right_vlr)))} ${checkedChoosing === 'spatioTemporal'? "%":"BW/s"}</text>
                <text x="${(Platform.OS === 'android') ?(isTablet ? (landscape? '30' : '55') : "55"):"55"}" y="${120 - Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" style="font-size: ${fontName};" id="textHtml">Date</text>
                <text x="${(Platform.OS === 'android') ?(isTablet ? (landscape? '20' : '30') : "30"):"55"}" y="${180 - Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" style="font-size: ${fontName};" id="textHtml"> ${new Intl.DateTimeFormat("fr-fr",{year: "numeric", month: "numeric", day: "numeric"}).format(new Date(Date.parse(el.date)))} </text>
                <text x="${(Platform.OS === 'android') ?(isTablet ? (landscape? '370' : '620') : "600"):"260"}" y="${120 - Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" style="font-size: ${fontName};" id="textHtml">Horaire</text>
                <text x="${(Platform.OS === 'android') ?(isTablet ? (landscape? '360' : '620') : "590"):"260"}" y="${180 - Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" style="font-size: ${fontName};" id="textHtml"> ${el.date.split(' ')[4]} </text>
                <text x="${(Platform.OS === 'android') ?((checkedChoosing === 'spatioTemporal') ? (isTablet ? (landscape? '230' : '495') : "430"):(isTablet ? (landscape? '360' : '590') : "590")):"260"}" y="${280 - Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" style="font-size: ${fontName};" id="textHtml">${checkedChoosing === 'spatioTemporal'?  "Longueur de la foulée":" Amorti"}</text>
                ${checkedChoosing === 'spatioTemporal'?`<text x="590" y="${340 - Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" style="font-size: ${fontName};" id="textHtml">${(el.stride_parameters.stride_length).toFixed(2).toString().replace('.', ',')} m</text>`:`<text></text>`}
                <text x="${(Platform.OS === 'android') ?(isTablet ? (landscape? '10' : '25') : "25"):"75"}" y="${280 - Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" style="font-size: ${fontName};" id="textHtml">${checkedChoosing === 'spatioTemporal'? "Durée de la foulée":"Force d'impact au sol"}</text>
                ${checkedChoosing === 'spatioTemporal' ? `<text x="55" y="${340 - Math.round((checkedChoosing === 'spatioTemporal'? 100:1)*(checkedChoosing === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))}" style="font-size: ${fontName};" id="textHtml">${(el.stride_parameters.stride_duration).toFixed(2).toString().replace('.', ',')} s</text>`:`<text></text>`}           
            </svg>`;
        })
        return svgHtml;
    
      }
    


    const html1 = `
        <html>
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=yes" />
                <style>
                    #firstRectHtml{
                        fill:#F57F17C0;
                    }
                    #secondRectHtml{
                        fill:#00d5f7A0;
                    }
                    #textHtml{
                        fill: black;
                        font-weight: bold;
                    }
                    
                </style>
            </head>
            <body style="width: 100vw; height:100vh; font-family: Helvetica Neue; font-weight: bold; margin: 0px 0px 0px 0px; padding:0px 0px 0px 0px;">
            ${isTablet ? 
                `<div style="width: 100%; height:100%; margin: 0px 0px 0px 0px; padding:0px 0px 0px 0px; ">
                    <div style="font-weight: bold; overflow-wrap: break-word; width: 100%; height:${923}; margin: 0px 0px 300px 0px; padding:0px 0px 0px 0px; ">
                        <h1 style="font-size: ${fontProfile}; text-align: center; margin: 0px 0px 40px auto; border-bottom: 3px solid #dbdad5;">
                            Bilan biomécanique
                        </h1>
                        <div style="display: flex;">
                            <div style="width: 70%;">
                                <h2 style=" margin: 0px 0px 100px 40px; font-size: ${fontProfile}; border-bottom: 3px solid #dbdad5;">
                                Profil
                                </h2>
                                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                                <strong>Bilan délivré le</strong> ${dateString}
                                </p><br>
                                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                                    <strong>Nom(s)</strong> : ${titleCase(runner.first_name)}
                                </p><br>
                                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                                    <strong>Prénom(s)</strong> : ${titleCase(runner.last_name)}
                                </p><br><br><br><br>
                                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName}; width: ${isTablet ? (landscape? '700px' : '70%') : '800px'}">
                                    <strong>Mesure des paramètres</strong>${checkedChoose === 'spatioTemporal'?" spatio-temporels.":checkedChoose === 'kinetic'? " cinétiques.":" spatio-temporels et cinétiques."}
                                </p>
                            </div>
                            <div style="width: 30%;">
                                <svg width="${isTablet? (landscape? '80%' : '48%') : '97%'}" height="143" style="border: 3px solid #dbdad5;">
                                    <rect x="20" y="20" rx="12" ry="12" width="70" height="40" id="firstRectHtml"/>
                                    <rect x="20" y="80" rx="12" ry="12" width="70" height="40" id="secondRectHtml"/>
                                    <text x="100" y="45" style="font-size: ${fontLabel};" >gauche</text>
                                    <text x="100" y="107" style="font-size: ${fontLabel};" >droite</text>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div style="width: 98%; align-items: center; display: inline-block; height: 98%; justify-content: space-between; margin: 0px 0px 0px 0px; padding:0px 0px 0px 0px;">  
                        ${checkedChoose === 'spatioKinetic'?`<div style="width: 100%; margin: 0px 0px 0px 0px; padding:0px 0px 0px 0px; ">
                            <div>
                                ${svgBar("spatioTemporal")}
                            </div> 
                            <svg width="${isTablet? (landscape ? "80%" : '70%') : '99%'}" height="${isTablet? (landscape? '40%' : '80%') : '95%'}" style=" border: 3px solid #FFF; margin:  0px ${isTablet? (landscape? '5px' : '10px') : '0px'} ${isTablet? (landscape? '170px' : '200px') : '44px'} 0px;">
                                <text x=${(landscape ? "300" : "650")} y="400" style="font-size: ${fontLabel};" >Les paramètres cinétiques</text>
                            </svg>
                            <div>
                                ${svgBar("kinetic")}
                            </div> 
                        </div>`:
                        `<div> 
                            ${svgBar(checkedChoose)} 
                        </div>`}
                        ${textComment ?`<div style="overflow-wrap: break-word; width: 100%; height:${isTablet? (landscape? '40%' : '80%') : 923}; margin: 0px 0px 300px 0px; padding:0px 0px 0px 0px; ">
                            <p style="font-size: ${fontName}; text-align: justify;"> &nbsp;&nbsp;Commentaire(s) :<br> ${textComment}</p>
                        </div>`:`<p></p>`}
                        <div style=" overflow-wrap: break-word; width: 100%; height:${isTablet? (landscape? '40%' : '80%') : 923}; margin: 0px 0px 300px 0px; padding:0px 0px 0px 0px; ">
                            <h1 style="text-align: justify; font-size: ${fontName};"> ${checkedChoose === 'spatioTemporal'? "&nbsp;Durée de la foulée : durée d'une enjambée, entre deux poses successives du pied droit au sol (somme des durées des pas droit et gauche).<br><br>&nbsp;Longueur de la foulée : longueur d'une enjambée, entre deux poses successives du pied droit au sol (somme des longueurs des pas droit et gauche).<br><br>&nbsp;Les valeurs sur les histogrammes représentent les proportions de la durée du pas gauche par rapport à la durée de la foulée et la durée de pas droit par rapport à la durée de la foulée; ainsi que les proportions de la longueur du pas gauche par rapport à la longueur de la foulée et la longueur de pas droit par rapport à la longueur de la foulée.": 
                            checkedChoose === 'spatioKinetic'? "&nbsp;Durée de la foulée : durée d'une enjambée, entre deux poses successives du pied droit au sol (somme des durées des pas droit et gauche).<br><br>&nbsp;Longueur de la foulée : longueur d'une enjambée, entre deux poses successives du pied droit au sol (somme des longueurs des pas droit et gauche).<br><br>&nbsp;Les valeurs sur les histogrammes représentent les proportions de la durée du pas gauche par rapport à la durée de la foulée et la durée de pas droit par rapport à la durée de la foulée; ainsi que les proportions de la longueur du pas gauche par rapport à la longueur de la foulée et la longueur de pas droit par rapport à la longueur de la foulée.<br><br><br><br>&nbsp; Force d'impact au sol : estimation, sur la base de l'accélération des tibias, des forces d'impacts maximales au sol au moment des poses du pied à droite et gauche exprimée en Newton (accélération x poids du sujet).<br><br>&nbsp;Amorti : estimation, sur la base de l'accélération des tibias, de la capacité d'amorti du sujet (aussi appelé vertical loading rate), à droite et à gauche. Il s'agit de la mesure de la pente de la courbe de force lors de l'impact au sol, par division de la force d'impact maximale par le temps entre le début de l'impact et ce maximum, exprimée en « body weight » par seconde (nombre de fois le poids du sujet appliqué au sol par seconde)." : 
                            "&nbsp;Force d'impact au sol : estimation, sur la base de l'accélération des tibias, des forces d'impacts maximales au sol au moment des poses du pied à droite et gauche exprimée en Newton (accélération x poids du sujet).<br><br>&nbsp;Amorti : estimation, sur la base de l'accélération des tibias, de la capacité d'amorti du sujet (aussi appelé vertical loading rate), à droite et à gauche. Il s'agit de la mesure de la pente de la courbe de force lors de l'impact au sol, par division de la force d'impact maximale par le temps entre le début de l'impact et ce maximum, exprimée en « body weight » par seconde (nombre de fois le poids du sujet appliqué au sol par seconde)."}</h1>
                            <p style="font-size: 22; text-align: justify; text-align: center;">&copy; mySmartmove ${yeardateString}</p>
                        </div>
                    </div>
                    
                </div>`:
                `<div style="width: 100%; height:100%; margin: 0px 0px 0px 0px; padding:0px 0px 0px 0px; ">
                    <div style="font-weight: bold; overflow-wrap: break-word; width: 100%; height:${930}; margin: 0px 0px 300px 0px; padding:0px 0px 0px 0px; ">
                        <h1 style="font-size: ${fontProfile}; text-align: center; margin: 0px 0px 40px auto; border-bottom: 3px solid #dbdad5;">
                            Bilan biomécanique
                        </h1>
                        <div style="display: flex;">
                            <div style="width: 70%;">
                                <h2 style=" margin: 0px 0px 100px 40px; font-size: ${fontProfile}; border-bottom: 3px solid #dbdad5;">
                                Profil
                                </h2>
                                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                                <strong>Bilan délivré le</strong> ${dateString}
                                </p><br>
                                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                                    <strong>Nom(s)</strong> : ${titleCase(runner.first_name)}
                                </p><br>
                                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                                    <strong>Prénom(s)</strong> : ${titleCase(runner.last_name)}
                                </p><br><br><br><br>
                                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName}; width: ${isTablet ? '70%' : '800px'}">
                                    <strong>Mesure des paramètres</strong>${checkedChoose === 'spatioTemporal'?" spatio-temporels.":checkedChoose === 'kinetic'? " cinétiques.":" spatio-temporels et cinétiques."}
                                </p>
                            </div>
                            <div style="width: 30%;">
                                <svg width="${isTablet? (isHorizontalDisplay? '48%' : '48%') : '97%'}" height="143" style="border: 3px solid #dbdad5;">
                                    <rect x="20" y="20" rx="12" ry="12" width="70" height="40" id="firstRectHtml"/>
                                    <rect x="20" y="80" rx="12" ry="12" width="70" height="40" id="secondRectHtml"/>
                                    <text x="100" y="45" style="font-size: ${fontLabel};" >gauche</text>
                                    <text x="100" y="107" style="font-size: ${fontLabel};" >droite</text>
                                </svg>
                            </div>
                        </div>
                    </div>
                    ${checkedChoose === 'spatioKinetic'?`<div style="width: 100%; margin: 0px 0px 0px 0px; padding:0px 0px 0px 0px; ">
                        <div>
                            ${svgBar("spatioTemporal")}
                        </div> 
                        <svg width="${isTablet? (landscape ? "48.5%" : 780) : '99%'}" height="${isTablet? (landscape? '40%' : '80%') : '95%'}" style=" border: 3px solid #FFF; margin:  0px ${isTablet? (landscape? '5px' : '10px') : '0px'} ${isTablet? (landscape? '170px' : '200px') : '44px'} 0px;">
                            <text x="150" y="400" style="font-size: ${fontLabel};" >Les paramètres cinétiques</text>
                        </svg>
                        <div>
                            ${svgBar("kinetic")}
                        </div> 
                    </div>`:
                    `<div> 
                        ${svgBar(checkedChoose)} 
                    </div>`}
                    ${textComment ?`<div style="overflow-wrap: break-word; width: 100%; height:${930}; margin: 0px 0px 300px 0px; padding:0px 0px 0px 0px; ">
                        <p style="font-size: ${fontName}; text-align: justify;"> &nbsp;&nbsp;Commentaire(s) :<br> ${textComment}</p>
                    </div>`:`<p></p>`}
                    <div style=" overflow-wrap: break-word; width: 100%; height:${930}; margin: 0px 0px 300px 0px; padding:0px 0px 0px 0px; ">
                        <h1 style="text-align: justify; font-size: ${fontName};"> ${checkedChoose === 'spatioTemporal'? "&nbsp;Durée de la foulée : durée d'une enjambée, entre deux poses successives du pied droit au sol (somme des durées des pas droit et gauche).<br><br>&nbsp;Longueur de la foulée : longueur d'une enjambée, entre deux poses successives du pied droit au sol (somme des longueurs des pas droit et gauche).<br><br>&nbsp;Les valeurs sur les histogrammes représentent les proportions de la durée du pas gauche par rapport à la durée de la foulée et la durée de pas droit par rapport à la durée de la foulée; ainsi que les proportions de la longueur du pas gauche par rapport à la longueur de la foulée et la longueur de pas droit par rapport à la longueur de la foulée.":
                        checkedChoose === 'spatioKinetic'? "&nbsp;Durée de la foulée : durée d'une enjambée, entre deux poses successives du pied droit au sol (somme des durées des pas droit et gauche).<br><br>&nbsp;Longueur de la foulée : longueur d'une enjambée, entre deux poses successives du pied droit au sol (somme des longueurs des pas droit et gauche).<br><br>&nbsp;Les valeurs sur les histogrammes représentent les proportions de la durée du pas gauche par rapport à la durée de la foulée et la durée de pas droit par rapport à la durée de la foulée; ainsi que les proportions de la longueur du pas gauche par rapport à la longueur de la foulée et la longueur de pas droit par rapport à la longueur de la foulée.<br><br><br><br>&nbsp; Force d'impact au sol : estimation, sur la base de l'accélération des tibias, des forces d'impacts maximales au sol au moment des poses du pied à droite et gauche exprimée en Newton (accélération x poids du sujet).<br><br>&nbsp;Amorti : estimation, sur la base de l'accélération des tibias, de la capacité d'amorti du sujet (aussi appelé vertical loading rate), à droite et à gauche. Il s'agit de la mesure de la pente de la courbe de force lors de l'impact au sol, par division de la force d'impact maximale par le temps entre le début de l'impact et ce maximum, exprimée en « body weight » par seconde (nombre de fois le poids du sujet appliqué au sol par seconde)." : 
                        "&nbsp;Force d'impact au sol : estimation, sur la base de l'accélération des tibias, des forces d'impacts maximales au sol au moment des poses du pied à droite et gauche exprimée en Newton (accélération x poids du sujet).<br><br>&nbsp;Amorti : estimation, sur la base de l'accélération des tibias, de la capacité d'amorti du sujet (aussi appelé vertical loading rate), à droite et à gauche. Il s'agit de la mesure de la pente de la courbe de force lors de l'impact au sol, par division de la force d'impact maximale par le temps entre le début de l'impact et ce maximum, exprimée en « body weight » par seconde (nombre de fois le poids du sujet appliqué au sol par seconde)."}</h1>
                        <p style="font-size: 32; text-align: justify; text-align: center;">&copy; mySmartmove ${yeardateString}</p>
                    </div>
                </div>` }
            </body>
        </html>`;

    const html3 = `
        <html>
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=yes" />
            </head>
            <body style=" font-family: Helvetica Neue; font-weight: bold;">
            ${isTablet ? 
                `<div>
                    <h1>Aucune donnée ne correspond à votre demande, veuillez réessayer </h1>
                </div>`:
                `<div>
                    <h1>Aucune donnée ne correspond à votre demande, veuillez réessayer</h1>
                </div>` }
            </body>
        </html>`;

    let dataModel = (param)=>{
        switch (param) {
            case 'spatioTemporal':
            return html1;
            case 'kinetic':
            return html1;
            case 'spatioKinetic':
            return html1;
            default:
            return html3;
        }
    }

    let printToFile = async () => {
        const html = dataModel(checkedChoose);
      // On iOS/android prints the given html. On web prints the HTML from the current page.
        const { uri } = await Print.printToFileAsync({ html, width : cardWidth, height : height, base64 : false });
        setUriCall(uri);
        setAffiche(true)
            Share.open({url:uri})
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                err && console.log(err);
        });
    };

    useEffect(() => {
        // let printToFile = async () => {
        //     const html = dataModel(checkedChoose);
        //   // On iOS/android prints the given html. On web prints the HTML from the current page.
        //   const { uri } = await Print.printToFileAsync({ html, width : cardWidth, height : height, base64 : false });
        //   setUriCall(uri);
        //   setAffiche(true)
        //     Share.open({url:uri})
        //       .then((res) => {
        //         console.log(res);
        //       })
        //       .catch((err) => {
        //         err && console.log(err);
        //       });
        // };
        // printToFile();
      }, []);


    return(
        <I18nProvider locale={locale} defaultLocale="fr-FR">
          <NativeBaseProvider>

            {affiche && <View style={{flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 1,}}>
              <Pdf
                trustAllCerts={false}
                source={{ uri: uriCall}}
                onLoadComplete={(numberOfPages,filePath) => {
                    console.log(`Number of pages: ${numberOfPages}`);
                }}
                onPageChanged={(page,numberOfPages) => {
                    console.log(`Current page: ${page}`);
                }}
                onError={(error) => {
                    console.log(error);
                }}
                onPressLink={(uri) => {
                    console.log(`Link pressed: ${uri}`);
                }}
                style={{flex:1,
                  width:width, height:height}}
              />
            </View>}
            {addComment ==="" && <Center>
                <View mt="70px" style={{width: (width>800)? width*0.50 : width*0.90, alignItems: 'center'}}>
                    <Text style={[styles.size_number_check,{fontSize: isTablet ? 20:16}]}>{translate('input_pdf_comment')}</Text>
                    <TextInput
                        multiline
                        maxLength={800} 
                        style={styles.input}
                        width={width*0.90}
                        height="50%"
                        onChangeText={onChangeText}
                        value={textComment}
                    />
                </View>
                <TouchableOpacity style={{marginLeft: "0%", width: isTablet? (landscape? '20%' : '15%') : "40%", height: isTablet? (landscape? '7%' : '10%') : 30, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
                    onPress={() => { 
                        setAddComment("OK");
                        printToFile();
                    }}
                >
                    <Heading fontSize={18} style={{ color: "#000", alignSelf: "center" }}>Générer le PDF</Heading>
                </TouchableOpacity>
            </Center>}
          </NativeBaseProvider>
        </I18nProvider>
    );
    
}
export default PdfComparedSessionsParams;

const styles = StyleSheet.create({
    size_number_check:{
        fontSize: 16,
        color: "#000",
        fontWeight: 'bold',
    },
    input: {
        marginTop: 5,
        borderWidth: 1,
        borderRadius: 4,
        borderColor:"#00d5f7"
    },
    
})