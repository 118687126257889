import React, {useState} from 'react';
import { Box, Center, Text, Image, Heading } from 'native-base';
import { BarChart } from 'react-native-svg-charts';

// intl components
import 'intl';
import translate from '../i18n/translate';
import { View, StyleSheet, TouchableOpacity, useWindowDimensions, Alert, ScrollView } from 'react-native';

// import components
import { SpatioLabelsFirst, SpatioLabelsSecond, CineticLabelsFirst, CineticLabelsSecond } from './LabelsGraph';
import { SessionMaxValueGraph } from './SessionMaxValueGraph';

// require images
const pelvisInclinaisonImage = require('../images/png/pelvis_obl.png');
const pelvisVersionImage = require('../images/png/pelvis_ver.png');
const hanchesAddImage = require('../images/png/hip_add.png');
const hanchesFlexImage = require('../images/png/hip_flex.png');
const hanchesRotImage = require('../images/png/hip_rot.png');
const genouxFlexImage = require('../images/png/knee_flex.png');
const genouxRotImage = require('../images/png/knee_rot.png');
const spatioTempImage = require('../images/png/step_time.png');
const spatioLengthImage = require('../images/png/step_length.png');
const cineticStrengthImage = require('../images/png/impact_force.png');
const cineticRateImage = require('../images/png/vertical_loading_rate.png');
const bezierCurveImage = require('../images/png/bezier_curve_icon.png');

const SessionSupportCard = ({navigation, sessionList, session, spatioTemporalCard, cineticCard, pelvisCard, hipsCard, kneesCard, sessionAngularParameters, sessionStrideParameters}) => {

    const [displayCommentInfo, setDisplayCommentInfo] = useState("");
    const [displaySpecificInfo, setDisplaySpecificInfo] = useState("");

    const { height, width } = useWindowDimensions();
    const largeBreakpoint   = 992;
    const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint)
    const midBreakpoint = 688;
    const isLandscape = width > height;
    const isWideEnough = width>600;
    const isHorizontalDisplay = isLandscape && height<midBreakpoint;
    const marginTopSize = (width <= midBreakpoint || isHorizontalDisplay)? 9 : 0;
    const maxValueLg = (width>= 1460)? 4 :(width >= largeBreakpoint)? 3: (width<midBreakpoint || isHorizontalDisplay)? 1:2;
    const messageValue = (width>= 1460)? "Vous ne pouvez afficher que quatre éléments" : (width >= largeBreakpoint)? "Vous ne pouvez afficher que trois éléments" : 
          (width<midBreakpoint || isHorizontalDisplay)? "Vous ne pouvez afficher qu'un seul élément" : "Vous ne pouvez afficher que deux éléments";
    const fontSize = (width <= midBreakpoint || isHorizontalDisplay)? 11.7 : 15;
    const imageStyle = isHorizontalDisplay? {resizeMode: 'contain', marginRight:10} : {resizeMode: 'contain'};
    const imageWidth = isHorizontalDisplay? width/8 : (height >= midBreakpoint)? Math.min(height/6, 128) : width*0.26;
    const imageHeight = isHorizontalDisplay? width/8 : (height >= midBreakpoint)? Math.min(height/6, 128) : width*0.26;
    const graphHeight = isHorizontalDisplay? height*0.30 : (height >= midBreakpoint)? 116 : height/7;
    
    const PelvisCard = () => {

        const data = [
            [
                Math.round(sessionAngularParameters.pelvis_inc_max),
                Math.round(sessionAngularParameters.pelvis_inc_min)
            ],
            [ 
                Math.round(sessionAngularParameters.pelvis_ver_max),
                Math.round(sessionAngularParameters.pelvis_ver_min),
            ]
        ]

        const colors = ['#8BC34AA0'];
        const keys = ['pelvis'];

        const _loadSessionActivityPelvis_inc = ()=>{ 
            navigation.navigate('RunnerSessionBehavior',{
              "pelvis_inc": sessionAngularParameters.params_user_list.pelvis_inc,
              "param_data_right": undefined,
              "param_data_left": undefined,
              "motion_param": "Inclinaison du bassin",
              "curve_name_right": "Inclinaison",
              "curve_name_left": "Inclinaison",
              "phase": "Phase d'appui"
            })
        }

        const _loadSessionActivityPelvis_ver = ()=>{ 
            navigation.navigate('RunnerSessionBehavior',{
              "pelvis_inc": sessionAngularParameters.params_user_list.pelvis_ver,
              "param_data_right": undefined,
              "param_data_left": undefined,
              "motion_param": "Version du bassin",
              "curve_name_right": "Version",
              "curve_name_left": "Version",
              "phase": "Phase d'appui"
            })
          }

        return(
            <View style={{borderWidth:0.2, elevation: 1, width:(isHorizontalDisplay || width<370)? "98%" : 360, marginTop:marginTopSize}}>
                <Center style={styles.Heading_container}>
                    <Text fontWeight="bold" color="white">{translate('pelvis')} ({translate('support_phase')})</Text>
                </Center>
                <View style={{justifyContent: 'space-around', margin: 3, flexDirection:isHorizontalDisplay? 'row' : 'column', paddingTop:isHorizontalDisplay? 0.05*height : 0}}>
                    <View style={styles.first_parameters}>
                        <TouchableOpacity style={{marginLeft: "0%", width: 30, height: 30, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
                            onPress={() => { 
                            if(displayCommentInfo !==""){
                                setDisplayCommentInfo("");
                            }else{
                                setDisplayCommentInfo(translate('pelvis_information'));
                                setDisplaySpecificInfo("PelvisCard");
                            }              
                            }}>
                            <Heading fontSize={18} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
                        </TouchableOpacity>
                        <View style={{width: '40%'}}>
                            <Image
                                style={imageStyle}
                                w={imageWidth}
                                h={imageHeight}   
                                source={ pelvisInclinaisonImage }
                                alt="image inclinaison du bassin"
                            />
                            <Text fontWeight="bold" style={styles.text_container} fontSize={fontSize}>{translate('tilt')}</Text>
                        </View>
                        <View style={{width: '50%'}}>
                            <TouchableOpacity onPress={() => _loadSessionActivityPelvis_inc()}>
                                <SessionMaxValueGraph data={data} 
                                    indexes={[0]}
                                    colors={colors}
                                    keys={keys}
                                    graphWidth={isHorizontalDisplay? width/6 : 120}
                                    graphHeight={graphHeight}
                                    isVertical={true}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.first_parameters}>
                        <View style={{width: '50%'}}>
                            <Image
                                style={imageStyle}
                                w={imageWidth}
                                h={imageHeight}   
                                source={ pelvisVersionImage }
                                alt="image version du bassin"
                            />
                            <Text fontWeight="bold" style={styles.text_container} fontSize={fontSize}>{translate('version')}</Text>
                        </View>
                        <View style={{width: '50%'}}>
                            <TouchableOpacity onPress={() => _loadSessionActivityPelvis_ver()}>
                                <SessionMaxValueGraph data={data} 
                                    indexes={[1]}
                                    colors={colors}
                                    keys={keys}
                                    graphWidth={isHorizontalDisplay? width/6 : 120}
                                    graphHeight={graphHeight}
                                    isVertical={true}
                                />
                            </TouchableOpacity>
                        </View>
                    </View> 
                </View>
                {(displayCommentInfo !=="" && displaySpecificInfo ==="PelvisCard") &&<View style={[styles.information_container, {width: isWideEnough? isHorizontalDisplay ? 410 : 390 : (width*0.8), height:"91%"},{borderRadius: 15}]}>
                    <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
                        <Text style={[styles.information_text, {fontSize:fontSize}]}>
                        {displayCommentInfo}
                        </Text>
                        <TouchableOpacity
                            style={styles.button}
                            onPress={() => {
                            setDisplayCommentInfo("");
                            }}>
                        <Text style={[styles.button_text, {fontSize:fontSize}]}>{translate('go_Back')}</Text>
                        </TouchableOpacity>
                    </ScrollView>  
                </View>}
            </View>
        );
      }
      const HipsCard = () => {

        const data = [
            [
              Math.round(sessionAngularParameters.lhip_add_max),
              Math.round(sessionAngularParameters.lhip_add_min), 
            ],
            [       
              Math.round(sessionAngularParameters.rhip_add_max),
              Math.round(sessionAngularParameters.rhip_add_min),    
            ],   
            [
              Math.round(sessionAngularParameters.lhip_flex_max),
              Math.round(sessionAngularParameters.lhip_flex_min), 
            ],         
            [
              Math.round(sessionAngularParameters.rhip_flex_max),
              Math.round(sessionAngularParameters.rhip_flex_min), 
            ],
            [
              Math.round(sessionAngularParameters.lhip_rot_max),
              Math.round(sessionAngularParameters.lhip_rot_min),
            ],
            [
              Math.round(sessionAngularParameters.rhip_rot_max),
              Math.round(sessionAngularParameters.rhip_rot_min),
            ],
        ]
        
        const colors = ['#00d5f7A0', '#F57F17C0'];
        const keys = ['left_hip', 'right_hip'];

        const _loadSessionActivityHip_add = ()=>{ 
            navigation.navigate('RunnerSessionBehavior',{
              "param_data_right": sessionAngularParameters.params_user_list.rhip_add,
              "param_data_left": sessionAngularParameters.params_user_list.lhip_add,
              "motion_param": "L'addiction des hanches",
              "curve_name_right": "Droite",
              "curve_name_left": "Gauche",
              "phase": "Phase d'appui"
            })
        }
        const _loadSessionActivityHip_flex = ()=>{ 
            navigation.navigate('RunnerSessionBehavior',{
              "param_data_right": sessionAngularParameters.params_user_list.rhip_flex,
              "param_data_left": sessionAngularParameters.params_user_list.lhip_flex,
              "motion_param": "La flexion des hanches",
              "curve_name_right": "Droite",
              "curve_name_left": "Gauche",
              "phase": "Phase d'appui"
            })
        }
        const _loadSessionActivityHip_rot = ()=>{ 
            navigation.navigate('RunnerSessionBehavior',{
              "param_data_right": sessionAngularParameters.params_user_list.rhip_rot,
              "param_data_left": sessionAngularParameters.params_user_list.lhip_rot,
              "motion_param": "La rotation des hanches",
              "curve_name_right": "Droite",
              "curve_name_left": "Gauche",
              "phase": "Phase d'appui"
            })
          }

        return(
            <View style={{borderWidth:0.2, elevation: 1, width:(isHorizontalDisplay || width<370)? "98%" : 360, marginTop:marginTopSize}}>
                <Center style={styles.Heading_container}>
                    <Text fontWeight="bold" color="white">{translate('hips')} ({translate('support_phase')})</Text>
                </Center>
                <View style={{justifyContent: 'space-around', margin: 3, flexDirection:isHorizontalDisplay? 'row' : 'column', paddingTop:isHorizontalDisplay? 0.05*height : 0}}>
                    <View style={styles.first_parameters}>
                        <TouchableOpacity style={{marginLeft: "0%", width: 30, height: 30, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
                            onPress={() => {
                                if(displayCommentInfo !==""){
                                setDisplayCommentInfo("");
                                }else {
                                setDisplayCommentInfo(translate('hip_information'));
                                setDisplaySpecificInfo("HipsCard");
                                } 
                                
                            }}>
                            <Heading fontSize={18} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
                        </TouchableOpacity>
                        <View style={{width: '40%'}}>
                            <Image
                                style={imageStyle}
                                w={imageWidth}
                                h={imageHeight} 
                                source={ hanchesAddImage }
                                alt="image addiction des hanches"
                            />
                            <Text fontWeight="bold" style={styles.text_container} fontSize={fontSize}>Abd./Add.</Text>
                        </View>
                        <TouchableOpacity onPress={() => _loadSessionActivityHip_add()} style={{width: '50%'}}>
                            <SessionMaxValueGraph data={data} 
                                indexes={[0,1]}
                                colors={colors}
                                keys={keys}
                                graphWidth={isHorizontalDisplay? width/8 : 180}
                                graphHeight={graphHeight}
                                isVertical={false}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.first_parameters}>
                        <View style={{width: '50%'}}>
                            <Image
                                style={imageStyle}
                                w={imageWidth}
                                h={imageHeight}  
                                source={ hanchesFlexImage }
                                alt="image flexion des hanches"
                            />
                            <Text fontWeight="bold" style={styles.text_container} fontSize={fontSize}>Ext./Flex.</Text>
                        </View>
                        <TouchableOpacity onPress={() => _loadSessionActivityHip_flex()} style={{width: '50%'}}>
                            <SessionMaxValueGraph data={data} 
                                indexes={[2,3]}
                                colors={colors}
                                keys={keys}
                                graphWidth={isHorizontalDisplay? width/8 : 180}
                                graphHeight={graphHeight}
                                isVertical={false}
                            />
                        </TouchableOpacity>
                    </View> 
                    <View style={styles.first_parameters}>
                        <View style={{width: '50%'}}>
                            <Image
                                style={imageStyle}
                                w={imageWidth}
                                h={imageHeight}  
                                source={ hanchesRotImage }
                                alt="image rotation des hanches"
                            />
                            <Text fontWeight="bold" style={styles.text_container} fontSize={fontSize}>Rot.ext./int.</Text>
                        </View>
                        <TouchableOpacity onPress={() => _loadSessionActivityHip_rot()} style={{width: '50%'}}>
                            <SessionMaxValueGraph data={data} 
                                indexes={[4,5]}
                                colors={colors}
                                keys={keys}
                                graphWidth={isHorizontalDisplay? width/8 : 180}
                                graphHeight={graphHeight}
                                isVertical={false}
                            />
                        </TouchableOpacity>
                    </View> 
                </View>
                {(displayCommentInfo !=="" && displaySpecificInfo ==="HipsCard") &&<View style={[styles.information_container, {width: isWideEnough? isHorizontalDisplay ? 410 : 390 : (width*0.8),height:isTablet?"94%":"94%"},{borderRadius: 15}]}>
                    <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
                        <Text style={[styles.information_text,{paddingBottom:isTablet? 30 :0, fontSize:fontSize}]}>
                            {displayCommentInfo}
                        </Text>
                        <TouchableOpacity
                            style={[styles.button,{marginRight: isTablet ? 50 : 0, marginTop: 0}]}
                            onPress={() => {
                                setDisplayCommentInfo("");
                            }}>
                            <Text style={[styles.button_text, {fontSize:fontSize}]}>{translate('go_Back')}</Text>
                        </TouchableOpacity>
                    </ScrollView>  
                </View>}
            </View>
        );
      }
    
      const KneesCard = () => {

        const data = [
            [
              Math.round(sessionAngularParameters.lknee_flex_max),
              Math.round(sessionAngularParameters.lknee_flex_min), 
            ],         
            [
              Math.round(sessionAngularParameters.rknee_flex_max),
              Math.round(sessionAngularParameters.rknee_flex_min), 
            ],
            [
              Math.round(sessionAngularParameters.lknee_rot_max),
              Math.round(sessionAngularParameters.lknee_rot_min),
            ],
            [
              Math.round(sessionAngularParameters.rknee_rot_max),
              Math.round(sessionAngularParameters.rknee_rot_min),
            ],
        ]
        
        const colors = ['#00d5f7A0', '#F57F17C0'];
        const keys = ['left_knee', 'right_knee'];

        const _loadSessionActivityKnee_flex = ()=>{ 
            navigation.navigate('RunnerSessionBehavior',{
              "param_data_right": sessionAngularParameters.params_user_list.rknee_flex,
              "param_data_left": sessionAngularParameters.params_user_list.lknee_flex,
              "motion_param": "La flexion du genou",
              "curve_name_right": "Droite",
              "curve_name_left": "Gauche",
              "phase": "Phase d'appui"
            })
        }
        const _loadSessionActivityKnee_rot = ()=>{ 
            navigation.navigate('RunnerSessionBehavior',{
              "param_data_right": sessionAngularParameters.params_user_list.rknee_rot,
              "param_data_left": sessionAngularParameters.params_user_list.lknee_rot,
              "motion_param": "La rotation du genou",
              "curve_name_right": "Droite",
              "curve_name_left": "Gauche",
              "phase": "Phase d'appui"
            })
        }

        return(
            <View style={{borderWidth:0.2, elevation: 1, width:(isHorizontalDisplay || width<370)? "98%" : 360, marginTop:marginTopSize}}>
                <Center style={styles.Heading_container}>
                    <Text fontWeight="bold" color="white">{translate('knees')} ({translate('support_phase')})</Text>
                </Center>
                <View style={{justifyContent: 'space-around', margin: 3, flexDirection:isHorizontalDisplay? 'row' : 'column', paddingTop:isHorizontalDisplay? 0.05*height : 0}}>
                    <View style={styles.first_parameters}>
                        <TouchableOpacity style={{marginLeft: "0%", width: 30, height: 30, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
                            onPress={() => { 
                                if(displayCommentInfo !==""){
                                setDisplayCommentInfo("");
                                }else {
                                setDisplayCommentInfo(translate('knee_information'));
                                setDisplaySpecificInfo("KneesCard");
                                } 
                            }}>
                            <Heading fontSize={18} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
                        </TouchableOpacity>
                        <View style={{width: '40%'}}>
                            <Image 
                                 style={imageStyle}
                                 w={imageWidth}
                                 h={imageHeight}
                                source={ genouxFlexImage }
                                alt="image flexion du genoux"
                            />
                            <Text fontWeight="bold" style={styles.text_container} fontSize={fontSize}>Ext./Flex</Text>
                        </View>
                        <TouchableOpacity onPress={() => _loadSessionActivityKnee_flex()} style={{width: '50%'}}>
                            <SessionMaxValueGraph data={data} 
                                indexes={[0,1]}
                                colors={colors}
                                keys={keys}
                                graphWidth={isHorizontalDisplay? width/4 : 180}
                                graphHeight={graphHeight}
                                isVertical={false}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.first_parameters}>
                        <View style={{width: '50%'}}>
                            <Image
                                style={imageStyle}
                                w={imageWidth}
                                h={imageHeight} 
                                source={ genouxRotImage }
                                alt="image rotation du genoux"
                            />
                            <Text fontWeight="bold" style={styles.text_container} fontSize={fontSize}>Rot.ext./int.</Text>
                        </View>
                        <TouchableOpacity onPress={() => _loadSessionActivityKnee_rot()} style={{width: '50%'}}>
                            <SessionMaxValueGraph data={data} 
                                indexes={[2,3]}
                                colors={colors}
                                keys={keys}
                                graphWidth={isHorizontalDisplay? width/4 : 180}
                                graphHeight={graphHeight}
                                isVertical={false}
                            />
                        </TouchableOpacity>
                    </View> 
                </View>
                {(displayCommentInfo !=="" && displaySpecificInfo ==="KneesCard") &&<View style={[styles.information_container, {width: isWideEnough? isHorizontalDisplay ? 410 : 390 : (width*0.8), height:isTablet?"94%":"94%"},{borderRadius: 15}]}>
                    <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
                        <Text style={[styles.information_text, {fontSize:fontSize}]}>
                            {displayCommentInfo}
                        </Text>
                        <TouchableOpacity
                            style={styles.button}
                            onPress={() => {
                                setDisplayCommentInfo("");
                            }}>
                            <Text style={[styles.button_text, {fontSize:fontSize}]}>{translate('go_Back')}</Text>
                        </TouchableOpacity>
                    </ScrollView>  
                </View>}
            </View>
        );
      }
    
      const SpatioTemporalCard = () => {


        let data1 = [
            {
              value: Math.round(100*(sessionStrideParameters.left_step_duration/sessionStrideParameters.stride_duration)),
              svg: {fill: '#F57F17C0',stroke:'#F57F17C0'},
            },
            {
              value: Math.round(100*(sessionStrideParameters.right_step_duration/sessionStrideParameters.stride_duration))
            }
        ];
        let data2 = [
            {
              value: Math.round(100*(sessionStrideParameters.left_step_length/sessionStrideParameters.stride_length)),
              svg: {fill: '#F57F17C0',stroke:'#F57F17C0'},
            },
            {
              value: Math.round(100*(sessionStrideParameters.right_step_length/sessionStrideParameters.stride_length))
            }
        ];
          
        const strideDuration = (sessionStrideParameters.stride_duration).toFixed(2).toString().replace('.', ',');
        const strideLength = (sessionStrideParameters.stride_length).toFixed(2).toString().replace('.', ',');

        return(
            <View style={{borderWidth:0.2, elevation: 1, width:(isHorizontalDisplay || width<370)? "98%" : 360, marginTop:marginTopSize}}>
                <Center style={styles.Heading_container}>
                    <Text fontWeight="bold" color="white">{translate('spatio_temporal')} ({translate('support_phase')})</Text>
                </Center>
                <View style={{margin: 3, justifyContent: isHorizontalDisplay? "flex-start" : "space-around", height:(isHorizontalDisplay || height <midBreakpoint)? "50%" : 450}}>
                    <View style={styles.first_parameters}>
                        <TouchableOpacity style={{marginLeft: "0%", width: 30, height: 30, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
                            onPress={() => { 
                                if(displayCommentInfo !==""){
                                setDisplayCommentInfo("");
                                }else {
                                setDisplayCommentInfo(translate('spatio_temporal_information'));
                                setDisplaySpecificInfo("SpatioTemporalCard");
                                }
                            }}>
                            <Heading fontSize={18} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
                        </TouchableOpacity>
                        <View style={{alignItems:'center', width: '40%'}}>
                            <Image  
                                style={imageStyle}
                                source={ spatioTempImage }
                                w={63}
                                h={63}
                                alt="image durée de la foulée"
                            />
                            <View style={{flexDirection:isHorizontalDisplay? "row" : "column", alignItems:"center"}} >
                                <Text fontWeight="bold" style={{textAlign:'center', color:'#00d5f7', paddingRight:isHorizontalDisplay? 5 : 0}}>{translate('stride_time')}</Text>
                                <Text style={styles.text_container_math_value}>{ strideDuration + " s"}</Text>
                            </View>
                        </View>
                        <View style={{alignItems:'center', width: '50%'}}>
                            <Image 
                                style={imageStyle}
                                source={ spatioLengthImage }
                                w={101}
                                h={101}
                                alt="image longueur de la foulée"
                            />
                            <View style={{flexDirection:isHorizontalDisplay? "row" : "column", alignItems:"center"}} >
                                <Text fontWeight="bold" style={{textAlign:'center', color:'#00d5f7', paddingRight:isHorizontalDisplay? 5 : 0}}>{translate('stride_length')}</Text>
                                <Text style={styles.text_container_math_value}>{ strideLength + " m"}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ height:isHorizontalDisplay? '33%' : '73.5%', flexDirection: 'row', justifyContent:"space-between", alignItems:'center'}}>
                        <View style={isHorizontalDisplay? { width: "45%"} : { width: "50%", paddingRight:20 }}>
                            <View style={isHorizontalDisplay? {height: height/6, flexDirection: 'row'} : { height: 250, flexDirection: 'row' }}>
                                <BarChart horizontal={isHorizontalDisplay} gridMin={0}  spacingInner={0.2} style={isHorizontalDisplay? {height: height/6, width:"80%"} : { height: 250, width:"100%"}}  data={data1} svg={{ fill:'#00d5f7A0',stroke:'#00d5f7A0' }} yAccessor={({ item }) => item.value} contentInset={{ top: 5, bottom: 0 }}>
                                    <SpatioLabelsFirst/>
                                </BarChart>
                            </View>
                            {isHorizontalDisplay?  <></> : <View style={styles.horizontalBar}/>}
                        </View>
                        <View style={isHorizontalDisplay? { width: "45%"} : { width: "50%", paddingRight:20 }}>
                            <View style={isHorizontalDisplay? {height: height/6, flexDirection: 'row'} : { height: 250, flexDirection: 'row' }}>
                                <BarChart horizontal={isHorizontalDisplay} gridMin={0}  spacingInner={0.2} style={isHorizontalDisplay? {height: height/6, width:"80%"} : { height: 250, width:"100%"}}  data={data2} svg={{ fill:'#00d5f7A0',stroke:'#00d5f7A0' }} yAccessor={({ item }) => item.value}  contentInset={{ top: 5, bottom: 0 }}>
                                    <SpatioLabelsSecond/>
                                </BarChart>
                            </View>
                            {isHorizontalDisplay?  <></> : <View style={styles.horizontalBar}/>}
                        </View>
                    </View> 
                </View>
                {(displayCommentInfo !=="" && displaySpecificInfo ==="SpatioTemporalCard") &&<View style={[styles.information_container, {width: isWideEnough? isHorizontalDisplay ? 410 : 390 : (width*0.8), height:isTablet?"94%":"94%"},{borderRadius: 15}]}>
                    <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
                        <Text style={[styles.information_text,{paddingBottom:isTablet? 30 :8, fontSize:fontSize}]}>
                            {displayCommentInfo}
                        </Text>
                        <TouchableOpacity
                            style={[styles.button,{marginTop: 0}]}
                            onPress={() => {
                                setDisplayCommentInfo("");
                            }}>
                            <Text style={[styles.button_text, {fontSize:fontSize}]}>{translate('go_Back')}</Text>
                        </TouchableOpacity>
                    </ScrollView>  
                </View>}
            </View>
        );
      }
    
      const CineticCard = () => {

        let data1 = [
            {
              value: Math.round(sessionStrideParameters.left_acc_peak),
              svg: {fill: '#F57F17C0',stroke:'#F57F17C0'},
            },
            {
              value: Math.round(sessionStrideParameters.right_acc_peak)
            }
        ];
        let data2 = [
            {
              value: Math.round(sessionStrideParameters.left_vlr),
              svg: {fill: '#F57F17C0',stroke:'#F57F17C0'},
            },
            {
              value: Math.round(sessionStrideParameters.right_vlr)
            }
        ];

        return(
            <View style={{borderWidth:0.2, elevation: 1, width:(isHorizontalDisplay || width<370)? "98%" : 360, marginTop:marginTopSize}}>
                <Center style={styles.Heading_container}>
                    <Text fontWeight="bold" color="white">{translate('kinetic')} ({translate('support_phase')})</Text>
                </Center>
                <View style={{margin: 3, justifyContent: isHorizontalDisplay? "flex-start" : "space-around", height:(isHorizontalDisplay || height <midBreakpoint)? "50%" : 450}}>
                    <View style={styles.first_parameters}>
                        <TouchableOpacity style={{marginLeft: "0%", width: 30, height: 30, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
                            onPress={() => { 
                                if(displayCommentInfo !==""){
                                setDisplayCommentInfo("");
                                }else {
                                setDisplayCommentInfo(translate('kinetic_information'));
                                setDisplaySpecificInfo("CineticCard");
                                }
                            }}>
                            <Heading fontSize={18} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
                        </TouchableOpacity>
                        <View style={{alignItems:'center', width: isTablet? '50%':'40%'}}>
                            <Image  
                                style={imageStyle}
                                source={ cineticStrengthImage }
                                w={53}
                                h={49}
                                alt="logo de creation de compte"
                            />
                            <Text fontWeight="bold" style={[styles.text_container,{marginBottom: 15}]}>{translate('impact_force')}</Text>
                            <TouchableOpacity style={{height:50, width:50, borderRadius:5, justifyContent:"center", alignItems:"center", backgroundColor: '#F57F17C0' }}
                                onPress={() => {
                                navigation.navigate('BiomechaParamsBehavior', {id:8, mParam: "Force d'impact", mParamTitle:translate('acc_peak'), sessionList:sessionList, runnerSession:session});
                                }}>
                                <Image 
                                style={{}}
                                source={ bezierCurveImage }
                                w={43}
                                h={39}
                                alt="Courbe force d'impact au sol"
                            />
                            </TouchableOpacity>
                        </View>
                        <View style={{alignItems:'center', width: isTablet? '40%':'50%'}}>
                            <Image  
                                style={imageStyle}
                                source={ cineticRateImage }
                                w={47}
                                h={45}
                                alt="image taux de charge"
                            />
                            <Text fontWeight="bold" style={[styles.text_container,{marginBottom: 15}]}>{translate('loading_rate')}</Text>
                            <TouchableOpacity style={{height:50, width:50, borderRadius:5, justifyContent:"center", alignItems:"center", backgroundColor: '#F57F17C0'}}
                                onPress={() => {
                                navigation.navigate('BiomechaParamsBehavior', {id:9, mParam:"Amorti", mParamTitle:translate('loading_rate'), sessionList:sessionList, runnerSession:session});
                                }}>
                                <Image 
                                style={{}}
                                source={ bezierCurveImage }
                                w={43}
                                h={39}
                                alt="Courbe force d'impact au sol"
                            />
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ height:isHorizontalDisplay? '33%' : '73.5%', flexDirection: 'row', justifyContent:"space-between", alignItems:'center'}}>
                        <View style={isHorizontalDisplay? { width: "45%"} : { width: "50%", paddingRight:20 }}>
                            <View style={isHorizontalDisplay? {height: height/6, flexDirection: 'row'} : { height: 250, flexDirection: 'row' }}>
                                <BarChart horizontal={isHorizontalDisplay} gridMin={0}  spacingInner={0.2} style={isHorizontalDisplay? {height: height/6, width:"80%"} : { height: 250, width:"100%"}}  data={data1} svg={{ fill:'#00d5f7A0',stroke:'#00d5f7A0' }} yAccessor={({ item }) => item.value} contentInset={{ top: 5, bottom: 0 }}>
                                    <CineticLabelsFirst/>
                                </BarChart>
                            </View>
                            {isHorizontalDisplay?  <></> : <View style={styles.horizontalBar}/>}
                        </View>
                        <View style={isHorizontalDisplay? { width: "45%"} : { width: "50%", paddingRight:20 }}>
                            <View style={isHorizontalDisplay? {height: height/6, flexDirection: 'row'} : { height: 250, flexDirection: 'row' }}>
                                <BarChart horizontal={isHorizontalDisplay} gridMin={0}  spacingInner={0.2} style={isHorizontalDisplay? {height: height/6, width:"80%"} : { height: 250, width:"100%"}}  data={data2} svg={{ fill:'#00d5f7A0',stroke:'#00d5f7A0' }} yAccessor={({ item }) => item.value}  contentInset={{ top: 5, bottom: 0 }}>
                                    <CineticLabelsSecond/>
                                </BarChart>
                            </View>
                            {isHorizontalDisplay?  <></> : <View style={styles.horizontalBar}/>}
                        </View>
                    </View>  
                </View>
                {(displayCommentInfo !=="" && displaySpecificInfo ==="CineticCard") &&<View style={[styles.information_container, {width: isWideEnough? isHorizontalDisplay ? 410 : 390 : (width*0.8), height:isTablet?"94%":"94%"},{borderRadius: 15}]}>
                    <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
                        <Text style={[styles.information_text,{paddingBottom:isTablet? 30 :0, fontSize:fontSize}]}>
                            {displayCommentInfo}
                        </Text>
                        <TouchableOpacity
                            style={[styles.button,{marginTop: 0}]}
                            onPress={() => {
                                setDisplayCommentInfo("");
                            }}>
                            <Text style={[styles.button_text, {fontSize:fontSize}]}>{translate('go_Back')}</Text>
                        </TouchableOpacity>
                    </ScrollView>  
                </View>}
            </View>
        );
      }

    

    const sessionTabs = [PelvisCard, HipsCard, KneesCard, SpatioTemporalCard, CineticCard];
    const resultsTabs = [];
    let counter = 0
    sessionTabs.map((component)=>{
        if((pelvisCard && component == PelvisCard) || (hipsCard && component == HipsCard)
         || (kneesCard && component == KneesCard) || (spatioTemporalCard && component == SpatioTemporalCard)
         || (cineticCard && component == CineticCard )){
            if(counter < maxValueLg){
                resultsTabs.push(component);
                counter=counter+1;
            }else{
                Alert.alert("", messageValue,[{ text: "OK" }])
            }
        }
    })


    return(
        <Box style={{flexDirection:"row", justifyContent:"space-around", width:"100%"}} h="500px" >
            {(resultsTabs || []).map(Card=>(<Card key={resultsTabs.indexOf(Card)}/>))}
        </Box>
    );
  }

export default SessionSupportCard;

const styles = StyleSheet.create({
    Heading_container:{
        height:'6%',
        backgroundColor: "#00d5f7",
    },
    base_cards_dim:{
        borderWidth:0.2,
        elevation: 1,
    },
    view_container:{
        justifyContent: 'space-around',
        margin: 3
    },
    text_container:{
        textAlign:'center',
        color:'#00d5f7'
    },
    text_container_math_value:{
        color: "#00d5f7",
        fontWeight:"bold", 
    },
    text_Sp_container:{
        textAlign:'center',
        padding:3,
        color: "#F57F17C0",
    },
    first_parameters:{
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems:'center'
    },
    customBtnBG: {
        backgroundColor: "#00d5f7",
        paddingHorizontal: 30,
        paddingVertical: 3,
        borderRadius: 30,
        width: 100,
    },
    button_container:{
        width: "100%",
        alignItems: "center",
    },
    customBtnText: {
        fontSize: 15,
        fontWeight: '400',
        color: "#fff",
        width: 60,

    },
    horizontalBar: {
        width: '100%',
        height: '0.5%',
        backgroundColor: "grey",
    },
    information_container:{ 
        borderWidth:1,
        alignSelf:"center",
        flex: 1,
        borderColor: '#00d5f7',
        borderRadius: 20,
        backgroundColor: '#FFF',
        position: 'absolute',
        top :33,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        elevation: 1, 
    },
    information_text:{
        textAlign:"justify", 
        padding: 5, 
        paddingHorizontal: 15,
        color:"black"
    },
    button:{
        backgroundColor: "#00d5f7", 
        alignSelf:"center",
        borderRadius:200,
        marginBottom: 10,
        marginTop: 20,
    },
    button_text:{
        fontSize: 14, 
        alignSelf:"center", 
        marginHorizontal:20, 
        marginVertical:2,
        color:"#000",
        fontWeight:"bold"
    },
})