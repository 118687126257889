import React, { useState } from 'react';
import { Text, Button } from 'native-base';
import { Platform, StyleSheet, ToastAndroid } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// intl components
import 'intl';
import translate from '../i18n/translate';

// utils functions
import { login }     from '../src/fetch.js';

// Import the native module
import { NativeModules } from 'react-native';

const { CollectData } = NativeModules;

const LoginButton = ({navigation, email, password, setErrorEmail, setErrorPassword, checkNetworkConnexion, networkFrequency, checked}) => {


  const loginOrRegister = async ()=>{
    checkNetworkConnexion();
    const value = await AsyncStorage.getItem('cfgmsm');
    const emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const passwordValidator = /(?=.*\d)(?=.[a-zA-Z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

    if((email!=="" && emailValidator.test(email)) || email == "trainer1" )
    {
      if((password!=="" && passwordValidator.test(password)) || password ==="trainer1")
      {
        if(checked === true){
          await AsyncStorage.setItem('cfuseremail', email);
          await AsyncStorage.setItem('cfuserpassword', password);  
        }
           
        login(email, password).then((response) =>
        {
          if(response.status === 200)
          {
            setErrorPassword("");
            setErrorEmail("");
            navigation.navigate('RunnersList', {
              public_id: response.public_id,
              access_token: response.access_token,
              cfgmsm: value,
              networkFrequency: networkFrequency
            })
          }
          else
          {
            setErrorPassword(translate('credentials_login'));
          }
        }).catch((err) => console.error(err));
        }
        else
        {
          setErrorPassword(translate('err_password'));
        }
      }
      else
      {
          setErrorEmail(translate('err_email'));
      }
    return;
  }

  return(
    <Button mt="2" colorScheme="indigo" _text={{ color: 'white' }}
      onPress={()=>loginOrRegister()}
    >
    <Text fontWeight="bold" style={styles.account_color}>{translate('connect_button')}</Text>
    </Button>
  );
}

export default LoginButton;

const styles = StyleSheet.create({
  account_color:{
    fontSize: 18,
    color: "#FFF" 
  }
})
