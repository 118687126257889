// original index.js
// import { registerRootComponent } from 'expo';

// import App from './App';

// // registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// // It also ensures that whether you load the app in the Expo client or in a native build,
// // the environment is set up appropriately
// registerRootComponent(App);
//----------------------------------------------------------

// fix index.js
// https://stackoverflow.com/questions/62649381/invariant-violation-main-has-not-been-registered
import { AppRegistry, Platform, LogBox } from 'react-native';
import App from './App.js';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/fr.js';
import 'intl/locale-data/jsonp/de.js';
import "babel-polyfill";
// service workers
import * as serviceWorkerRegistration from "./pwa/serviceWorkerRegistration.js";
global._babelPolyfill = false;

// Prevent warning and error logs from appearing on the Android device screen
// during development.
if (Platform.OS === 'android') {
  LogBox.ignoreAllLogs();
}


// The environment variable MYSMARTMOVE_API define the URL of the back-end server.
// During development, we could also redirect request via the webpack server
// configured in `webpack.config.js`
console.info(`MYSMARTMOVE_API = ${process.env["MYSMARTMOVE_API"]}`);

AppRegistry.registerComponent('main', () => App);

if (Platform.OS === 'web') {
  const rootTag = document.getElementById('root') || document.getElementById('main');
  AppRegistry.runApplication('main', { rootTag });
  serviceWorkerRegistration.register();
}
