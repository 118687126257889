import React, { useState,useEffect } from 'react';
import { NativeBaseProvider, Stack, ScrollView} from 'native-base';
import { View, StyleSheet, useWindowDimensions, Platform, TouchableOpacity, Alert, Image} from 'react-native';
// import Pdf from 'react-native-pdf';
// import * as Print from 'expo-print';
// import Share from 'react-native-share';


// import PDFReader from 'rn-pdf-reader-js';
// import { Asset } from "expo-asset";
// import * as FileSystem from 'expo-file-system'

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import translate from '../i18n/translate';

// components
import Logo          from '../components/Logo.js';
import LoginFields   from '../components/LoginFields.js';

// require images
// const pelvisInclinaisonImage = Image.resolveAssetSource(require('../images/png/pelvis_obl.png'));
// const pelvisVersionImage = Image.resolveAssetSource(require('../images/png/pelvis_ver.png'));
// const hanchesAddImage = Image.resolveAssetSource(require('../images/png/hip_add.png'));
// const hanchesFlexImage = Image.resolveAssetSource(require('../images/png/hip_flex.png'));
// const hanchesRotImage = Image.resolveAssetSource(require('../images/png/hip_rot.png'));
// const genouxFlexImage = Image.resolveAssetSource(require('../images/png/knee_flex.png'));
// const genouxRotImage = Image.resolveAssetSource(require('../images/png/knee_rot.png'));
// const spatioTempImage = Image.resolveAssetSource(require('../images/png/step_time.png'));
// const spatioLengthImage = Image.resolveAssetSource(require('../images/png/step_length.png'));
// const cineticStrengthImage = Image.resolveAssetSource(require('../images/png/impact_force.png'));
// const cineticRateImage = Image.resolveAssetSource(require('../images/png/vertical_loading_rate.png'));
// const arrowDownIcon = Image.resolveAssetSource(require('../images/png/arrow_down_icon.png'));
// const arrowUpIcon = Image.resolveAssetSource(require('../images/png/arrow_up_icon.png'));
// const arrowRightIcon = Image.resolveAssetSource(require('../images/png/arrow_right_icon.png'));



const PdfSession = ({route, navigation}) => {

  const { session, stride_params, identity, sessionSym, runner, allSession, refSession } = route.params;
  const { mParamCadence, mParamLoadingRate, mParamImpact, mParamStep, mParamPelvis, mParamHips, mParamKnees } = sessionSym;
  const { mRefParamCadence, mRefParamLoadingRate, mRefParamImpact, mRefParamStep, mRefParamPelvis, mRefParamHips, mRefParamKnees } = refSession;
  const [uriCall, setUriCall] = useState(null);
  const [affiche, setAffiche] = useState(false);
  const [locale, setLocale]= useState(LOCALES.FRENCH)
  const setEnglishlanguages = (lang) =>{
    lang == 'Français' ? setLocale(LOCALES.ENGLISH): setLocale(LOCALES.FRENCH)  
  }
  const date       = new Date(Date.parse(allSession.date));
  const options    = {year: "numeric", month: "numeric", day: "numeric"};
  const dateString = new Intl.DateTimeFormat("fr-fr",options).format(date);

  const largeBreakpoint = 992;
  const midBreakpoint = 688;
  const {width, height} = useWindowDimensions();
  const isLandscape = width > height;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
  const isHorizontalDisplay = isLandscape && height<midBreakpoint;
  const graphHeight = isHorizontalDisplay? height*0.30 : (height >= midBreakpoint)? 116 : height/7;
  const fontSizeText = (width>= midBreakpoint)? (width >= largeBreakpoint)? "24.5px" : "22.2px" : "22.4px";
  const fontProfile = (width>= midBreakpoint)? (width >= largeBreakpoint)? "29px" : "32px" : "52px";
  const fontName = (width>= midBreakpoint)? (width >= largeBreakpoint)? "25px" : "30px" : "40px";
  const fontTitle = (width>= midBreakpoint)? (width >= largeBreakpoint)? "28px" : "28px" : "40px";
  const fontLabel = (width>= midBreakpoint)? (width >= largeBreakpoint)? "25px" : "25px" : "40px";
  const cardWidth = (width>= midBreakpoint)? (width >= largeBreakpoint)? width : width : 690;
  const marginWidth = (width>= midBreakpoint)? (width >= largeBreakpoint)? "0.5%" : "4%" : "0.5%";
  const isWideEnough = (width>= midBreakpoint)? (width >= largeBreakpoint)? "40%" : "50%" : "50%";
  //const cardBlockdisplay = isTablet ? "display: flex;":"display: block;" 
  const incContainer_second_Knee = `font-family: Helvetica Neue; font-weight: normal; font-size: ${fontSizeText}; height: 68%; width: 100%; border: 2px solid #dbdad5; margin: 0px ${marginWidth};`
  const incContainerStyle = isTablet ? "display: inline-block; display: flex;height: 100%; justify-content: space-between; width: 100%;": "display: inline-block; height: 100%; width: 100%;";
  const incContainerStyle_hip = isTablet ? "display: inline-block; display: flex;height: 100%; justify-content: space-between; width: 100%;": "display: inline-block; height: 100%; width: 100%;";
  const incContainer_second = isTablet ? `font-family: Helvetica Neue; font-weight: normal; font-size: ${fontSizeText}; height: 74.7%; width: 48%; border: 3px solid #dbdad5; margin: 0px ${marginWidth};` : `font-family: Helvetica Neue; font-weight: normal; font-size: ${fontSizeText}; height: 100%; width: 100%; border: 2px solid #dbdad5; margin: 0px ${marginWidth};`
  const incContainer_second_hip = isTablet ? `font-family: Helvetica Neue; font-weight: normal; font-size: ${fontSizeText}; height: 98%; width: 48%; border: 3px solid #dbdad5; margin: 0px ${marginWidth};` : `font-family: Helvetica Neue; font-weight: normal; font-size: ${fontSizeText}; height: 100%; width: 100%; border: 2px solid #dbdad5; margin: 0px ${marginWidth};`
  const verticalBar = "position: absolute; left: 25%; top: 5%; height: 90%; width: 0.03%; background-color: grey;"
  const horizontalBar = `position: absolute;top: ${isTablet ? '56%' : '68%'}; left: 2%;  width: 90%; height: 0.3%; background-color: grey;`
  const horizontalBarTop = "position: absolute;top: 56%; left: 2%;  width: 90%; height: 0.3%; background-color: grey;"


  const findAbsoluteMaximum = (data, indexes) => { // Returns the absolute maximum (sign is not taken into account)
    let absoluteMax = 0;
    for (let i of indexes){    // find the absolute maximum
        const val = data[i].map(x => Math.abs(x));
        if (Math.max(...val) > absoluteMax) {
            absoluteMax = Math.max(...val) ;
        }
    }
    return absoluteMax;
}
const getHeightforDisplay = (data, index, indexes) => {
  return (50*Math.abs(data[index][1]-data[index][0])/findAbsoluteMaximum(data, indexes));
}
const getBottomMarginforDisplay = (data, index, indexes, graphHeight) => {
  const absoluteMax = findAbsoluteMaximum(data, indexes);
  if (data[index][1] < 0){
      return parseInt((graphHeight/1.3)*((absoluteMax-Math.abs(data[index][1]))/absoluteMax));
  }
  return parseInt((graphHeight/2)*(1.45+(data[index][1]/absoluteMax)));
}

  const pelviCard = (indexes, isVertical)=>{
    const data = [
      [
          Math.round(session["pelvis_inc_max"]),
          Math.round(session["pelvis_inc_min"])
      ],
      [ 
          Math.round(session["pelvis_ver_max"]),
          Math.round(session["pelvis_ver_min"]),
      ]
    ]

    const colors = ['#8BC34AA0'];
    const keys = ['pelvis'];
    const graphWidth = isHorizontalDisplay? width/6 : 120;
    
    
    let pelvisHtml = '';
    indexes.forEach(index => {
      pelvisHtml +=`
      <div key=${keys[indexes.slice(-1)-index]}
        style=" display: inline-block;justify-content: space-between;position: relative;
        bottom: ${isVertical? parseInt(graphHeight*(-0.20)+((graphHeight*0.90*(index-indexes[0]))/indexes.length)) : getBottomMarginforDisplay(data, index, indexes, graphHeight)};
        left:${isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length))};
        height: ${isVertical? `${parseInt(50/indexes.length)}%` : `${getHeightforDisplay(data, index, indexes)}%`}; 
        width:${isVertical? `${getHeightforDisplay(data, index, indexes)/2}%` : `${parseInt(50/indexes.length)}%`}; 
        border-radius: 12px; 
        background-color: ${colors[indexes.slice(-1)-index]};">
      </div>`}
    );
      return pelvisHtml;
  }
  const kneeCard = (indexes, isVertical)=>{
    const data = [
      [
          Math.round(session["lknee_flex_max"]),
          Math.round(session["lknee_flex_min"])
      ],
      [ 
          Math.round(session["rknee_flex_max"]),
          Math.round(session["rknee_flex_min"]),
      ],
      [
        Math.round(session["lknee_rot_max"]),
        Math.round(session["lknee_rot_min"])
      ],
      [ 
          Math.round(session["rknee_rot_max"]),
          Math.round(session["rknee_rot_min"]),
      ]
    ]

    const colors = ['#00d5f7A0', '#F57F17C0'];
    const keys = ['left_knee', 'right_knee'];
    const graphWidth = isHorizontalDisplay? width/6 : 120;
    
    
    let kneeHtml = '';
    indexes.forEach(index => {
      kneeHtml +=`<div key=${keys[indexes.slice(-1)-index]}
        style=" display: inline-block;justify-content: space-between;position: absolute;
        bottom: ${isVertical? parseInt(graphHeight*(-0.20)+((graphHeight*0.90*(index-indexes[0]))/indexes.length)) : getBottomMarginforDisplay(data, index, indexes, graphHeight) };
        left:${isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*(0.15)+((graphWidth*2*(index-indexes[0]))/indexes.length))};
        height: ${isVertical? `${parseInt(50/indexes.length)}%` : `${getHeightforDisplay(data, index, indexes)}%`}; 
        width:${isVertical? `${getHeightforDisplay(data, index, indexes)/2}%` : `${parseInt(50/indexes.length)}%`}; 
        border-radius: 12px; 
        background-color: ${colors[indexes.slice(-1)-index]};">
      </div>`}
    );
    return kneeHtml;
  }
  const hipCard = (indexes, isVertical)=>{
    const data = [
      [
          Math.round(session["lhip_add_max"]),
          Math.round(session["lhip_add_min"])
      ],
      [ 
          Math.round(session["rhip_add_max"]),
          Math.round(session["rhip_add_min"]),
      ],
      [
        Math.round(session["lhip_flex_max"]),
        Math.round(session["lhip_flex_min"])
      ],
      [ 
          Math.round(session["rhip_flex_max"]),
          Math.round(session["rhip_flex_min"]),
      ],
      [
        Math.round(session["lhip_rot_max"]),
        Math.round(session["lhip_rot_min"])
      ],
      [ 
          Math.round(session["rhip_rot_max"]),
          Math.round(session["rhip_rot_min"]),
      ],
    ]

    const colors = ['#00d5f7A0', '#F57F17C0'];
    const keys = ['left_hip', 'right_hip'];
    const graphWidth = isHorizontalDisplay? width/6 : 120;
    
    
    let hipHtml = '';
    indexes.forEach(index => {
      hipHtml +=`<div key=${keys[indexes.slice(-1)-index]}
        style=" display: inline-block;justify-content: space-between;position: absolute;
        bottom: ${isVertical? parseInt(graphHeight*(-0.20)+((graphHeight*0.90*(index-indexes[0]))/indexes.length)) : getBottomMarginforDisplay(data, index, indexes, graphHeight) };
        left:${isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*(0.15)+((graphWidth*2*(index-indexes[0]))/indexes.length))};
        height: ${isVertical? `${parseInt(50/indexes.length)}%` : `${getHeightforDisplay(data, index, indexes)}%`}; 
        width:${isVertical? `${getHeightforDisplay(data, index, indexes)/2}%` : `${parseInt(50/indexes.length)}%`}; 
        border-radius: 12px; 
        background-color: ${colors[indexes.slice(-1)-index]};">
      </div>`}
    );
    return hipHtml;
  }
  const pelviDisplayGraphAngles = (indexes, isVertical)=>{
    const data = [
      [
          Math.round(session["pelvis_inc_max"]),
          Math.round(session["pelvis_inc_min"])
      ],
      [ 
          Math.round(session["pelvis_ver_max"]),
          Math.round(session["pelvis_ver_min"]),
      ]
    ]

    const colors = ['#8BC34AA0'];
    const keys = ['pelvis'];
    //const graphWidth = isHorizontalDisplay? width/6 : 120;
    const graphWidth = isHorizontalDisplay? width/6 : 100;
    
    
    let pelviAngles = '';
    indexes.forEach(index =>{

     pelviAngles += `<div key=${keys[indexes.slice(-1)-index]} style="display: inline-block;justify-content: space-between;">
        <p style=" position: relative;  font-size: ${fontLabel};
            bottom: ${isVertical?(isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : getBottomMarginforDisplay(data, index, indexes, graphHeight)) : (isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : (-0.4)*getBottomMarginforDisplay(data, index, indexes, graphHeight))};
            left : ${isVertical ? ( (isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphWidth/indexes.length)) - 7*((`${Math.round((1)*(data[index][1]))}°`).length)  ) : ( (isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : (-0.8)*parseInt(graphWidth+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+((data[index][1]<0) ? parseInt(0.25*graphWidth/indexes.length): 4.5*parseInt(0.25*graphWidth/indexes.length)) ) - 3*((`${Math.round((1)*(data[index][1]))}°`).length) )};
            "
        >${Math.round((1)*(data[index][1]))}°</p>
        <p style=" position: relative; font-size: ${fontLabel};
            bottom:${isVertical ? (isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : getBottomMarginforDisplay(data, index, indexes, graphHeight) ): ( ( isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : (-0.2)*getBottomMarginforDisplay(data, index, indexes, graphHeight) ) + parseInt((graphHeight/100)*getHeightforDisplay(data, index, indexes)) )};
            left :${isVertical ? ( (isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphWidth/indexes.length)) + parseInt((graphWidth/100)*getHeightforDisplay(data, index, indexes)) + 5 ) : ( ( isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : (2.5)*parseInt(((data[index][1]<0) ? graphWidth*(-0.15): graphWidth*(0.15))+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+  (2.5)*parseInt(0.25*graphWidth/indexes.length) ) - 3*((`${Math.round((1)*(data[index][1]))}°`).length) )};
            "
        >${Math.round((1)*(data[index][0]))}°</p> 
      </div>`}
    );
    return pelviAngles;

  }

  const kneeDisplayGraphAngles = (indexes, isVertical)=>{
    const data = [
      [
        Math.round(session["lknee_flex_max"]),
        Math.round(session["lknee_flex_min"])
      ],
      [ 
          Math.round(session["rknee_flex_max"]),
          Math.round(session["rknee_flex_min"]),
      ],
      [
        Math.round(session["lknee_rot_max"]),
        Math.round(session["lknee_rot_min"])
      ],
      [ 
          Math.round(session["rknee_rot_max"]),
          Math.round(session["rknee_rot_min"]),
      ]
    ]

    const colors = ['#00d5f7A0', '#F57F17C0'];
    const keys = ['left_knee', 'right_knee'];
    //const graphWidth = isHorizontalDisplay? width/6 : 120;
    const graphWidth = isHorizontalDisplay? width/6 : 100;
    
    
    let kneeAngles = '';
    indexes.forEach(index => {
      kneeAngles +=`<div key=${keys[indexes.slice(-1)-index]} style="display: inline-block;justify-content: space-between;">
        <p style=" position: relative;  font-size: ${fontLabel};
          bottom: ${isVertical?(isVertical? -20+parseInt((data[index][1]< 0?  (isTablet ? graphHeight*(-1):graphHeight*(-2)):(isTablet ? graphHeight*(-0.38):graphHeight*(-0.98)))+((((data[index][1]< 0 && data[index][0]> 0)? graphHeight*(0.40):graphHeight*(-0.010))*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : getBottomMarginforDisplay(data, index, indexes, graphHeight)) : (isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : (-0.4)*getBottomMarginforDisplay(data, index, indexes, graphHeight))};
          left : ${isVertical ? ( (isVertical? -66+(parseInt(graphHeight*(0.5)+((graphHeight*1.7*(index-indexes[0]))/indexes.length)))+getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphWidth/indexes.length)) - 7*((`${Math.round((1)*(data[index][1]))}°`).length)  ) : ( (isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : (-0.8)*parseInt(graphWidth+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+((data[index][1]<0) ? parseInt(0.25*graphWidth/indexes.length): 4.5*parseInt(0.25*graphWidth/indexes.length)) ) - 3*((`${Math.round((1)*(data[index][1]))}°`).length) )};
          "
        >${Math.round((1)*(data[index][1]))}°</p>
        <p style=" position: relative; font-size: ${fontLabel};
          bottom:${isVertical ? (isVertical? parseInt((data[index][0]< 0? graphHeight*(-0.8):graphHeight*(0.3))+((((data[index][0]< 0 && data[index][1]< 0)? graphHeight*(0.90):graphHeight*(0.30))*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : getBottomMarginforDisplay(data, index, indexes, graphHeight) ): ( ( isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : (-0.2)*getBottomMarginforDisplay(data, index, indexes, graphHeight) ) + parseInt((graphHeight/100)*getHeightforDisplay(data, index, indexes)) )};
          left :${isVertical ? ( (isVertical? -60+(-parseInt(graphHeight*(0.15)-(((data[index][0]< 0? graphHeight*(2.5):graphHeight*(1.4))*(index-indexes[0]))/indexes.length)))+getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphWidth/indexes.length)) + parseInt((graphWidth/100)*getHeightforDisplay(data, index, indexes)) + 5 ) : ( ( isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : (2.5)*parseInt(((data[index][1]<0) ? graphWidth*(-0.15): graphWidth*(0.15))+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+  (2.5)*parseInt(0.25*graphWidth/indexes.length) ) - 3*((`${Math.round((1)*(data[index][1]))}°`).length) )};
          "
        >${Math.round((1)*(data[index][0]))}°</p> 
      </div>`}
    );
    return kneeAngles;

  }

  const hipDisplayGraphAngles = (indexes, isVertical)=>{
    const data = [
      [
          Math.round(session["lhip_add_max"]),
          Math.round(session["lhip_add_min"])
      ],
      [ 
          Math.round(session["rhip_add_max"]),
          Math.round(session["rhip_add_min"]),
      ],
      [
        Math.round(session["lhip_flex_max"]),
        Math.round(session["lhip_flex_min"])
      ],
      [ 
          Math.round(session["rhip_flex_max"]),
          Math.round(session["rhip_flex_min"]),
      ],
      [
        Math.round(session["lhip_rot_max"]),
        Math.round(session["lhip_rot_min"])
      ],
      [ 
          Math.round(session["rhip_rot_max"]),
          Math.round(session["rhip_rot_min"]),
      ],
    ]

    const colors = ['#00d5f7A0', '#F57F17C0'];
    const keys = ['left_hip', 'right_hip'];
    //const graphWidth = isHorizontalDisplay? width/6 : 120;
    const graphWidth = isHorizontalDisplay? width/6 : 100;
    
    let hipAngles = '';
    indexes.forEach(index =>{ 
      hipAngles += `<div key=${keys[indexes.slice(-1)-index]} style="display: inline-block;justify-content: space-between;">
        <p style=" position: relative;  font-size: ${fontLabel};
          bottom: ${isVertical?(isVertical? -20+parseInt((data[index][1]< 0? (isTablet ? graphHeight*(-1):graphHeight*(-1.2)):(isTablet ? graphHeight*(-0.38):graphHeight*(-0.78)))+((((data[index][1]< 0 && data[index][0]> 0)? graphHeight*(0.5):graphHeight*(-0.1))*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : getBottomMarginforDisplay(data, index, indexes, graphHeight)) : (isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : (-0.4)*getBottomMarginforDisplay(data, index, indexes, graphHeight))};
          left : ${isVertical ? ( (isVertical? -66+(parseInt(graphHeight*(0.5)+(((data[index][1]< 0? graphHeight*(2.5):graphHeight*(1.7))*(index-indexes[0]))/indexes.length)))+getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphWidth/indexes.length)) - 7*((`${Math.round((1)*(data[index][1]))}°`).length)  ) : ( (isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : (-0.8)*parseInt(graphWidth+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+((data[index][1]<0) ? parseInt(0.25*graphWidth/indexes.length): 4.5*parseInt(0.25*graphWidth/indexes.length)) ) - 3*((`${Math.round((1)*(data[index][1]))}°`).length) )};
          "
        >${Math.round((1)*(data[index][1]))}°</p>
        <p style=" position: relative; font-size: ${fontLabel};
          bottom:${isVertical ? (isVertical? parseInt((data[index][0]< 0? graphHeight*(-0.1):graphHeight*(0.3))+((((data[index][0]< 0 && data[index][1]< 0)? graphHeight*(-0.4):graphHeight*(0.30))*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : getBottomMarginforDisplay(data, index, indexes, graphHeight) ): ( ( isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : (-0.2)*getBottomMarginforDisplay(data, index, indexes, graphHeight) ) + parseInt((graphHeight/100)*getHeightforDisplay(data, index, indexes)) )};
          left :${isVertical ? ( (isVertical? -50+(-parseInt(graphHeight*(0.15)-(((data[index][0]< 0? graphHeight*(2.6):graphHeight*(2))*(index-indexes[0]))/indexes.length)))+getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphWidth/indexes.length)) + parseInt((graphWidth/100)*getHeightforDisplay(data, index, indexes)) + 5 ) : ( ( isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : (2.5)*parseInt(((data[index][1]<0) ? graphWidth*(-0.15): graphWidth*(0.15))+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+  (2.5)*parseInt(0.25*graphWidth/indexes.length) ) - 3*((`${Math.round((1)*(data[index][1]))}°`).length) )};
          "
        >${Math.round((1)*(data[index][0]))}°</p> 
      </div>`}
    );
    return hipAngles;

  }

  const spatioTemporalFirstBar = ()=>{
    const data =[5*Math.round(100*((stride_params.left_step_duration)/(stride_params.stride_duration))),5*Math.round(100*((stride_params.right_step_duration)/(stride_params.stride_duration)))];
    let recHtml='';
    let x= 10;
    data.forEach(L=>{
      recHtml += `<rect x="${x}" y="${(isTablet ? 591 : 800) - L}" width="100" height="${L}" rx="12" ry="12" id="${data[0] === L? 'firstRectHtml':'secondRectHtml'}"/>`;
      x += x + (isTablet ? 171 : 221);
    })
    return recHtml;

  }
  const spatioTemporalSecondBar = ()=>{
    const data =[5*Math.round(100*((stride_params.left_step_length)/(stride_params.stride_length))),5*Math.round(100*((stride_params.right_step_length)/(stride_params.stride_length)))];
    let rectHtml='';
    let x= isTablet ? 496 : 700;
    data.forEach(L=>{
      rectHtml += `<rect x="${x}" y="${(isTablet ? 591 : 800) - L}" width="100" height="${L}" rx="12" ry="12" id="${data[0] === L? 'firstRectHtml':'secondRectHtml'}"/>`;
      x += x - (isTablet ? 327 : 459);
    })
    return rectHtml;

  }

  const cineticFirstBar = ()=>{
    const data =[5*Math.round(stride_params.left_acc_peak),5*Math.round(stride_params.right_acc_peak)];
    let recHtml='';
    let x= 10;
    data.forEach(L=>{
      recHtml += `<rect x="${x}" y="${(isTablet ? 591 : 800) - L}" width="100" height="${L}" rx="12" ry="12" id="${data[0] === L? 'firstRectHtml':'secondRectHtml'}"/>`;
      x += x + (isTablet ? 171 : 221);
    })
    return recHtml;

  }

  const cineticSecondBar = ()=>{
    const data =[5*Math.round(stride_params.left_vlr),5*Math.round(stride_params.right_vlr)];
    let rectHtml='';
    let x= isTablet ? 496 : 700;
    data.forEach(L=>{
      rectHtml += `<rect x="${x}" y="${(isTablet ? 591 : 800) - L}" width="100" height="${L}" rx="12" ry="12" id="${data[0] === L? 'firstRectHtml':'secondRectHtml'}"/>`;
      x += x - (isTablet ? 327 : 459);
    })
    return rectHtml;

  }




  
  const isVertical= true;
  const notIsVertical= false;
  const indexesPelv1 = [0];
  const indexesPelv2 = [1];
  const indexesKneeFlex = [0,1];
  const indexesKneeRot = [2,3];
  const indexesHip = [4,5];
  
  const html = `
  <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=yes" />
      <style>
        
        #boxes {
          content: "";
          clear: both;
          width: 100%;
          height: 30%;
          display: flex;
          justify-content: space-between;
          margin: 0px 0px 20px auto;
          // border: 1px solid black;
        }
        #boxes_hip {
          content: "";
          clear: both;
          width: 100%;
          height: 70%;
          display: flex;
          justify-content: space-between;
          margin: 0px 0px 20px auto;
          // border: 1px solid black;
        }
        
        #boxes_spatio{
          content: "";
          clear: both;
          width: 100%;
          height: 22.5%;
          display: flex;
          justify-content: space-between;
        }
        #firstRectHtml{
          fill:#F57F17C0;
        }
        #secondRectHtml{
          fill:#00d5f7A0;
        }
        
      </style>
    </head>
    <body style=" font-family: Helvetica Neue; font-weight: bold;">
      ${isTablet ? `<div>
        <div style="display: inline-block; height: 100%; width: 100%;">
          <div>
            <h1 style="text-align: center; margin: 0px 0px 40px auto; border-bottom: 3px solid #dbdad5;">
              Bilan biomécanique
            </h1>
            <div style="display: flex;">
              <div style="width: ${isWideEnough};">
                <h2 style=" margin: 0px 0px 20px 40px; font-size: ${fontProfile}; border-bottom: 3px solid #dbdad5;">
                  Profil
                </h2>
                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                  <strong>Nom</strong> : ${runner.first_name} ${runner.last_name}
                </p>
                <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                  <strong>Date</strong> : ${dateString}
                </p>
              </div>
              <div style="width: ${isWideEnough};">
                <svg width="645" height="143">
                  <rect x="20" y="20" rx="12" ry="12" width="70" height="40" id="firstRectHtml"/>
                  <rect x="20" y="80" rx="12" ry="12" width="70" height="40" id="secondRectHtml"/>
                  <text x="100" y="45" style="font-size: ${fontLabel};" >gauche</text>
                  <text x="100" y="107" style="font-size: ${fontLabel};" >droite</text>
                </svg>
              </div>
            </div>
          </div>
          <div style="${incContainerStyle}">

            <div style="${incContainer_second}">
              <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5;font-size: ${fontTitle};">
                Bassin (${identity})
              </h2>
              <div style="width: 100%; margin: 0px 0px 0px auto;"> 
                <div id="boxes">
                  <div style="height: 100%; width: 30%;">
                    <img class="fit-picture"
                      src="${pelvisInclinaisonImage.uri}" 
                      alt="pelvis tilt image"
                      style="height: 100%; width: 100%;"
                    >
                  </div>
                  <div style=" height: 100%; width: 45%; position: relative;">
                    <div style="${verticalBar}"></div>
                    ${pelviCard(indexesPelv1, isVertical)}
                    ${pelviDisplayGraphAngles(indexesPelv1, notIsVertical)}
                  </div>
                </div>
                <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                  Inclinaison
                </h3>
              </div>
              <div style="width: 100%; margin: 0px 0px 0px auto;"> 
                <div id="boxes">
                  <div style=" height: 100%; width: 30%;">
                    <img class="fit-picture"
                      src="${pelvisVersionImage.uri}" 
                      alt="pelvis version image"
                      style=" height: 100%; width: 100%;"
                    >
                  </div>
                  <div style=" height: 100%; width: 45%; position: relative;">
                  <div style="${verticalBar}"></div>
                  ${pelviCard(indexesPelv2, isVertical)}
                  ${pelviDisplayGraphAngles(indexesPelv2, notIsVertical)}
                  </div>
                </div>
                <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                  Version
                </h3>
              </div>  
            </div>

            <div style="${incContainer_second};">
              <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
                Genoux (${identity})
              </h2>
              <div style="width: 100%; margin: 0px 0px 0px auto;"> 
                <div id="boxes">
                  <div style="height: 100%; width: 30%;">
                    <img class="fit-picture"
                      src="${genouxFlexImage.uri}" 
                      alt="knee flex image"
                      style="height: 100%; width: 100%;"
                    >
                  </div>
                  <div style=" height: 100%; width: 45%; position: relative;">
                    <div style="${horizontalBar}"></div>
                    ${kneeCard(indexesKneeFlex, notIsVertical)}
                    ${kneeDisplayGraphAngles(indexesKneeFlex, isVertical)}
                  </div>
                </div>
                <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                  Ext./Flex.
                </h3>
              </div>
              <div style="width: 100%; margin: 0px 0px 0px auto;"> 
                <div id="boxes">
                  <div style=" height: 100%; width: 30%;">
                    <img class="fit-picture"
                      src="${genouxRotImage.uri}" 
                      alt="knee rot image"
                      style=" height: 100%; width: 100%;"
                    >
                  </div>
                  <div style=" height: 100%; width: 45%; position: relative;">
                  <div style="${horizontalBar}"></div>
                  ${kneeCard(indexesKneeRot, notIsVertical)}
                  ${kneeDisplayGraphAngles(indexesKneeRot, isVertical)}
                  </div>
                </div>
                <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                  Rot.ext./int.
                </h3>
              </div>  
            </div>
          </div>
        </div>
        <div style="${incContainerStyle_hip}">
          
          <div style="${incContainer_second_hip}">
            <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
              Hanches (${identity})
            </h2>
            <div style="width: 100%;height: 31%; margin: 0px 0px 0px auto;"> 
              <div id="boxes_hip">
                <div style="height: 100%; width: 30%;">
                  <img class="fit-picture"
                    src="${hanchesAddImage.uri}" 
                    alt="hip add image"
                    style="height: 100%; width: 100%;"
                  >
                </div>
                <div style=" height: 100%; width: 45%; position: relative;">
                  <div style="${horizontalBar}"></div>
                  ${hipCard(indexesKneeFlex, notIsVertical)}
                  ${hipDisplayGraphAngles(indexesKneeFlex, isVertical)}
                </div>
              </div>
              <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                Abd./Add.
              </h3>
            </div>
            <div style="width: 100%;height: 30%; margin: 0px 0px 0px auto;"> 
              <div id="boxes_hip">
                <div style="height: 100%; width: 30%;">
                  <img class="fit-picture"
                    src="${hanchesFlexImage.uri}" 
                    alt="hip flex image"
                    style="height: 100%; width: 100%;"
                  >
                </div>
                <div style=" height: 100%; width: 45%; position: relative;">
                  <div style="${horizontalBar}"></div>
                  ${hipCard(indexesKneeRot, notIsVertical)}
                  ${hipDisplayGraphAngles(indexesKneeRot, isVertical)}
                </div>
              </div>
              <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                Ext./Flex.
              </h3>
            </div>
            <div style="width: 100%;height: 31%; margin: 0px 0px 0px auto;"> 
              <div id="boxes_hip">
                <div style="height: 100%; width: 30%;">
                  <img class="fit-picture"
                    src="${hanchesRotImage.uri}" 
                    alt="hip rot image"
                    style="height: 100%; width: 100%;"
                  >
                </div>
                <div style=" height: 100%; width: 45%; position: relative;">
                  <div style="${horizontalBar}"></div>
                  ${hipCard(indexesHip, notIsVertical)}
                  ${hipDisplayGraphAngles(indexesHip, isVertical)}
                </div>
              </div>
              <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                Rot.ext./int.
              </h3>
            </div>  
          </div>
          
          <div style="${incContainer_second_hip}">
            <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
              Spatio-temporels (${identity})
            </h2>
            <div id="boxes_spatio">
                <div style="height: 40%; width: 15%;">
                  <img class="fit-picture"
                    src="${spatioTempImage.uri}" 
                    alt="spatiotemporal image"
                    style="height: 100%; width: 100%;margin: 0px 0px 0px 40px;"
                  >
                  <p style="font-size: ${fontLabel};width: 250px;margin: 20px 0px 0px 40px;">Durée de la foulée</p>
                  <p style="font-size: ${fontLabel};width: 250px;text-align: center;">${(stride_params.stride_duration).toFixed(2).toString().replace('.', ',')} s</p>
                </div>
                
                <div style="height: 40%; width: 15%;margin: 0px 150px 0px 0px;">
                  <img class="fit-picture"
                    src="${spatioLengthImage.uri}" 
                    alt="spatiotemporal length image"
                    style="height: 100%; width: 100%;"
                  >
                  <p style="font-size: ${fontLabel};width: 289px;">Longueur de la foulée</p>
                  <p style="font-size: ${fontLabel};width: 289px;text-align: center;">${(stride_params.stride_length).toFixed(2).toString().replace('.', ',')} m</p>
                </div>
                
            </div>

            <svg width="100%" height="591" style="border-bottom: 3px solid #dfcad5;">
              ${spatioTemporalFirstBar() + spatioTemporalSecondBar()}
              <text x="35" y="${585 - 5*Math.round(100*((stride_params.left_step_duration)/(stride_params.stride_duration)))}" style="font-size: ${fontLabel};" >${Math.round(100*((stride_params.left_step_duration)/(stride_params.stride_duration)))} %</text>
              <text x="215" y="${585 - 5*Math.round(100*((stride_params.right_step_duration)/(stride_params.stride_duration)))}" style="font-size: ${fontLabel};" > ${Math.round(100*((stride_params.right_step_duration)/(stride_params.stride_duration)))} %</text>
              <text x="510" y="${585 - 5*Math.round(100*((stride_params.left_step_length)/(stride_params.stride_length)))}" style="font-size: ${fontLabel};" > ${Math.round(100*((stride_params.left_step_length)/(stride_params.stride_length)))} %</text>
              <text x="690" y="${585 -5*Math.round(100*((stride_params.right_step_length)/(stride_params.stride_length)))}" style="font-size: ${fontLabel};" > ${Math.round(100*((stride_params.right_step_length)/(stride_params.stride_length)))} %</text>
            </svg>
          </div>        
        </div>


        <div style="${incContainerStyle_hip}">
          <div style="${incContainer_second_hip}">
            <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
              Cinétique (${identity})
            </h2>
            <div id="boxes_spatio">
                <div style="height: 40%; width: 15%;">
                  <img class="fit-picture"
                    src="${cineticStrengthImage.uri}" 
                    alt="kinetic strength image"
                    style="height: 100%; width: 100%;margin: 0px 0px 0px 40px;"
                  >
                  <p style="font-size: ${fontLabel};width: 270px; margin: 20px 0px 0px 40px;">Force d'impact au sol</p>
                </div>
                
                <div style="height: 40%; width: 15%;margin: 0px 90px 0px 0px;">
                  <img class="fit-picture"
                    src="${cineticRateImage.uri}" 
                    alt="kinetic rate image"
                    style="height: 100%; width: 100%;"
                  >
                  <p style="font-size: ${fontLabel};width: 200px;">Taux de charge</p>
                </div>
                
            </div>

            <svg width="100%" height="591" style="border-bottom: 3px solid #dfcad5;">
              ${cineticFirstBar() + cineticSecondBar()}
              <text x="24" y="${585 - 5*Math.round(stride_params.left_acc_peak)}" style="font-size: ${fontLabel};" >${Math.round(stride_params.left_acc_peak)} BW</text>
              <text x="205" y="${585 - 5*Math.round(stride_params.right_acc_peak)}" style="font-size: ${fontLabel};" > ${Math.round(stride_params.right_acc_peak)} BW</text>
              <text x="495" y="${585 - 5*Math.round(stride_params.left_vlr)}" style="font-size: ${fontLabel};" > ${Math.round(stride_params.left_vlr)} BW/s</text>
              <text x="665" y="${585 -5*Math.round(stride_params.right_vlr)}" style="font-size: ${fontLabel};" > ${Math.round(stride_params.right_vlr)} BW/s</text>
            </svg>
          </div> 
          
          <div style="${incContainer_second_hip}">
            <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
            Rapport sur la symétrie (${identity})
            </h2>
            
            <div style="display:flex; margin-bottom: 4%;">
              <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Cadence</p>
              <p style="font-size: ${fontLabel};width: 32%;">: ${mParamCadence} pas/min</p>
              <div style="display:flex; height: 40%; width: 15%;">
                <img class="fit-picture"
                  src="${(mParamCadence-mRefParamCadence) >= 0 ? ((mParamCadence-mRefParamCadence) === 0 ? arrowRightIcon.uri: arrowUpIcon.uri) : arrowDownIcon.uri}" 
                  alt="arrow up icon or arrow down icon"
                  style="height: 80px; width: 80px;"
                >
                <p style="font-size: ${fontLabel};">${mParamKnees-mRefParamKnees}</p>
              </div> 
            </div>
            <div style="display:flex; margin-bottom: 4%;">
              <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des amorties</p>
              <p style="font-size: ${fontLabel};width: 32%;">: ${mParamLoadingRate} %</p>
              <div style="display:flex; height: 40%; width: 15%;">
                <img class="fit-picture"
                  src="${(mParamLoadingRate-mRefParamLoadingRate) >= 0 ? ((mParamLoadingRate-mRefParamLoadingRate) === 0 ?arrowRightIcon.uri:arrowUpIcon.uri) : arrowDownIcon.uri}" 
                  alt="arrow up icon or arrow down icon"
                  style="height: 80px; width: 80px;"
                >
                <p style="font-size: ${fontLabel};"> ${mParamLoadingRate-mRefParamLoadingRate} </p>
              </div>    
            </div>
            <div style="display:flex; margin-bottom: 4%;">
              <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des impacts</p>
              <p style="font-size: ${fontLabel};width: 32%;">: ${mParamImpact} %</p>
              <div style="display:flex; height: 40%; width: 15%;">
                <img class="fit-picture"
                  src="${(mParamImpact-mRefParamImpact) >= 0 ? ((mParamImpact-mRefParamImpact) === 0 ? arrowRightIcon.uri: arrowUpIcon.uri) : arrowDownIcon.uri}" 
                  alt="arrow up icon or arrow down icon"
                  style="height: 80px; width: 80px;"
                >
                <p style="font-size: ${fontLabel};">${mParamImpact-mRefParamImpact}</p>
              </div>    
            </div>
            <div style="display:flex; margin-bottom: 4%;">
              <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des pas</p>
              <p style="font-size: ${fontLabel};width: 32%;">: ${mParamStep} %</p>
              <div style="display:flex; height: 40%; width: 15%;">
                <img class="fit-picture"
                  src="${(mParamStep-mRefParamStep) >= 0 ? ((mParamStep-mRefParamStep) === 0 ?arrowRightIcon.uri: arrowUpIcon.uri) : arrowDownIcon.uri}" 
                  alt="arrow up icon or arrow down icon"
                  style="height: 80px; width: 80px;"
                >
                <p style="font-size: ${fontLabel};"> ${mParamStep-mRefParamStep} </p>
              </div>    
            </div>
            <div style="display:flex; margin-bottom: 4%;">
              <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie du bassin</p>
              <p style="font-size: ${fontLabel};width: 32%;">: ${mParamPelvis} %</p>
              <div style="display:flex; height: 40%; width: 15%;">
                <img class="fit-picture"
                  src="${(mParamPelvis-mRefParamPelvis) >= 0 ? ((mParamPelvis-mRefParamPelvis) === 0 ? arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                  alt="arrow up icon or arrow down icon"
                  style="height: 80px; width: 80px;"
                >
                <p style="font-size: ${fontLabel};">${mParamPelvis-mRefParamPelvis}</p>
              </div>    
            </div>
            <div style="display:flex; margin-bottom: 4%;">
              <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des hanches</p>
              <p style="font-size: ${fontLabel};width: 32%;">: ${mParamHips} %</p>
              <div style="display:flex; height: 40%; width: 15%;">
                <img class="fit-picture"
                  src="${(mParamHips-mRefParamHips) >= 0 ? ((mParamHips-mRefParamHips) >= 0 ?arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                  alt="arrow up icon or arrow down icon"
                  style="height: 80px; width: 80px;"
                >
                <p style="font-size: ${fontLabel};">${(mParamHips-mRefParamHips)}</p>
              </div>    
            </div>
            <div style="display:flex;">
              <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des genoux</p>
              <p style="font-size: ${fontLabel};width: 32%;">: ${mParamKnees} %</p>
              <div style="display:flex; height: 40%; width: 15%;">
                <img class="fit-picture"
                  src="${(mParamKnees-mRefParamKnees) >= 0 ? ((mParamKnees-mRefParamKnees) >= 0 ? arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                  alt="arrow up icon or arrow down icon"
                  style="height: 80px; width: 80px;"
                >
                <p style="font-size: ${fontLabel};">${(mParamKnees-mRefParamKnees)}</p>
              </div>    
            </div>

          </div>       
        </div>
      </div>`:`<div>
      
      <div style="display: inline-block; height: 100%; width: 100%;">
        <div>
          <h1 style="text-align: center; margin: 0px 0px 40px auto; border-bottom: 3px solid #dbdad5;font-size: ${fontProfile};">
            Bilan biomécanique
          </h1>
          <div style="display: flex;">
            <div style="width: ${isWideEnough};">
              <h2 style=" margin: 0px 0px 20px 40px; font-size: ${fontProfile}; border-bottom: 3px solid #dbdad5;">
                Profil
              </h2>
              <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                <strong>Nom</strong> : ${runner.first_name} ${runner.last_name}
              </p>
              <p style=" margin: 0px 0px 10px 40px; font-size: ${fontName};">
                <strong>Date</strong> : ${dateString}
              </p>
            </div>
            <div style="width: ${isWideEnough};">
              <svg width="645" height="143">
                <rect x="20" y="20" rx="12" ry="12" width="70" height="40" id="firstRectHtml"/>
                <rect x="20" y="80" rx="12" ry="12" width="70" height="40" id="secondRectHtml"/>
                <text x="100" y="45" style="font-size: ${fontLabel};" >gauche</text>
                <text x="100" y="107" style="font-size: ${fontLabel};" >droite</text>
              </svg>
            </div>
          </div>
        </div>
        <div style="${incContainerStyle}">

          <div style="${incContainer_second_Knee}">
            <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5;font-size: ${fontTitle};">
              Bassin (${identity})
            </h2>
            <div style="width: 100%; margin: 0px 0px 0px auto;"> 
              <div id="boxes">
                <div style="height: 100%; width: 30%;">
                  <img class="fit-picture"
                    src="${pelvisInclinaisonImage.uri}" 
                    alt="pelvis tilt image"
                    style="height: 100%; width: 100%;"
                  >
                </div>
                <div style=" height: 100%; width: 45%; position: relative;">
                  <div style="${verticalBar}"></div>
                  ${pelviCard(indexesPelv1, isVertical)}
                  ${pelviDisplayGraphAngles(indexesPelv1, notIsVertical)}
                </div>
              </div>
              <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                Inclinaison
              </h3>
            </div>
            <div style="width: 100%; margin: 0px 0px 0px auto;"> 
              <div id="boxes">
                <div style=" height: 100%; width: 30%;">
                  <img class="fit-picture"
                    src="${pelvisVersionImage.uri}" 
                    alt="pelvis version image"
                    style=" height: 100%; width: 100%;"
                  >
                </div>
                <div style=" height: 100%; width: 45%; position: relative;">
                <div style="${verticalBar}"></div>
                ${pelviCard(indexesPelv2, isVertical)}
                ${pelviDisplayGraphAngles(indexesPelv2, notIsVertical)}
                </div>
              </div>
              <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                Version
              </h3>
            </div>  
          </div>

          
        </div>
      </div>
      <div style="${incContainerStyle_hip};">
        <div style="${incContainer_second}">
            <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
              Genoux (${identity})
            </h2>
            <div style="width: 100%; margin: 0px 0px 0px auto;"> 
              <div id="boxes">
                <div style="height: 100%; width: 30%;">
                  <img class="fit-picture"
                    src="${genouxFlexImage.uri}" 
                    alt="knee flex image"
                    style="height: 100%; width: 100%;"
                  >
                </div>
                <div style=" height: 100%; width: 45%; position: relative;">
                  <div style="${horizontalBar}"></div>
                  ${kneeCard(indexesKneeFlex, notIsVertical)}
                  ${kneeDisplayGraphAngles(indexesKneeFlex, isVertical)}
                </div>
              </div>
              <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                Ext./Flex.
              </h3>
            </div>
            <div style="width: 100%; margin: 0px 0px 0px auto;"> 
              <div id="boxes">
                <div style=" height: 100%; width: 30%;">
                  <img class="fit-picture"
                    src="${genouxRotImage.uri}" 
                    alt="knee rot image"
                    style=" height: 100%; width: 100%;"
                  >
                </div>
                <div style=" height: 100%; width: 45%; position: relative;">
                <div style="${horizontalBar}"></div>
                ${kneeCard(indexesKneeRot, notIsVertical)}
                ${kneeDisplayGraphAngles(indexesKneeRot, isVertical)}
                </div>
              </div>
              <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
                Rot.ext./int.
              </h3>
            </div>  
          </div>       
      </div>

      <div style="${incContainerStyle_hip};">
        
        <div style="${incContainer_second_hip}">
          <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
            Hanches (${identity})
          </h2>
          <div style="width: 100%;height: 31%; margin: 0px 0px 0px auto;"> 
            <div id="boxes_hip">
              <div style="height: 100%; width: 30%;">
                <img class="fit-picture"
                  src="${hanchesAddImage.uri}" 
                  alt="hip add image"
                  style="height: 100%; width: 100%;"
                >
              </div>
              <div style=" height: 100%; width: 45%; position: relative;">
                <div style="${horizontalBar}"></div>
                ${hipCard(indexesKneeFlex, notIsVertical)}
                ${hipDisplayGraphAngles(indexesKneeFlex, isVertical)}
              </div>
            </div>
            <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
              Abd./Add.
            </h3>
          </div>
          <div style="width: 100%;height: 30%; margin: 0px 0px 0px auto;"> 
            <div id="boxes_hip">
              <div style="height: 100%; width: 30%;">
                <img class="fit-picture"
                  src="${hanchesFlexImage.uri}" 
                  alt="hip flex image"
                  style="height: 100%; width: 100%;"
                >
              </div>
              <div style=" height: 100%; width: 45%; position: relative;">
                <div style="${horizontalBar}"></div>
                ${hipCard(indexesKneeRot, notIsVertical)}
                ${hipDisplayGraphAngles(indexesKneeRot, isVertical)}
              </div>
            </div>
            <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
              Ext./Flex.
            </h3>
          </div>
          <div style="width: 100%;height: 31%; margin: 0px 0px 0px auto;"> 
            <div id="boxes_hip">
              <div style="height: 100%; width: 30%;">
                <img class="fit-picture"
                  src="${hanchesRotImage.uri}" 
                  alt="hip rot image"
                  style="height: 100%; width: 100%;"
                >
              </div>
              <div style=" height: 100%; width: 45%; position: relative;">
                <div style="${horizontalBar}"></div>
                ${hipCard(indexesHip, notIsVertical)}
                ${hipDisplayGraphAngles(indexesHip, isVertical)}
              </div>
            </div>
            <h3 style="color: blue;padding:0px 0px 0px 20px; font-size: ${fontLabel};">
              Rot.ext./int.
            </h3>
          </div>  
        </div>       
      </div>


      <div style="${incContainerStyle_hip};">
        
        <div style="${incContainer_second_hip}">
          <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
            Spatio-temporels (${identity})
          </h2>
          <div id="boxes_spatio">
              <div style="height: 40%; width: 18%;">
                <img class="fit-picture"
                  src="${spatioTempImage.uri}" 
                  alt="spatiotemporal image"
                  style="height: 100%; width: 100%;margin: 0px 0px 0px 40px;"
                >
                <p style="font-size: ${fontLabel};width: 350px;margin: 20px 0px 0px 40px;">Durée de la foulée</p>
                <p style="font-size: ${fontLabel};width: 350px;text-align: center;">${(stride_params.stride_duration).toFixed(2).toString().replace('.', ',')} s</p>
              </div>
              
              <div style="height: 40%; width: 25%;margin: 0px 150px 0px 0px;">
                <img class="fit-picture"
                  src="${spatioLengthImage.uri}" 
                  alt="spatiotemporal length image"
                  style="height: 100%; width: 100%;"
                >
                <p style="font-size: ${fontLabel};width: 389px;">Longueur de la foulée</p>
                <p style="font-size: ${fontLabel};width: 289px;text-align: center;">${(stride_params.stride_length).toFixed(2).toString().replace('.', ',')} m</p>
              </div>
              
          </div>

          <svg width="100%" height="800" style="border-bottom: 3px solid #dfcad5;">
            ${spatioTemporalFirstBar() + spatioTemporalSecondBar()}
            <text x="20" y="${785 - 5*Math.round(100*((stride_params.left_step_duration)/(stride_params.stride_duration)))}" style="font-size: ${fontLabel};" >${Math.round(100*((stride_params.left_step_duration)/(stride_params.stride_duration)))} %</text>
            <text x="250" y="${785 - 5*Math.round(100*((stride_params.right_step_duration)/(stride_params.stride_duration)))}" style="font-size: ${fontLabel};" > ${Math.round(100*((stride_params.right_step_duration)/(stride_params.stride_duration)))} %</text>
            <text x="705" y="${785 - 5*Math.round(100*((stride_params.left_step_length)/(stride_params.stride_length)))}" style="font-size: ${fontLabel};" > ${Math.round(100*((stride_params.left_step_length)/(stride_params.stride_length)))} %</text>
            <text x="945" y="${785 -5*Math.round(100*((stride_params.right_step_length)/(stride_params.stride_length)))}" style="font-size: ${fontLabel};" > ${Math.round(100*((stride_params.right_step_length)/(stride_params.stride_length)))} %</text>
          </svg>
        </div>       
      </div>

      <div style="${incContainerStyle_hip}">
        <div style="${incContainer_second_hip}">
          <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
            Cinétique (${identity})
          </h2>
          <div id="boxes_spatio">
              <div style="height: 40%; width: 15%;">
                <img class="fit-picture"
                  src="${cineticStrengthImage.uri}" 
                  alt="kinetic strength image"
                  style="height: 100%; width: 100%;margin: 0px 0px 0px 40px;"
                >
                <p style="font-size: ${fontLabel};width: 470px; margin: 20px 0px 0px 40px;">Force d'impact au sol</p>
              </div>
              
              <div style="height: 40%; width: 18%;margin: 0px 90px 0px 0px;">
                <img class="fit-picture"
                  src="${cineticRateImage.uri}" 
                  alt="kinetic rate image"
                  style="height: 100%; width: 100%;"
                >
                <p style="font-size: ${fontLabel};width: 290px;">Amorti</p>
              </div>
              
          </div>

          <svg width="100%" height="800" style="border-bottom: 3px solid #dfcad5;">
            ${cineticFirstBar() + cineticSecondBar()}
            <text x="20" y="${785 - 5*Math.round(stride_params.left_acc_peak)}" style="font-size: ${fontLabel};" >${Math.round(stride_params.left_acc_peak)} BW</text>
            <text x="240" y="${785 - 5*Math.round(stride_params.right_acc_peak)}" style="font-size: ${fontLabel};" > ${Math.round(stride_params.right_acc_peak)} BW</text>
            <text x="675" y="${785 - 5*Math.round(stride_params.left_vlr)}" style="font-size: ${fontLabel};" > ${Math.round(stride_params.left_vlr)} BW/s</text>
            <text x="885" y="${785 -5*Math.round(stride_params.right_vlr)}" style="font-size: ${fontLabel};" > ${Math.round(stride_params.right_vlr)} BW/s</text>
          </svg>
        </div>        
      </div>

      <div style="${incContainerStyle_hip}">
        <div style="${incContainer_second_hip}">
          <h2 style="color: blue; text-align: center;border-bottom: 3px solid #dbdad5; font-size: ${fontTitle};">
          Rapport sur la symétrie (${identity})
          </h2>
          
          <div style="display:flex; margin-bottom: 4%;">
            <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Cadence</p>
            <p style="font-size: ${fontLabel};width: 32%;">: ${mParamCadence} pas/min</p>
            <div style="display:flex; height: 40%; width: 15%;">
              <img class="fit-picture"
                src="${(mParamCadence-mRefParamCadence) >= 0 ? ((mParamCadence-mRefParamCadence) === 0 ? arrowRightIcon.uri: arrowUpIcon.uri) : arrowDownIcon.uri}" 
                alt="arrow up icon or arrow down icon"
                style="height: 80px; width: 80px;"
              >
              <p style="font-size: ${fontLabel};">${mParamKnees-mRefParamKnees}</p>
            </div> 
          </div>
          <div style="display:flex; margin-bottom: 4%;">
            <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des amorties</p>
            <p style="font-size: ${fontLabel};width: 32%;">: ${mParamLoadingRate} %</p>
            <div style="display:flex; height: 40%; width: 15%;">
              <img class="fit-picture"
                src="${(mParamLoadingRate-mRefParamLoadingRate) >= 0 ? ((mParamLoadingRate-mRefParamLoadingRate) === 0 ? arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                alt="arrow up icon or arrow down icon"
                style="height: 80px; width: 80px;"
              >
              <p style="font-size: ${fontLabel};"> ${mParamLoadingRate-mRefParamLoadingRate} </p>
            </div>    
          </div>
          <div style="display:flex; margin-bottom: 4%;">
            <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des impacts</p>
            <p style="font-size: ${fontLabel};width: 32%;">: ${mParamImpact} %</p>
            <div style="display:flex; height: 40%; width: 15%;">
              <img class="fit-picture"
                src="${(mParamImpact-mRefParamImpact) >= 0 ? ((mParamImpact-mRefParamImpact) === 0 ? arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                alt="arrow up icon or arrow down icon"
                style="height: 80px; width: 80px;"
              >
              <p style="font-size: ${fontLabel};">${mParamImpact-mRefParamImpact}</p>
            </div>    
          </div>
          <div style="display:flex; margin-bottom: 4%;">
            <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des pas</p>
            <p style="font-size: ${fontLabel};width: 32%;">: ${mParamStep} %</p>
            <div style="display:flex; height: 40%; width: 15%;">
              <img class="fit-picture"
                src="${(mParamStep-mRefParamStep) >= 0 ? ((mParamStep-mRefParamStep) === 0 ? arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                alt="arrow up icon or arrow down icon"
                style="height: 80px; width: 80px;"
              >
              <p style="font-size: ${fontLabel};"> ${mParamStep-mRefParamStep} </p>
            </div>    
          </div>
          <div style="display:flex; margin-bottom: 4%;">
            <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie du bassin</p>
            <p style="font-size: ${fontLabel};width: 32%;">: ${mParamPelvis} %</p>
            <div style="display:flex; height: 40%; width: 15%;">
              <img class="fit-picture"
                src="${(mParamPelvis-mRefParamPelvis) >= 0 ? ((mParamPelvis-mRefParamPelvis) === 0 ? arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                alt="arrow up icon or arrow down icon"
                style="height: 80px; width: 80px;"
              >
              <p style="font-size: ${fontLabel};">${mParamPelvis-mRefParamPelvis}</p>
            </div>    
          </div>
          <div style="display:flex; margin-bottom: 4%;">
            <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des hanches</p>
            <p style="font-size: ${fontLabel};width: 32%;">: ${mParamHips} %</p>
            <div style="display:flex; height: 40%; width: 15%;">
              <img class="fit-picture"
                src="${(mParamHips-mRefParamHips) >= 0 ? ((mParamHips-mRefParamHips) === 0 ? arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                alt="arrow up icon or arrow down icon"
                style="height: 80px; width: 80px;"
              >
              <p style="font-size: ${fontLabel};">${(mParamHips-mRefParamHips)}</p>
            </div>    
          </div>
          <div style="display:flex;">
            <p style="padding-left: 1%; font-size: ${fontLabel};width: 50%;">Symétrie des genoux</p>
            <p style="font-size: ${fontLabel};width: 32%;">: ${mParamKnees} %</p>
            <div style="display:flex; height: 40%; width: 15%;">
              <img class="fit-picture"
                src="${(mParamKnees-mRefParamKnees) >= 0 ? ((mParamKnees-mRefParamKnees) === 0 ? arrowRightIcon.uri : arrowUpIcon.uri) : arrowDownIcon.uri}" 
                alt="arrow up icon or arrow down icon"
                style="height: 80px; width: 80px;"
              >
              <p style="font-size: ${fontLabel};">${(mParamKnees-mRefParamKnees)}</p>
            </div>    
          </div>
        </div>       
      </div>
    </div>`}
    </body>
  </html>
  `;

    useEffect(() => {
      let printToFile = async () => {
        // On iOS/android prints the given html. On web prints the HTML from the current page.
        const { uri } = await Print.printToFileAsync({ html, width : cardWidth, height : height, base64 : false });
        setUriCall(uri);
        setAffiche(true)
          Share.open({url:uri})
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              err && console.log(err);
            });
      };
      printToFile();
    }, []);
  

  return(
    <I18nProvider locale={locale} defaultLocale="fr-FR">
      <NativeBaseProvider>
        {affiche && <View style={{flex: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: 25,}}>
          <Pdf
            trustAllCerts={false}
            source={{ uri: uriCall}}
            onLoadComplete={(numberOfPages,filePath) => {
                console.log(`Number of pages: ${numberOfPages}`);
            }}
            onPageChanged={(page,numberOfPages) => {
                console.log(`Current page: ${page}`);
            }}
            onError={(error) => {
                console.log(error);
            }}
            onPressLink={(uri) => {
                console.log(`Link pressed: ${uri}`);
            }}
            style={{flex:1,
              width:width, height:height}}
          />
        </View>}
      </NativeBaseProvider>
    </I18nProvider>
  );
}

export default PdfSession;