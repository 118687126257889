import React, {useState} from 'react';
import { NativeBaseProvider, Stack, ScrollView } from 'native-base';
import FormUpdateNewPatient from '../components/FormUpdateNewPatient.js'

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import {Picker} from '@react-native-picker/picker';
import { View, StyleSheet, useWindowDimensions } from 'react-native';

const UpdateRunner = ({route, navigation})=>{

    const [selectedLanguage, setSelectedLanguage] = useState("Français");
    const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}]
    const [locale, setLocale]= useState(LOCALES.FRENCH)
    const setEnglishlanguages = (lang) =>{
        lang == "Français" ? setLocale(LOCALES.ENGLISH): setLocale(LOCALES.FRENCH)
    }

    const isHighEnough = useWindowDimensions().height>730;
    const isWideEnough = useWindowDimensions().width>600;

    const { runner, access_token, public_id, setRunnersList } = route.params;
     
    return(
        <NativeBaseProvider style={{fontFamily:"sans-serif"}}>
            <ScrollView _contentContainerStyle={{height: isWideEnough? (isHighEnough? '100%' : 460) : 700 }}>                    
                <View style={styles.picker_container}>
                    <Picker style={styles.picker_component}
                        selectedValue={selectedLanguage}
                        onValueChange={(itemValue, itemIndex) =>{
                            setSelectedLanguage(itemValue)
                            setEnglishlanguages(selectedLanguage)
                        }}>
                        {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
                    </Picker>
                </View>
                <Stack
                alignItems="center"
                >
                    <FormUpdateNewPatient runner={runner} access_token={access_token} navigation={navigation} public_id={public_id} setRunnersList={setRunnersList} />
                </Stack>
            </ScrollView>
        </NativeBaseProvider>
    )
}

export default UpdateRunner

const styles = StyleSheet.create({
    picker_component:{
      flex:1,
      borderRadius: 4,
      width: 150,
      marginRight: 30,
      justifyContent:'flex-end'
    },
    picker_container:{
      width: "100%",
      alignItems: "flex-end",
      height: 30,
    }
  })
  