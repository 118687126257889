import React, { useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity, useWindowDimensions } from "react-native";

import { FormControl, Input, Select, Center } from "native-base";
import { RadioButton } from 'react-native-paper';
import translate from '../i18n/translate';
// utils functions
import { updateRunner  } from '../src/fetch.js';
import { get } from '../src/fetch.js';

export default function FormUpdateNewPatient({runner, setRunnersList, access_token, navigation, public_id}) {

    const [firstName, setFirstName] = useState(runner.first_name);
    const [firstnameError, setFirstnameError] = useState('');
    const [lastName, setLastName] = useState(runner.last_name);
    const [lastNameError, setLastNameError] = useState('');
    const [checked, setChecked] = useState(runner.sex);
    const [checkedError, setCheckedError] = useState('');
    const [size, setSize] = useState(`${runner.size}`);
    const [sizeError, setSizeError] = useState(null);
    const [weight, setWeight] = useState(`${runner.weight}`);
    const [weightError, setWeightError] = useState(null);  
    const [age, setAge] = useState(`${runner.age}`);
    const [ageError, setAgeError] = useState(null);
    const [selectArea, setSelectArea] = useState(runner.body_region);
    const [selectAreaError, setSelectAreaError] = useState('');    
    const [selectSercice, setSelectSercice] = useState(runner.diagnosis);

    const largeBreakpoint = 600;
    const width = useWindowDimensions().width;
    const isWideEnough = (width > largeBreakpoint);
      
    const onSubmit = () =>{
        const data = {
            "first_name": firstName,
            "last_name": lastName,
            "sex": checked,
            "size" : size,
            "weight": weight,
            "age": age,
            "body_region": selectArea,
            "diagnosis" : selectSercice
        }

        const regexNumber = /^[0-9]+$/;
        const regexString = /^(?:[^\d\W][\-\s\']{0,1}){1,20}$/i;
        const route = `/api/runners/${runner.public_id}`;
        const getRoute = `/api/trainers/${public_id}/runners`;
        if(firstName!=="" && regexString.test(firstName)){
            if(lastName!=="" && regexString.test(lastName)){
                if(checked!==""){
                    if(weight!==null && regexNumber.test(weight)){
                        if(size!==null && regexNumber.test(size)){
                            if(age!==null && regexNumber.test(age)){
                                if(selectArea!=="" && selectSercice!==""){
                                    updateRunner(route, access_token, data)
                                        .then(({json}) => {
                                            console.log(json)
                                            get(getRoute, access_token)
                                                .then(({json}) => {
                                                    setRunnersList(json);
                                                    navigation.navigate('RunnersList',{
                                                        access_token,
                                                        public_id
                                                      });
                                                })
                                                .catch( err => console.error(err));        
                                        })
                                        .catch( err => console.error(err)); 
                                }else{
                                    setSelectAreaError("Veuillez renseigner tous les deux champs de diagnostic")
                                }          
                            }else{
                                setAgeError("Uniquement des nombres")
                            }                            
                        }else{
                            setSizeError("Uniquement des nombres")
                        }                        
                    }else{
                        setWeightError("Uniquement des nombres")
                    }                   
                }else{
                    setCheckedError("Femme ou Homme ?")
                }              
            }else{
                setLastNameError("Renseignez bien ce champ")
            }            
        }else{
            setFirstnameError("Renseignez bien ce champ");
        }       
    } 

    const cancelForm = () =>{  
        navigation.navigate('RunnersList',{
            access_token,
            public_id
          });
    } 

    return (
        <Center style={[styles.add_patient, {width:isWideEnough? 590 : '90%', height:isWideEnough? 350 : 'auto'}]}>
            <View  style={styles.formContainer}>
                <Text style={styles.textTitle}>{translate('new_patient')}</Text>
                <View style={styles.form}>
                    <View style={[styles.flexInputText, {flexDirection:isWideEnough? 'row' : 'column'}]}>
                        <FormControl style={[styles.InputTextControl, {padding: isWideEnough? 10 : 0}]}>
                            <Input
                                style={styles.input}
                                placeholder="Nom"
                                value={firstName}
                                onChangeText={(text) => setFirstName(text)}
                            />
                            {firstnameError&&<Text style={styles.errorMsg}>{firstnameError}</Text>}
                        </FormControl>
                        <FormControl style={[styles.InputTextControl, {padding: isWideEnough? 10 : 0}]}>
                            <Input
                                style={styles.input}
                                placeholder="Prénom"
                                value={lastName}
                                onChangeText={(text) => setLastName(text)}
                            />
                            {lastNameError&&<Text style={styles.errorMsg}>{lastNameError}</Text>}
                        </FormControl>  
                        <FormControl style={styles.InputTextControl}>
                            <View style={[styles.flexRadio, {justifyContent:isWideEnough? 'center' : 'flex-start'}]} >
                                <RadioButton
                                    value="F"  
                                    status={ checked === 'F' ? 'checked' : 'unchecked' }
                                    onPress={() => setChecked('F')}
                                />
                                <Text>F</Text>
                                <RadioButton
                                    value="M"
                                    status={ checked === 'M' ? 'checked' : 'unchecked' }
                                    onPress={() => setChecked('M')}
                                />
                                <Text>M</Text>
                            </View>
                            {checkedError&&<Text style={styles.errorMsg}>{checkedError}</Text>}
                        </FormControl>       
                    </View>
                    <View style={[styles.flexInputNumber, {flexDirection: isWideEnough? 'row' : 'column', alignItems:'flex-start'}]}>
                        <View style={styles.InputTextControl}>
                            <FormControl style={styles.flexString}>
                                <Input
                                    style={styles.inputNumber}
                                    placeholder="Poids"
                                    value={weight}
                                    onChangeText={(text) => setWeight(text)}
                                />
                                <Text>kg</Text>
                            </FormControl>
                            {weightError&&<Text style={styles.errorMsg}>{weightError}</Text>}
                        </View>
                        <View style={styles.InputTextControl}>
                            <FormControl style={styles.flexString}>
                                <Input
                                    style={styles.inputNumber}
                                    placeholder="Taille"
                                    value={size}
                                    onChangeText={(text) => setSize(text)}
                                />
                                <Text>cm</Text>
                            </FormControl>
                            {sizeError&&<Text style={styles.errorMsg}>{sizeError}</Text>}
                        </View>
                        <View style={styles.InputTextControl}>
                            <FormControl style={styles.flexString}>
                                <Input
                                    style={styles.inputNumber}
                                    placeholder="Age" 
                                    value={age}
                                    onChangeText={(text) => setAge(text)}
                                />
                                <Text>ans</Text>
                            </FormControl>
                            {ageError&&<Text style={styles.errorMsg}>{ageError}</Text>}
                        </View>  
                    </View>
                    <Text style={styles.sizText}>{translate('input_diagrostic')} :</Text>
                    <View style={[styles.radioButtonForm, {flexDirection:isWideEnough? 'row' : 'column', alignItems:isWideEnough? 'center' : 'flex-start'}]} >
                        <Select
                                my={isWideEnough? 0 : 1}
                                borderColor='#00d5f7'
                                borderRadius={60}
                                selectedValue={selectArea}
                                minWidth={260}
                                marginRight={2}
                                height={39.9}
                                placeholder="Région du corps"
                                onValueChange={(itemValue) => setSelectArea(itemValue)}
                                >
                            <Select.Item label="Pied/cheville droit(e)" value="Pied/cheville droit(e)" />
                            <Select.Item label="Pied/cheville gauche" value="Pied/cheville gauche" />
                            <Select.Item label="Genou/jambe droit(e)" value="Genou/jambe droit(e)" />
                            <Select.Item label="Genou/jambe gauche" value="Genou/jambe gauche" />
                            <Select.Item label="Hanche/cuisse droite" value="Hanche/cuisse droite" />
                            <Select.Item label="Hanche/cuisse gauche" value="Hanche/cuisse gauche" />
                            <Select.Item label="Dos/bassin" value="Dos/bassin" />
                        </Select>
                        
                        <Select
                            selectedValue={selectSercice}
                            borderRadius={60}
                            borderColor='#00d5f7'
                            minWidth={300}
                            height={39.9}
                            placeholder="Pathologie"
                            onValueChange={(itemValue) => setSelectSercice(itemValue)}
                            >
                            <Select.Item label="Syndrome rotulien (SFP)" value="Syndrome rotulien (SFP)" />
                            <Select.Item label="Rupture des ligaments croisés" value="Rupture des ligaments croisés" />
                            <Select.Item label="Entorse du genou" value="Entorse du genou" />
                            <Select.Item label="Fracture diaphysaire de la jambe" value="Fracture diaphysaire de la jambe" />
                            <Select.Item label="Fracture du genou" value="Fracture du genou" />
                            <Select.Item label="Rupture du tendon patellaire" value="Rupture du tendon patellaire" />
                            <Select.Item label="Tendinopathie quadricipitale" value="Tendinopathie quadricipitale" />
                        </Select>
                    </View>
                    <Text style={styles.errorMsg}>{selectAreaError}</Text>
                    <View style={[styles.flexButton, {width:isWideEnough? 550 : "95%"}]}>
                        <TouchableOpacity
                            style={[styles.customBtnBG, {width:isWideEnough ? 150 : 100}]}
                            onPress={cancelForm}>
                            <Text style={styles.customBtnText}>{translate('cancel')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.customBtnBG, {width:isWideEnough ? 150 : 100}]}
                            onPress={onSubmit} >
                            <Text style={styles.customBtnText}>{translate('add')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>    
        </Center>
    );
}

const styles = StyleSheet.create({
    add_patient: {
        flex: 1,
        borderColor: '#00d5f7',
        margin: 5,
        borderRadius: 20,
        backgroundColor: '#FFF',
        position: 'absolute',
        top :40,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 2,
    },
    input:{
        borderWidth: 1,
        borderColor: '#00d5f7',
        borderStyle: 'solid',
        borderRadius: 50,
        margin: 5,
        marginRight: 50,        
        width: 180,
        height: 40,
    },
    inputNumber:{
        borderWidth: 1,
        borderColor: '#00d5f7',
        borderStyle: 'solid',
        borderRadius: 50,
        padding: 5,
        margin: 5,
        width: 150,
        height: 40,
    },
    button:{
        height: 40,
        padding: 50,
        margin: 50,
        borderRadius: 10,
    },
    customBtnBG: {
        backgroundColor: "#00d5f7",
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 30,
        margin: 12,
    },
    customBtnText: {
        fontSize: 18,
        fontWeight: '400',
        color: "#fff",
        textAlign: 'center'
    },
    form: {
        flex: 1,
        height: 'auto',
        justifyContent: 'center',
        padding: 5,
        marginTop: 5,
        marginBottom: 10
    },
    formContainer:{
        justifyContent: 'center',
        marginTop: 5,
    },
    flexInputText:{
        flex: 1,
        marginBottom: 30,
        alignItems: 'center'
    },
    flexInputNumber:{
        flex: 1,
        width: '100%',
        justifyContent: 'center',
    },
    InputTextControl:{
        flex: 1,
        padding: 10,  
        margin: -14        
    },
    radioButtonForm:{
        flex: 1,  
        width: "100%",       
    },
    
    flexString:{
        flex: 1,  
        flexDirection: 'row',  
        alignItems: 'center', 
    },
    flexRadio:{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center', 
    },
    flexButton:{
        flex: 1,  
        flexDirection: 'row',  
        justifyContent: 'space-around',
        alignItems: 'center', 
        marginTop: 15,
    },
    sizText:{
        marginTop: 20,
        marginBottom: 10,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00d5f7'
    },
    textTitle:{
        fontSize: 19,
        marginBottom: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#00d5f7'
    },
    errorMsg:{
        fontSize: 12,
        width: '100%', 
        color: 'red',
        padding: 0,
        margin: 0,
    }
})
  