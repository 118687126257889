import React, {useEffect, useState, useRef} from 'react';
import { NativeBaseProvider, Box, Text, Button, Icon, Center, VStack, Heading, Image, ScrollView } from 'native-base';
import NetInfo from "@react-native-community/netinfo";
import { parseError, handleErrors } from '../src/masterErrorManagement.js';
import { AnimatedCircularProgress } from 'react-native-circular-progress';

// intl components
import 'intl';
import translate from '../i18n/translate';
import { I18nProvider, LOCALES } from '../i18n';
import { View, StyleSheet, ActivityIndicator, useWindowDimensions, TouchableOpacity } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import  { RadioButton } from 'react-native-paper';


// Import the native module
import { NativeModules } from 'react-native';

const { CollectData } = NativeModules;

// Import Image
const bodyFigure = require('../images/png/full_body_captors.png');

const RetrieveSession = ({ route, navigation }) => {

  const { activity, sessionList, access_token, runner, choiceActivity, label } = route.params;

  const [numberOfIMUs, setNumberOfIMUs] = useState(5);
  const [imuInformation, setImuInformation] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("Français");
  const [isCalibrating, setIsCalibrating] = useState(false);
  const [desabledButton, setDesabledButton] = useState(false);
  const [xsensStatus, setXsensStatus] = useState(["0000000", "00000000000000"]);
  const [offline, setOffline] = useState('');
  const [isSynchro, setIsSynchro] = useState(false);
  const [initFile, setinitFile] = useState("");
  const [synchroCount, setSynchroCount] = useState(true);
  const [scaleStatus, setScaleStatus] = useState(true);
  const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}];

  const [capitalErrors, setCapitalErrors] = useState("");
  const [pingMasterErrors, setPingMasterErrors] = useState("");
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { height, width } = useWindowDimensions();
  const largeBreakpoint = 992;
  const midBreakpoint = 688;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
  const isWideEnough = width >= midBreakpoint;
  const isHorizontal = (width > height);
  const isFigureDisplayable = (isHorizontal && isWideEnough) || (height > largeBreakpoint);

  const canNavigate = useRef(capitalErrors);
  canNavigate.current = (capitalErrors==="");
  let isInternetReachable = false;
  let circularProgressRef = React.createRef();

  useEffect(() => {
    if(scaleStatus){
      init_file_and_sensors_connected();
      setScaleStatus(false);
    }
    const unsubscribeHandler = NetInfo.addEventListener(state => {
      if(state.isInternetReachable === false){
        isInternetReachable = true;
      }
      if(state.details.ssid != undefined){
        console.log(state.details.ssid)
        setOffline("");
        if(state.details.ssid.substring(0, 11) === "MYSMARTMOVE"){
          CollectData.CloseAll()
          .then((res)=>{
            if(res === "Success"){
              setOffline(translate('disconnect_box_message'));
              console.log("The socket closed successfully");
            }
          })
          .catch((res)=>{
            if(res === "BAD CLOSE"){
              console.log("socket close failed");
            }
          }); 
    
        }else{    
          if(isInternetReachable && state.isInternetReachable === true){
            navigation.navigate('Runner', {access_token, runner });
          } 
        } 
      }else{
        CollectData.CloseAll()
        .then((res)=>{
          if(res === "Success"){
            setOffline(translate('disconnect_box_message'));
            console.log("The socket closed successfully");
          }
        })
        .catch((res)=>{
          if(res === "BAD CLOSE"){
            console.log("socket close failed");
          }
        });
        
      }    
    });
    return ()=>{
      console.log("exec unsubscribeHandle");
      unsubscribeHandler();
    } 
  }, []);

    useEffect(() => {
        const intervalGetError = setInterval(asyncGetError, 3000);
        if(isSynchro){circularProgressRef.current.animate(100, 5000);}
        if (!isMeasuring) clearInterval(intervalGetError);

        return () => {
            clearInterval(intervalGetError);
        };
    }, [isMeasuring, isSynchro]);

    const asyncGetError = () => {
        console.log("ERROR detection initiated");
        CollectData.ErrorMessage().then(response => {
            if (response != "NO ERROR") {
                const messages = response.split('\n').filter(function (item) { return item.length === 9 });
                messages.forEach(msg => {
                    console.log(parseError(msg));
                });
                const errs = handleErrors(messages);
                if (errs === "") {
                    console.log("No error, Master is fine.");
                } else {
                    console.log("Master Error:: ::" + errs);
                    CollectData.GetAcknowledgePingMaster().then((pinResponse)=>{
                      console.log("The ping response Master is :: :: " + pinResponse);
                      if(pinResponse === 1){
                        setIsMeasuring(false);
                        setErrorMessage(errs);
                        setCapitalErrors(translate('error_master_message'));
                      }else if(pinResponse === 0){
                        console.log("The ping response Master is :: :: " + pinResponse);
                        setPingMasterErrors(translate('error_master_notImus'))
                      }
                    }).catch((err)=>console.log(err));
                }
            } else {
                console.log("No error, Master is fine.");
            }
        }).catch(error => console.error(error));
    }

    const testPingMaster = ()=>{
      CollectData.GetAcknowledgePingMaster().then((rest)=>{
        console.log("Rest yes yes oui:: :: " + rest);
      }).catch((err)=>console.log(err))
    }

    const statusIsConnected = (status) => {
    try{
      //Checking that we are connected to each sensor and that the battery level is not nil
      for(let i = 0; i<numberOfIMUs; i++)
      {
        if(status[0][i] != 1)
        {
            console.log("RetrieveSession / statusIsConnected ==> status[0][i] !=1 / i = %d" ,i)
            return false;
        }
        //if(status[1].slice(i,i+2) == '00')
        console.log("RetrieveSession / statusIsConnected ==> status[1].slice(i,i+2) = %d", parseInt(status[1].slice(i,i+2)))
        if(parseInt(status[1].slice(i,i+2)) <= 10)
        {
          console.log("RetrieveSession / statusIsConnected ==> status[1].slice(i,i+2) <= 10, %d", parseInt(status[1].slice(i,i+2)))
          return false;
        }
      }
      return true;
      } catch(error) {
        console.log("RetrieveSession / statusIsConnected ==> error = %s", error)
      }
    }

  const displayLoadingConnection = ()=>{
    if(!statusIsConnected(xsensStatus)){
      return(
       <View>
         <ActivityIndicator size='large'color="#000000"/>
       </View> 
      )
    }
  }

  const displayLoadingCalibration = ()=>{
    if(isCalibrating){
      return(
       <View>
         <ActivityIndicator size='large'color="#000000"/>
       </View> 
      )
    }
  }

  const init_file_and_sensors_connected = ()=>{
    console.log(`RetrieveSession.render:: calling Initialisation`);
    var counter = 0;
    var numberoftime = 0;
    var numberOfcount = 0;
    console.log('démarrage de intervalle appelée::'+ initFile)
    var setInter = setInterval(() => {
      numberOfcount++;
    }, 1000);
  
    var infoSystems  = setInterval(()=>{
      CollectData.DeviceInfo().then(response=>{
        numberoftime ++;
        console.log(`RetrieveSession.render sensors Xsens :: ${response}:: n° : ${numberoftime}`);
        setXsensStatus(response)
        if(statusIsConnected(response)){
          clearInterval(setInter);
          clearInterval(infoSystems);
          counter ++;
          
          if(label === "Quaternions"){
            console.log("Quaternions:: ::");
            CollectData.QuaternionsInitialisation().then(data=>{
              if(data){
                setDesabledButton(true);
              }
            }).catch(err => {
              ToastAndroid.show(
                `Une erreur est survenue lors de la mesure. Veuillez refaire l'exercice s'il vous plaît !`,
                ToastAndroid.LONG
              );
              CollectData.CloseAll()
              .then(res => {
                if(res === "Success"){
                  console.log("Socket has been successfully closed.");
                  navigation.navigate('Runner', {access_token, runner });
                }else{
                  console.log("An error occured while trying to close socket.", res);
                }
              })
              .catch(error => console.error(error));
            });
          }else{
            console.log("Exercice normale:: ::");
            CollectData.Initialisation().then(data=>{
              if(data){
                setDesabledButton(true);
              }
            }).catch(err => {
              ToastAndroid.show(
                `Une erreur est survenue lors de la mesure. Veuillez refaire l'exercice s'il vous plaît !`,
                ToastAndroid.LONG
              );
              CollectData.CloseAll()
              .then(res => {
                if(res === "Success"){
                  console.log("Socket has been successfully closed.");
                  navigation.navigate('Runner', {access_token, runner });
                }else{
                  console.log("An error occured while trying to close socket.", res);
                }
              })
              .catch(error => console.error(error));
            });
          }

        }
      }).catch(err => console.error(err))
      if(counter === 1 || numberoftime === 20 || numberOfcount === 20){
        clearInterval(setInter);
        clearInterval(infoSystems);
        if(numberoftime === 20 || numberOfcount === 20){
          CollectData.GetAcknowledgePingMaster().then((pinResponse)=>{
            console.log("The ping response Master is :: :: " + pinResponse);
            if(pinResponse === 1){
              setCapitalErrors(translate('sensors_offline_message'));
            }else if(pinResponse === 0){
              setPingMasterErrors(translate('error_master_notImus'))
            }
          }).catch((err)=>console.log(err));
          // setCapitalErrors(translate('sensors_offline_message'));
        }
      }
    },2000 ); 
  }

  const getKeysForSensors = () => {
    var ans = [];
    for (let i = 0; i < numberOfIMUs; i++)
    {
        ans.push(i);
        //console.log("RetrieveSessions / getKeysForSensors ==> i: %d", i)
    }
    return ans;
  }
  let imuPercentage = "";
  const getBatteryColor = (umiIndex) => {
    let col = '#00d5f7';
    if (xsensStatus[1].substring(umiIndex*2, (umiIndex+1)*2) <= 10){
      col = '#ff0000';
      imuPercentage = "Veuillez recharger vos capteurs";
    }else if(xsensStatus[1].substring(umiIndex*2, (umiIndex+1)*2) <= 50){
      col = '#ffb636';
    }
    return col;
  }

  const displayConnectionState = () => {
    return (
      <View flexDirection="row" style={{justifyContent:isWideEnough? "flex-start" : "center"}}>
        {isWideEnough && <Text style = {{fontSize:isTablet?15:12, paddingLeft: 15, paddingRight: 10, paddingTop: 10, lineHeight: 25}}>{translate('connection_status_message')} :{"\n"}{translate('iMUs_battery_level')} :</Text>}
        {getKeysForSensors().map(item => {
        try{
          return (
            <View key={item} style = {{flexDirection:'column', justifyContent: 'center', paddingRight: isTablet? 10:4, paddingTop: 8}}>
              <RadioButton value = {String(item)}
                  status = {xsensStatus[0].charAt(item) === '1' ? 'checked' : 'unchecked'}
                  color = {getBatteryColor(item)}
              />
              <Text style = {{fontSize:isTablet?15:12, paddingLeft:7}} color= {getBatteryColor(item)}
                >{xsensStatus[1].substring(item*2, (item+1)*2)}%</Text>
              {getBatteryColor(item) ==='#ff0000' && <Text style = {{fontSize:isTablet?18:12, paddingLeft:7}} color= {getBatteryColor(item)}
                >Rechargez</Text>}
            </View>)
            } catch (error) {
              console.error("RetrieveSession / displayConnectionState  ==> error " + error);
            }
          })
        }
      </View>
    )
  }

  const informationOrderIMU = () => {
    return (<TouchableOpacity style={{marginLeft: "2%", width: isTablet ? 30 : 20, height: isTablet ? 30 : 20, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
        onPress={() => setImuInformation(true)}>
        <Heading fontSize={isTablet ? 20 : 16} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
      </TouchableOpacity>
    );
  }
  return(
    <NativeBaseProvider flex={1} style={{fontFamily:"sans-serif"}}>
      <ScrollView>
        <View style = {styles.upper_container}>
          {isWideEnough && <View style={{flexDirection: "row"}}>
              {informationOrderIMU()}
              {displayConnectionState()}
          </View>}
          <View style={styles.picker_container}>
            <Picker style={styles.picker_component}
                selectedValue={selectedLanguage}
                onValueChange={(itemValue, itemIndex) =>{
                  setSelectedLanguage(itemValue)
                  route.params.translationFunc(selectedLanguage)
                }}>
                {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
            </Picker>
          </View>
        </View>
        <Box mt="5">
          {label !== "Quaternions" &&<Center>
            <Heading mt="40px" color="#00d5f7" fontSize={isWideEnough? "4xl" : "2xl"}>
              {translate('new_balance')}
            </Heading>
            <Text mt="20px" color="#00d5f7" fontSize={isWideEnough? "xl" : 16} >
            {"\t\t"} 1. {translate('calibration_information')}          
            </Text>
            <Text mt="20px" color="#00d5f7" fontSize={isWideEnough? "xl" : 16}>
            2. {translate('patient_position')}           
            </Text>
            { offline !== "" && <Text textAlign="center" mt="20px" mb="15px" color="#32a852" fontSize="xl">
              {offline}           
            </Text>}
            <View style = {{flexDirection: 'column'}}>
              <Text mt="20px" color="#000000" textAlign="center" mb="15px" fontSize="xl">
                {statusIsConnected(xsensStatus)? "Connexion établie."  : "Connexion en cours..."}
              </Text>
              {displayLoadingConnection()}
            </View>    
          </Center>}

          <VStack my="40px" space={5} alignItems="center">
            {label === "Quaternions" ?
            <Center marginTop= {100}>
              <View style = {{flexDirection: 'column'}}>
                {!isSynchro ?<Text style={{fontSize: isTablet? 18 : 15}} mt="20px" color="#000000" textAlign="center" mb="30px">
                  {statusIsConnected(xsensStatus)? "Connexion établie." : "Connexion en cours..."}
                </Text>:
               <Text style={{fontSize: isTablet? 18 : 15}} mt="15px" color="#000000" textAlign="center" mb="30px">
                Synchronisation en cours, vous allez démarrer une mesure dans 5 secondes.
              </Text>}
                {displayLoadingConnection()}
              </View>
              
              {(isSynchro) ?<AnimatedCircularProgress
                size={100}
                width={10}
                fill={0}
                tintColor="#3d5875"
                onAnimationComplete={() => {
                  if(synchroCount){
                  setSynchroCount(false);
                  setIsMeasuring(true);
                  setDesabledButton(false);
                  setIsCalibrating(true);
                  if(statusIsConnected(xsensStatus)){
                    setTimeout(()=>{
                      setIsMeasuring(false);
                      console.log(`RetrieveSessionQuaternion.render:: `);  
                      setIsCalibrating(false);
                      const xsensStatusSaved = xsensStatus;
                      if(canNavigate.current){
                        navigation.navigate('DeviceMesure', {activity, sessionList, access_token, runner, xsensStatusSaved, choiceActivity, label:"Quaternions"});
                      }
                      
                    }, 5000);
                  }else{
                    console.log(`Sensors Xsens no connected :: ${xsensStatus[0]}`);
                  }
                }
                }}
                ref={circularProgressRef}
                backgroundColor="#009BB3"
                arcSweepAngle={360}
              >
                {
                  (fill) => (
                    <Text>
                      { Math.round((fill*5)/100)} s
                    </Text>
                  )
                }
              </AnimatedCircularProgress>:
              <Button
                isDisabled={!desabledButton}
                size="45"
                backgroundColor="#00d5f7"
                _text={{ fontSize: 16, color: "#000"}}
                minWidth="250px"
                borderRadius="30px"
                onPress={() => {
                  if(statusIsConnected(xsensStatus)){
                      setIsSynchro(true);
                  }else{
                    console.log(`Sensors Xsens no connected :: ${xsensStatus[0]}`);
                  }

                }}
              >
                <Text style={[styles.account_color,{fontSize: isTablet? 18 : 15}]}>Synchronisation</Text>
              </Button>}
            </Center>:
            <Button
              isDisabled={!desabledButton}
              marginBottom="10px"
              size="45"
              backgroundColor="#00d5f7"
              _text={{ fontSize: 16, color: "#000"}}
              minWidth="250px"
              borderRadius="30px"
              onPress={() => {
                setIsMeasuring(true);
                setDesabledButton(false);
                setIsCalibrating(true);
                if(statusIsConnected(xsensStatus)){
                  setTimeout(()=>{
                    setIsMeasuring(false);
                    CollectData.Calibration()
                      .then(data => {
                      console.log(`RetrieveSession.render:: ${data}`);
                        if (data === "OK") {
                          setIsCalibrating(false);
                          const xsensStatusSaved = xsensStatus;
                          if(canNavigate.current){
                            navigation.navigate('DeviceMesure', {activity, sessionList, access_token, runner, xsensStatusSaved, choiceActivity});
                          }
                        }
                    })
                    .catch(err => console.error);
                  }, 15000);
                }else{
                  console.log(`Sensors Xsens no connected :: ${xsensStatus[0]}`);
                }
              }}
              >
              <Text style={[styles.account_color,{fontSize: isTablet? 18 : 15}]}>{translate('calibration_button_message')}</Text>
            </Button>}
            {(capitalErrors != "") && <View style={{ borderWidth: 0.7,borderRadius:7, borderColor: '#00d5f7'}}>
                <View style={{ margin: 10, alignSelf: "center", alignItems: "center" }}>
                    <Text>
                        {capitalErrors} {errorMessage != "" && errorMessage}
                    </Text>
                    <TouchableOpacity
                        style={[styles.button, { width: 70, backgroundColor: "#00d5f7" }]}
                        onPress={() => {
                            CollectData.Reset().then(resolve => {
                                console.log(resolve);
                                if (resolve === "OK") {
                                    console.log("Master has been successfully reset.");
                                } else {
                                    console.log("An error occured while trying to reset the Master.");
                                }
                                navigation.navigate('Runner', {access_token, runner});
                            }).catch(error => console.error(error));
                        }}>
                        <Text style={{alignSelf: "center", fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                    </TouchableOpacity>
                </View>
            </View>}

            {(pingMasterErrors != "" || imuPercentage !== "") && <View style={{ borderWidth: 0.7,borderRadius:7, borderColor: '#00d5f7'}}>
                <View style={{ margin: 10, alignSelf: "center", alignItems: "center" }}>
                    <Text>
                        {pingMasterErrors && pingMasterErrors} {imuPercentage && imuPercentage}
                    </Text>
                    <TouchableOpacity
                        style={[styles.button, { width: 70, backgroundColor: "#00d5f7" }]}
                        onPress={() => {
                          CollectData.CloseAll()
                          .then(res => {
                            if(res === "Success"){
                              console.log("Socket has been successfully closed.");
                              navigation.navigate('Runner', {access_token, runner});
                            }else{
                              console.log("An error occured while trying to close socket.", res);
                            }
                          })
                          .catch(error => console.error(error));
                        }}>
                        <Text style={{alignSelf: "center", fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                    </TouchableOpacity>
                </View>
            </View>}
            {!isSynchro && displayLoadingCalibration()}
            {!isWideEnough && <View>
              {displayConnectionState()}
            </View>}
          </VStack>
        </Box>
      </ScrollView>
      {isFigureDisplayable && (capitalErrors === "") && <View style={{
          position: isWideEnough && isHorizontal? "absolute" : "relative",
          alignSelf: "center",
          width: isWideEnough && isHorizontal? "20%" : width*0.4,
          height: isWideEnough && isHorizontal? "80%" : height*0.4, 
          right: isWideEnough && isHorizontal? "2%" : null, 
          top: isWideEnough && isHorizontal? "15%": null,
          bottom: isWideEnough && isHorizontal? null: "5%"}}>
          <Image 
              style={{
                width: "100%",
                height: "100%",
                resizeMode:"contain"}}
              source={ bodyFigure }
              alt="silouhette"/>
      </View>}
      {imuInformation && <View style={{position: "absolute", width:"100%", height:"90%", justifyContent:"center", alignItems:"center"}}>
          <View style={{width:"70%", backgroundColor :"#F2F2F2", borderWidth:2, borderColor:"#ccc"}}>
            <View style={{margin: 5}}>
              <TouchableOpacity style={{backgroundColor: "#888", width: 25, height: 25, borderRadius:90, alignSelf:"flex-end", alignItems:"center"}}
                onPress={()=> setImuInformation(false)}>
                <Text style={{fontWeight:"bold", color:"white", fontSize: isTablet? 18 : 15}}>X</Text>
              </TouchableOpacity>
              <Heading style={{fontSize:20}}>{translate('display_xsens_status')}</Heading>
              <Text style={{textAlign: "justify", paddingHorizontal:"1%", fontSize: isTablet ? 15 : 11}}>
              {translate('display_xsens_order_message')}</Text>
            </View>
          </View>
        </View>}
    </NativeBaseProvider>
  );
}


export default RetrieveSession;

const styles = StyleSheet.create({
  picker_component:{
    flex:1,
    borderRadius: 4,
    width: 150,
    marginRight: 30,
    justifyContent:'flex-end'
  },
  picker_container:{
    flex: 1,
    alignItems: "flex-end",
    height: 30,
  },
  isLoading_container:{
    position:'absolute',
    left:0,
    right:0,
    top:100,
    bottom:0,
    alignItems: 'center',
    justifyContent:'center'
  },
  upper_container:{
    paddingTop: 10,
    justifyContent : 'flex-end',
    flexDirection : 'row',
    backgroundColor : 'transparent',
  },
  account_color:{
    color: "#000" 
  },
  button:{
    marginTop: 15,
    width: 150,
    height: 40,
    borderRadius: 30,
    backgroundColor:"#00d5f7",
    justifyContent: "center",
    alignItems: "center"
  }
})
