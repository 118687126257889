import {LOCALES} from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'welcome'                               :'Welcome',
        'connect_button'                        :'Connect',
        'connexion_message'                     :'Log in to continue',
        'email'                                 :'e-mail Adresse',
        'password'                              :'Password',
        'fist_name'                             :"First name",
        'last_name'                             :"Last name",
        'forgot_password'                       :'Forgot your password ?',
        'not_account'                           :"I do not have an account.",
        'account'                               :"Create an account",
        'registry'                              :"Registered patients",
        'last_session'                          :"Last sessions",
        'patient_details'                       :"patient details",
        'id'                                    :'ID',
        'age'                                   :'Age',
        'sex'                                   :'Sex',
        'size'                                  :'Size',
        'weight'                                :'Weight',
        'diagnostic'                            :'Diagnostic',
        'body_region'                           :'body region',
        'evolution_functional_level'            :'Functional level evolution',
        'start_balance'                         :'Start a balance sheet',
        'start_measurement'                     :'Start',
        'stop_measurement'                      :'Stop',
        'next_step'                             :'Next',
        'continue'                              :'Continue',
        'network_name'                          :'Box network name',
        'comment'                               :'Comment',
        'submit_button'                         :'Submit',
        'submit_config'                         :'Submit',
        'Walk_balance'                          :'Marche',
        'run_balance'                           :'Course',
        'slow_Walk_balance'                     :'slow walk',
        'quick_Walk_balance'                    :'Quick walk',
        'slow_run_balance'                      :'slow race',
        'quick_run_balance'                     :'Quick race',
        'country_option'                        :'en-US',
        'new_balance'                           :'New balance sheet',
        'functional_level_evolution_activities' :'How does he assess his functional level during his daily activities?',
        'course_lenght'                         :'Distance traveled',
        'estimated_speed'                       :'Estimated speed',
        "cadence"                               :'Pace',
        'damping_symetry'                       :'Symmetry of the damped',
        'impact_symetry'                        :'Symmetry of impacts',
        'step_symetry'                          :'Symmetry of steps',
        'pelvic_symetry'                        :'Pelvis symmetry',
        'hip_symetry'                           :'Hips symmetry',
        'knee_symetry'                          :'Knees symmetry',
        'steps_label'                           :'steps/min',
        'spatio_temporal'                       :'Spatio-temporal',
        'kinetic'                               :'Kinetic',
        'pelvis'                                :'Pelvis',
        'hips'                                  :'Hips',
        'knees'                                 :'Knees',
        'global'                                :'Global',
        'support_phase'                         :"Support phase",
        'oscillating_phase'                     :'Oscillating phase',
        'tilt'                                  :'Tilt',
        'detail'                                :'Detail',
        'version'                               :'Version',
        'right'                                 :'right',
        'left'                                  :'left',
        'at_right'                              :'right',
        'at_left'                               :'left',
        'stride_time'                           :'Stride time',
        'stride_length'                         :'Stride length',
        'impact_force'                          :"Ground impact force",
        'loading_rate'                          :'Cushioning',
        'new_patient'                           :'New patient',
        'input_diagrostic'                      :"Input diagnostic",
        'add'                                   :"Add",
        'go_Back'                               :"Go back",
        'ok'                                    :"OK",
        'cancel'                                :"Cancel",
        'settings'                              :"Settings",
        'app_version'                           :"Version 1.44.1",
        'web_site'                              :"Website",
        'update_patient'                        :"Change the patient information",
        'validate'                              :"Validate",
        'create_acount'                         :"Create your account to continue!",
        'confirm_password'                      :"Confirm password",
        'err_email'                             :"Please fill in your email correctly",
        'err_password'                          :"The password must have at least 8 characters, at least one uppercase letter and at least one number.",
        'err_confirm_password'                  :"Please fill in the same password.",
        'err_first_name'                        :"Please enter your first name.",
        'err_last_name'                         :"Please enter your last name.",
        'fill_current_field'                    :"please complete this field.",
        'calibration_information'               :"Place the sensors on your patient.",
        'patient_position'                      :"Stand upright, legs straight.",
        'sucess_text'                           :"The information is validated successfully.",
        'session_calibration'                   :"Do you want to perform a calibration or do an exercise ?",
        'config_not_found'                      :"No Master configuration, do you want to configure a Master?",
        'expiration_count'                      :"Your session expires in 10 min, you can restart by navigating from one screen to another.",
        'xsens_calibration'                     :"Calibration",
        'calibration_name'                      :"Calibrate",
        'session_start'                         :"Start a session",
        'session_start_message'                 :"Start your session",
        'session_calibration_message'           :"Start your calibration",
        'start_new_config'                      :"Start a new configuration",
        'start_new_calibration'                 :"Start a new calibration",
        'warning_mesure_message'                :"LpasG and StrideLength can not be processed / process of VLRD impossible.",
        'ok_message'                            :"Ok",
        'error_mesure_message'                  :"An error occurred during measurement. Please recalibrate!",
        'time_message'                          :"Please wait for a few moments ...",
        'sensors_offline_message'               :"Impossible Connection for the sensors, please check them.",
        'error_master_message'                  :"WARNING | An error has occurred at the Master level.\n\nErrors Log: \n",
        'error_master_notImus'                  :"WARNING | A spontaneous error has occurred at the Master level.",
        'message_measurement'                   :"A measurement is running...",
        'step_measurement_message'              :"Wait a few moments for the data transfer please !",
        'offline_master_error'                  :"WARNING | An error has occurred at the Master level: master disconnection",
        'failed_measurement'                    :"Failed during measurement, please retry the experiment.",
        'failed_calibration_message'            :"An error occurred during calibration. Please recalibrate.",
        'success_calibration_message'           :"The calibration was successful, you can take a measurement.",
        'take_again_measurement'                :"An error occurred during the exercise. Please do the exercise again!",
        'take_again_calibration'                :"An error occurred during measurement. Please recalibrate!",
        'sensors_connection_message'            :"connection in progress...",
        'restart_master_device'                 :"The Master will restart.",
        'master_battery_level'                  :"Master battery level",
        'iMUs_battery_level'                    :"Battery level",
        'bad_master_connection'                 :"Unable to connect, please try again!",
        'error_master_connection'               :"Connection impossible, please check the status of your master!",
        'error_config_connection'               :"Connection impossible, please check the presence access point of the master!",
        'master_configuration_message'          :"Please configure your master",
        'network_store_ssid1_message'           :"Your configuration network is: ",
        'network_store_ssid2_message'           :".\n Please log in again.",
        'mysmartmove_network_message'           :"In order to complete your configuration, please connect to the MYSMARTMOVE network",
        'device_access_location'                :"For this step, you also need to enable device location.",
        'check_musmartmove_network'             :"If the MYSMARTMOVE access point does not exist, then click on OK to initialize it:",
        'ssid_connection_message'               :"You are now connected to",
        'ssid_connection_second_message'        :"You are connected to",
        'not_connection_message'                :"No connection, please wait a few moments",
        'failed_location'                       :"Location permission denied",
        'success_config_message'                :"The configuration was done successfully. \nYou will be redirected in a few moments...",
        'failed_config_message'                 :"An error occurred during configuration.",
        'failed_config_reset'                   :"An error occurred during configuration, please reset the master.",
        'failed_socket_connection'              :"Unable to connect, please re-initialize the master.",
        'connection_status_message'             :"Connection status",
        'evolution_functional_level_message'    :"\t\t Previous sessions are displayed on this graph with respect to their functional level (their quality).\n\n \t\t You can click on one of the sessions to select it as the reference session. It will then be used as a reference in the display of the results, available by clicking on one of the sessions in the 'Last Sessions' list.",
        'session_message'                       :"Session :",
        'reference_session_message'             :"Reference session :",
        'functional_level_choice'               :"Please choose a functional level.",
        'disconnect_box_message'                :"You are no longer connected to the network.",
        'connection_and_battery_status'         :"Connection status :\n Battery level :",
        'calibration_measure_xsens'             :"Please rotate the sensors in all directions for one minute at least.",
        'time_left_calibration'                 :"Left time : ",
        'manually_master_reset'                 :"Please reset the Master manually.",
        'display_xsens_status'                  :"Display sensors status.",
        'display_xsens_order_message'           :"The sensors are displayed in order, from 0 to 4. You can thus determine the origin of a connection problem which concerns one or more sensors.",
        'calibration_button_message'            :"Patient alignment",
        'session_number'                        :"Session n°",
        'credentials_login'                     :"Sorry, the credentials that you provided are incorrect.",
        'credentials_connection'                :"Sorry, impossible for the master to connect with the credentials that you provided. Try again in a few moments please!",
        'new_comment'                           :"New comment",
        'edit_comment'                          :"Edit comment",
        'input_comment'                         :"Enter your comment",
        'input_pdf_comment'                     :"Would you like to add a comment?",
        'recommendation_message'                :"Recommendation message :",
        'recalibration_message'                 :"Would you like to recalibrate? ",
        'reactivate_master_communication'       :"Reactivate communication with the master",
        'add_pathologie'                        :"Add a pathology",
        'no_WiFi_connexion'                     :"You are not connected on a WiFi network",
        'format_DatFile_KO'                     :"Wrong format data",
        'xsens_charge'                          :"Please recharge your sensors",
        'medium_wifi_network_speed'             :"Depending on the quality of your wifi, data processing may take longer than expected.",
        'bad_wifi_network_speed'                :"Depending on the speed of your wifi, data processing may take quite a long time than expected. Your speed is ",
        'wifi_network_frequency'                :"Your WiFi currently operates at 5 GHz. For data exchange with the master, configure it at 2.4 GHz. Thanks.",
        'remember_me'                           :"Remember me.",
        'acc_peak'                              :"impact force",
        'params_behavior'                       :"Evolution of the parameters of:",
        'session_date'                          :"session date",
        'spatio_and_kinetic'                    :"Spatio-temporal and kinetics",
        'choose_icon'                           :"Click the icon →",
        'pelvis_information'                    :"Pelvic tilt :\n Maximum angular amplitudes of lateral inclination of the pelvic towards the left side (in negative) and right (in positive) from behind view during the movement, in the frontal plane (vertical plane which passes through the midline of the body and divides it into two ventral and dorsal parts).\n\nPelvic version :\n Maximum angular amplitudes of retroversion (in negative) and anteversion (in positive) of the pelvic, in the sagittal plane (vertical plane which passes through the midline of the body and divides it into two right and left parts).",
        'hip_information'                       :"Abd./Add.(abduction/adduction) :\n Maximum angular amplitudes of adduction (in positive) and abduction (in negative) of the right hips (in blue) and left (in orange) during the movement (frontal plane).\n\n Ext./Flex.(Extension/Flexion) :\n Maximum angular amplitudes of flexion (in positive) and extension (in negative) of the right (in blue) and left (in orange) hips during movement (sagittal plane).\n\n Rot.ext./int.(Rotation) :\n Maximum angular amplitudes of internal (positive) and external (negative) rotation of the right (in blue) and left (in orange) hips during movement (transverse plane).",
        'knee_information'                      :"Ext./Flex.(Extension/Flexion) :\n Maximum angular amplitudes of flexion (in positive) and extension (in negative) of the right knees (in blue) and left (in orange) during the movement (frontal plane).\n\n Rot.ext./int.(Rotation) :\n Maximum angular amplitudes of internal (positive) and external (negative) rotation of the right (in blue) and left (in orange) knees during movement (transverse plane).",
        'spatio_temporal_information'           :"  Stride duration : duration of a stride, between two successive poses of the right foot on the ground (sum of the durations of the right and left steps).\n\n Stride length : length of a stride, between two successive poses of the right foot on the ground (sum of the lengths of the right and left steps).\n\n The values on the histograms represent the proportions of the duration of the left step compared to the duration of the stride and the duration of a right step compared to the duration of the stride; as well as the proportions of left step length compared to stride length and right step length compared to stride length.",
        'kinetic_information'                   :"  Ground impact force : estimate, based on shins acceleration of the maximum impact forces on the ground at the moment of right and left foot poses expressed in Newton(acceleration x subject weight).\n\n Cushioning : estimation, based on the acceleration of the shins of the cushioning capacity of the subject (also called vertical loading rate), right and left. This is the measurement of the slope of the force curve upon impact with the ground, by dividing the maximum impact force by the time between the start of the impact and this maximum, expressed in « body weight » per second (number of times the weight of the subject applied to the ground per second).",
        'cadence_information'                   :" Pace : number of steps per minute.\n\n",
        'damping_symetry_information'           :"Damping symmetry : this greatness reflects the symmetry of the cushioning on the right and on the left.\n\n",
        'impact_symetry_information'            :"Symmetry of impacts : this greatness reflects the symmetry between the impacts on the right and on the left.\n\n",
        'step_symetry_information'              :" Symmetry of steps : this greatness reflects the symmetry between right and left steps in terms of both duration and length.\n\n",
        'pelvic_symetry_information'            :"  Pelvic symmetry : this greatness reflects the symmetry of the movements of the pelvis when running or walking.\n\n",
        'hip_symetry_information'               :"Hips symmetry : this greatness reflects the symmetry of the movements between the right and left hips when running or walking.\n\n",
        'knee_symetry_information'              :" Knees symmetry : this greatness reflects the symmetry of the movements between the right and left knees when running or walking.\n\n",
        'percentage_arrows_information'         :"A percentage of 100% indicates perfect symmetry, while a percentage of 0% indicates total asymmetry.\n\nThe arrows characterize the comparison of the greatness of a session compared to the previous target session chosen as a reference.",
    }
}