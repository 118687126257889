import React from 'react';
import { Text } from 'react-native-svg';
import { useWindowDimensions } from 'react-native';

const CUT_OFF_Y = 10;
const CUT_OFF_REF = 1;

const ReferenceLabels = ({ x, y, bandwidth, data }) => {
    const {width, height} = useWindowDimensions();
    const isWideEnough = (width >= 600);
    return (data.map((value, index) => (
        <Text
            key={ index }
            x={ x(index) + (bandwidth / 2) }
            y={ value.value < CUT_OFF_REF ? y(value.value) - 10 : y(value.value) + 15 }
            fontSize={isWideEnough ? 20 : 17}
            fill={ value.value > CUT_OFF_REF ? '#000' : '#000' }
            alignmentBaseline={ 'middle' }
            textAnchor={ 'middle' }
        >
            {value.value == 0 ? value.value + " reference": value.value}
        </Text>
    ))
    )};

const SpatioLabelsFirst = ({ x, y, height, width, bandwidth, data }) => (
    data.map((value, index) => (
        <Text
            key={ index }
            x={(height>width)? x(index) + (bandwidth / 2) : x(value.value) - (String(value.value).length+2)*5}
            y={(height>width)? value.value < CUT_OFF_Y ? y(value.value) - 10 : y(value.value) + 15 : y(index) + (bandwidth / 2)}
            fontSize={ 14 }
            fill={ value.value >= CUT_OFF_Y ? '#000' : '#FFF' }
            alignmentBaseline={ 'middle' }
            textAnchor={ 'middle' }
        >
            {value.value +' %'}
        </Text>
    ))
  );

  const SpatioLabelsSecond = ({ x, y, height, width, bandwidth, data }) => (
    data.map((value, index) => (
        <Text
            key={ index }
            x={(height>width)? x(index) + (bandwidth / 2) : x(value.value) - (String(value.value).length+2)*5}
            y={(height>width)? value.value < CUT_OFF_Y ? y(value.value) - 10 : y(value.value) + 15 : y(index) + (bandwidth / 2)}
            fontSize={ 14 }
            fill={ value.value >= CUT_OFF_Y ? '#000' : '#FFF' }
            alignmentBaseline={ 'middle' }
            textAnchor={ 'middle' }
        >
            {value.value +' %'}
        </Text>
    ))
  );

  const CineticLabelsFirst = ({ x, y, height, width, bandwidth, data }) => (
    data.map((value, index) => (
        <Text
            key={ index }
            x={(height>width)? x(index) + (bandwidth / 2) : x(value.value) - (String(value.value).length+3)*5}
            y={(height>width)? (value.value < CUT_OFF_REF ? y(value.value)-10 : value.value < CUT_OFF_Y ? y(value.value) +6 : y(value.value) + 15) : y(index) + (bandwidth / 2)}
            fontSize={ 14 }
            fill={ value.value >= CUT_OFF_Y ? '#000' : '#000' }
            alignmentBaseline={ 'middle' }
            textAnchor={ 'middle' }
        >
            {value.value +' BW'}
        </Text>
    ))
  );
  const CineticLabelsSecond = ({ x, y, height, width, bandwidth, data }) => (
    data.map((value, index) => (
        <Text
            key={ index }
            x={(height>width)? x(index) + (bandwidth / 2) : x(value.value) - (String(value.value).length+5)*5}
            y={(height>width)? (value.value < CUT_OFF_REF ? y(value.value)-10 : value.value < CUT_OFF_Y ? y(value.value) +8 : y(value.value) + 15) : y(index) + (bandwidth / 2)}
            fontSize={ 14 }
            fill={ value.value >= CUT_OFF_Y ? '#000' : '#000' }
            alignmentBaseline={ 'middle' }
            textAnchor={ 'middle' }
        >
            {value.value +' BW/s'}
        </Text>
    ))
  );


export { ReferenceLabels, SpatioLabelsFirst, SpatioLabelsSecond, CineticLabelsFirst, CineticLabelsSecond }