import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Center, Image } from 'native-base';

// require images
const baseImage = require('../images/png/logos.png');
const largeImage = require('../images/png/logo.png');


const Logo = () => {

  const { height, width } = useWindowDimensions();
  const largeBreakpoint   = 992;
  const image             = (width < largeBreakpoint)? baseImage : largeImage;

  const baseImageW        = 1864;
  const baseImageH        = 617;
  const baseAspectRatio   = baseImageW/baseImageH;

  const largeImageW       = 1864;
  const largeImageH       = 903;
  const largeAspectRatio  = largeImageW/largeImageH;

  return (
    <Center>
      <Image
        source={ image }
        alt="logo"
        w={{ base: 200,                 lg: 400 }}
        h={{ base: 200/baseAspectRatio, lg: 400/largeAspectRatio }}
        mt={3}
        mb={0}
        mx={{ base:0, lg:75 }}
      />
    </Center>
  );
};

export default Logo;
