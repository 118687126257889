import React from 'react';
import { Flex, Center, Text } from 'native-base';

// intl components
import 'intl';
import translate from '../i18n/translate';
import { StyleSheet, useWindowDimensions } from 'react-native';


const SessionResultsTopBar = ({ session }) => {

  const { distance, speed, pace } = session.stride_parameters;

  // change the speed format from this '2.6' to this '2,6'
  const speedWithComma = Number(speed).toFixed(1).toString().replace('.', ',');

  const { height, width } = useWindowDimensions();
  const largeBreakpoint   = 992;
  const margintop = (width >= largeBreakpoint)? 0:3;
  const isTablet = (width > largeBreakpoint)||(height > largeBreakpoint);


  return(
    <Flex flexDirection="row" justifyContent="space-around" shadow="1" style={styles.picker_component} mt={margintop}>
      {/* <Center>
        <Text fontSize={isTablet? "lg" : 12} color="#00d5f7" style={styles.text_container_first}>{translate('course_lenght')}</Text>
        <Text fontSize={isTablet? 15 : "sm"} color="#00d5f7" bold style={styles.text_container_second}>{Math.round(distance)} m</Text>
      </Center>
      <Center>
        <Text fontSize={isTablet? "lg" : 12} color="#00d5f7" style={styles.text_container_first}>{translate('estimated_speed')}</Text>
        <Text fontSize={isTablet? 15 : "sm"} color="#00d5f7" bold style={styles.text_container_second}>{speedWithComma} km/h</Text>
      </Center> */}
      <Center>
        <Text fontWeight="bold" fontSize={isTablet? 20 : 19} color="#000" style={styles.text_container_first}>{translate('cadence')}</Text>
        <Text fontWeight="bold" fontSize={isTablet? 18.7 : 17} color="#000" bold style={styles.text_container_second}>{Math.round(pace)} {translate('steps_label')}</Text>
      </Center>
    </Flex>
  );
}

export default SessionResultsTopBar;

const styles = StyleSheet.create({
  
  picker_component:{
    
    //borderRadius: 1,
    height: 55,
    elevation:1  
  },
  text_container:{
    fontSize: 15,
  },
  text_container_first:{
    paddingTop:3,
    //borderWidth: 1,
  },
  text_container_second:{
    paddingBottom:0,
    //borderWidth: 1,
    height: 30,
    paddingTop:0,
    marginTop:0,
    marginBottom:10,
    top:0
  }

})
