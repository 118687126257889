import React, { useState } from 'react';
import {NativeBaseProvider, Box, Text, Heading, VStack, FormControl, Input, Link,
  Button, ScrollView, } from 'native-base';
import { StyleSheet, View, useWindowDimensions } from 'react-native';


// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import translate from '../i18n/translate';
import { Picker } from '@react-native-picker/picker';

// utils functions
import { register } from '../src/fetch.js';

const Signup = ({navigation}) => {

  const { height, width } = useWindowDimensions();
  const largeBreakpoint = 992;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);

  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [errorConfirmPassword, serErrorConfirmPassword] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState("Français");
  const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}]
  const [locale, setLocale]= useState(LOCALES.FRENCH)
  const setEnglishlanguages = (lang) =>{
    lang == "Français" ? setLocale(LOCALES.ENGLISH): setLocale(LOCALES.FRENCH)
  }

  const onSubmit = ()=>{
    const emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const passwordValidator = /(?=.*\d)(?=.[a-zA-Z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
    const route = '/auth/register';
    if(lastName!==""){
      if(firstName!==""){
        if(email!=="" && emailValidator.test(email)){
          if(password!=="" && passwordValidator.test(password)){
            if(password === confirmpassword){
              register(route, email, password, firstName, lastName)
                .then(({public_id, access_token}) => {
                  if(public_id){
                    navigation.navigate("Login")
                  }   
                }).catch( err => console.error(err)); 
            }else{
              serErrorConfirmPassword(translate('err_confirm_password'));
            }

          }else{
            setErrorPassword(translate('err_password'));
          }
        }else{
          setErrorEmail(translate('err_email'));
        }
      }else{
        setErrorFirstName(translate('err_first_name'));
      }  
    }else{
      setErrorLastName(translate('err_last_name'));
    }
  }

  return(
    <I18nProvider locale ={locale} defaultLocale="fr-FR">
      <NativeBaseProvider flex={1}>
        <ScrollView style={{fontFamily:"sans-serif"}}>
          <View style={styles.picker_container}>
            <Picker style={styles.picker_component}
              selectedValue={selectedLanguage}
              onValueChange={(itemValue, itemIndex) =>{
                setSelectedLanguage(itemValue)
                setEnglishlanguages(selectedLanguage)
              }}>
              {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
            </Picker>
          </View>
          <Box safeArea flex={1} p="2" w={{ base:"75%", md:"65%", lg:"50%" }} mx="auto" py="8">
            <Heading size="lg" color="coolGray.800" fontWeight="600" fontSize ="23">
              {translate('welcome')}
            </Heading>
            <Heading mt="1" color="coolGray.600" fontWeight="medium" size="xs">
              {translate('create_acount')}
            </Heading>

            <VStack space={0.3} mt="3">
              <FormControl isRequired>
                <FormControl.Label
                  _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}>
                  <Text>{translate('last_name')}</Text>
                </FormControl.Label>
                <Input 
                  value={lastName}
                  onChangeText={(text) => setLastName(text)}
                />
                {errorLastName&&<Text style={styles.errorMsg}>{errorLastName}</Text>}
              </FormControl>
              <FormControl isRequired>
                <FormControl.Label
                  _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}>
                  <Text>{translate('fist_name')}</Text>
                </FormControl.Label>
                <Input 
                  value={firstName}
                  onChangeText={(text) => setFirstName(text)}
                />
                {errorFirstName&&<Text style={styles.errorMsg}>{errorFirstName}</Text>}
              </FormControl>
              <FormControl isRequired>
                <FormControl.Label
                  _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}>
                  <Text>{translate('email')}</Text>
                </FormControl.Label>
                <Input 
                  value={email}
                  onChangeText={(text) => setEmail(text)}
                />
                {errorEmail&&<Text style={styles.errorMsg}>{errorEmail}</Text>}
              </FormControl>
              <FormControl isRequired>
                <FormControl.Label
                  _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}>
                  <Text>{translate('password')}</Text>
                </FormControl.Label>
                <Input
                  type="password"
                  value={password}
                  onChangeText={(text) => setPassword(text)}
                  />
                {errorPassword&&<Text style={styles.errorMsg}>{errorPassword}</Text>}
              </FormControl>
              <FormControl isRequired>
                <FormControl.Label
                  _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}>
                  <Text>{translate('confirm_password')}</Text>
                </FormControl.Label>
                <Input 
                type="password" 
                value={confirmpassword}
                onChangeText={(text) => setConfirmpassword(text)}
                />
                {errorConfirmPassword&&<Text style={styles.errorMsg}>{errorConfirmPassword}</Text>}
              </FormControl>
              <Button colorScheme="indigo" _text={{ color: 'white' }} onPress={onSubmit}>
                <Text style={[styles.account_color,{fontSize: isTablet? 18 : 15}]}>{translate('validate')}</Text>
              </Button>
            </VStack>
          </Box>
        </ScrollView>
      </NativeBaseProvider>
    </I18nProvider>
  );
}


export default Signup;

const styles = StyleSheet.create({
  errorMsg:{
    fontSize: 12,
    width: '100%', 
    color: 'red',
    padding: 0,
    margin: 0,
  },
  picker_component:{
    flex:1,
    borderRadius: 4,
    width: 150,
    marginRight: 30,
    justifyContent:'flex-end'
  },
  picker_container:{
    width: "100%",
    alignItems: "flex-end",
    height: 30,
  },
  account_color:{
    color: "#FFF" 
  }
})
