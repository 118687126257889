import React, { useEffect, useState } from 'react';
import { Box, Center, Heading, Text, HStack, Image, Pressable} from 'native-base';

// intl components
import 'intl';
import translate from '../i18n/translate';
import { I18nProvider, LOCALES } from '../i18n';
import { View, StyleSheet, useWindowDimensions, Platform, TouchableOpacity } from 'react-native';

// cards
import SessionGlobalCard from './SessionGlobalCard.js';
import SessionFlightCard from './SessionFlightCard.js';
import SessionSupportCard from './SessionSupportCard.js';
import SessionFilteredCardsForm from './SessionFilteredCardsForm.js';

 // require images
 const addImage = require('../images/png/down_arrow.png');

const SessionResultsFilteredCards = ({ navigation, sessionList, session, sessionSym, refSession, runner }) => {

  const { height, width } = useWindowDimensions();
  const largeBreakpoint = 992;
  const midBreakpoint = 688;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint)
  const marginBottomSize = (width <= midBreakpoint)? 0 : 80;
  const widthSize = (width >= midBreakpoint);

  const [displayLoding, setDisplayLoding] = useState(false);
  const [spatioTemporalCard, setSpatioTemporalCard] = useState(false);
  const [cineticCard, setCineticCard] = useState(false);
  const [pelvisCard, setPelvisCard] = useState(false);
  const [hipsCard, setHipsCard] = useState(false);
  const [kneesCard, setKneesCard] = useState(false);
  const [checked, setChecked] = useState('');

  const legendStyle = isTablet? {position:"absolute", flexDirection:isTablet? 'column' : 'row', bottom: 20, right: "1%"} 
                              : {position:"absolute", flexDirection:isTablet? 'column' : 'row', top: 10, right: "0.5%"};
  const displayLegend = () => {
    let numOfCards = [cineticCard, hipsCard, kneesCard, spatioTemporalCard, pelvisCard].filter(Boolean).length;
    if(checked != '' && (cineticCard || hipsCard || kneesCard || spatioTemporalCard) && numOfCards < 3){
      return (
        <View style = {legendStyle}>
          {(Platform.OS === "android") && widthSize? <View style = {{flexDirection: 'row', marginBottom:marginBottomSize}}>
            <TouchableOpacity
            style={styles.button}
              onPress={() => {
                switch (checked) {
                  case 'global':
                    navigation.navigate("PdfSession",{session: session.angular_parameters_global, stride_params: session.stride_parameters, identity: 'Global', sessionSym:sessionSym, runner:runner, allSession:session, refSession:refSession});
                    break;
                  case 'oscillante':
                    navigation.navigate("PdfSession",{session: session.angular_parameters_flight, stride_params: session.stride_parameters, identity: 'Oscillante', sessionSym:sessionSym, runner:runner, allSession:session, refSession:refSession});
                    break;
                  case 'appui':
                    navigation.navigate("PdfSession",{session: session.angular_parameters_support, stride_params: session.stride_parameters, identity: 'Appui', sessionSym:sessionSym, runner:runner, allSession:session, refSession:refSession});
                    break;
                  default:
                    console.log(`Sorry, we are out of ${checked}.`);
                } 
              }}
              >
                <Text style={{fontSize:16, color:'#FFF'}}>PDF
                <Image alt="logo pour commentaire" style={{width: 30, height:30}} source={require('../images/png/pdf_1024.png')}/>
                </Text>
              </TouchableOpacity>
          </View>:
          (Platform.OS === "android" && !widthSize) && <View style = {{flexDirection: 'row', marginBottom:marginBottomSize,paddingRight:7}}>
          <TouchableOpacity
          style={styles.button_smart}
            onPress={() => {
              switch (checked) {
                case 'global':
                  navigation.navigate("PdfSession",{session: session.angular_parameters_global, stride_params: session.stride_parameters, identity: 'Global', sessionSym:sessionSym, runner:runner, allSession:session, refSession:refSession});
                  break;
                case 'oscillante':
                  navigation.navigate("PdfSession",{session: session.angular_parameters_flight, stride_params: session.stride_parameters, identity: 'Oscillante', sessionSym:sessionSym, runner:runner, allSession:session, refSession:refSession});
                  break;
                case 'appui':
                  navigation.navigate("PdfSession",{session: session.angular_parameters_support, stride_params: session.stride_parameters, identity: 'Appui', sessionSym:sessionSym, runner:runner, allSession:session, refSession:refSession});
                  break;
                default:
                  console.log(`Sorry, we are out of ${checked}.`);
              } 
            }}
            >
              <Text fontWeight="bold" style={{fontSize:16, color:'#000'}}>{"\t"}
              <Image alt="logo pour commentaire" style={{width: 30, height:30}} source={require('../images/png/pdf_1024.png')}/>
              </Text>
            </TouchableOpacity>
        </View>}
          <View style = {{flexDirection: 'row'}}>
            <View style = {{backgroundColor: '#F57F17C0', width: 35, height: 20, borderRadius: 5, alignSelf:'center', marginRight:'2%'}}></View>
            <Text color="#F57F17C0" style={styles.text_container_first}>{translate('left')}</Text>
          </View>
          <View style = {{flexDirection: 'row', marginLeft: isTablet? 0 : "3%", marginTop: isTablet? 10 : 0}}>
            <View style = {{backgroundColor: '#00d5f7A0', width: 35, height: 20, borderRadius: 5, alignSelf:'center', marginRight:'2%'}}></View>
            <Text color="#00d5f7A0" style={styles.text_container_first}>{translate('right')}</Text>
          </View>
        </View>
      )
    }
  }

  return(
    <Box style={styles.boxWidth}>
      {displayLegend()}
      {displayLoding?
      <View style={styles.border_filter_container}>
        <View style={{flexDirection: 'row', borderWidth:0.2, elevation: 1, marginLeft:"1%", width:isTablet? "98%" : 120}} > 
          <View style={styles.base_cards_head}>
            <Heading style={styles.heading_style}>Filtres</Heading>
          </View>
          <View style={styles.base_cards_head_icon}>
            <Pressable onPress={() => setDisplayLoding(false)}>
              <Image style={[{
                width: 30,
                height: 30,
                marginRight: isTablet? 30 : 10}, 
                (Platform.OS != "android") && { cursor : "pointer"}]}
              source={ addImage }
              alt="image pour afficher les paramètres aux choix"/>
            </Pressable> 
          </View>
        </View>
        
      </View>:
      <SessionFilteredCardsForm 
        setDisplayLoding={setDisplayLoding}
        setChecked={setChecked}
        checked={checked}
        spatioTemporalCard={spatioTemporalCard}
        setSpatioTemporalCard={setSpatioTemporalCard}
        cineticCard={cineticCard}
        setCineticCard={setCineticCard}
        pelvisCard={pelvisCard}
        setPelvisCard={setPelvisCard}
        hipsCard={hipsCard}
        setHipsCard={setHipsCard}
        kneesCard={kneesCard}
        setKneesCard={setKneesCard} />}
      <HStack style={styles.cards_container}>
        {checked === 'global' && <SessionGlobalCard 
                                    navigation= {navigation} sessionList={sessionList} session={session} spatioTemporalCard={spatioTemporalCard}  cineticCard={cineticCard} pelvisCard={pelvisCard} hipsCard={hipsCard} kneesCard={kneesCard}
                                    sessionAngularParameters={session.angular_parameters_global} sessionStrideParameters={session.stride_parameters}/>}
        {checked === 'oscillante' && <SessionFlightCard 
                                        navigation= {navigation} sessionList={sessionList} session={session} spatioTemporalCard={spatioTemporalCard} cineticCard={cineticCard}
                                        pelvisCard={pelvisCard} hipsCard={hipsCard} kneesCard={kneesCard} sessionAngularParameters={session.angular_parameters_flight} sessionStrideParameters={session.stride_parameters}/>}
        {checked === 'appui' && <SessionSupportCard 
                                  navigation= {navigation} sessionList={sessionList} session={session} spatioTemporalCard={spatioTemporalCard} cineticCard={cineticCard}
                                  pelvisCard={pelvisCard} hipsCard={hipsCard} kneesCard={kneesCard} sessionAngularParameters={session.angular_parameters_support} sessionStrideParameters={session.stride_parameters}/>}
      </HStack>
    </Box>
  );
}

export default SessionResultsFilteredCards;

const styles = StyleSheet.create({

  boxWidth:{
    width: "100%",
    flex: 1,
  },
  heading_style:{
    color:"#F1A049",
    fontSize:18
  },
  base_cards_head:{
    width:"55%",
    //borderWidth:1,
    alignItems: 'flex-end',
    //alignSelf:'flex-end'
  },
  base_cards_head_icon:{
    width:"45%",
    //borderWidth:1,
    alignItems: 'flex-end',
    alignSelf:'center'
  },
  cards_container:{
    marginTop:5,
    width:'100%'
  },
  border_filter_container:{
  alignItems: 'flex-start',
  justifyContent:'center'
  },
  button:{
    width: 68,
    height: 32,
    borderRadius: 5,
    backgroundColor:"#1B5F37",
    justifyContent: "center",
    alignItems: "center"
  },
  button_smart:{
    width: 68,
    //height: 32,
    // borderRadius: 5,
    // backgroundColor:"#1B5F37",
    justifyContent: "center",
    alignItems: "center"
  },
})
