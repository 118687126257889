import React, { useState } from 'react';
import { NativeBaseProvider, Stack, ScrollView, Text, Center} from 'native-base';

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';

// components

const UnitySession = ({route, navigation}) => {

    const { runner, access_token} = route.params;

  const [locale, setLocale]= useState(LOCALES.FRENCH)
  const setEnglishlanguages = (lang) =>{
    lang == 'Français' ? setLocale(LOCALES.ENGLISH): setLocale(LOCALES.FRENCH)  
  }

  return(
    <I18nProvider locale={locale} defaultLocale="fr-FR">
      <NativeBaseProvider style={{fontFamily:"sans-serif"}}>
        <ScrollView>
            <Center>
                <Text textAlign="center" mt="30px" fontWeight="bold" style={{fontSize: 18}}>Bienvenue dans la section de scène Piloter par Unity.</Text>
            </Center>
        </ScrollView>
      </NativeBaseProvider>
    </I18nProvider>
  );
}

export default UnitySession;
