import React from 'react';
import {  StyleSheet, TouchableOpacity, Alert } from "react-native";
import { Pressable, Box, Center, Divider, Heading, Text, View, Image} from 'native-base';
import { titleCase } from '../src/utils.js';


// require images
const editImage = require('../images/png/edit.png');
const updateImage = require('../images/png/eye.png');
const deleteImage = require('../images/png/delete.png');

// utils functions
import { deleteOneRunner } from '../src/fetch.js';



const RunnerCard = ({navigation, access_token, runner, _deleteOneRunner, setRunnersList, public_id, width}) => {

  const _displayTouchableUpdate = ()=>{
    return (
      <TouchableOpacity 
        style={styles.padin_image} 
        onPress={() => navigation.navigate('UpdateRunner', {
          runner,
          access_token,
          public_id,
          setRunnersList
        })}
        >
        <Image 
          style={[styles.dimension_image, styles.update_image]} 
          source={ updateImage }
          alt="logo de creation de compte"/>
      </TouchableOpacity>
    )

  }

  const _deleteOneRunnerFromDatabase = ()=>{ 
    console.log('suppression du runner dans la liste', runner.public_id)
    const route = `/api/runners/${runner.public_id}`;
    Alert.alert('', 'êtes vous sûr de vouloir supprimer ?',[{
      text: 'Non',
      onPress: ()=>null,
      style: 'cancel'
  },{
      text: 'Oui',
      onPress: ()=>{
        deleteOneRunner(route, access_token)
        .then((status) => {
          if(status === 200){
            console.log(status);
            _deleteOneRunner(runner);
            
          }
        })
        .catch( err => console.error(err));
      }
  }])
  }

  const _displayTouchableDelete = ()=>{
    return (
      <TouchableOpacity
        style={styles.padin_image} 
        onPress={() => _deleteOneRunnerFromDatabase()}
        >
        <Image 
          style={[styles.dimension_image, styles.delete_image]} 
          source={ deleteImage }
          alt="logo de suppression de compte"/>
      </TouchableOpacity>
    )
  }

  return (
    <View key={runner.public_id}>
      <View style={styles.container} >
        <Pressable
          
          onPress={() => {
          console.log('Navigating to runner');
          navigation.navigate('Runner', {runner, "public_id":runner.public_id, access_token});
        }}>
          {({ isHovered, isFocused, isPressed }) => {
            return(
              <Box
                style={{
                  transform: [
                    {
                      scale: isPressed ? 0.96 : 1,
                    },
                  ],
              
                }}
              >
                <View style={[styles.view_container, {cursor : "pointer"}]} width={Math.min(400, width*0.68)}>
                  <Text style={styles.text_size}>
                    {titleCase(runner.first_name) +' '+ titleCase(runner.last_name)}
                  </Text>
                </View>
              </Box>
            );
          }}
        </Pressable>{_displayTouchableUpdate()}{_displayTouchableDelete()}
      </View>
    </View>
  );
};

export default RunnerCard;

const styles = StyleSheet.create({
  view_container:{
    textAlign: "center",
    padding:0,
  },
  container:{
    flex:1,
    flexDirection:'row',
    borderBottomWidth: 0.5,
    borderBottomColor: "#00d5f7"
  },
  update_image: {
    alignSelf: "center",
    width: 26,
    height: 14
  },
  delete_image: {
    alignSelf: "center",
    width: 17,
    height: 22
  },
  padin_image:{
    padding: 9,
    alignItems: 'center'
  },
  dimension_image: {
    // width: 50,
    // height: 70
  },
  text_size:{
    color: '#00d5f7',
    fontSize: 17,
  }  
})

