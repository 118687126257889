import React, { useState } from 'react';
import { Text, Image, Pressable} from 'native-base';
import CheckBox from '@react-native-community/checkbox';
import { RadioButton } from 'react-native-paper';

// intl components
import 'intl';
import translate from '../i18n/translate';
import { View, StyleSheet, useWindowDimensions, Platform, Alert } from 'react-native';

 // require images
 const addImage = require('../images/png/down_arrow.png');


const SessionFilteredCardsForm = ({ session, refSession, setDisplayLoding, checked, setChecked,
                                    spatioTemporalCard, setSpatioTemporalCard, cineticCard, setCineticCard,
                                    pelvisCard, setPelvisCard, hipsCard, setHipsCard, kneesCard, setKneesCard}) => {

    const [counter, setCounter] = useState(0);
    const { height, width } = useWindowDimensions();
    const largeBreakpoint   = 992;
    const midBreakpoint = 688;
    const isLandscape = width > height;
    const isHorizontalDisplay = isLandscape && height<midBreakpoint;
    const maxValueLg = (width>= 1460)? 4 :(width >= largeBreakpoint)? 3: (width<midBreakpoint || isHorizontalDisplay)? 1:2;
    const fontSize = (width>= midBreakpoint)? (width >= largeBreakpoint || height >= largeBreakpoint)? 15 : 12 : 10.7;
    const messageValue = (width>= 1460)? "Vous ne pouvez afficher que quatre éléments" : (width >= largeBreakpoint)? "Vous ne pouvez afficher que trois éléments" : 
          (width<midBreakpoint || isHorizontalDisplay)? "Vous ne pouvez afficher qu'un seul élément" : "Vous ne pouvez afficher que deux éléments";
    

  return(
    <View style={styles.border_filter_container}>
        <View style={styles.border_filter}>
            <View style={{flexDirection:(width>=midBreakpoint)? "row" : "column"}}>
                <View style={{alignItems:(width>=midBreakpoint)? "center" : "flex-start", width:(width>=midBreakpoint)? "30%" : "100%"}}>
                    <View style={{justifyContent:"space-around", alignItems:"flex-start", flexDirection:(width>=midBreakpoint)? "column" : "row"}}>
                        <View style={{alignItems: 'center', justifyContent: 'center', flexDirection:"row"}}>
                            {(Platform.OS == 'android')? 
                                <CheckBox
                                    value={spatioTemporalCard}
                                    onValueChange={(newValue) => {
                                        
                                        if((counter < maxValueLg) && spatioTemporalCard === false){
                                            setCounter(counter+1);
                                            setSpatioTemporalCard(newValue);
                                        }else if(spatioTemporalCard === true){
                                            setCounter(counter - 1);
                                            setSpatioTemporalCard(newValue);
                                        }else{
                                            Alert.alert("", messageValue,[{ text: "OK" }])
                                        }
                                    }}    
                                />
                                : <input type="checkbox" name="subscribe" onChange={() => {setSpatioTemporalCard(!spatioTemporalCard)}}></input>
                            }
                            <Text fontSize={fontSize} fontWeight="bold">{translate('spatio_temporal')}</Text>
                        </View>
                        <View style={{alignItems: 'center', justifyContent: 'center', flexDirection:"row"}}>
                        {(Platform.OS == 'android')? 
                                <CheckBox
                                    value={cineticCard}
                                    onValueChange={(newValue) => {
                                        if((counter < maxValueLg) && cineticCard === false){
                                            setCounter(counter+1);
                                            setCineticCard(newValue);
                                        }else if(cineticCard === true){
                                            setCounter(counter - 1);
                                            setCineticCard(newValue);
                                        }else{
                                            Alert.alert("", messageValue,[{ text: "OK" }])
                                        }
                                    }}    
                                />
                                : <input type="checkbox" name="subscribe" onChange={() => {setCineticCard(!cineticCard)}}></input> 
                            }
                            <Text  fontSize={fontSize} fontWeight="bold">{translate('kinetic')}</Text>
                        </View>
                    </View>
                </View>
                <View style={{alignItems: 'center', borderLeftWidth:(width>=midBreakpoint)? 0.5 : 0, width:(width>=midBreakpoint)? "60%" : "100%"}} >
                    <View style={styles.base_box_check}>
                        <View style={{flexDirection:"row", alignItems: 'center', width:"25%"}}>
                            {(Platform.OS == 'android')? 
                                <CheckBox
                                    value={pelvisCard}
                                    onValueChange={(newValue) => {
                                        if((counter < maxValueLg) && pelvisCard === false ){
                                            setCounter(counter+1);
                                            setPelvisCard(newValue);
                                        }else if(pelvisCard === true){
                                            setCounter(counter - 1);
                                            setPelvisCard(newValue);
                                        }else{
                                            Alert.alert("", messageValue,[{ text: "OK" }])
                                        }
                                    }}    
                                />
                                : <input type="checkbox" name="subscribe" onChange={() => {setPelvisCard(!pelvisCard)}}></input>
                            }
                            <Text fontSize={fontSize} fontWeight="bold">{translate('pelvis')}</Text>
                        </View>
                        <View style={{flexDirection:"row", alignItems: 'center', width:"33%"}}>
                            {(Platform.OS == 'android')? 
                                <CheckBox
                                    value={hipsCard}
                                    onValueChange={(newValue) => {
                                        if((counter < maxValueLg) && hipsCard === false){
                                            setCounter(counter+1);
                                            setHipsCard(newValue);
                                        }else if(hipsCard === true){
                                            setCounter(counter - 1);
                                            setHipsCard(newValue);
                                        }else{
                                            Alert.alert("", messageValue,[{ text: "OK" }])
                                        }
                                    }}    
                                />
                                : <input type="checkbox" name="subscribe" onChange={() => {setHipsCard(!hipsCard)}}></input>
                            }
                            <Text fontSize={fontSize} fontWeight="bold">{translate('hips')}</Text>
                        </View>
                        <View style={{flexDirection:"row", alignItems: 'center', width:"33%"}}>
                            {(Platform.OS == 'android')? 
                                <CheckBox
                                    value={kneesCard}
                                    onValueChange={(newValue) => {
                                        if((counter < maxValueLg) && kneesCard === false){
                                            setCounter(counter+1);
                                            setKneesCard(newValue);
                                        }else if(kneesCard === true){
                                            setCounter(counter - 1);
                                            setKneesCard(newValue);
                                        }else{
                                            Alert.alert("", messageValue,[{ text: "OK" }])
                                        }
                                    }}    
                                />
                                : <input type="checkbox" name="subscribe" onChange={() => {setKneesCard(!kneesCard)}}></input>
                            }
                            <Text fontSize={fontSize} fontWeight="bold">{translate('knees')}</Text>
                        </View>
                    </View>
                    <View style={styles.base_box_check_second}>
                       <View style={{flexDirection:"row", alignItems: 'center', width:"25%"}}>
                            <RadioButton
                                value="global"  
                                status={ checked === 'global' ? 'checked' : 'unchecked' }
                                onPress={() => setChecked('global')}
                            /><Text fontSize={fontSize} fontWeight="bold">{translate('global')}</Text>
                        </View>
                        <View style={{flexDirection:"row", alignItems: 'center', width:"33%"}}>
                            <RadioButton
                                value="appui"  
                                status={ checked === 'appui' ? 'checked' : 'unchecked' }
                                onPress={() => setChecked('appui')}
                            /><Text fontSize={fontSize} fontWeight="bold">{translate('support_phase')}</Text>
                        </View>
                        <View style={{flexDirection:"row", alignItems: 'center', width:"33%"}}>
                            <RadioButton
                                value="oscillante"  
                                status={ checked === 'oscillante' ? 'checked' : 'unchecked' }
                                onPress={() => setChecked('oscillante')}
                            /><Text fontSize={fontSize} fontWeight="bold">{translate('oscillating_phase')}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.base_cards_third}>
                    <Pressable onPress={() => setDisplayLoding(true)}>
                        <Image style={[{
                            width:30,
                            height:30,
                            transform: [{rotate: '180deg'}]}, 
                            (Platform.OS != "android") && { cursor : "pointer"}]} 
                        source={ addImage }
                        alt="image pour le scroll"/>
                    </Pressable>
                </View>
            </View>
        </View>
    </View>
  );
}

export default SessionFilteredCardsForm;

const styles = StyleSheet.create({
    border_filter_container:{
        alignItems: 'center',
        marginBottom:1,
        marginTop:0,
    },
    border_filter:{
        width:"98%",
        borderWidth:0.2,
        elevation: 1,
        backgroundColor: '#f2f2f2'  // To make sure that there is no display superposition with the legend
    },
    base_cards_third:{
        width: '10%',
        alignItems: 'center',
        alignSelf:'center'
    },
    base_box_check:{
        alignSelf: 'stretch',
        flexDirection:"row",
        alignItems: 'center',
        borderBottomWidth:0.5
    },
    base_box_check_second:{
        alignSelf: 'stretch',
        flexDirection:"row",
        alignItems: 'center',
    },
    
    base_check:{
        flexDirection:"row",
        alignItems: 'center',
    }, 
})
