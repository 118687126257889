import React from 'react';
import { Text } from 'native-base';
import { View, StyleSheet } from 'react-native';

// This component allows the creation of adaptative graphs with the design needed for sessionResults

const findAbsoluteMaximum = (data, indexes) => { // Returns the absolute maximum (sign is not taken into account)
    let absoluteMax = 0;
    for (let i of indexes){    // find the absolute maximum
        const val = data[i].map(x => Math.abs(x));
        if (Math.max(...val) > absoluteMax) {
            absoluteMax = Math.max(...val) ;
        }
    }
    return absoluteMax;
}

const getHeightforDisplay = (data, index, indexes) => {
    return (50*Math.abs(data[index][1]-data[index][0])/findAbsoluteMaximum(data, indexes));
}

const getBottomMarginforDisplay = (data, index, indexes, graphHeight) => {
    const absoluteMax = findAbsoluteMaximum(data, indexes);
    if (data[index][1] < 0){
        return parseInt((graphHeight/2)*((absoluteMax-Math.abs(data[index][1]))/absoluteMax));
    }
    return parseInt((graphHeight/2)*(1+(data[index][1]/absoluteMax)));
}

// Displays correctly the graph bars
const DisplayValuesOnGraph = ({data, index, indexes, col, graphWidth, graphHeight, isVertical}) => {

    return(
        <View
            style = {{position: 'absolute', flexDirection: 'row', justifyContent: 'space-between',
                bottom: isVertical? parseInt(graphHeight*0.25+((graphHeight*0.90*(index-indexes[0]))/indexes.length)) : getBottomMarginforDisplay(data, index, indexes, graphHeight),
                left : isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length)),
                height: isVertical? `${parseInt(50/indexes.length)}%` : `${getHeightforDisplay(data, index, indexes)}%`, 
                width: isVertical? `${getHeightforDisplay(data, index, indexes)}%` : `${parseInt(50/indexes.length)}%`, 
                borderRadius: 10, 
                backgroundColor: col,
                }}
            >
        </View>
    )
}

// Display the text for the Angles
const DisplayAnglesOnGraph = ({data, index, indexes, graphWidth, graphHeight, isVertical}) => {
    const displayHeight = getHeightforDisplay(data, index, indexes);
    const bottomMargin = isVertical? parseInt(graphHeight*0.15+((graphHeight*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphHeight/indexes.length) : getBottomMarginforDisplay(data, index, indexes, graphHeight);
    const leftMargin = isVertical? getBottomMarginforDisplay(data, index, indexes, graphWidth) : parseInt(graphWidth*0.15+((graphWidth*0.90*(index-indexes[0]))/indexes.length))+parseInt(0.25*graphWidth/indexes.length);
    const bottomText = `${Math.round((1)*(data[index][1]))}°`;
    const topText = `${Math.round((1)*(data[index][0]))}°`;

    return (
        <>
            <Text style = {{position: 'absolute', 
                bottom: isVertical? bottomMargin : bottomMargin-20,
                left : isVertical? leftMargin - 7*(bottomText.length) : leftMargin - 3*(bottomText.length)
                }}> {bottomText} </Text>
            <Text style = {{position: 'absolute', 
                bottom: isVertical? bottomMargin : bottomMargin  + parseInt(displayHeight*(graphHeight/100)),
                left : isVertical? leftMargin + parseInt(displayHeight*(graphWidth/100)) + 5 : leftMargin - 3*(bottomText.length)
                }}>{topText} </Text>
        </>
    )
}

/* PARAMS ::    data : a table of tables with two components, corresponding to the extrem values of the bar chart
 * 
 *              indexes : the tables to be taken out from the data table, 
 *                     for example [0, 1] will take only the first two bars from the data
 *                     
 *              colors : a table with the colors to be applied for each single bar selected, 
 *                     the length of this table should be equal to that of indexes
 *                     
 *              keys : keys given for each bar
 *              
 *              graphWidth : the display width of the graph
 *              
 *              graphHeight : the display Height of the graph
 *              
 *              isVertical : if true, the bars are displayed vertically, if false : horizontally
 */
export const SessionMaxValueGraph = ({data, indexes, colors, keys, graphWidth, graphHeight, isVertical}) => {

    return (
        <View style={{ height: graphHeight, width: graphWidth, flexDirection: 'row', position: 'relative', justifyContent: 'center'}}>
            <View style = {isVertical? styles.verticalBar : styles.horizontalBar}/>
            {indexes.map(index =>   {return(
                <DisplayValuesOnGraph key={keys[indexes.slice(-1)-index]}
                    data={data}
                    index={index}
                    indexes={indexes}
                    col={colors[indexes.slice(-1)-index]}
                    graphWidth={graphWidth}
                    graphHeight={graphHeight}
                    isVertical={isVertical}
                    />
                )}
            )}
            {indexes.map(index => {return(
                <DisplayAnglesOnGraph key={keys[indexes.slice(-1)-index]}
                    data={data}
                    index={index}
                    indexes={indexes}
                    graphWidth={graphWidth}
                    graphHeight={graphHeight}
                    isVertical={isVertical}
                    />
                )}
            )}             
        </View>
    )
}

const styles = StyleSheet.create({
    horizontalBar: {
        position: 'absolute',
        top: '50%',
        left: '5%',
        width: '90%',
        height: '1%',
        backgroundColor: "grey",
    },
    verticalBar: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        height: '90%',
        width: '1%',
        backgroundColor: "grey",
    }
})