const maxStep = {
  'MAX_IMPACT_GAIT'      : 3,
  'MAX_VLR_GAIT'         : 30,
  'MAX_STEP_LENGTH_GAIT' : 75,
  'MAX_STEP_DUR_GAIT'    : 75,
  'MAX_IMPACT_RUN'       : 8,
  'MAX_VLR_RUN'          : 150,
  'MAX_STEP_LENGTH_RUN'  : 75,
  'MAX_STEP_DUR_RUN'     : 75,
}

const maxAngles = {
  'pel_obl'          : 20,
  'pel_tilt'         : 20,
  'pel_osc'          : 0,
  'hip_abd'          : 30,
  'hip_add'          : 40,
  'hip_ext'          : 40,
  'hip_flex'         : 80,
  'hip_rot_int'      : 35,
  'hip_rot_ext'      : 30,
  'knee_valg'        : 30,
  'knee_var'         : 23,
  'knee_ext'         : 20,
  'knee_flex'        : 140,
  'knee_rot_int'     : 20,
  'knee_rot_ext'     : 50,
}

const maxAmplitudes = {
  'hip_add'   : maxAngles.hip_abd      + maxAngles.hip_add,
  'hip_flex'  : maxAngles.hip_ext      + maxAngles.hip_flex,
  'hip_rot'   : maxAngles.hip_rot_int  + maxAngles.hip_rot_ext,
  'knee_val'  : maxAngles.knee_var     + maxAngles.knee_valg,
  'knee_flex' : maxAngles.knee_flex    + maxAngles.knee_ext,
  'knee_rot'  : maxAngles.knee_rot_int + maxAngles.knee_rot_ext,
}


const simplified_parameters = ( angular_parameters, stride_parameters, activity) => {

  // 1. PELVIS
  //--------------------------------------------------------------------------------------
  const pelvis_amp = angular_parameters['pelvis_inc_max'] - angular_parameters['pelvis_inc_min'];

  const mParamPelvis = Math.round(computeSymmetryIndex(angular_parameters['pelvis_inc_max'], (-1)*angular_parameters['pelvis_inc_min'], maxAngles['pel_obl']));
  //--------------------------------------------------------------------------------------
  

  // 2. HIPS AND KNEES
  //--------------------------------------------------------------------------------------
  let angles = {};
  const kneesHipsAngleNames = [
    'rhip_add' ,  'rhip_flex' , 'rhip_rot' ,
    'lhip_add' ,  'lhip_flex' , 'lhip_rot' ,
    'rknee_val',  'rknee_flex', 'rknee_rot',
    'lknee_val',  'lknee_flex', 'lknee_rot',
  ];

  kneesHipsAngleNames.forEach(name => {
    let max = angular_parameters[name + "_max"];
    let min = angular_parameters[name + "_min"];

    let angle = {
      "max"  : max,
      "min"  : min,
      "amp"  : (max - min),
      "mean" : computeMean(name, max, min),
    };

    angles[name] = angle;
  });
  
  const mParamHips = Math.round( (1/6) * (
    computeSymmetryIndex(angles['rhip_add'].amp,    angles['lhip_add'].amp,    maxAmplitudes['hip_add']/2) +
    computeSymmetryIndex(angles['rhip_add'].mean,   angles['lhip_add'].mean,   maxAmplitudes['hip_add']/2) +
    computeSymmetryIndex(angles['rhip_flex'].amp,   angles['lhip_flex'].amp,   maxAmplitudes['hip_flex']/2) +
    computeSymmetryIndex(angles['rhip_flex'].mean,  angles['lhip_flex'].mean,  maxAmplitudes['hip_flex']/2) +
    computeSymmetryIndex(angles['rhip_rot'].amp,    angles['lhip_rot'].amp,    maxAmplitudes['hip_rot']/2) +
    computeSymmetryIndex(angles['rhip_rot'].mean,   angles['lhip_rot'].mean,   maxAmplitudes['hip_rot']/2)
  ));
  
  const mParamKnees = Math.round( (1/6) * (
    computeSymmetryIndex(angles['rknee_val'].amp,   angles['lknee_val'].amp,   maxAmplitudes['knee_val']/2) +
    computeSymmetryIndex(angles['rknee_val'].mean,  angles['lknee_val'].mean,  maxAmplitudes['knee_val']/2) +
    computeSymmetryIndex(angles['rknee_flex'].amp,  angles['lknee_flex'].amp,  maxAmplitudes['knee_flex']/2) +
    computeSymmetryIndex(angles['rknee_flex'].mean, angles['lknee_flex'].mean, maxAmplitudes['knee_flex']/2) +
    computeSymmetryIndex(angles['rknee_rot'].amp,   angles['lknee_rot'].amp,   maxAmplitudes['knee_rot']/2) +
    computeSymmetryIndex(angles['rknee_rot'].mean,  angles['lknee_rot'].mean,  maxAmplitudes['knee_rot']/2)
  ));
  
  //--------------------------------------------------------------------------------------


  // 3. WIP
  //--------------------------------------------------------------------------------------

  // step

  const StepLengthSym = computeSymmetryIndex(stride_parameters['right_step_length'], stride_parameters['left_step_length'], 0.5*(stride_parameters['right_step_length'] + stride_parameters['left_step_length']));
  const StepTimeSym  = computeSymmetryIndex(stride_parameters['right_step_duration'], stride_parameters['right_step_duration'], 0.5*(stride_parameters['right_step_duration'] + stride_parameters['right_step_duration']));
  
  const mParamStep = Math.round((StepLengthSym + StepTimeSym)/2);
  const mParamCadence = Math.round(stride_parameters['pace']);

  // impact

  let normImpact = 0;
  let normVLR = 0;
  if(activity === 'walk')
  {
    normImpact = maxStep.MAX_IMPACT_GAIT;
    normVLR = maxStep.MAX_VLR_GAIT;
  }
  else {
    normImpact = maxStep.MAX_IMPACT_RUN;
    normVLR = maxStep.MAX_VLR_RUN;
  }

  const mParamImpact = Math.round(computeSymmetryIndex(stride_parameters['right_acc_peak'], stride_parameters['left_acc_peak'], normImpact));
  const mParamLoadingRate = Math.round(computeSymmetryIndex(stride_parameters['right_vlr'], stride_parameters['left_vlr'], normVLR));
  
  //--------------------------------------------------------------------------------------
  let symetry_parameters_result = {
    "mParamCadence": mParamCadence,
    "mParamLoadingRate": mParamLoadingRate,
    "mParamImpact": mParamImpact,
    "mParamStep": mParamStep,
    "mParamPelvis": mParamPelvis,
    "mParamHips": mParamHips,
    "mParamKnees": mParamKnees
  }

  return symetry_parameters_result;
};

const simplified_parameters_reference = ( ref_angular_parameters, ref_stride_parameters, ref_activity ) => {

  // 1. PELVIS
  // Reference params
  //--------------------------------------------------------------------------------------

  const mRefParamPelvis = Math.round(computeSymmetryIndex(ref_angular_parameters['pelvis_inc_max'], (-1)*ref_angular_parameters['pelvis_inc_min'], maxAngles['pel_obl']));

  // 2. HIPS AND KNEES
  //--------------------------------------------------------------------------------------
  let angles = {};
  const kneesHipsAngleNames = [
    'rhip_add' ,  'rhip_flex' , 'rhip_rot' ,
    'lhip_add' ,  'lhip_flex' , 'lhip_rot' ,
    'rknee_val',  'rknee_flex', 'rknee_rot',
    'lknee_val',  'lknee_flex', 'lknee_rot',
  ];

  kneesHipsAngleNames.forEach(name => {
    let max = ref_angular_parameters[name + "_max"];
    let min = ref_angular_parameters[name + "_min"];

    let angle = {
      "max"  : max,
      "min"  : min,
      "amp"  : (max - min),
      "mean" : computeMean(name, max, min),
    };

    angles[name] = angle;
  });
  
  const mRefParamHips = Math.round( (1/6) * (
    computeSymmetryIndex(angles['rhip_add'].amp,    angles['lhip_add'].amp,    maxAmplitudes['hip_add']/2) +
    computeSymmetryIndex(angles['rhip_add'].mean,   angles['lhip_add'].mean,   maxAmplitudes['hip_add']/2) +
    computeSymmetryIndex(angles['rhip_flex'].amp,   angles['lhip_flex'].amp,   maxAmplitudes['hip_flex']/2) +
    computeSymmetryIndex(angles['rhip_flex'].mean,  angles['lhip_flex'].mean,  maxAmplitudes['hip_flex']/2) +
    computeSymmetryIndex(angles['rhip_rot'].amp,    angles['lhip_rot'].amp,    maxAmplitudes['hip_rot']/2) +
    computeSymmetryIndex(angles['rhip_rot'].mean,   angles['lhip_rot'].mean,   maxAmplitudes['hip_rot']/2)
  ));
  

  const mRefParamKnees = Math.round( (1/6) * (
    computeSymmetryIndex(angles['rknee_val'].amp,   angles['lknee_val'].amp,   maxAmplitudes['knee_val']/2) +
    computeSymmetryIndex(angles['rknee_val'].mean,  angles['lknee_val'].mean,  maxAmplitudes['knee_val']/2) +
    computeSymmetryIndex(angles['rknee_flex'].amp,  angles['lknee_flex'].amp,  maxAmplitudes['knee_flex']/2) +
    computeSymmetryIndex(angles['rknee_flex'].mean, angles['lknee_flex'].mean, maxAmplitudes['knee_flex']/2) +
    computeSymmetryIndex(angles['rknee_rot'].amp,   angles['lknee_rot'].amp,   maxAmplitudes['knee_rot']/2) +
    computeSymmetryIndex(angles['rknee_rot'].mean,  angles['lknee_rot'].mean,  maxAmplitudes['knee_rot']/2)
  ));
  // console.log("reference genou calculés", mParamKnees)
  //--------------------------------------------------------------------------------------


  // 3. WIP
  //--------------------------------------------------------------------------------------

  // step

  const StepRefLengthSym = computeSymmetryIndex(ref_stride_parameters['right_step_length'], ref_stride_parameters['left_step_length'], 0.5*(ref_stride_parameters['right_step_length'] + ref_stride_parameters['left_step_length']));
  const StepRefTimeSym  = computeSymmetryIndex(ref_stride_parameters['right_step_duration'], ref_stride_parameters['right_step_duration'], 0.5*(ref_stride_parameters['right_step_duration'] + ref_stride_parameters['right_step_duration']));
  
  const mRefParamStep = Math.round((StepRefLengthSym + StepRefTimeSym)/2);
  const mRefParamCadence = Math.round(ref_stride_parameters['pace']);
  

  // impact

  let normImpact = 0;
  let normVLR = 0;
  if(ref_activity === 'walk')
  {
    normImpact = maxStep.MAX_IMPACT_GAIT;
    normVLR = maxStep.MAX_VLR_GAIT;
  }
  else {
    normImpact = maxStep.MAX_IMPACT_RUN;
    normVLR = maxStep.MAX_VLR_RUN;
  }

  const mRefParamImpact = Math.round(computeSymmetryIndex(ref_stride_parameters['right_acc_peak'], ref_stride_parameters['left_acc_peak'], normImpact));
  const mRefParamLoadingRate = Math.round(computeSymmetryIndex(ref_stride_parameters['right_vlr'], ref_stride_parameters['left_vlr'], normVLR));
  
  let ref_symetry_parameters_result = {
    "mRefParamCadence": mRefParamCadence,
    "mRefParamLoadingRate": mRefParamLoadingRate,
    "mRefParamImpact": mRefParamImpact,
    "mRefParamStep": mRefParamStep,
    "mRefParamPelvis": mRefParamPelvis,
    "mRefParamHips": mRefParamHips,
    "mRefParamKnees": mRefParamKnees
  }

  return ref_symetry_parameters_result;
};


/** computeMean
 *
 *  @param  angle    String with format "rhip_add"
 */
const computeMean = ( angle, max, min ) => {

  switch (angle) {

    case "rhip_add":
      return -0.5 * (max + min);
      break;
    case "rhip_flex":
      return  0.5 * (max + min);
      break;
    case "rhip_rot":
      return  0.5 * (max + min);
      break;

    case "lhip_add":
      return  0.5 * (max + min);
      break;
    case "lhip_flex":
      return  0.5 * (max + min);
      break;
    case "lhip_rot":
      return -0.5 * (max + min);
      break;

    case "rknee_val":
      return -0.5 * (max + min);
      break;
    case "rknee_flex":
      return -0.5 * (max + min);
      break;
    case "rknee_rot":
      return  0.5 * (max + min);
      break;

    case "lknee_val":
      return  0.5 * (max + min);
      break;
    case "lknee_flex":
      return -0.5 * (max + min);
      break;
    case "lknee_rot":
      return -0.5 * (max + min);
      break;

    default:
      throw new Error("");
  }
}

const computeSymmetryIndex = (rightParameter, leftParameter, norm) => {
    const i             = Math.abs(rightParameter - leftParameter)/norm;
    const symmetryIndex = Math.max(100*(1 - i),0);
    return symmetryIndex;
}

export { simplified_parameters, simplified_parameters_reference};
