
import { Platform } from 'react-native';


// Import components
import NewSessionAndroid from './NewSessionAndroid.js';
import NewSessionWeb     from './NewSessionWeb.js';

// Conditional rendering
let NewSession;
if (Platform.OS == 'android') {
  NewSession = NewSessionAndroid
}
else if (Platform.OS == 'web') {
  NewSession = NewSessionWeb
}

export default NewSession;
