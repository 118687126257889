import React, { useState } from 'react';
import { Box, Center, Heading } from 'native-base';
import translate from '../i18n/translate';
import { Text } from 'native-base';
// Import components
import NewSessionButton from './NewSessionButton.js';

// Import native modules
import { useWindowDimensions, ScrollView, StyleSheet, View, TouchableOpacity } from 'react-native';

const NewSessionAndroid = ({sessionList, access_token, runner }) => {

 
  

  const { height, width } = useWindowDimensions();
  const largeBreakpoint   = 992;
  const isTablet = ((width >= largeBreakpoint )||(height >= largeBreakpoint));
  const isWideEnough = (width >= 600);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [ssidDeviceMessage, setSsidDeviceMessage] = useState("");
  const [ssiddevice, setSsiddevice] = useState("");
  const [ssidDeviceSecondMessage, setSsidDeviceSecondMessage] = useState("");
  const [opacityButton, setOpacityButton] = useState(1);
  
  const displayNotificationMessage = (message)=>{
    setNotificationMessage(message)
  }
  const ssiddeviceFirstMessage = (message, ssid)=>{
    setSsidDeviceMessage(message);
    setSsiddevice(ssid)
  }
  const ssiddeviceSecondMessage = (message)=>{
    setSsidDeviceSecondMessage(message);
  } 



  return(
    <Box position="absolute" bottom="-30%" left="5%" background="#01D2F9" width="90%" height="60%"
      borderTopLeftRadius={width}
      borderTopRightRadius={width}
    >
      <Center style={{marginTop: isTablet? height*0.08 : (isWideEnough? height*0.02 : height*0.08 )}}>
        <Center >
          {/* <Text style={{ fontSize: 18, color: "black", alignSelf:"center"}}> {notificationMessage} </Text> */}
          <Heading color="white" fontSize={isTablet? "4xl": "2xl"}>
          {translate('start_balance')} 
          </Heading>
        </Center>
        
      </Center>
        { (ssidDeviceMessage !=="") && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.97)},{borderRadius: 15}]}>
          <Text style={styles.information_text}>
          {ssidDeviceMessage !== "" && ssidDeviceMessage} {ssiddevice !== "" && ssiddevice} {ssidDeviceSecondMessage !== "" && ssidDeviceSecondMessage}
          </Text>
          
          <TouchableOpacity
              style={styles.button}
              onPress={() => {
                setSsidDeviceMessage("");
                setSsidDeviceSecondMessage("");
                setNotificationMessage("");
                setSsiddevice("");
              }}>
            <Text style={styles.button_text}>{translate('go_Back')}</Text>
          </TouchableOpacity>
        </View>}
        { (notificationMessage !=="" && ssidDeviceMessage ==="") && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.97)},{borderRadius: 15}]}>
          <Text style={styles.information_text}>
            { notificationMessage}
          </Text>
          
          <TouchableOpacity
              style={styles.button}
              onPress={() => {
                setNotificationMessage("");
              }}>
            <Text style={styles.button_text}>{translate('go_Back')}</Text>
          </TouchableOpacity>
        </View>}
      {isWideEnough ? <Center style={{marginTop: height*0.02, flexDirection:"row"}}>  
        <NewSessionButton 
          activity="walk"
          choiceActivity="slowWalk" 
          sessionList = {sessionList} 
          access_token = {access_token} 
          runner = {runner}
          displayNotificationMessage = {displayNotificationMessage}
          ssiddeviceFirstMessage = {ssiddeviceFirstMessage} 
          ssiddeviceSecondMessage = {ssiddeviceSecondMessage}
          setOpacityButton = {setOpacityButton}
          opacityButton = {opacityButton}
         />
        <NewSessionButton 
          activity="walk"
          choiceActivity="quickWalk"  
          sessionList = {sessionList} 
          access_token = {access_token} 
          runner = {runner}
          displayNotificationMessage = {displayNotificationMessage}
          ssiddeviceFirstMessage = {ssiddeviceFirstMessage}
          ssiddeviceSecondMessage = {ssiddeviceSecondMessage}
          setOpacityButton = {setOpacityButton}
          opacityButton = {opacityButton}  
          />
          <NewSessionButton 
          activity="run"
          choiceActivity="slowRace"  
          sessionList = {sessionList} 
          access_token = {access_token} 
          runner = {runner}
          displayNotificationMessage = {displayNotificationMessage}
          ssiddeviceFirstMessage = {ssiddeviceFirstMessage} 
          ssiddeviceSecondMessage = {ssiddeviceSecondMessage} 
          setOpacityButton = {setOpacityButton}
          opacityButton = {opacityButton}
          />
          <NewSessionButton 
          activity="run" 
          choiceActivity="quickRace"  
          sessionList = {sessionList} 
          access_token = {access_token} 
          runner = {runner}
          displayNotificationMessage = {displayNotificationMessage}
          ssiddeviceFirstMessage = {ssiddeviceFirstMessage} 
          ssiddeviceSecondMessage = {ssiddeviceSecondMessage} 
          setOpacityButton = {setOpacityButton}
          opacityButton = {opacityButton}
          />
      </Center>:
      <Center style={{marginTop: height*0.02, height:70, flexDirection: "column"}}>
        <ScrollView style={{height:"10%"}} showsVerticalScrollIndicator={false}>
          <NewSessionButton 
            activity="walk"
            choiceActivity="slowWalk" 
            sessionList = {sessionList} 
            access_token = {access_token} 
            runner = {runner}
            displayNotificationMessage = {displayNotificationMessage}
            ssiddeviceFirstMessage = {ssiddeviceFirstMessage}
            ssiddeviceSecondMessage = {ssiddeviceSecondMessage}
            setOpacityButton = {setOpacityButton}
            opacityButton = {opacityButton}  
          />
          <NewSessionButton 
            activity="walk"
            choiceActivity="quickWalk"  
            sessionList = {sessionList} 
            access_token = {access_token} 
            runner = {runner}
            displayNotificationMessage = {displayNotificationMessage} 
            ssiddeviceFirstMessage = {ssiddeviceFirstMessage}
            ssiddeviceSecondMessage = {ssiddeviceSecondMessage} 
            setOpacityButton = {setOpacityButton}
            opacityButton = {opacityButton} 
            />
            <NewSessionButton 
            activity="run"
            choiceActivity="slowRace"  
            sessionList = {sessionList} 
            access_token = {access_token} 
            runner = {runner}
            displayNotificationMessage = {displayNotificationMessage}
            ssiddeviceFirstMessage = {ssiddeviceFirstMessage}
            ssiddeviceSecondMessage = {ssiddeviceSecondMessage} 
            setOpacityButton = {setOpacityButton}
            opacityButton = {opacityButton}  
            />
            <NewSessionButton 
            activity="run" 
            choiceActivity="quickRace"  
            sessionList = {sessionList} 
            access_token = {access_token} 
            runner = {runner}
            displayNotificationMessage = {displayNotificationMessage}
            ssiddeviceFirstMessage = {ssiddeviceFirstMessage}
            ssiddeviceSecondMessage = {ssiddeviceSecondMessage} 
            setOpacityButton = {setOpacityButton}
            opacityButton = {opacityButton}  
            />
          </ScrollView>
      </Center>}
    </Box>
  );
}
//height*0.02

export default NewSessionAndroid;

const styles = StyleSheet.create({
  information_container:{ 
    borderWidth:1,
    alignSelf:"center",
    flex: 1,
    borderColor: '#00d5f7',
    borderRadius: 20,
    backgroundColor: '#FFF',
    position: 'absolute',
    top :0,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, 
  },
  button:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:200,
    marginBottom: 10,
    marginTop: 10
  },
  button_text:{
    fontSize: 14, 
    alignSelf:"center", 
    marginHorizontal:20, 
    marginVertical:2
  },
  information_text:{
    textAlign:"justify", 
    padding: 5, 
    paddingHorizontal: 15,
    color:"black"
  },
  session_circle:{
    width: 20,
    height: 20,
    borderRadius:10,
    backgroundColor:"#707070",
    alignSelf:"center"
  },
  reference_circle:{
    width: 20,
    height: 20,
    borderRadius:10,
    backgroundColor:"#00d5f7",
    alignSelf:"center"
  }
});
