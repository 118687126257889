import React from 'react';
import { Center, Text, Box } from 'native-base';

// intl components
import 'intl';
import translate from '../i18n/translate';
import { View, StyleSheet, useWindowDimensions, Platform } from 'react-native';

const SessionResultsSymmetry = ({ session, refSession }) => {

  const { mParamCadence, mParamLoadingRate, mParamImpact, mParamStep, mParamPelvis, mParamHips, mParamKnees } = session;

  const numOfValues = 8; // Number of fields in the Component +1 for display purposes

  const { height, width } = useWindowDimensions();
  const largeBreakpoint = 992;
  const otherBreakpoint = 768;
  const midBreakpoint = 688;
  const largeurLg = (width>= midBreakpoint)? (width >= largeBreakpoint)? width : width : width*2;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint)
  const fontsize = isTablet? 14.5:10.1;
  const fontWeightSize = isTablet? "bold":"400";
  const fontsizePercent = isTablet? 18:11.6;
  const websize = width > otherBreakpoint ? 18: 12;
  const textWidth = (width>= midBreakpoint)? width/numOfValues : (2*width/numOfValues)
  const justifyContent = width > otherBreakpoint ? "space-between": "flex-end";
  const paddingRights =  width > otherBreakpoint ? 3: 0;
  const paddingLefts =  width > otherBreakpoint ? 5: 0;
  const isWideEnough = (width >= 600);
  return(
    <Box mt="2%">
    { (Platform.OS === 'android')? <Center justifyContent="space-between" flexDirection="row" mb="3%" w={isWideEnough? largeurLg : "91%"}>
        <View style={{width:isWideEnough? textWidth:12, marginLeft:isWideEnough? 0:"1.5%"}} >
          <Text fontWeight={fontWeightSize}  width={isWideEnough? textWidth:10}  marginBottom= {isWideEnough? 0:8} textAlign={isWideEnough? "center":"auto"} fontSize={fontsize} color="#000">{translate('cadence')}</Text><Text fontWeight="bold" width={isWideEnough? textWidth:10} fontSize={fontsizePercent} color="#000" textAlign="center" px={0}>{mParamCadence} pas/min</Text>
        </View>
        <View style={{width:isWideEnough? textWidth:12}}>
          <Text fontWeight={fontWeightSize} width={isWideEnough? textWidth:10} marginBottom= {isWideEnough? 0:5} textAlign="center" fontSize={fontsize} color="#000">{translate('damping_symetry')}</Text><Text fontWeight="bold" width={isWideEnough? textWidth:10} fontSize={fontsizePercent} color="#000" textAlign="center" px={0}>{mParamLoadingRate} %</Text>
        </View>
        <View style={{width:isWideEnough? textWidth:12}}>
          <Text fontWeight={fontWeightSize} width={isWideEnough? textWidth:10} marginBottom= {isWideEnough? 0:5} textAlign="center" fontSize={fontsize} color="#000">{translate('impact_symetry')}</Text><Text fontWeight="bold" width={isWideEnough? textWidth:10} fontSize={fontsizePercent} color="#000" textAlign="center" px={0}>{mParamImpact} %</Text>
        </View>
        <View style={{width:isWideEnough? textWidth:12}}>
          <Text fontWeight={fontWeightSize} width={isWideEnough? textWidth:10} marginBottom= {isWideEnough? 0:8} textAlign="center" fontSize={fontsize} color="#000">{translate('step_symetry')}</Text><Text fontWeight="bold" width={isWideEnough? textWidth:10} fontSize={fontsizePercent} color="#000" textAlign="center" px={0}>{mParamStep} %</Text>
        </View>
        <View style={{width:isWideEnough? textWidth:12}}>
          <Text fontWeight={fontWeightSize} width={isWideEnough? textWidth:10} marginBottom= {isWideEnough? 0:5} textAlign="center" fontSize={fontsize} color="#000">{translate('pelvic_symetry')}</Text><Text fontWeight="bold" width={isWideEnough? textWidth:10} fontSize={fontsizePercent} color="#000" textAlign="center" px={0}>{mParamPelvis} %</Text>
        </View>
        <View style={{width:isWideEnough? textWidth:12}}>
          <Text fontWeight={fontWeightSize} width={isWideEnough? textWidth:10} marginBottom= {isWideEnough? 0:5} textAlign="center" fontSize={fontsize} color="#000">{translate('hip_symetry')}</Text><Text fontWeight="bold" width={isWideEnough? textWidth:10} fontSize={fontsizePercent} color="#000" textAlign="center" px={0}>{mParamHips} %</Text>
        </View>
        <View style={{width:isWideEnough? textWidth:12}}>
          <Text fontWeight={fontWeightSize} width={isWideEnough? textWidth:10} marginBottom= {isWideEnough? 0:5} textAlign="center" fontSize={fontsize} color="#000">{translate('knee_symetry')}</Text><Text fontWeight="bold" width={isWideEnough? textWidth:10} fontSize={fontsizePercent} color="#000" style={styles.bordure} textAlign="center" px={0}>{mParamKnees} %</Text>
        </View>
      </Center>: 
      <Box flexDirection="row" justifyContent={justifyContent}>
          <Text fontWeight="bold" color="#00d5f7" fontSize={websize} paddingRight={(width > otherBreakpoint) ? 10: 2} paddingLeft={paddingLefts}><p>{translate('cadence')}</p><p>{mParamCadence} pas/min</p></Text>
          <Text fontWeight="bold" color="#00d5f7" fontSize={websize} paddingLeft={(width > otherBreakpoint) ? 19: 2}><p>{translate('damping_symetry')}</p><p>{mParamLoadingRate} %</p></Text>
          <Text fontWeight="bold" color="#00d5f7" fontSize={websize} paddingLeft={(width > otherBreakpoint) ? 3: 2}><p>{translate('impact_symetry')}</p><p>{mParamImpact} %</p></Text>
          <Text fontWeight="bold" color="#00d5f7" fontSize={websize} paddingLeft={(width > otherBreakpoint) ? 5: 2}><p>{translate('step_symetry')}</p><p>{mParamStep} %</p></Text>
          <Text fontWeight="bold" color="#00d5f7" fontSize={websize} paddingLeft={(width > otherBreakpoint) ? 5: 2}><p>{translate('pelvic_symetry')}</p><p>{mParamPelvis} %</p></Text>
          <Text fontWeight="bold" color="#00d5f7" fontSize={websize} paddingLeft={(width > otherBreakpoint) ? 5: 2}><p>{translate('hip_symetry')}</p><p>{mParamHips} %</p></Text>
          <Text fontWeight="bold" color="#00d5f7" fontSize={websize} paddingLeft={(width > otherBreakpoint) ? 0: 2} paddingRight={paddingRights}><p>{translate('knee_symetry')}</p><p>{mParamKnees} %</p></Text>
      </Box>
      }
    </Box>
  );
}

export default SessionResultsSymmetry;

const styles = StyleSheet.create({
  container: { 
    color: '#00d5f7',
     borderWidth: 1
     
  },
  borderDimension: {
    borderWidth:1,
    borderColor: 'red',
    //width:90
  },
  borderDimension2: {
    borderWidth:1,
    borderColor: 'red',
    width:120
  },
  symetryParams:{
    flexDirection:"row"
  }


})