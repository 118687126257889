import React,{ useState, useEffect } from 'react';
import { NativeBaseProvider, Center, Stack, Heading } from 'native-base';

import {View, StyleSheet, TouchableOpacity, useWindowDimensions, Platform, Text, ScrollView } from 'react-native';
import { Grid, LineChart, XAxis, YAxis } from 'react-native-svg-charts';
import { Circle, Path, Rect, Use } from 'react-native-svg';
import { simplified_parameters, simplified_parameters_reference } from '../src/simplified_parameters';
import {Picker} from '@react-native-picker/picker';

// intl components
import 'intl';
import translate from '../i18n/translate';


const BiomechaParamsBehavior = ({route, navigation})=>{


  const [dayDate, setDayDate] = useState('');
  const [paramsValue, setParamsValue] = useState('');
  const [impactStrengthParams, setImpactStrengthParams] = useState('');
  const [labelParams, setLabelParams] = useState('');
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("Français");
  const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}];



  const {id, mParam, mParamTitle, sessionList, runnerSession} = route.params;
  const midBreakpoint = 688;
  const { height, width } = useWindowDimensions();
  const isWideEnough = width>600;
  const largeBreakpoint   = 992;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
  const flexWidth = (width>= midBreakpoint)? (width >= largeBreakpoint)? 1 : 0 : 1;
  const fontSize = (width<=midBreakpoint)? 18 : 18;
  const graphWidth = Math.min(width*0.9, 1000);
  const graphHeight = Math.min(height*0.45, 300);


  const getSessionListChronological = (array) => {
    var chronologicalSessionList = array.sort(function(a,b){
      return new Date(Date.parse(a.date)) - new Date(Date.parse(b.date));
    });
    return chronologicalSessionList;
  }
  let filteredWalkArray = (getSessionListChronological(sessionList) || []).filter( elt => elt.activity === "walk");
  let filteredRunArray = (getSessionListChronological(sessionList) || []).filter( elt => elt.activity === "run");
  // console.log(runnerSession.date)

  const getFilterArray = ()=>{
    if(runnerSession.activity === "walk"){
      return filteredWalkArray;
    }
    return filteredRunArray;
  }



  let filteredArray = (getFilterArray() || []).filter( (elt, index) => index <= (startIndex !== null ?startIndex : getFilterArray().indexOf(runnerSession)) && index > (endIndex !==null ? endIndex : (getFilterArray().indexOf(runnerSession) - (isTablet ? 8 : 5)) ));
  let filteredArrayIndex = getSessionListChronological(filteredArray);
  let arrayParamCadence = [];
  let arrayParamLoadingRate = [];
  let arrayParamImpact = [];
  let arrayParamStep = [];
  let arrayParamPelvis = [];
  let arrayParamHips = [];
  let arrayParamKnees = [];
  let impactForceLeftAccPeak = [];
  let impactForceRightAccPeak = [];
  let loadingRateLeft = [];
  let loadingRateRight = [];
  let arrayDate = [];
  let getSimplifiedParameters = ()=>{
    filteredArrayIndex.map((element)=>{
      let sessionParameters = simplified_parameters(element.angular_parameters_global, element.stride_parameters, element.activity);
      arrayParamCadence.push(sessionParameters.mParamCadence);
      arrayParamLoadingRate.push(sessionParameters.mParamLoadingRate);
      arrayParamImpact.push(sessionParameters.mParamImpact);
      arrayParamStep.push(sessionParameters.mParamStep);
      arrayParamPelvis.push(sessionParameters.mParamPelvis);
      arrayParamHips.push(sessionParameters.mParamHips);
      arrayParamKnees.push(sessionParameters.mParamKnees);
      arrayDate.push(new Date(Date.parse(element.date)));
    })
  }

  let getImpactForceParameter = ()=>{
    filteredArrayIndex.map((element)=>{
      impactForceLeftAccPeak.push(element.stride_parameters.left_acc_peak);
      impactForceRightAccPeak.push(element.stride_parameters.right_acc_peak);
      arrayDate.push(new Date(Date.parse(element.date)));
    })
  }

  let getLodingRateParameter = ()=>{
    filteredArrayIndex.map((element)=>{
      loadingRateLeft.push(element.stride_parameters.left_vlr);
      loadingRateRight.push(element.stride_parameters.right_vlr);
      arrayDate.push(new Date(Date.parse(element.date)));
    })
  }

  (mParam === "Force d'impact" || mParam === "Amorti") ? (mParam === "Amorti"? getLodingRateParameter() : getImpactForceParameter()): getSimplifiedParameters();
  
  const Line = ({line}) => (
    <Path
      d={line} stroke={'#707070'} fill={'none'}
    />
  );
  let dataModel = (id)=>{
    switch (id) {
      case 1:
        return arrayParamCadence;
      case 2:
        return arrayParamLoadingRate;
      case 3:
        return arrayParamImpact;
      case 4:
        return arrayParamStep;
      case 5:
        return arrayParamPelvis;
      case 6:
        return arrayParamHips;
      case 7:
        return arrayParamKnees;
      case 8:
        return impactForceLeftAccPeak;
      case 9:
        return loadingRateLeft;
      default:
        console.log(`Sorry, we are out of ${id}.`);
    }
  }
  

  const Decorator = ({x, y, data}) => {
    return data.map((value, index) => (
      <Circle
        key={index}   r={11}
        cx={x(index)} cy={y(value)}
        stroke={'#00D5F7'}
        fill={'#000'}
        onPress={() => {
          const options    = {year: "numeric", month: "numeric", day: "numeric",hour: "numeric", minute: "numeric"};
          const dateString = new Intl.DateTimeFormat("fr-fr",options).format(arrayDate[index]);
          setParamsValue(Math.round(value));
          setDayDate(dateString);
        }}
      />
    ));
  };

  const DecoratorWeb = ({x, y, data}) => {
    return data.map((value, index) => (
      <Circle
        key={index}   r={11}
        cx={x(index)} cy={y(value)}
        stroke={'#00D5F7'}
        fill={'#000'}
        onClick={() => {
          const options    = {year: "numeric", month: "numeric", day: "numeric",hour: "numeric", minute: "numeric"};
          const dateString = new Intl.DateTimeFormat("fr-fr",options).format(arrayDate[index]);
          setParamsValue(Math.round(value));
          setDayDate(dateString);  
        }}
      />
    ));
  };

  useEffect(() => {
    (id === 8 ||id === 9) ? setLabelParams("Gauche") : setLabelParams("");
    setStartIndex(getFilterArray().indexOf(runnerSession));
    setEndIndex(getFilterArray().indexOf(runnerSession) - (isTablet ? 8 : 5));
  }, []);

    return(
      <NativeBaseProvider>
        <Stack alignItems="center" style={{fontFamily:"sans-serif"}}>
          <View style={styles.picker_container}>
            <Picker style={styles.picker_component}
                selectedValue={selectedLanguage}
                onValueChange={(itemValue, itemIndex) =>{
                  setSelectedLanguage(itemValue);
                  route.params.translationFunc(selectedLanguage);
                }}>
                {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
            </Picker>
          </View>
          <Center mt="5">
            <Heading size="md" style={{marginBottom: 30,padding: 5}}>
              {translate('params_behavior')} {mParamTitle} {labelParams !=="" && (labelParams ==="Gauche"? translate('at_left'):translate('at_right'))}
            </Heading> 
            <Center style={{ flexDirection: 'row',width:'80%', justifyContent:"space-evenly",marginBottom: 40}}>
              {(endIndex >= 0) &&<TouchableOpacity style={{height:30, width:'20%', borderWidth:1, borderRadius:15, justifyContent:"center", alignItems:"center"}}
                onPress={() => {
                  setStartIndex(endIndex);
                  setEndIndex(endIndex - (isTablet ? 8 : 5));
                }}>
                <Heading size="md">←</Heading>
              </TouchableOpacity>} 
              {(startIndex + 1 < getFilterArray().length) &&<TouchableOpacity style={{height:30, width:'20%', borderWidth:1, borderRadius:15, justifyContent:"center", alignItems:"center"}}
                onPress={() => { 
                  setStartIndex(startIndex + (isTablet ? 8 : 5));
                  setEndIndex(startIndex);
                }}>
                <Heading size="md">→</Heading>
              </TouchableOpacity>} 
            </Center>
            <View style={{ width:width*0.95}}>
            {((id === 8 || id === 9) && impactStrengthParams ==="") &&<TouchableOpacity style={{height:30, width:width*0.25, borderRadius:15, justifyContent:"center", alignItems:"center",backgroundColor: '#F57F17C0'}}
                onPress={() => { 
                  setImpactStrengthParams("OK");
                  setLabelParams("Droite");
                }}>
                <Heading size="md" style={{fontSize: 18}}>{translate('at_right')} ?</Heading>
              </TouchableOpacity>}
              {((id === 8 || id === 9) && impactStrengthParams !=="") &&<TouchableOpacity style={{height:30, width:width*0.25, borderRadius:15, justifyContent:"center", alignItems:"center",backgroundColor: '#F57F17C0'}}
                onPress={() => { 
                  setImpactStrengthParams("");
                  setLabelParams("Gauche");
                }}>
                <Heading size="md" style={{fontSize: 18}}>{translate('at_left')} ?</Heading>
              </TouchableOpacity>} 
            </View>
            <View style={{  flexDirection: 'row',padding: 5}}> 
              <YAxis
                data={impactStrengthParams !== "" ? (mParam === "Amorti" ? loadingRateRight : impactForceRightAccPeak) : dataModel(id)}
                contentInset={{top: 20, bottom: 20, left: 20, right:20}}
                svg={{
                    fill: '#000',
                    fontSize: 15,
                    fontWeight:"bold"
                }}
                numberOfTicks={10}
                formatLabel={(value) => `${value} ${(mParam==="Cadence"?' pas/min': ((mParam==="Force d'impact" || mParam === "Amorti") ? (mParam === "Amorti" ? ' BW/s': ' BW'):' %'))}`}
              />
              <LineChart
                style={{flex: flexWidth,height: 300, width: (isWideEnough ? 500 : (width*0.8)),marginLeft: 10}}
                data={impactStrengthParams !== "" ? (mParam === "Amorti" ? loadingRateRight : impactForceRightAccPeak) : dataModel(id)}
                contentInset={{top: 20, bottom: 20, left: 20, right:20}}
                svg={{stroke: '#000'}}
              >
                <Grid/>
                <Line/>
                {Platform.OS === 'android' ? <Decorator/> : <DecoratorWeb/>}
              </LineChart>
            </View>
            {(dayDate != "") && <View style={[styles.information_container, {width: isWideEnough? 300 : (width*0.8)},{borderRadius: 15,elevation: 2}]}>
              <Text style={styles.information_text}>
              {translate('session_date')} : {dayDate +' min'}.
              </Text>
              <Text style={styles.information_text}>
              {mParamTitle} : {paramsValue}{(mParam==="Cadence"? translate('steps_label'):((mParam==="Force d'impact" || mParam==="Amorti")?(mParam==="Amorti" ?' BW/s':' BW'):' %'))}.
              </Text>
              <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    setDayDate('');
                  }}>
                <Text style={styles.button_text}> {translate('go_Back')} </Text>
              </TouchableOpacity>
            </View>}
          </Center>
        </Stack>
      </NativeBaseProvider>
    )
}

export default BiomechaParamsBehavior

const styles = StyleSheet.create({
  picker_component:{
    flex:1,
    borderRadius: 4,
    width: 150,
    marginRight: 30,
    justifyContent:'flex-end'
  },
  picker_container:{
    width: "100%",
    alignItems: "flex-end",
    height: 30,
  },
  information_container:{ 
    borderWidth:1,
    alignSelf:"center",
    flex: 1,
    borderColor: '#00d5f7',
    borderRadius: 20,
    backgroundColor: '#FFF',
    position: 'absolute',
    top :40,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, 
  },
  information_text:{
    textAlign:"justify", 
    padding: 5, 
    paddingHorizontal: 15,
    color:"black",
    fontWeight:"bold"
  },
  button:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:200,
    marginBottom: 10,
    marginTop: 20,
    fontWeight:"bold"
  },
  button_text:{
    fontSize: 14, 
    alignSelf:"center", 
    marginHorizontal:20, 
    marginVertical:2,
    color:"#000",
    fontWeight:"bold"
  },
  })