import React,{ useState, useEffect } from 'react';
import {NativeBaseProvider, Center, Stack, Heading, Divider } from 'native-base';
import { View, TouchableOpacity, Image, Platform, useWindowDimensions, StyleSheet, ScrollView} from 'react-native';
import { Circle, Path, Rect, Use, Svg, Text, } from 'react-native-svg';
import { RadioButton } from 'react-native-paper';

// intl components
import 'intl';
import translate from '../i18n/translate';

//get Image
const resultsHighIcon = require('../images/png/open_select_hand_gesture_icon.png');

const BiomechaParamsReport = ({route, navigation  }) => {

    const {asArrayResults, runner} = route.params;


    const [checked, setChecked] = useState('');
    const [checkedChoose, setCheckedChoose] = useState('');
    const [displayCommentInfo, setDisplayCommentInfo] = useState("");
    const [modifierChoose, setModifierChoose] = useState('');


    const largeBreakpoint = 992;
    const midBreakpoint = 688;
    const {width, height} = useWindowDimensions();
    const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
    const isLandscape = width > height;
    const isHorizontalDisplay = isLandscape;
    const fontSize = (width<=midBreakpoint)? 15 : 15;
    const isWideEnough = width>600;
    

    const spatioTemporalFirstBar = (data)=>{
        let x= 10;
        let recHtml = data.map((L, index)=>
            <Rect key={index} x={index===0? x: x + (isTablet ? 80 : 80) } y={(isTablet ? 400 : 400) - (L<0? 200: (L>400? 200: L))} width={60} height={(L<0? 150: (L>400? 200: L))} rx={12} ry={12} fill={index===0?"#F57F17C0":"#00d5f7A0"}/>
        )
        return recHtml;
    }
    
    const spatioTemporalSecondBar = (data)=>{
        let x= isTablet ? 218 : 218;
        let recHtml = data.map((L,index)=>
            <Rect key={index} x={index===0? x: x + (isTablet ? 80 : 80) } y={(isTablet ? 400 : 400) - (L<0? 200: (L>400? 200: L))} width={60} height={(L<0? 150: (L>400? 200: L))} rx={12} ry={12} fill={index===0?"#F57F17C0":"#00d5f7A0"}/>
        )
        return recHtml;
    }

    const decorator = (data) =><Circle
            r={16}
            cx={190} cy={25}
            stroke='#00D5F7'
            fill='#00D5F7'
            onPress={() => {
              console.log('cliquer', data.comment)
              if(data.comment !== ""){
                displayCommentInfo !== ""? setDisplayCommentInfo("") : setDisplayCommentInfo(data.comment); 
                }else{
                    
                    displayCommentInfo !== ""? setDisplayCommentInfo("") : setDisplayCommentInfo("Aucun commentaire pour l'instant.");
                }
            }}
          />
      

      const decoratorWeb = (data, modifier) => <Circle
          r={16}
          cx={190} cy={26}
          stroke='#00D5F7'
          fill='#00D5F7'
          onClick={() => { 
            console.log('cliquer', data.comment)
            if(data.comment !== ""){
                modifier=="top"? setModifierChoose("top"):modifier=="bottom"? setModifierChoose("bottom"):setModifierChoose("");
                displayCommentInfo !== ""? setDisplayCommentInfo("") : setDisplayCommentInfo(data.comment); 
            }else{
                modifier=="top"? setModifierChoose("top"):modifier=="bottom"? setModifierChoose("bottom"):setModifierChoose("");
                displayCommentInfo !== ""? setDisplayCommentInfo("") : setDisplayCommentInfo("Aucun commentaire pour l'instant.");
            }        
          }}
        />
    

    return (
        <NativeBaseProvider>
            <Stack>
                <Center mt="5" style={{backgroundColor:'#f3f3f1'}}>
                    <View style={{flexDirection:"row", alignItems: 'center', width:"100%"}}>
                        <Heading size="md" style={{padding: 5, width:(Platform.OS === 'android') ?"85%":"50%"}}>
                            {translate('settings')} : 
                        </Heading>
                        {((checkedChoose !== 'OK') && checkedChoose !== '') &&<TouchableOpacity
                            style={[styles.button_result_icon,{width:(Platform.OS === 'android') ?"8.5%":"5.5%", alignItems: 'center'}]}
                            onPress={() => {
                                setCheckedChoose('OK');
                                setChecked('');
                            }}
                        >
                            <Image alt="High icon results logo" style={(Platform.OS === 'android') ? {} : {width: 25, height:30}}   source={resultsHighIcon}/>
                        </TouchableOpacity>}
                    </View>
                    {(Platform.OS === "android" && checked !=="") &&<TouchableOpacity
                        style={{width:(Platform.OS === 'android') ?"20%":"20%"}}
                        onPress={() => {
                            navigation.navigate("PdfComparedSessionsParams",{asArrayResults:asArrayResults, checkedChoose: checkedChoose, runner:runner});
                        }}
                    >
                        <Image alt="logo pour générer le pdf" style={{width: 30, height:30}} source={require('../images/png/pdf_1024.png')}/>
                    </TouchableOpacity>}
                    {((checkedChoose === 'OK') || checkedChoose === '') &&<View style={{flexDirection:"column", alignItems: 'center'}}>
                        <View style={{flexDirection:"row", alignItems: 'center', width:250,}}>
                            <RadioButton
                                value="spatioTemporal"  
                                status={ checked === 'spatioTemporal' ? 'checked' : 'unchecked' }
                                onPress={() => {
                                    setChecked('spatioTemporal');
                                    setCheckedChoose("spatioTemporal");
                                }}
                            /><Heading fontSize={fontSize} fontWeight="bold" > {translate('spatio_temporal')} </Heading>
                        </View>
                        <View style={{flexDirection:"row", alignItems: 'center', width:250,}}>
                            <RadioButton
                                value="kinetic"  
                                status={ checked === 'kinetic' ? 'checked' : 'unchecked' }
                                onPress={() => {
                                    setChecked('kinetic');
                                    setCheckedChoose("kinetic");
                                }}
                            /><Heading fontSize={fontSize} fontWeight="bold" > {translate('kinetic')} </Heading>
                        </View>
                        <View style={{flexDirection:"row",marginLeft:(Platform.OS === 'android') ?(isTablet? 24 : 0):79, alignItems: 'center', width:(Platform.OS === 'android') ?(isTablet? (isHorizontalDisplay? '24%' : '44%') : 250):330}}>
                            <RadioButton
                                value="spatioKinetic"  
                                status={ checked === 'spatioKinetic' ? 'checked' : 'unchecked' }
                                onPress={() => {
                                    setChecked('spatioKinetic');
                                    setCheckedChoose("spatioKinetic");
                                }}
                            /><Heading fontSize={fontSize} fontWeight="bold" > {translate('spatio_and_kinetic')} </Heading>
                        </View>
                    </View>}
                    
                    
                    {(checkedChoose === 'spatioTemporal' || checkedChoose === 'kinetic') &&
                    <ScrollView style={{height: isTablet? '90%' : '90%',width: isTablet? '98%' : '98%'}} showsVerticalScrollIndicator={false}>
                        <Center style={{width:isTablet? (isHorizontalDisplay? '95%' : '95%') : '100%', marginTop:50, marginBottom:50, backgroundColor:"#dbdad5"}}>
                            <Heading style={{textAlign:'center', borderColor: '#dbdad5', width:"94%", padding:8, fontSize:isTablet? 18 : 20}}> {checkedChoose ==="spatioTemporal"? translate('spatio_temporal'): (checkedChoose ==="spatioKinetic"? translate('spatio_and_kinetic'): (checkedChoose ==="kinetic"? translate('kinetic'):("")))} </Heading>
                        </Center>
                        <Center style={{flexDirection: (isTablet? 'row' : 'column'), justifyContent:(isTablet? 'space-between' : 'space-between'), flexWrap:'wrap', alignContent:'center', marginBottom:20}}>
                            {asArrayResults.map((el, index)=> <Svg key={index} width={isTablet? (isHorizontalDisplay? '30%' : '48%') : '96%'} height="400" style={{borderWidth:1,borderColor: '#dbdad5',marginTop:4}}>
                            {Platform.OS === 'android' ? decorator(el) : decoratorWeb(el, "")}
                            <Text x={(Platform.OS === 'android') ?"186":"189"} y={41} text-anchor="middle" fontSize={15} fontWeight="bold" fill="black">c</Text>
                            {spatioTemporalFirstBar([2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*((checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))),2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'?((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))])} 
                            {spatioTemporalSecondBar([2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr))),2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.right_vlr)))])} 
                            <Text x={(Platform.OS === 'android') ? "20":"42"} y={390 - 2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))} {checkedChoose === 'spatioTemporal'? "%":"BW"} </Text>
                            <Text x={(Platform.OS === 'android') ? "100":"122"} y={390 - 2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))} {checkedChoose === 'spatioTemporal'? "%":"BW"}</Text>
                            <Text x={(Platform.OS === 'android') ? ((checkedChoose === 'spatioTemporal') ? '219':'208'):"249"} y={((checkedChoose === 'spatioTemporal') ? (el.stride_parameters.stride_length < 0 ? 90: 390):390) - 2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_length <0? 0 : el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr)))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr)))} {checkedChoose === 'spatioTemporal'? "%":"BW/s"}</Text>
                            <Text x={(Platform.OS === 'android') ? ((checkedChoose === 'spatioTemporal') ? '307':'287'):"325"} y={390 - 2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.right_step_length <0 ? 0 : el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.right_vlr)))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? (100*((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)) >100 ? 1 : ((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length))):(el.stride_parameters.right_vlr)))} {checkedChoose === 'spatioTemporal'? "%":"BW/s"}</Text>
                            <Text x={(Platform.OS === 'android') ?"25":"75"} y={115} text-anchor="middle" fontSize={16} fontWeight="bold" fill="black">{checkedChoose === 'spatioTemporal'? "Durée de la foulée":"Force d'impact au sol"}</Text>
                            {checkedChoose === 'spatioTemporal' &&<Text x="55" y={145} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{(el.stride_parameters.stride_duration).toFixed(2).toString().replace('.', ',')} s</Text>}
                            <Text x={(Platform.OS === 'android') ?((checkedChoose === 'spatioTemporal') ? '200':'260'):"280"} y={115} text-anchor="middle" fontSize={16} fontWeight="bold" fill="black">{checkedChoose === 'spatioTemporal'?  "Longueur de la foulée":" Amorti"}</Text>
                            {checkedChoose === 'spatioTemporal' &&<Text x={(Platform.OS === 'android') ?"270":"290"} y={145} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{(el.stride_parameters.stride_length).toFixed(2).toString().replace('.', ',')} m</Text>}
                            <Text x={(Platform.OS === 'android') ?"55":"55"} y={50} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">Date</Text>
                            <Text x={(Platform.OS === 'android') ?"30":"55"} y={80} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {new Intl.DateTimeFormat("fr-fr",{year: "numeric", month: "numeric", day: "numeric"}).format(new Date(Date.parse(el.date)))} </Text>
                            <Text x={(Platform.OS === 'android') ?"260":"290"} y={50} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">Horaire</Text>
                            <Text x={(Platform.OS === 'android') ?"254":"290"} y={80} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {el.date.split(' ')[4]} </Text>
                            </Svg>)}
                        </Center>
                    </ScrollView>}
                    {(checkedChoose === 'spatioKinetic') &&
                    <ScrollView style={{height: isTablet? '90%' : '90%',width: isTablet? '98%' : '98%'}} showsVerticalScrollIndicator={false}>
                        <Center style={{width:isTablet? (isHorizontalDisplay? '95%' : '95%') : '100%', marginTop:50, marginBottom:50, backgroundColor:"#dbdad5" }}>
                            <Heading style={{ textAlign:'center', borderColor: '#dbdad5', width:"94%", padding:8, fontSize:isTablet? 18 : 20}}> {translate('spatio_temporal')} </Heading>
                        </Center>
                        <Center style={{flexDirection: (isTablet? 'row' : 'column'), justifyContent:(isTablet? 'space-between' : 'space-between'), flexWrap:'wrap', alignContent:'center'}}>
                            {asArrayResults.map((el, index)=> <Svg key={index} width={isTablet? (isHorizontalDisplay? '30%' : '48%') : '96%'} height="400" style={{borderWidth:1,borderColor: '#dbdad5',marginTop:4}}>
                            {Platform.OS === 'android' ? decorator(el) : decoratorWeb(el, "top")}
                            <Text x={(Platform.OS === 'android') ?"186":"189"} y={41} text-anchor="middle" fontSize={15} fontWeight="bold" fill="black">c</Text>
                            {spatioTemporalFirstBar([2*Math.round(100*((((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration))))),2*Math.round(100*(((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration))))])} 
                            {spatioTemporalSecondBar([2*Math.round(100*(((el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)))),2*Math.round(100*(((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length))))])} 
                            <Text x={(Platform.OS === 'android') ? "20":"42"} y={390 - 2*Math.round(100*(((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration))))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{Math.round(100*(((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration))))} % </Text>
                            <Text x={(Platform.OS === 'android') ? "100":"122"} y={390 - 2*Math.round(100*(((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration))))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{Math.round(100*(((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration))))} %</Text>
                            <Text x={(Platform.OS === 'android') ? "227":"249"} y={(el.stride_parameters.stride_length < 0 ? 90 : 390) - 2*Math.round(100*(((checkedChoose === 'spatioTemporal' ? (el.stride_parameters.left_step_length < 0 ? 0 : el.stride_parameters.left_step_length): el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length))))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {Math.round(100*(((el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length))))} %</Text>
                            <Text x={(Platform.OS === 'android') ? "307":"325"} y={390 - 2*Math.round(100*(((el.stride_parameters.right_step_length < 0 ? 0 : el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length))))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {Math.round(100*(100*((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)) >100 ? 1 :((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length))))} %</Text>
                            <Text x={(Platform.OS === 'android') ?"25":"75"} y={115} text-anchor="middle" fontSize={16} fontWeight="bold" fill="black">Durée de la foulée</Text>
                            <Text x="55" y={145} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{(el.stride_parameters.stride_duration).toFixed(2).toString().replace('.', ',')} s</Text>
                            <Text x={(Platform.OS === 'android') ?"195":"280"} y={115} text-anchor="middle" fontSize={16} fontWeight="bold" fill="black">Longueur de la foulée</Text>
                            <Text x={(Platform.OS === 'android') ?"270":"290"} y={145} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{(el.stride_parameters.stride_length).toFixed(2).toString().replace('.', ',')} m</Text>
                            <Text x={(Platform.OS === 'android') ?"55":"55"} y={50} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">Date</Text>
                            <Text x={(Platform.OS === 'android') ?"30":"55"} y={80} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {new Intl.DateTimeFormat("fr-fr",{year: "numeric", month: "numeric", day: "numeric"}).format(new Date(Date.parse(el.date)))} </Text>
                            <Text x={(Platform.OS === 'android') ?"260":"290"} y={50} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">Horaire</Text>
                            <Text x={(Platform.OS === 'android') ?"254":"290"} y={80} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {el.date.split(' ')[4]} </Text>
                            </Svg>)}
                            {(displayCommentInfo !=="" && modifierChoose === "top") &&
                            <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.8), height:(Platform.OS === 'android') ? "70%":"50%"},{borderRadius: 15}]}>
                                <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
                                    <Heading fontSize={fontSize} fontWeight="bold" style={styles.information_text}>
                                    {displayCommentInfo}
                                    </Heading>
                                    <TouchableOpacity
                                    style={styles.button}
                                    onPress={() => {
                                        setDisplayCommentInfo("");
                                    }}>
                                    <Heading fontSize={fontSize} fontWeight="bold" style={styles.button_text}>{translate('go_Back')}</Heading>
                                    </TouchableOpacity>
                                </ScrollView>  
                            </View>}
                        </Center>
                        <Center style={{width:isTablet? (isHorizontalDisplay? '95%' : '95%') : '100%', marginTop:50, marginBottom:50, backgroundColor:"#dbdad5"}}>
                            <Heading style={{ textAlign:'center', borderColor: '#dbdad5', width:"94%", padding:8, fontSize:isTablet? 18 : 20}}> {translate('kinetic')} </Heading>
                        </Center>
                        <Center style={{flexDirection: (isTablet? 'row' : 'column'), marginBottom:20, justifyContent:(isTablet? 'space-between' : 'space-between'), flexWrap:'wrap', alignContent:'center'}}>
                            {asArrayResults.map((el, index)=> <Svg key={index} width={isTablet? (isHorizontalDisplay? '30%' : '48%') : '96%'} height="400" style={{borderWidth:1,borderColor: '#dbdad5',marginTop:4}}>
                            {Platform.OS === 'android' ? decorator(el) : decoratorWeb(el,"bottom")}
                            <Text x={(Platform.OS === 'android') ?"186":"189"} y={41} text-anchor="middle" fontSize={15} fontWeight="bold" fill="black">c</Text>
                            {spatioTemporalFirstBar([2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*((checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))),2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'?((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))])} 
                            {spatioTemporalSecondBar([2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr))),2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.right_vlr)))])} 
                            <Text x={(Platform.OS === 'android') ? "20":"42"} y={390 - 2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.left_acc_peak)))} {checkedChoose === 'spatioTemporal'? "%":"BW"} </Text>
                            <Text x={(Platform.OS === 'android') ? "100":"122"} y={390 - 2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.right_step_duration)/(el.stride_parameters.stride_duration)):(el.stride_parameters.right_acc_peak)))} {checkedChoose === 'spatioTemporal'? "%":"BW"}</Text>
                            <Text x={(Platform.OS === 'android') ? "210":"249"} y={((checkedChoose === 'spatioTemporal') ? (el.stride_parameters.stride_length < 0 ? 90: 390):390) - 2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_length<0 ? 0: el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr)))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.left_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.left_vlr)))} {checkedChoose === 'spatioTemporal'? "%":"BW/s"}</Text>
                            <Text x={(Platform.OS === 'android') ? "284":"325"} y={390 - 2*Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? ((el.stride_parameters.right_step_length <0? 0:el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)):(el.stride_parameters.right_vlr)))} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {Math.round((checkedChoose === 'spatioTemporal'? 100:1)*(checkedChoose === 'spatioTemporal'? (100*((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length)) >100 ? 1 : ((el.stride_parameters.right_step_length)/(el.stride_parameters.stride_length))):(el.stride_parameters.right_vlr)))} {checkedChoose === 'spatioTemporal'? "%":"BW/s"}</Text>
                            <Text x={(Platform.OS === 'android') ?"25":"95"} y={115} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{checkedChoose === 'spatioTemporal'? "Durée de la foulée":"Force d'impact au sol"}</Text>
                            {checkedChoose === 'spatioTemporal' &&<Text x="55" y={145} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{(el.stride_parameters.stride_duration).toFixed(2).toString().replace('.', ',')} s</Text>}
                            <Text x={(Platform.OS === 'android') ?"260":"290"} y={115} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{checkedChoose === 'spatioTemporal'?  "Longueur de la foulée":" Amorti"}</Text>
                            {checkedChoose === 'spatioTemporal' &&<Text x="270" y={145} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">{(el.stride_parameters.stride_length).toFixed(2).toString().replace('.', ',')} m</Text>}
                            <Text x={(Platform.OS === 'android') ?"55":"55"} y={50} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">Date</Text>
                            <Text x={(Platform.OS === 'android') ?"30":"55"} y={80} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {new Intl.DateTimeFormat("fr-fr",{year: "numeric", month: "numeric", day: "numeric"}).format(new Date(Date.parse(el.date)))} </Text>
                            <Text x={(Platform.OS === 'android') ?"260":"290"} y={50} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black">Horaire</Text>
                            <Text x={(Platform.OS === 'android') ?"254":"290"} y={80} text-anchor="middle" fontSize={fontSize} fontWeight="bold" fill="black"> {el.date.split(' ')[4]} </Text>
                            </Svg>)}
                            {(displayCommentInfo !=="" && modifierChoose === "bottom") &&
                            <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.8), height:(Platform.OS === 'android') ? "70%":"50%"},{borderRadius: 15}]}>
                                <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
                                    <Heading fontSize={fontSize} fontWeight="bold" style={styles.information_text}>
                                    {displayCommentInfo}
                                    </Heading>
                                    <TouchableOpacity
                                    style={styles.button}
                                    onPress={() => {
                                        setDisplayCommentInfo("");
                                    }}>
                                    <Heading fontSize={fontSize} fontWeight="bold" style={styles.button_text}>{translate('go_Back')}</Heading>
                                    </TouchableOpacity>
                                </ScrollView>  
                            </View>}
                        </Center>
                        
                    </ScrollView>}
                    {(displayCommentInfo !=="" && modifierChoose === "") &&
                    <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.8), height:(Platform.OS === 'android') ? "70%":"50%"},{borderRadius: 15}]}>
                        <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
                            <Heading fontSize={fontSize} fontWeight="bold" style={styles.information_text}>
                            {displayCommentInfo}
                            </Heading>
                            <TouchableOpacity
                            style={styles.button}
                            onPress={() => {
                                setDisplayCommentInfo("");
                            }}>
                            <Heading fontSize={fontSize} fontWeight="bold" style={styles.button_text}>{translate('go_Back')}</Heading>
                            </TouchableOpacity>
                        </ScrollView>  
                    </View>}
                </Center>
            </Stack>
        
        </NativeBaseProvider>
    );
}
export default BiomechaParamsReport;

const styles = StyleSheet.create({
    button_result_icon:{
      backgroundColor: "#F1A049", 
     alignSelf:"center",
     borderRadius:15,
    },
    information_container:{ 
        borderWidth:1,
        alignSelf:"center",
        flex: 1,
        borderColor: '#00d5f7',
        borderRadius: 20,
        backgroundColor: '#FFF',
        position: 'absolute',
        top :33,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1, 
      },
      information_text:{
        textAlign:"justify", 
        padding: 25, 
        paddingHorizontal: 15,
        color:"black"
      },
      button:{
        backgroundColor: "#00d5f7", 
        alignSelf:"center",
        borderRadius:200,
        marginBottom: 10,
        marginTop: 20,
      },
      button_text:{
        alignSelf:"center", 
        marginHorizontal:20, 
        marginVertical:2,
        color:"#000",
        fontWeight:"bold",
      },
    
});
