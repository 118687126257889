import React, {useState} from 'react';
import { Button, Platform, TouchableOpacity, Image, View } from 'react-native';
import {NativeBaseProvider} from 'native-base';
// stack navigation
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import 'intl';
import { I18nProvider, LOCALES } from './i18n';
import translate from './i18n/translate';

// components
import Login from './screens/Login.js';
import Signup from './screens/Signup.js';
import RunnersList from './screens/RunnersList.js';
import Runner from './screens/Runner.js';
import RetrieveSession from './screens/RetrieveSession.js';
import SessionResults from './screens/SessionResults.js';
import UpdateRunner from './screens/UpdateRunner.js';
import RunnerSessionBehavior from './screens/RunnerSessionBehavior';
import BiomechaParamsBehavior from './screens/BiomechaParamsBehavior';
import BiomechaParamsReport from './screens/BiomechaParamsReport';
import DeviceConfig from './screens/DeviceConfig';
import DeviceMesure from './screens/DeviceMesure';
import PdfSession from './screens/PdfSessions';
import PdfComparedSessionsParams from './screens/PdfComparedSessionsParams';
import UnitySession from './screens/UnitySession';
import SessionCalibration from './screens/SessionCalibration';
import Parameters from './components/Parameters';
import NavigationConstraintButton from './components/NavigationConstraintButton.js';
import NavigationBalanceConstraint from './components/NavigationBalanceConstraint.js';


const Stack = createNativeStackNavigator();
const optimoveHeader = (navigation, route) => {
  return({
    title: 'mySmartmove',
    headerStyle: {
      backgroundColor: '#00d5f7',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',
    },
    headerRight: () => (
      <Parameters navigation={navigation} route={route}/>
    )
  })
}

const exerciseHeader = (navigation, route) => {
  return({
    title: 'mySmartmove',
    headerStyle: {
      backgroundColor: '#00d5f7',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',
    },
    gestureEnabled: false,
    headerLeft: () => (     //remove possibility of goind back
      <NavigationConstraintButton navigation={navigation} route={route}/>
      ), 
    headerRight: () => (
      <Parameters navigation={navigation} route={route}/>
    )
  })
}

const balanceHeader = (navigation, route) => {
  return({
    title: 'mySmartmove',
    headerStyle: {
      backgroundColor: '#00d5f7',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',
    },
    gestureEnabled: false,
    headerLeft: () => (     //remove possibility of goind back
      <NavigationBalanceConstraint navigation={navigation} route={route}/>
      ), 
    headerRight: () => (
      <Parameters navigation={navigation} route={route}/>
    )
  })
}

export default function App() {

  const [locale, setLocale]= useState(LOCALES.FRENCH)
  const setEnglishlanguages = (lang) =>{
    lang == "Français" ? setLocale(LOCALES.ENGLISH): setLocale(LOCALES.FRENCH)
  }
  
  return (
    <>
      <I18nProvider locale ={locale} defaultLocale="fr-FR">
        <NativeBaseProvider>
          <NavigationContainer>
            <Stack.Navigator>
              <Stack.Screen
                name="Login"
                component={Login}
                initialParams={{ itemId: 42 }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="Signup"
                component={Signup}
                // initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="RunnersList"
                component={RunnersList}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="Runner"
                component={Runner}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="RunnerSessionBehavior"
                component={RunnerSessionBehavior}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="SessionCalibration"
                component={SessionCalibration}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => exerciseHeader(navigation, route)}
              />
              <Stack.Screen
                name="RetrieveSession"
                component={RetrieveSession}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => exerciseHeader(navigation, route)}
              />
              <Stack.Screen
                name="UnitySession"
                component={UnitySession}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => exerciseHeader(navigation, route)}
              />
              <Stack.Screen
                name="SessionResults"
                component={SessionResults}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => balanceHeader(navigation, route)}
              />
              <Stack.Screen
                name="UpdateRunner"
                component={UpdateRunner}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="DeviceConfig"
                component={DeviceConfig}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="DeviceMesure"
                component={DeviceMesure}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => exerciseHeader(navigation, route)}
              />
              <Stack.Screen
                name="PdfSession"
                component={PdfSession}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="PdfComparedSessionsParams"
                component={PdfComparedSessionsParams}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="BiomechaParamsBehavior"
                component={BiomechaParamsBehavior}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
              <Stack.Screen
                name="BiomechaParamsReport"
                component={BiomechaParamsReport}
                initialParams={{ translationFunc: setEnglishlanguages }}
                options={({navigation, route}) => optimoveHeader(navigation, route)}
              />
            </Stack.Navigator>
          </NavigationContainer>
        </NativeBaseProvider>
      </I18nProvider>
    </>
  );
}
