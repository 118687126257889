import React, { useEffect, useState } from 'react';
import { useWindowDimensions, Platform, TouchableOpacity, View, NativeModules } from 'react-native';
import NetInfo from "@react-native-community/netinfo";
import { Center, Heading, ScrollView, NativeBaseProvider, Stack, Text } from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';

// utils functions
import { get }       from '../src/fetch.js';
import { titleCase } from '../src/utils.js';

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import { StyleSheet } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import translate from '../i18n/translate';

// components
import SessionList   from '../components/SessionList.js';
import SessionGraph  from '../components/SessionGraph.js';
import NewSession    from '../components/NewSession.js';

// Interaction with collectData Class
const { CollectData } = NativeModules;

const Runner = ({ route, navigation }) => {

  const [sessionsList, setSessionsList] = useState([]);
  const [refId, setRefId] = useState(-1);
  const [expirationCount, setExpirationCount] = useState("");
  const [verifyExpirationCount, setVerifyExpirationCount] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("Français");
  const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}];

  const [sessionPosition, setSessionPosition] =useState(0);
  const maxDisplay = 4; // Maximum number of sessions simultaneously displayed

  const largeBreakpoint = 992;
  const {width, height} = useWindowDimensions();
  const isTablet = (width > largeBreakpoint);
  const isWideEnough = (width >= 600);
  const isNotWideEnough = (width <= 600);

  const { access_token, runner, sessionList } = route.params;
  const isFocused = useIsFocused();

  useEffect(() => {
      const route = `/api/runners/${runner.public_id}/sessions`;
      get(route, access_token)
      .then(({json}) => {setSessionsList(json);
      })
      .catch( err => console.error(err));
      if(Platform.OS === 'android') VerifyDisconectedServer();
  }, [isFocused]);

  const VerifyDisconectedServer = ()=>{
    CollectData.CancelExpirationDeltaHours().then((resp)=>{ 
      if(resp === 0){
        CollectData.ExpirationDeltaHours();
        setTimeout(() => {
          CollectData.VerifyFirstExpirationDeltaHours().then((response)=>{
            console.log("Runner 11 "+response);
            if(response > 6500 && (verifyExpirationCount === true)){
              console.log("Runner 1 " + response);
              setExpirationCount(translate('expiration_count'));
            }
          }).catch((err)=>console.log(err));
        }, 6600000);
        setTimeout(() => {
          CollectData.VerifyExpirationDeltaHours().then((response)=>{
            console.log("Runner 12 "+response);
            if(response > 7100 && (verifyExpirationCount === true)){
              console.log("Runnerlist 2 "+response);
              navigation.navigate("Login");
            }
          }).catch((err)=>console.log(err));
        }, 7200000);
      }else{
        return;
      }
    }).catch((err)=>console.log(err));
    
  }

  const idReferenceSession = (id)=>{
    setRefId(id)
  }
  const dataprocessRecalibrationValue = async (value) => {
    try {
        await AsyncStorage.setItem('dataprocessRecalibration', value)
    } catch (error) {
        console.error(error)
    }
  }
  dataprocessRecalibrationValue("");

  return (
    <NativeBaseProvider flex={1} style={{fontFamily:"sans-serif"}}>
      <ScrollView  _contentContainerStyle={{height: isTablet? '100%' : 'auto'}} showsVerticalScrollIndicator={false} >
        <View style={styles.picker_container}>
          <Picker style={styles.picker_component}
              selectedValue={selectedLanguage}
              onValueChange={(itemValue, itemIndex) =>{
                setSelectedLanguage(itemValue);
                route.params.translationFunc(selectedLanguage);
              }}>
              {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
          </Picker>
        </View>
        <View>
          <Center h="20" >
            <Heading>
              {runner.first_name.toUpperCase() +' '+ titleCase(runner.last_name)}
            </Heading>
          </Center>
          { (expirationCount !== "") && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.97)},{borderRadius: 15, elevation:1}]}>
            <Text style={styles.information_text}>
              { expirationCount}
            </Text>
          
            <TouchableOpacity
                style={styles.buttonself}
                onPress={() => {
                  setVerifyExpirationCount(false);
                  setExpirationCount("");
                }}>
              <Text style={styles.button_text}>{translate('ok')}</Text>
            </TouchableOpacity>
          </View>}
          <Stack
            style = {{justifyContent: "space-evenly"}}
            h={isTablet? "50%" : "auto"}
            direction={{ base: 'column', lg: 'row' }}
            alignItems="center"
            mb={height*0.3}
            width={isWideEnough? '100%':'100%'}
          >
            <SessionGraph sessionList={sessionsList} idReferenceSession = {idReferenceSession} maxDisplay={maxDisplay} sessionPosition={sessionPosition}/>
            <View>
              <View style = {{flexDirection: 'row', marginTop:5, marginBottom:10}}>
                {(sessionPosition != (sessionsList.length-maxDisplay)) && <TouchableOpacity style={{height:30, width:30, borderWidth:1, borderRadius:15, justifyContent:"center", alignItems:"center"}}
                  onPress={() => setSessionPosition(Math.min((sessionsList.length-maxDisplay), sessionPosition+maxDisplay))}>
                  <Heading size="md">←</Heading>
                </TouchableOpacity>}
                <Heading size="md" px={3} >{translate('last_session')}</Heading>
                {(sessionPosition != 0) && <TouchableOpacity style={{height:30, width:30, borderWidth:1, borderRadius:15, justifyContent:"center", alignItems:"center"}}
                  onPress={() => setSessionPosition(Math.max(0, sessionPosition-maxDisplay))}>
                  <Heading size="md">→</Heading>
                </TouchableOpacity>}
              </View>
              <SessionList access_token={access_token} runner={runner} sessionList={sessionsList} refId = {refId} maxDisplay={maxDisplay} sessionPosition={sessionPosition}/>
            </View>
          </Stack>
        </View>
      </ScrollView>
      <NewSession  
        sessionList = {sessionsList} 
        access_token = {access_token} 
        runner = {runner}
      />
    </NativeBaseProvider>
  );
}

export default Runner;

const styles = StyleSheet.create({
  picker_component:{
    flex:1,
    borderRadius: 4,
    width: 150,
    marginRight: 30,
    justifyContent:'flex-end'
  },
  picker_container:{
    width: "100%",
    alignItems: "flex-end",
    height: 30,
  },
  buttonself:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:200,
    marginBottom: 10,
    marginTop: 10
  },
  button_text:{
    fontSize: 14, 
    alignSelf:"center", 
    marginHorizontal:20, 
    marginVertical:2
  },
  information_container:{ 
    borderWidth:1,
    alignSelf:"center",
    flex: 1,
    borderColor: '#00d5f7',
    borderRadius: 20,
    backgroundColor: '#FFF',
    position: 'absolute',
    top :0,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, 
  },
  information_text:{
    textAlign:"justify", 
    padding: 5, 
    paddingHorizontal: 15,
    color:"black"
  },
})
