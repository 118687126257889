import React, {useEffect, useState} from 'react';
import { Text } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import translate from '../i18n/translate';
import { NativeModules, ToastAndroid, useWindowDimensions, TouchableOpacity, Alert } from 'react-native';
import NetInfo from "@react-native-community/netinfo";
import AsyncStorage from '@react-native-async-storage/async-storage';

const { CollectData } = NativeModules;

//mDNS services
import { MdnsConfig } from '../src/zeroconf_mdns';

const NewSessionButton = ({ activity, sessionList, access_token, runner, displayNotificationMessage,ssiddeviceFirstMessage,ssiddeviceSecondMessage,setOpacityButton,opacityButton, choiceActivity}) => {

  const navigation = useNavigation();
  const [ipAdress, setIpAdress] = useState("");
  const [ssiddevice, setSsiddevice] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [frequency, setfrequency] = useState(0); 
  // const displayActivity = (activity==="walk")? translate('Walk_balance') : translate('run_balance');
  
  // Conditional rendering
  let displayActivity;
  switch (choiceActivity) {
    case 'slowWalk':
      displayActivity = translate('slow_Walk_balance');
      break;
    case 'quickWalk':
      displayActivity = translate('quick_Walk_balance');
      break;
    case 'slowRace':
      displayActivity = translate('slow_run_balance');
      break;
    case 'quickRace':
      displayActivity = translate('quick_run_balance');
      break;
    default:
      console.log(`Sorry, we are out of ${activity}.`);
  }
  
  const { height, width } = useWindowDimensions();
  const largeBreakpoint   = 992;
  const isTablet = ((width >= largeBreakpoint )||(height >= largeBreakpoint));
  
  const checkConfig = async (public_id, access_token) => {
    
    if(Platform.OS === "android"){
      displayNotificationMessage(translate('time_message'));
      try {
        const value = await AsyncStorage.getItem('cfgmsm');
        const ssidCfgmsm = await AsyncStorage.getItem('ssid_cfgmsm');
        CollectData.GetNetworkSpeed().then((responseSpeed)=>{
          if(value !== null && (ssidCfgmsm === ssiddevice) && (frequency.toString().substring(0, 1) === "2")) {
            CollectData.GetAdress(ipAdress);
            if(ipAdress){
              CollectData.Connection()
              .then(data => {
                console.log(`RetrieveSession.render:: ${data} connected`);
                if(data === 1){
                  displayNotificationMessage("");
                  setOpacityButton(1);
                  setDisabledButton(false);
                  navigation.navigate('SessionCalibration', {activity, sessionList, access_token, runner, choiceActivity, responseSpeed:+responseSpeed[0]});
                }else if(data === 0){
                  displayNotificationMessage(translate('error_master_connection'));
                  CollectData.CloseAll()
                  .then(res => {
                    if(res === "Success"){
                      setOpacityButton(1);
                      setDisabledButton(false);
                      console.log("Socket has been successfully closed.");
                    }else{
                      console.log("An error occured while trying to close socket.", res);
                    }
                  })
                  .catch(error => console.error(error));
                }else if(data === "connectionTimeOut"){
                  displayNotificationMessage(translate('error_master_connection'));
                  CollectData.CloseAll()
                  .then(res => {
                    if(res === "Success"){
                      setOpacityButton(1);
                      setDisabledButton(false);
                      console.log("Socket has been successfully closed.");
                    }else{
                      console.log("An error occured while trying to close socket.", res);
                    }
                  })
                  .catch(error => console.error(error));
                }else{
                  displayNotificationMessage(translate('error_master_connection'));
                  CollectData.CloseAll()
                  .then(res => {
                    if(res === "Success"){
                      setOpacityButton(1);
                      setDisabledButton(false);
                      console.log("Socket has been successfully closed.");
                    }else{
                      console.log("An error occured while trying to close socket.", res);
                    }
                  })
                  .catch(error => console.error(error));  
                }  
              })
              .catch(error => {
                displayNotificationMessage(translate('bad_master_connection'));
                CollectData.CloseAll()
                .then(res => {
                  if(res === "Success"){
                    setOpacityButton(1);
                    setDisabledButton(false);
                    console.log("Socket has been successfully closed.");
                  }else{
                    console.log("An error occured while trying to close socket.", res);
                  }
                })
                .catch(error => console.error(error));});
              }  
          }else if(+frequency.toString().substring(0, 1) === 5){
            displayNotificationMessage(translate('wifi_network_frequency'));
          }else if((ssidCfgmsm !== null) && (ssidCfgmsm !== ssiddevice)){
            ssiddeviceFirstMessage(translate('network_store_ssid1_message'), ssidCfgmsm);
            ssiddeviceSecondMessage(translate('network_store_ssid2_message'));
            
          }else if(value === null || (ssidCfgmsm === null)){
            displayNotificationMessage(translate('master_configuration_message'));
          }
        }).catch((err)=>console.log(err));
      
      } catch(error) {
          console.error(error)
      }
    }
  }
  const getMdnsIdentity = async () => {
    try {
      const value = await AsyncStorage.getItem('cfgmsm');
      const ssidCfgmsm = await AsyncStorage.getItem('ssid_cfgmsm');
      if(value !== null && (ssidCfgmsm !== null)) {
        if(Platform.OS === 'android'){
          NetInfo.fetch().then(state => {
            if(state.details.ssid !== "MYSMARTMOVE" && (state.details.ssid === ssidCfgmsm) && state.isInternetReachable === true){ 
              MdnsConfig(value, state.details.ipAddress);
            }
          });
        }
      }
    } catch(error) {
      console.error(error)
    }
  }
  getMdnsIdentity();
  useEffect(() => {
    let mounted = true;
    const unsubscribeHandler = NetInfo.addEventListener(state => {
      if(state.details.ssid != undefined && state.isInternetReachable === true){
        if(state.details.ssid.substring(0, 11) !== "MYSMARTMOVE"){
          setfrequency(state.details.frequency);
          setIpAdress(state.details.ipAddress);
          setSsiddevice(state.details.ssid); 
          setOpacityButton(1);
          setDisabledButton(false); 
        }
      }else{
        console.log("Your ssid is undefined");
      }    
    });
    return ()=>{
      console.log("unsubscribeHandle");
      if(mounted){
        unsubscribeHandler();
        mounted = false;
      }
      
    }
  }, []);

  return(
    <TouchableOpacity
      disabled={disabledButton}
      style={{marginHorizontal: isTablet? 12 : width*0.02,
        marginBottom: isTablet? 0 : height*0.01,
        width: isTablet ? 144 : 200,
        height: isTablet ? 45 : 30,
        borderRadius: 30,
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
        opacity: opacityButton,
      }}
      onPress={() => {setDisabledButton(true); setOpacityButton(0.4); checkConfig(runner.public_id, access_token);}}>
      <Text fontWeight="bold" style={{ fontSize: isTablet? 18 : 15, color: "black", alignSelf:"center"}}>{displayActivity}</Text>
    </TouchableOpacity>
  );
}

export default NewSessionButton;
