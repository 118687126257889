import React, {useEffect, useState, useRef} from 'react';
import {Box, Center, Heading, ScrollView, VStack, NativeBaseProvider, Text, Image} from "native-base"
import NetInfo from "@react-native-community/netinfo";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';

// intl components
import translate from '../i18n/translate';
import { I18nProvider, LOCALES } from '../i18n';
import { View, StyleSheet, useWindowDimensions, Platform, TouchableOpacity, Alert, NativeModules} from 'react-native';
import 'intl';
import { Picker } from '@react-native-picker/picker';

// components
import RunnerCard from '../components/RunnerCard.js';
import FormAddNewPatient from '../components/FormAddNewPatient.js';
import FormAddPathology from '../components/FormAddPathology.js';

//mDNS services
import { MdnsConfig } from '../src/zeroconf_mdns';

// Interaction with collectData Class
const { CollectData } = NativeModules;

// require images
const addImage = require('../images/png/add.png');

// utils functions
import { get } from '../src/fetch.js';
import { getPathologies } from '../src/fetch.js';


const RunnersList = ({route, navigation}) => {
  const refed = useRef();
  const [runnersList, setRunnersList] = useState([]);
  const [displayLoding, setDisplayLoding] = useState(false);
  const [displayPathologyForm, setDisplayPathologyForm] = useState(true);
  const [datapathologies, setDatapathologies] = useState([]);
  const [expirationCount, setExpirationCount] = useState("");
  const [masterConfig, setMasterConfig] = useState("");
  const [masterConfigValue, setMasterConfigValue] = useState(true);
  const [verifyExpirationCount, setVerifyExpirationCount] = useState(true);
  const [networkFrequencyDisplay, setNetworkFrequencyDisplay] = useState(0); 
  const [selectedLanguage, setSelectedLanguage] = useState("Français");
  const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}];


  const largeBreakpoint = 600;
  const {width, height} = useWindowDimensions();
  const isWideEnough = (width > largeBreakpoint);

  const { public_id, access_token, addPathology, cfgmsm, networkFrequency } = route.params;
  const isFocused = useIsFocused();
  const _displayLodingForm = ()=>{
    return <FormAddNewPatient
    datapathologies={datapathologies} 
    displayloading={setDisplayLoding} 
    public_id={public_id} 
    access_token={access_token} 
    setRunnersList={setRunnersList}/>           
  }

  const _displayLodingPathologyForm = ()=>{
    return <FormAddPathology  
    public_id={public_id} 
    access_token={access_token}
    navigation={navigation} 
    />           
  }

  const _deleteOneRunner = (runner)=>{
    if(runner){
      runnersList.splice(runner, 1); 
      const route = `/api/trainers/${public_id}/runners`;
      get(route, access_token)
      .then(({json}) => {
        setRunnersList(json);
      })
      .catch( err => console.error(err));
    }   
  }

  const getMdnsIdentity = async () => {
    try {
      const value = await AsyncStorage.getItem('cfgmsm');
      const ssidCfgmsm = await AsyncStorage.getItem('ssid_cfgmsm');
      if(Platform.OS === 'android' && (value != null)){
        NetInfo.fetch().then(state => {
          if(state.details.ssid !== "MYSMARTMOVE" && (state.details.ssid === ssidCfgmsm) && state.isInternetReachable === true){
            MdnsConfig(value, state.details.ipAddress);
          }
        });
      }
    } catch(error) {
      console.error(error)
    }
  }
  getMdnsIdentity();

  const VerifyDisconectedServer = ()=>{
    if((cfgmsm === null) && (masterConfigValue === true)){
      setMasterConfigValue(false),
      setMasterConfig(translate('config_not_found'));
    }
    CollectData.CancelExpirationDeltaHours().then((resp)=>{ 
      if(resp === 0){
        CollectData.ExpirationDeltaHours();
        setTimeout(() => {
          CollectData.VerifyFirstExpirationDeltaHours().then((response)=>{
            if(response > 6500 && (verifyExpirationCount === true)){
              setExpirationCount(translate('expiration_count'));
            }
          }).catch((err)=>console.log(err));
        },6600000);
        setTimeout(() => {
          CollectData.VerifyExpirationDeltaHours().then((response)=>{
            if(response > 7100 && (verifyExpirationCount === true)){
              navigation.navigate("Login");
            }
          }).catch((err)=>console.log(err));
        }, 7200000);
      }else{
        return;
      }
    }).catch((err)=>console.log(err));  
  }

  useEffect(() => {
    const route = `/api/trainers/${public_id}/runners`;
    const pathologyRoute = `/api/runners/${public_id}/pathologies`;
    getPathology(pathologyRoute)
    get(route, access_token)
    .then(({json}) => {
      setRunnersList(json);
    })
    .catch( err => console.error(err));
    if(Platform.OS === 'android') VerifyDisconectedServer();
  }, [isFocused]);

  const getPathology = (pathologyRoute)=>{
      getPathologies(pathologyRoute, access_token)
      .then((response)=>{
        if(response.status === 200){
          setDatapathologies(response.data)
        }
      })
      .catch( err => console.error(err));
  }
  
  const displayshotview = async()=>{
    const uri = await refed.current.capture();
    console.log("do something with ", uri);
  }
  return (
    <NativeBaseProvider style={{fontFamily:"sans-serif"}}>
      <Box style={styles.welcome_box}    
        borderTopLeftRadius={width}
        borderTopRightRadius={width}
        height={height*0.17}
        >
        <Center style={{marginTop: Platform.OS === 'android' ? height*0.005 : 0}}>
          <Heading style={{color:"white", fontSize:isWideEnough? 23 : 22}}>
            {translate('welcome')}
          </Heading>
        </Center>
      </Box>
      <ScrollView _contentContainerStyle={{height: isWideEnough? '69%' : '100%'}} showsVerticalScrollIndicator={false}>
        <View style={styles.picker_container}>
          <Picker style={styles.picker_component}
            selectedValue={selectedLanguage}
            onValueChange={(itemValue, itemIndex) =>{  
              setSelectedLanguage(itemValue)
              route.params.translationFunc(selectedLanguage)
            }}>
            {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language} ></Picker.Item>)}
          </Picker>
        </View> 
        <Center pt="1" mt="10" mb="150">
          <Heading  mb="8" fontSize="2xl">
            {translate('registry')}
          </Heading>
          <TouchableOpacity onPress={() => setDisplayLoding(true)}>
            <Image 
              style={styles.share_image} 
              source={ addImage }
              alt="logo de creation de compte"/>
          </TouchableOpacity>
          { (expirationCount !== "") && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.97)},{borderRadius: 15, elevation:1}]}>
            <Text style={styles.information_text}>
              { expirationCount}
            </Text>
          
            <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  setVerifyExpirationCount(false);
                  setExpirationCount("");
                }}>
              <Text style={styles.button_text}>{translate('ok')}</Text>
            </TouchableOpacity>
          </View>}
          { (networkFrequencyDisplay !== 0) && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.97)},{borderRadius: 15, elevation:1}]}>
            <Text style={styles.information_text}>
              {translate('wifi_network_frequency')}
            </Text>
          
            <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  setNetworkFrequencyDisplay(0);
                }}>
              <Text style={styles.button_text}>{translate('ok')}</Text>
            </TouchableOpacity>
          </View>}
          { (masterConfig !== "") && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.97)},{borderRadius: 15, elevation:1}]}>
            <Text style={styles.information_text}>
              { masterConfig}
            </Text>
            <Center style={{flexDirection:'row', justifyContent: 'space-around', alignItems: 'center', width:"90%"}}>
              <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    setMasterConfig("");
                  }}>
                <Text style={styles.button_text}>{translate('cancel')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  if(+networkFrequency.toString().substring(0, 1) === 5){
                    setMasterConfig("");
                    setNetworkFrequencyDisplay(1);
                  }else{
                    setMasterConfig("");
                    navigation.navigate("DeviceConfig", {"previous_params": route.params, "previous_page": route.name});
                  }
                  
                }}>
                <Text style={styles.button_text}>{translate('ok')}</Text>
              </TouchableOpacity>
            </Center>
          </View>}
          <ScrollView showsVerticalScrollIndicator={false} 
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderWidth: 0,
                borderTopWidth: 0,  
          }}>
            <VStack space="2" mt="4" >
              {(runnersList || []).map( runner => RunnerCard({
                navigation,
                access_token,
                runner,
                _deleteOneRunner,
                setRunnersList,
                public_id,
                width
              }))}
            </VStack>        
          </ScrollView>
          {displayLoding && _displayLodingForm()}
          {(addPathology === "addPathology" && displayPathologyForm) && _displayLodingPathologyForm()}
        </Center>
      </ScrollView>
    </NativeBaseProvider>
  );
}

export default RunnersList;

const styles = StyleSheet.create({
  picker_component:{
    flex:1,
    borderRadius: 4,
    width: 150,
    marginRight: 10,
    justifyContent:'flex-end'
  },
  picker_container:{
    width: "100%",
    alignItems: "flex-end",
    height: 30,
  },
  share_image: {
    width: 42,
    height: 42
  },
  share_touchable_floatingactionbutton: {
    position: 'absolute',
    right: 630,
    bottom: 150,
    justifyContent: 'center',
    alignItems: 'center'
  },
  displayLodingFormPatient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 200,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  welcome_box: {
    position:"absolute",
    width:"90%",
    height: Platform.OS === 'android' ? "36%" : "30%",
    left:"5%",
    backgroundColor:"#01D2F9",
    bottom: '-30%',
  },
  button:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:200,
    marginBottom: 10,
    marginTop: 10
  },
  button_text:{
    fontSize: 14, 
    alignSelf:"center", 
    marginHorizontal:20, 
    marginVertical:2
  },
  information_container:{ 
    borderWidth:1,
    alignSelf:"center",
    flex: 1,
    borderColor: '#00d5f7',
    borderRadius: 20,
    backgroundColor: '#FFF',
    position: 'absolute',
    top :0,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, 
  },
  information_text:{
    textAlign:"justify", 
    padding: 5, 
    paddingHorizontal: 15,
    color:"black"
  },
})
