import React, {useState} from 'react';
import { Center, Text, Box, Heading, Image, ScrollView } from 'native-base';

// intl components
import 'intl';
import translate from '../i18n/translate';
import { View, StyleSheet, useWindowDimensions, Platform, TouchableOpacity } from 'react-native';
import { titleCase } from '../src/utils.js';

const SessionResultsBoards = ({runner, session, runnerSession, refSession, navigation, sessionList }) => {

  const [displayCommentInfo, setDisplayCommentInfo] = useState("");

  const { mParamCadence, mParamLoadingRate, mParamImpact, mParamStep, mParamPelvis, mParamHips, mParamKnees } = session;
  const { mRefParamCadence, mRefParamLoadingRate, mRefParamImpact, mRefParamStep, mRefParamPelvis, mRefParamHips, mRefParamKnees } = refSession;

  const date       = new Date(Date.parse(runnerSession.date));
  let dateTime = runnerSession.date.split(' ');
  const options    = {month: 'short', day: '2-digit'};
  const dateString = new Intl.DateTimeFormat(translate('country_option'),options).format(date);

  const numOfValues = 8; // Number of fields in the Component +1 for display purposes

  const { height, width } = useWindowDimensions();
  const largeBreakpoint = 992;
  const otherBreakpoint = 768;
  const midBreakpoint = 688;
  const largeurLg = (width>= midBreakpoint)? (width >= largeBreakpoint)? width : width : width*2;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint)
  const fontSize = (width<=midBreakpoint)? 17.4 : 18;
  const fontWeightSize = isTablet? "bold":"bold";
  const fontsizePercent = isTablet? 18.7:17;
  const websize = width > otherBreakpoint ? 18: 12;
  const textWidth = (width>= midBreakpoint)? width/numOfValues : (2*width/numOfValues)
  const justifyContent = width > otherBreakpoint ? "space-between": "flex-end";
  const paddingRights =  width > otherBreakpoint ? 3: 0;
  const paddingLefts =  width > otherBreakpoint ? 5: 0;
  const isWideEnough = (width >= 600);
  return(
    <Box height={height*0.6}>
      <View height={height*0.6}>
        <Center padding= '0.5%' style={{flexDirection:"row"}}>
          <View>
            <Heading fontWeight="bold" fontSize={isTablet? 20 : 19} textAlign="center">{runner.first_name.toUpperCase() +' '+ titleCase(runner.last_name)}</Heading>
            <Text fontWeight="bold" fontSize={fontsizePercent} textAlign="center">{dateString}</Text>
          </View>
          <TouchableOpacity style={{marginLeft: "10%", width: 30, height: 30, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
            onPress={() => { 
              if(displayCommentInfo !==""){
                setDisplayCommentInfo("");
              }else{
                setDisplayCommentInfo("OK");
              }              
            }}>
            <Heading fontSize={18} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
          </TouchableOpacity>
        </Center> 
        <ScrollView style={{marginTop:4, horizontal:false, height:height*0.6, showsVerticalScrollIndicator:false}}>
          <View style={[styles.boardContainer,{flexDirection: isWideEnough? "row" : "column", width:isWideEnough? '100%':'100%'}]} >
            <View  style={[styles.uniqueBoardContainer, {width:isWideEnough? '48.5%':'100%'}]}>
              <TouchableOpacity style={[styles.uniqueBoard,{width:isWideEnough? '49%':'49%', height:isWideEnough? height*0.2 : height*0.2,}]}
                onPress={() => {
                  navigation.navigate('BiomechaParamsBehavior', {id:1, mParam:"Cadence", mParamTitle:translate('cadence'), sessionList:sessionList, runnerSession:runnerSession});
                  }}>
                <View style={[styles.uniqueBoard,{width:isWideEnough? '100%':'100%', height:isWideEnough? height*0.2 : height*0.2}]}>
                  <View style={{flex:2}}>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center", padding: '2%'}}>{translate('cadence')}</Text>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center"}}>{mParamCadence} {translate('steps_label')}</Text>                       
                  </View>
                  <View style={styles.referenceSession}>
                    <Image  alt="icon de flèche" style={{ alignSelf:'center', width:isWideEnough? 30 :15, height:isWideEnough? 50 :40}} source={(mParamCadence-mRefParamCadence) >= 0 ? ((mParamCadence-mRefParamCadence) === 0 ? require('../images/png/arrow_right_icon.png') : require('../images/png/arrow_up_icon.png')) : require('../images/png/arrow_down_icon.png')}/>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, textAlign:"center"}}>{mParamCadence-mRefParamCadence}</Text>                       
                  </View>    
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.uniqueBoard,{width:isWideEnough? '49%':'49%', height:isWideEnough? height*0.2 : height*0.2}]}
                onPress={() => {
                  navigation.navigate('BiomechaParamsBehavior', {id:2, mParam:"Symétrie des amortis", mParamTitle:translate('damping_symetry'), sessionList:sessionList, runnerSession:runnerSession});
                  }}>
                <View style={[styles.uniqueBoard,{width:isWideEnough? '100%':'100%', height:isWideEnough? height*0.2 : height*0.2}]} >
                  <View style={{flex:2}}>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center", padding:'2%'}}>{translate('damping_symetry')}</Text>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center"}}>{mParamLoadingRate} %</Text>                       
                  </View>
                  <View style={styles.referenceSession}>
                    <Image  alt="icon de flèche" style={{ alignSelf:'center', width:isWideEnough? 30 :15, height:isWideEnough? 50 :40}} source={(mParamLoadingRate-mRefParamLoadingRate) >= 0 ? ((mParamLoadingRate-mRefParamLoadingRate) === 0 ? require('../images/png/arrow_right_icon.png') : require('../images/png/arrow_up_icon.png')) : require('../images/png/arrow_down_icon.png')}/>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, textAlign:"center"}}>{mParamLoadingRate-mRefParamLoadingRate}</Text>                       
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            <View style={[styles.uniqueBoardContainer,{width:isWideEnough? '50.5%':'100%', marginTop:isWideEnough? 0:7}]}>
              <TouchableOpacity style={[styles.uniqueBoard,{width:isWideEnough? '49%':'49%', height:isWideEnough? height*0.2 : height*0.2}]}
                onPress={() => {
                  navigation.navigate('BiomechaParamsBehavior', {id:3, mParam:"Symétrie des impacts", mParamTitle:translate('impact_symetry'), sessionList:sessionList, runnerSession:runnerSession});
                  }}>
                <View style={[styles.uniqueBoard,{width:isWideEnough? '100%':'100%', height:isWideEnough? height*0.2 : height*0.2}]}>
                  <View style={{flex:2}}>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center", padding:'2%'}}>{translate('impact_symetry')}</Text>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center"}}>{mParamImpact} %</Text>                       
                  </View>
                  <View style={styles.referenceSession}>
                    <Image  alt="icon de flèche" style={{ alignSelf:'center', width:isWideEnough? 30 :15, height:isWideEnough? 50 :40}} source={(mParamImpact-mRefParamImpact) >= 0 ? ((mParamImpact-mRefParamImpact) === 0 ?require('../images/png/arrow_right_icon.png'):require('../images/png/arrow_up_icon.png')) : require('../images/png/arrow_down_icon.png')}/>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, textAlign:"center"}}>{mParamImpact-mRefParamImpact}</Text>                       
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.uniqueBoard,{width:isWideEnough? '49%':'49%', height:isWideEnough? height*0.2 : height*0.2}]}
                onPress={() => {
                  navigation.navigate('BiomechaParamsBehavior', {id:4, mParam:"Symétrie des pas", mParamTitle:translate('step_symetry'), sessionList:sessionList, runnerSession:runnerSession});
                  }}>
                <View style={[styles.uniqueBoard,{width:isWideEnough? '100%':'100%', height:isWideEnough? height*0.2 : height*0.2}]}>
                  <View style={{flex:2}}>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center", padding:'2%'}}>{translate('step_symetry')}</Text>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center"}}>{mParamStep} %</Text>                       
                  </View>
                  <View style={styles.referenceSession}>
                    <Image  alt="icon de flèche" style={{ alignSelf:'center', width:isWideEnough? 30 :15, height:isWideEnough? 50 :40}} source={(mParamStep-mRefParamStep) >= 0 ? ((mParamStep-mRefParamStep) === 0 ? require('../images/png/arrow_right_icon.png') : require('../images/png/arrow_up_icon.png')) : require('../images/png/arrow_down_icon.png')}/>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, textAlign:"center"}}>{mParamStep-mRefParamStep}</Text>                       
                  </View>
                </View>
              </TouchableOpacity>
            </View>  
          </View>
          <View style={[styles.boardContainer,{flexDirection:isWideEnough? "row" : "column", width:isWideEnough? '100%':'100%'}]}>
            <View style={[styles.uniqueBoardContainer,{width:isWideEnough? '48.5%':'100%'}]}>
              <TouchableOpacity style={[styles.uniqueBoard,{width:isWideEnough? '49%':'49%', height:isWideEnough? height*0.2 : height*0.2}]}
                onPress={() => {
                  navigation.navigate('BiomechaParamsBehavior', {id:5, mParam:"Symétrie du bassin", mParamTitle:translate('pelvic_symetry'), sessionList:sessionList, runnerSession:runnerSession});
                  }}>
                <View style={[styles.uniqueBoard,{width:isWideEnough? '100%':'100%', height:isWideEnough? height*0.2 : height*0.2}]}>
                  <View style={{flex:2}}>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center", padding:'2%'}}>{translate('pelvic_symetry')}</Text>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center" }}>{mParamPelvis} %</Text>                       
                  </View>
                  <View style={styles.referenceSession}>
                    <Image  alt="icon de flèche" style={{ alignSelf:'center', width:isWideEnough? 30 :15, height:isWideEnough? 50 :40}} source={(mParamPelvis-mRefParamPelvis) >= 0 ? ((mParamPelvis-mRefParamPelvis) === 0 ? require('../images/png/arrow_right_icon.png') : require('../images/png/arrow_up_icon.png')) : require('../images/png/arrow_down_icon.png')}/>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, textAlign:"center"}}>{mParamPelvis-mRefParamPelvis}</Text>                       
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.uniqueBoard, {width:isWideEnough? '49%':'49%', height:isWideEnough? height*0.2 : height*0.2}]}
                onPress={() => {
                  navigation.navigate('BiomechaParamsBehavior', {id:6, mParam:"Symétrie des hanches", mParamTitle:translate('hip_symetry'), sessionList:sessionList, runnerSession:runnerSession});
                  }}>
                <View style={[styles.uniqueBoard, {width:isWideEnough? '100%':'100%', height:isWideEnough? height*0.2 : height*0.2}]}>
                  <View style={{flex:2}}>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center", padding:'2%'}}>{translate('hip_symetry')}</Text>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center"}}>{mParamHips} %</Text>                       
                  </View>
                  <View style={styles.referenceSession}>
                    <Image  alt="icon de flèche" style={{ alignSelf:'center', width:isWideEnough? 30 :15, height:isWideEnough? 50 :40}} source={(mParamHips-mRefParamHips) >= 0 ? ((mParamHips-mRefParamHips) === 0 ? require('../images/png/arrow_right_icon.png') : require('../images/png/arrow_up_icon.png')) : require('../images/png/arrow_down_icon.png')}/>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, textAlign:"center"}}>{mParamHips-mRefParamHips}</Text>                       
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            <View style={[styles.uniqueBoardContainer,{width:isWideEnough? '50.5%':'100%', marginTop:isWideEnough? 0:7, marginBottom:isWideEnough? 0:9}]} >
              <TouchableOpacity style={[styles.uniqueBoard,{width:isWideEnough? '49%':'49%', height:isWideEnough? height*0.2 : height*0.2}]}
                onPress={() => {
                  navigation.navigate('BiomechaParamsBehavior', {id:7, mParam:"Symétrie des genoux", mParamTitle:translate('knee_symetry'), sessionList:sessionList, runnerSession:runnerSession});
                  }}>
                <View style={[styles.uniqueBoard,{width:isWideEnough? '100%':'100%', height:isWideEnough? height*0.2 : height*0.2}]}>
                  <View style={{flex:2}}>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center", padding:'2%'}}>{translate('knee_symetry')}</Text>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, flex:1, textAlign:"center"}}>{mParamKnees} %</Text>                       
                  </View>
                  <View style={styles.referenceSession}>
                    <Image  alt="icon de flèche" style={{ alignSelf:'center', width:isWideEnough? 30 :15, height:isWideEnough? 50 :40}} source={(mParamKnees-mRefParamKnees) >= 0 ? ((mParamKnees-mRefParamKnees) === 0 ? require('../images/png/arrow_right_icon.png') : require('../images/png/arrow_up_icon.png')) : require('../images/png/arrow_down_icon.png')}/>
                    <Text style={{fontSize:fontsizePercent, fontWeight:fontWeightSize, textAlign:"center"}}>{mParamKnees-mRefParamKnees}</Text>                       
                  </View>
                </View>
              </TouchableOpacity>
              <View style={{width:isWideEnough? '49%':'50%', alignItems:'center'}}> 
              </View>
            </View>
          </View>
        </ScrollView>
        {(displayCommentInfo !=="") &&<View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.8), height:"98%"},{borderRadius: 15}]}>
          <ScrollView style={{height:"100%",width: "100%"}} showsVerticalScrollIndicator={false}>
            <Text style={[styles.information_text, {fontSize:fontSize}]}>
              {translate('cadence_information')} {translate('damping_symetry_information')} {translate('impact_symetry_information')} {translate('step_symetry_information')}
              {translate('pelvic_symetry_information')} {translate('hip_symetry_information')} {translate('knee_symetry_information')} {translate('percentage_arrows_information')}
            </Text>
            <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  setDisplayCommentInfo("");
                }}>
              <Text style={[styles.button_text, {fontSize:fontSize}]}>{translate('go_Back')}</Text>
            </TouchableOpacity>
          </ScrollView>  
        </View>}
      </View> 
    </Box>
  );
}

export default SessionResultsBoards;

const styles = StyleSheet.create({
  boardContainer: { 
    justifyContent:"space-between",
    padding:'1%',
  },
  alignCont: {
    alignContent:'space-between',
  },
  referenceSession: {
    flexDirection:"row",
    paddingRight:'2%',
  },
  uniqueBoardContainer: {
    justifyContent:"space-between",
    flexDirection:"row",
    marginRight:0,
  },
  uniqueBoard:{
    borderWidth:1,
    flexDirection:"row",
    borderColor:"#c9c6ac",
    borderRadius:8,
    backgroundColor:'#f2f2f2',
    elevation:1,
    alignItems:'center',
  },
  information_container:{ 
    borderWidth:1,
    alignSelf:"center",
    flex: 1,
    borderColor: '#00d5f7',
    borderRadius: 20,
    backgroundColor: '#FFF',
    position: 'absolute',
    top :40,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, 
  },
  information_text:{
    textAlign:"justify", 
    padding: 20, 
    paddingHorizontal: 15,
    color:"black"
  },
  button:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:200,
    marginBottom: 10,
    marginTop: 20,
  },
  button_text:{
    fontSize: 14, 
    alignSelf:"center", 
    marginHorizontal:20, 
    marginVertical:2,
    color:"#000",
    fontWeight:"bold",
  },


})