import React, {useEffect, useState, useRef } from 'react';
import { Platform, View, StyleSheet, useWindowDimensions, TouchableOpacity, NativeModules} from 'react-native';
import { Flex, Box, ScrollView, NativeBaseProvider, Text, Heading, Image } from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { parseError, handleErrors, displayWarning } from '../src/masterErrorManagement.js';
import translate from '../i18n/translate';

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import {Picker} from '@react-native-picker/picker';


// require images
const addImageLeft = require('../images/png/left_arrow.png');
const addImageRight = require('../images/png/right_arrow.png');

// components
import SessionResultsTopBar from '../components/SessionResultsTopBar.js';
import SessionResultsSymmetry from '../components/SessionResultsSymmetry.js';
import SessionResultsBoards from '../components/SessionResultsBoards.js';
import SessionResultsFilteredCards from '../components/SessionResultsFilteredCards.js';
import SessionResultsReference from '../components/SessionResultsReference.js';
import FormAddComment from '../components/FormAddComment.js';

import { simplified_parameters, simplified_parameters_reference } from '../src/simplified_parameters';

// Interaction with collectData Class
const { CollectData } = NativeModules;




const SessionResults = ({route, navigation}) => {

  const [displayLoadingBack, setDisplayLoadingBack] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("Français");
  const [displayLodingRight, setDisplayLodingRight] = useState(true);
  const [displayLodingLeft, setDisplayLodingLeft] = useState(true);
  const [displayLoding, setDisplayLoding] = useState(false);
  const [expirationCount, setExpirationCount] = useState("");
  const [verifyExpirationCount, setVerifyExpirationCount] = useState(true);
  
  const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}];

  const { runner, session, refSession, warnings, activity, sessionList, access_token, choiceActivity} = route.params;

  console.groupCollapsed('SessionResults.constructor::');
  console.log(`SessionResults.constructor:: got runner`);
  if (Platform.OS === "web") console.dir(runner);
  console.log(`SessionResults.constructor:: got session`);
  if (Platform.OS === "web") console.dir(session);
  console.log(`SessionResults.constructor:: got refSession`);
  if (Platform.OS === "web") console.dir(refSession);
  console.groupEnd();
  
  let sessionParameters = simplified_parameters(session.angular_parameters_global, session.stride_parameters, session.activity);
  let sessionParametersReference = simplified_parameters_reference(refSession.angular_parameters_global, refSession.stride_parameters, refSession.activity)
  
  
  const { height, width } = useWindowDimensions();
  const largeBreakpoint = 992;
  const midBreakpoint = 688;
  const isHighEnough = (height>=600);
  const largeurLg = (width>= midBreakpoint)? (width >= largeBreakpoint)? width : width : width;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
  const largeurLg2 = width;
  const isWideEnough = (width >= 600);
  const scrollResf = useRef();
  //w={largeurLg}
  const storeCalibrationState = async (value) => {
    try {
      await AsyncStorage.setItem('displayCalibrationButton', value)
    } catch (error) {
      console.error(error)
    }
  }

  const dataprocessRecalibrationValue = async (value) => {
    try {
      await AsyncStorage.setItem('dataprocessRecalibration', value)
    } catch (error) {
      console.error(error)
    }
  }

  let warningArray = warnings ? warnings.split('/') : "";
  let displayRecalibrationButton = warningArray.includes("W_CAL_IMUSTOCALIBRATE_STR") ? "yes" : "no";

  const _displayLodingForm = ()=>{
    return <FormAddComment
    warningArray = {warningArray}
    displayloading={setDisplayLoding} 
    public_id={session.public_id} 
    access_token={access_token}
    datacom ={session.comment} 
    />           
  }

  const VerifyDisconectedServer = ()=>{
    CollectData.CancelExpirationDeltaHours().then((resp)=>{  
      if(resp === 0){
        CollectData.ExpirationDeltaHours();
        setTimeout(() => {
          CollectData.VerifyFirstExpirationDeltaHours().then((response)=>{
            if(response > 6500 && (verifyExpirationCount === true)){
              setExpirationCount(translate('expiration_count'));
            }
          }).catch((err)=>console.log(err));
        }, 6600000);
        setTimeout(() => {
          CollectData.VerifyExpirationDeltaHours().then((response)=>{
            if(response > 7100 && (verifyExpirationCount === true)){
              navigation.navigate("Login");
            }
          }).catch((err)=>console.log(err));
        }, 7200000);
      }else{
        return;
      }
    }).catch((err)=>console.log(err));  
  }

  useEffect(() => {
    if(Platform.OS === 'android') VerifyDisconectedServer();
  }, [ ]);
  


  return (
    <NativeBaseProvider flex={1} vw={100}  vh={100} style={{fontFamily:"sans-serif"}}>
        <View style={styles.picker_container}>
            <Picker style={styles.picker_component}
                selectedValue={selectedLanguage}
                onValueChange={(itemValue, itemIndex) =>{
                  setSelectedLanguage(itemValue);
                  route.params.translationFunc(selectedLanguage);
                }}>
                {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
            </Picker>
        </View>
        {isHighEnough && <SessionResultsTopBar session={session} />}
        {!displayLodingLeft && <TouchableOpacity
        style={[styles.share_touchable_floatingactionbuttonLeft,]} 
        onPress={() => {
          setDisplayLodingLeft(true); 
          setDisplayLodingRight(true);
          setDisplayLoadingBack(true);
          scrollResf.current.scrollTo(
            {x:0, y:0, animated:true,

            }
          )
          }}>
          <Image  
            style={styles.share_image} 
            source={ addImageLeft }
            alt="icon flèche droite"/>
        </TouchableOpacity>}
        {displayLoadingBack && <TouchableOpacity
        style={[styles.share_touchable_floatingactionbuttonLeft]} 
        onPress={() => {
          navigation.navigate('Runner', {access_token, runner, sessionList:sessionList });
          }}>
          <Image 
            style={styles.share_image} 
            source={ addImageLeft }
            alt="icon flèche gauche"/>
        </TouchableOpacity>}
        { (expirationCount !== "") && <View style={[styles.information_container, {width: isWideEnough? 500 : (width*0.97)},{borderRadius: 15, elevation:1}]}>
            <Text style={styles.information_text}>
              { expirationCount}
            </Text>
          
            <TouchableOpacity
                style={styles.buttonself}
                onPress={() => {
                  setVerifyExpirationCount(false);
                  setExpirationCount("");
                }}>
              <Text style={styles.button_text}>{translate('ok')}</Text>
            </TouchableOpacity>
          </View>}
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} pagingEnabled={true} ref={scrollResf}
        onLayout={(event)=>{console.log(event.nativeEvent.layout)}}
        >
          <Flex w={largeurLg} alignItems="center" justifyContent='center'>
            <Box height={'100%'} width={'100%'} justifyContent='center'>
              <SessionResultsBoards runner={runner} session={sessionParameters} runnerSession={session} refSession={sessionParametersReference} navigation={navigation} sessionList={sessionList} />
              {displayLoding && _displayLodingForm()}
              {displayLodingRight && <TouchableOpacity
              style={[styles.share_touchable_floatingactionbuttonRight,{bottom: (Platform.OS === 'android')?0:-50} ]} 
                onPress={() => {
                  setDisplayLodingRight(false); 
                  setDisplayLodingLeft(false);
                  setDisplayLoadingBack(false); 
                  scrollResf.current.scrollTo(
                    {x:width, y:0, animated:true,

                    }
                  )
                }}>
                  <Image

                    style={styles.share_image} 
                    source={ addImageRight }
                    alt="icon flèche droite"/>
              </TouchableOpacity>}

              {(displayLodingRight && warningArray === "" && Platform.OS === 'android')&& <TouchableOpacity
              style={[styles.share_touchable_floatingactionbuttoncalib, styles.button]} 
                onPress={() => {
                  setDisplayLoding(true);
                }}>
                  <Text fontWeight="bold" style={{fontSize: 18, paddingBottom:4}}>{translate('comment')}
                  <Image alt="icon pour commentaire" style={{ alignSelf:'center'}} source={require('../images/png/edit_pen.png')}/>
                  </Text>
              </TouchableOpacity>}
              {(warningArray != "") &&  <View style={styles.warnings_center}>
                <Heading fontWeight="bold" fontSize="xl">{translate('recommendation_message')}</Heading>
                {warningArray.map((elt, index)=><View key={index} style={styles.warning_style}>{displayWarning(elt.trim())}</View>)}
                <View style={styles.question_center}>
                  <View style={styles.question_center_calibration}>
                    <TouchableOpacity
                      style={[styles.button,{ backgroundColor: "#00d5f7", marginLeft:5}]}
                      onPress={() => {
                        setDisplayLoding(true);
                      }}>
                    <Text fontWeight="bold" style={{fontSize: 18}}>{translate('comment')}
                    <Image alt="icon pour commentaire" style={{ alignSelf:'center'}} source={require('../images/png/edit_pen.png')}/>
                    </Text>
                    </TouchableOpacity>
                  </View>
                  {displayRecalibrationButton === "yes" && <View style={styles.question_center_calibration}>
                    <Text fontWeight="bold" style={styles.heading_style}>{translate('recalibration_message')}</Text>
                    <TouchableOpacity
                      style={[styles.button,{width: 70, backgroundColor: "#00d5f7", marginLeft:5}]}
                      onPress={() => {
                        storeCalibrationState("0");
                        dataprocessRecalibrationValue("dataprocessingAgain");
                        navigation.navigate('SessionCalibration', {activity, sessionList, access_token, runner, choiceActivity });
                      }}>
                    <Text fontWeight="bold" style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                    </TouchableOpacity>
                  </View>}
                </View>
              </View>}
            </Box>
          </Flex>
          <Box  w={largeurLg2} alignItems="center" justifyContent='center'>
            <SessionResultsFilteredCards navigation = {navigation} sessionList={sessionList} session = {session} sessionSym = {sessionParameters} refSession = {sessionParametersReference} runner={runner} />
          </Box>
          
        </ScrollView>
    </NativeBaseProvider>
  );
}

export default SessionResults;

const styles = StyleSheet.create({

  share_touchable_floatingactionbuttonRight: {
    position: 'absolute',
    right: 30,
    // bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50
  },
  web_touchable: {
    bottom: -50,
  },
  share_touchable_floatingactionbuttoncalib: {
    position: 'absolute',
    left: 30,
    bottom: 6,
    justifyContent: 'center',
    alignItems: 'center',
    // width: 50,
    // height: 50
  },
  share_touchable_floatingactionbuttonLeft: {
    position: 'absolute',
    marginTop:20,
    marginLeft: 15,
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50
  },
  share_image: {
    width: 40,
    height: 40
  },
  picker_component:{
    flex:1,
    borderRadius: 1,
    width: 150,
    marginRight: 20,
    justifyContent:'flex-end'
  },
  picker_container:{
    width: "100%",
    alignItems: "flex-end",
    height: 30,
  },
  bordure: {
    borderWidth:1,
    borderColor: 'red'
  },
  warnings_center: {
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
  },
  question_center: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding:0,
    margin:0,
    width: "70%",
  },
  question_center_calibration: {
    alignItems: 'center',
    flexDirection: 'row',
    padding:0,
    margin:0,
    width: "50%",
  },
  button:{
    marginTop: 15,
    width: 170,
    height: 40,
    borderRadius: 30,
    backgroundColor:"#00d5f7",
    justifyContent: "center",
    alignItems: "center"
  },
  heading_style:{
    color:"#00d5f7",
    fontSize:14
  },
  warning_style:{
    width: "48%",
  },
  buttonself:{
    backgroundColor: "#00d5f7", 
    alignSelf:"center",
    borderRadius:200,
    marginBottom: 10,
    marginTop: 10
  },
  button_text:{
    fontSize: 14, 
    alignSelf:"center", 
    marginHorizontal:20, 
    marginVertical:2
  },
  information_container:{ 
    borderWidth:1,
    alignSelf:"center",
    flex: 1,
    borderColor: '#00d5f7',
    borderRadius: 20,
    backgroundColor: '#FFF',
    position: 'absolute',
    top :10,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, 
  },
  information_text:{
    textAlign:"justify", 
    padding: 5, 
    paddingHorizontal: 15,
    color:"black"
  },

})
