import React, { useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity,TextInput, useWindowDimensions } from "react-native";
import { FormControl, Input, Select, Center} from "native-base";
import { RadioButton } from 'react-native-paper';
import translate from '../i18n/translate';

// utils functions
import { updateComment } from '../src/fetch.js';

export default function FormAddComment({warningArray,displayloading, public_id, access_token, datacom}) {

    
    const [text, onChangeText] = useState(datacom);

    const largeBreakpoint = 600;
    const width = useWindowDimensions().width;
    const isWideEnough = (width > largeBreakpoint);

    const onSubmit = () =>{
        const route = `/api/runners/${public_id}/comments`;
        if(text !==""){
            const data = {
                "comment": text,
            }
            updateComment(route, access_token, data)
                .then(({json})=>{
                    const response = JSON.stringify(json);
                    const responseParse = JSON.parse(response);
                    console.log(responseParse.status);
                    if(responseParse.status === 'success'){
                        displayloading(false);
                    }
                })
                .catch((error)=>console.log(error))
        }      
    } 

    const cancelForm = () =>{  
        displayloading(false)
    } 
    
    return (
        <Center elevation= {1} style={[styles.add_patient, {width:isWideEnough? 590 : '90%', height:isWideEnough? 350 : 'auto'}]}>
            <View  style={styles.formContainer}>
                <Text style={styles.textTitle}>{warningArray !== "" ? translate('new_comment') : translate('edit_comment')}</Text>
                <View style={styles.form}>
                    <View mt="70px" style={{width: (width>800)? 400 : width*0.70, alignItems: 'center'}}>
                        <Text style={styles.size_number_check}>{translate('input_comment')}</Text>
                        <TextInput
                            multiline 
                            style={styles.input}
                            width={width*0.40}
                            height="50%"
                            onChangeText={onChangeText}
                            value={text}
                        />
                    </View>
                    <View style={[styles.flexButton, {width:isWideEnough? 550 : "95%"}]}>
                        <TouchableOpacity
                            style={[styles.customBtnBG, {width:isWideEnough ? 150 : 100}]}
                            onPress={cancelForm}>
                            <Text style={styles.customBtnText}>{translate('go_Back')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.customBtnBG, {width:isWideEnough ? 150 : 100}]}
                            onPress={onSubmit} >
                            <Text style={styles.customBtnText}>{translate('submit_button')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>    
        </Center>
    );
}

const styles = StyleSheet.create({
    size_number_check:{
        fontSize: 16,
      },
    input: {
    marginTop: 5,
    borderWidth: 1,
    borderRadius: 4,
    borderColor:"#00d5f7"
    },
    add_patient: {
        flex: 1,
        borderColor: '#00d5f7',
        margin: 5,
        borderRadius: 20,
        backgroundColor: '#FFF',
        position: 'absolute',
        top :40,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 2,
    }, 
    customBtnBG: {
        backgroundColor: "#00d5f7",
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 30,
        margin: 12,
    },
    customBtnText: {
        fontSize: 18,
        fontWeight: '400',
        color: "#fff",
        textAlign: 'center'
    },
    form: {
        flex: 1,
        height: 'auto',
        justifyContent: 'center',
        padding: 5,
        marginTop: 5,
        marginBottom: 10,
        alignItems: 'center',
    },
    formContainer:{
        justifyContent: 'center',
        marginTop: 5,
    },
    flexButton:{
        flex: 1,  
        flexDirection: 'row',  
        justifyContent: 'space-around',
        alignItems: 'center', 
        marginTop: 15,
    },
    textTitle:{
        fontSize: 19,
        marginBottom: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#00d5f7'
    },
})
  