import React from 'react';
import {Text} from "native-base";

// intl components
import translate from '../i18n/translate';
/* IMPORTANT

    Timestamps used are relative to the command call, i.e a small TimeStamp is more recent than a large one.

*/

// Keeps track of all the errors Available at the Master : "E"
const errorDictionary = {
    "00":"Unable to create file",
    "01":"Sensor data queue full",
    "02":"TCP socket disconnected",
    "03":"File is empty : 0 bytes"
}

// Keeps track of all the errors Available at the Master : "I"
const infoDictionary = {
    "00":"TCP socket connected",
}

const parseTime = (t) => {
    const timeInt = parseInt(t, 10);
    var timeString = String(Math.floor(timeInt / 3600)) + "h ";    // Hours
    timeString = timeString + String(Math.floor((timeInt%3600) / 60)) + "m ";  // Minutes
    timeString = timeString + String(timeInt % 60) + "s";  // Seconds
    return(timeString);
}

// Function to interpret an error message from the Master
const parseError = (message) => {
    var timeStamp = message.slice(0, 6);
    
    if(message.slice(6, 8) === "xc"){
        return("[MASTER] I:: xSens n°"+ message[8]+" connected " + parseTime(timeStamp) + " ago.");
    }else if(message.slice(6, 8) === "xd"){
        return("[MASTER] W:: xSens n°"+ message[8]+" disconnected " + parseTime(timeStamp) + " ago.");
    }else if(message[6] === "i"){
        return("[MASTER] I:: " + infoDictionary[message.slice(7,9)] + " " + parseTime(timeStamp) + " ago.");
    }else if(message[6] === "e"){
        return("[MASTER] E:: " + errorDictionary[message.slice(7,9)] + " " + parseTime(timeStamp) + " ago.");
    }
    return("Unkown Message : " + message);
}

const ignoreOlderThan = 7200;   // Will ignore all Error Messages which are at least 2hours old.

// Function used to detect if a capital, unhandlable error has occured
// In which case we are forced to Reset the Master.
const handleErrors = (messages) => {
    var xSensConnectionState = {};      // (Dictionary) Keeps track of the connection State of the sensors.
                                        // Key: xSens number, 
                                        // Value: String with 7 digits  |  0/1 -> Disconnected/Connected State 
                                        //                              |  XXXXXX -> TimeStamp
    var stateTCPSocket = "1999999"; // 0-Disconnected, 1-Connected + TimeStamp
    var errorList = "";     // Keeps track of the most important errors

    messages.forEach(message => {

        if(parseInt(message.slice(0,6)) > ignoreOlderThan){
            console.log("Message too old, ignoring");
        }else{
            if(message.slice(6, 8) === "xd"){
                // ***XSENS DISCONNECTED***
                if(!(message[8] in xSensConnectionState)){
                    xSensConnectionState[message[8]] = "0"+message.slice(0, 6);
                    // If this is the first message relative to this specific IMU
                }else if(parseInt(xSensConnectionState[message[8]].slice(1,7)) > parseInt(message.slice(0,6))){ 
                    // If the message is more recent than the stored one
                    xSensConnectionState[message[8]] = "0"+message.slice(0, 6);
                }
            }else if(message.slice(6, 8) === "xc"){
                // ***XSENS CONNECTED***
                if(!(message[8] in xSensConnectionState)){
                    xSensConnectionState[message[8]] = "1"+message.slice(0, 6);
                    // If this is the first message relative to this specific IMU
                }else if(parseInt(xSensConnectionState[message[8]].slice(1,7)) > parseInt(message.slice(0,6))){ 
                    // If the message is more recent than the stored one
                    xSensConnectionState[message[8]] = "1"+message.slice(0, 6);
                }
            }else if(message[6] === "e"){
                // ***SOCKET DISCONNECTED ERR***
                if(message.slice(7,9) == "02"){
                    if(parseInt(stateTCPSocket.slice(1,7)) > parseInt(message.slice(0,6))){ 
                        // If the message is more recent than the stored one
                        stateTCPSocket = "0"+message.slice(0, 6);
                    }
                // ***FILE CREATION ERR***
                }else if(message.slice(7,9) == "00"){
                    errorList = errorList + "  Une erreur s'est produite lors de la création du fichier, depuis " + parseTime(message.slice(0,6));
                // ***SENSOR DATA QUEUE FULL ERR*** The Data Queue was full
                }else if(message.slice(7,9) == "01"){
                    errorList = errorList + " L'un de vos capteurs s'est déconnecté depuis " + parseTime(message.slice(0,6)) + " ago.\n";
                // ***UNKNOWN ERR (WARNING)***  The Data File was empty (0 bytes)
                }else if(message.slice(7,9) == "03"){
                    errorList = errorList + " Le fichier de données était vide depuis " + parseTime(message.slice(0,6));
                }else{
                  //An Unknown error occured
                    errorList = errorList + "  Une erreur inconnue s'est produite depuis " + parseTime(message.slice(0,6));
                }
            }else if(message[6] === "i"){
                // ***SOCKET CONNECTED***
                if(message.slice(7,9) == "00"){
                    if(parseInt(stateTCPSocket.slice(1,7)) > parseInt(message.slice(0,6))){ 
                        // If the message is more recent than the stored one
                        stateTCPSocket = "1" + message.slice(0, 6);
                    }
                }
            }
        }
    });
        

    for (const [key, value] of Object.entries(xSensConnectionState)) {      // Verifying that every IMU is connected
        if(value[0] === '0'){
            errorList = errorList + "  Xsens n°" + key + " s'est déconnecté depuis " + parseTime(value.slice(1,7));
        }
    }

    // The following if statement doesn't work because the Master has a list of "TCP Socket disconnected" error
    // After a Reset, this leads to an impossibility of doing a new measure.
    // Possible solution : on Master side, when a reset occurs ignore all socket errors.

    // if(stateTCPSocket[0] === '0'){      // Verifying that TCP Socket is up and running
    //     errorList = errorList + "  Socket disconnected " + parseTime(stateTCPSocket.slice(1,7)) + " ago.\n";
    // }


    // console.log(errorList);
    return errorList;
}

const displayWarning = (warnings)=>{
    switch (warnings) {
      case "W_VLRGPROCESSIMPOSSIBLE.":
      case "W_VLRGPROCESSIMPOSSIBLE":
        return <Text>WARNING-Process of VLRG impossible</Text>;
      case "W_TIMESRHEELPUTTOOSMALL.":
      case "W_TIMESRHEELPUTTOOSMALL":
        return <Text>WARNING-The dataprocessing was made with too few steps.</Text>;
      case "W_RUNTIMETOOSHORT.":
      case "W_RUNTIMETOOSHORT":
        return <Text>WARNING-Your exercice is too short to be representative: start an other one.</Text>;
      case "W_VLRDPROCESSIMPOSSIBLE.":
      case "W_VLRDPROCESSIMPOSSIBLE":
        return <Text>WARNING-Process of VLRD impossible.</Text>;
      case "W_TOOMUCHRKNEEERRORS.":
      case "W_TOOMUCHRKNEEERRORS":
        return <Text>WARNING-There are too much important errors on Right knee angle process.</Text>;
      case "W_LPASDNEGATIV.":
      case "W_LPASDNEGATIV":
        return <Text>WARNING-LpasD is negative: it is impossible.</Text>;
      case "W_LPASGNEGATIV.":
      case "W_LPASGNEGATIV":
        return <Text>WARNING-LpasG is negative: it is impossible.</Text>;
      case "W_STRIDELUNCORRENEGATIV.":
      case "W_STRIDELUNCORRENEGATIV":
        return <Text>WARNING-LpasD and LpasG can not be processed.</Text>;
      case "W_STRIDELNOTPROC.":
      case "W_STRIDELNOTPROC":
        return <Text>WARNING-StrideLength can not be processed.</Text>;
      case "W_BIGDATALOOSE.":
      case "W_BIGDATALOOSE":
        return <Text>WARNING-More than 10 % of data are bad quality. % of loose by IMU </Text>;
      case "W_NBCYCLESTOOSHORT.":
      case "W_NBCYCLESTOOSHORT":
        return <Text>WARNING-Too few Number of steps: Relaunch an exercice.</Text>;
      case "W_INCOHERENTWEIGHT.":
      case "W_INCOHERENTWEIGHT":
        return <Text>WARNING-You enter an incoherent weight value.</Text>;
      case "W_IMUCALIBELLI.":
      case "W_IMUCALIBELLI":
        return <Text>WARNING-You did not move your sensors in all parts of space.</Text>;
      case "W_LPASGPASDNEGATIV.":
      case "W_LPASGPASDNEGATIV":
        return <Text>WARNING-LpasD and LpasG negativ:  it is impossible.</Text>;
      case "W_LPASDNEGATIVSDLNOTPROC.":
      case "W_LPASDNEGATIVSDLNOTPROC":
        return <Text>WARNING-LpasD and StrideLength can not be processed.</Text>;
      case "W_LPASGNEGATIVSDLNOTPROC.":
      case "W_LPASGNEGATIVSDLNOTPROC":
        return <Text>WARNING-LpasG and StrideLength can not be processed.</Text>;
      case "W_LPDGNEGATIVSDLNOTPROC.":
      case "W_LPDGNEGATIVSDLNOTPROC":
        return <Text>WARNING-LPASD, LpasG and StrideLength can not be processed.</Text>;
      case "W_NBPASNULL.":
      case "W_NBPASNULL":
        return <Text>WARNING-You did not walk or run.</Text>;
      case "W_NBSIGNALKO.":
      case "W_NBSIGNALKO":
        return <Text>WARNING-Number of signal are not consistant.</Text>;
      case "W_THISISNOTWALK0RRUN.":
      case "W_THISISNOTWALK0RRUN":
        return <Text>WARNING-Your exercice is not a walk or a run.</Text>;
      case "W_CAL_RESTARTIMUCALIB_OOR.":
      case "W_CAL_RESTARTIMUCALIB_OOR":
        return <Text>WARNING-Calibration IMUs recommended: Magnetic perturbation appears during exercice.</Text>;
      case "W_CAL_IMUSTOCALIBRATE_SD.":
      case "W_CAL_IMUSTOCALIBRATE_SD":
        return <Text>WARNING-Calibration IMUs recommended: Magnetic Fields dispersion.</Text>;
      case "W_CAL_IMUSTOCALIBRATE_KAN.":
      case "W_CAL_IMUSTOCALIBRATE_KAN":
        return <Text>WARNING-Calibration IMUs recommended: Negativ knees angles identified.</Text>;
      case "W_CAL_RESTARTIMUCALIB_SDBA.":
      case "W_CAL_RESTARTIMUCALIB_SDBA":
        return <Text>WARNING-Calibration IMUs recommended: Calibration bad quality.</Text>;
      case "W_CAL_IMUSTOCALIBRATE_STR.":
      case "W_CAL_IMUSTOCALIBRATE_STR":
        return <Text>WARNING-Calibration IMUs recommended: it is highly recommended to calibrate your IMUs.</Text>;
      case "W_INCOHERENTPACEWALK.":
      case "W_INCOHERENTPACEWALK":
        return <Text>WARNING-Walking cadence out of scope: verify the position of your sensors or do a new calibration.</Text>;
      case "W_INCOHERENTPACERUN.":
      case "W_INCOHERENTPACERUN":
        return <Text>WARNING-Running cadence out of scope: verify the position of your sensors or do a new calibration.</Text>;
      default:
        return;
    }
  }

export {parseError, handleErrors, displayWarning}; // To be commented if testing script



//****** Test script ******\\

// const messages = [
//     "007200xc0",
//     "005601xd1", 
//     "093222xc2",
//     "000003xc3",
//     "000004xc4",
//     "003661xc1",
//     "003662xd2",
//     "007200xc1",
//     "000890i00",
//     "000700e02",
//     "000512i00",
//     // "001222e01",
//     // "003244e00",
//     "004444e04"

// ]

// messages.forEach(msg => console.log(parseError(msg)));
// handleErrors(messages);