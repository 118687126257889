
import Zeroconf from 'react-native-zeroconf';
const zeroconf = new Zeroconf();
const ImplType = {
    NSD: 'NSD',
    DNSSD: 'DNSSD',
  }

const MdnsConfig = (masterId, ipAddress) => {

    const txt = {"ip": ipAddress, "bt_addr": "d422cd000976", "bt_addr": "d422cd000A77", "bt_addr": "d422cd000949", "bt_addr": "d422cd000A90", "bt_addr": "d422cd000929"};
    zeroconf.publishService('http', 'tcp', 'local.', 'cfg-msm-' + masterId, 10101, txt, ImplType.NSD);
    console.log("Good mDNS");
    // zeroconf.scan('http', 'tcp', 'local.')
    // zeroconf.on('resolved', service => {
    //     const { name } = service;
    //     if (service.name === 'cfg-msm-' + masterId) {
    //         console.log('[Resolve] already display', JSON.stringify(service, null, 2)) 
    //     }         
    // })
    zeroconf.on('error', err => {
        console.error('[Error zeroconf.publishService]', err);
    })

}

const MdnsBattery = (masterId)=>{
    zeroconf.scan('http', 'tcp', 'local.');
    const maPromesse = new Promise((resolve, reject) => {
        zeroconf.on('resolved', service => {
            if (service.name === 'sts-msm-'+ masterId) {
                resolve(JSON.stringify(service, null, 2)); 
            }           
        });
      });
    return maPromesse;
}

export {MdnsConfig, MdnsBattery};

