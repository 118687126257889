import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity,Image, useWindowDimensions, ToastAndroid, Platform } from 'react-native';
import { Box, Text, Heading, VStack, FormControl, Input, Link, HStack } from 'native-base';
import NetInfo from "@react-native-community/netinfo";
import { useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CheckBox from '@react-native-community/checkbox';

// Import the native module
import { NativeModules } from 'react-native';

const { CollectData } = NativeModules;


// intl components
import 'intl';
import translate from '../i18n/translate';
import { Picker } from '@react-native-picker/picker';

// components
import LoginButton from './LoginButton.js';

// Images
const spyPassword = require('../images/png/eye.png');


const LoginFields = ({navigation, setEnglishlanguages}) => {
  //email = trainer1
  //password = trainer1

  const isFocused = useIsFocused();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [showPassword, setShowPassword] = useState('password');
  const [ssidNetwork, setSsidNetwork] = useState("");
  const [oneRememberCredentials, setOneRememberCredentials] = useState(true);
  const [ssidNotification, setSsidNotification] = useState("");
  const [networkFrequency, setNetworkFrequency] = useState(0);
  const [checked, setChecked] = useState(false);
  const [NotChecked, setNotChecked] = useState(false); 

  const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}]
  const { height, width } = useWindowDimensions();
  const largeBreakpoint   = 992;
  const midBreakpoint = 688;
  const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
  const breakpointwith = width >= largeBreakpoint? width*0.50: width >= midBreakpoint? width*0.65: width*0.65;
  const [value, setValue] = useState('');
  const textWithoutSpace = (inputText) => {setValue(inputText.replace(/^\s/, '').replace(/\s$/, ''))};


  const checkNetworkConnexion = ()=>{
    (Platform.OS == 'android') && CollectData.GetNetworkSpeed().then((responseSpeed)=>{
      if(responseSpeed === 0) {
        setSsidNotification(translate('no_WiFi_connexion'));
      }else if(responseSpeed[3] !== "true") {
        setSsidNotification(translate('no_WiFi_connexion'));
      }
    }).catch((error)=>{
      console.log("error:: :: ", error);
    })
    
  }
  const unsubscribeHandler = NetInfo.addEventListener(state => {
    NetInfo.fetch("wifi").then(state => {
      if(state.details.ssid != undefined && state.isInternetReachable === true){
        if(state.details.ssid.substring(0, 11) !== "MYSMARTMOVE"){
          setNetworkFrequency(state.details.frequency);
          setSsidNetwork("");   
        }
      }else{
        setSsidNetwork("Failed");
      }  
    });  
  });

  const getUserCredentials = async () => {
    try {
        const useremail = await AsyncStorage.getItem('cfuseremail');
        const userpassword = await AsyncStorage.getItem('cfuserpassword');
        if(useremail !== null && userpassword !== null && oneRememberCredentials === true){
          setEmail(useremail);
          setPassword(userpassword);
          setOneRememberCredentials(false);
        }
        if(checked === false && NotChecked === true){
        await AsyncStorage.setItem('cfuseremail','' );
        await AsyncStorage.setItem('cfuserpassword','' );  
      }
      
      
    } catch(error) {
      console.error(error)
    }
  }
  getUserCredentials();

  useEffect(() => {
    let mounted = true;
    return ()=>{
      if(mounted){
        unsubscribeHandler();
        mounted = false;
      }   
    }
  }, [isFocused]);

  return (
    <Box safeArea w={{ base:"75%", md:"65%", lg:"50%" }} mt={5}>
      <View style={styles.picker_container}
          >
          <Picker style={styles.picker_component}
            selectedValue={selectedLanguage}
            onValueChange={(itemValue, itemIndex) =>{
              setSelectedLanguage(itemValue)
              setEnglishlanguages(selectedLanguage)
            }}
            >
              {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
          </Picker>
      </View>
      {(ssidNotification != "") && <View style={{ borderWidth: 0.7,borderRadius:7, borderColor: '#00d5f7'}}>
        <View style={{ margin: 10, alignSelf: "center", alignItems: "center" }}>
          <Text>
            {ssidNotification && ssidNotification}
          </Text>
          <TouchableOpacity
            style={[styles.button, { width: 70, backgroundColor: "#00d5f7" }]}
            onPress={() => {
              setSsidNotification("");
            }}>
            <Text style={{alignSelf: "center", fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
          </TouchableOpacity>
        </View>
      </View>}
      <Heading mt="5" size="lg" fontWeight="600" color="coolGray.800" fontSize ="23">
      {translate('welcome')}
      </Heading>
      <Heading  mt="1" color="coolGray.600" fontWeight="medium" size="xs">
        {translate('connexion_message')}
      </Heading>

      <VStack space={3} mt="5">
        <FormControl isRequired>
          <FormControl.Label
            _text={{
              color: 'coolGray.800',
              fontSize: 'xs',
              fontWeight: 500,
            }}>
            <Text fontWeight="bold">{translate('email')}</Text>
          </FormControl.Label>
          <Input
            width={breakpointwith}
            value={email}
            onChangeText={(text) => setEmail(text.trim())}
            //onChangeText={(text) => setEmail(textWithoutSpace(text))}
          />
          {errorEmail&&<Text style={styles.errorMsg}>{errorEmail}</Text>}
        </FormControl>
        <FormControl isRequired>
          <FormControl.Label
            _text={{
              color: 'coolGray.800',
              fontSize: 'xs',
              fontWeight: 500,
            }}>
            <Text fontWeight="bold">{translate('password')}</Text>
          </FormControl.Label>
          <View style={{flexDirection: "row"}}>
          <Input
            type={showPassword}
            width={breakpointwith}
            value={password}
            onChangeText={(text) => setPassword(text.trim())}
            //onChangeText={(text) => setPassword(textWithoutSpace(text))}

          />
          <TouchableOpacity 
            style={{alignSelf:"center"}} 
            onPress={() => setShowPassword((showPassword === '') ? 'password' : '')
            }>
            <Image 
              style={{width: 30, height:16, marginLeft: 10}}
              source={ spyPassword }
              alt="PasswordSpy"/>
          </TouchableOpacity>
          </View>
          {errorPassword&&<Text style={styles.errorMsg}>{errorPassword}</Text>}
        </FormControl>
        {(Platform.OS == 'android') && <View style={{ flexDirection: 'row' }}>
          <CheckBox
            value={checked}
            onValueChange={(newValue) => {
              if(newValue===true){
                setNotChecked(true);
                setChecked(newValue);
              }else{
                setNotChecked(true);
                setChecked(newValue);
              }
            }}    
          />
          <Text fontWeight="bold" style={{color :"#0033cc"}}> {translate('remember_me')}</Text>
        </View>}
      
        <LoginButton navigation={navigation} email={email} password={password} setErrorEmail={setErrorEmail} setErrorPassword={setErrorPassword} checkNetworkConnexion={checkNetworkConnexion} networkFrequency={networkFrequency} checked={checked}/>
        <HStack mt="6" justifyContent="center">
          <Text fontWeight="bold" fontSize="sm" color="muted.700" mb="5">
            {translate('not_account')}
          </Text>
          <Link
            _text={{
              color: 'indigo.500',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            onPress={() => navigation.navigate('Signup')}>
            <Text fontWeight="bold" style={styles.account_color}> {translate('account')}</Text>
          </Link>
        </HStack>
      </VStack>
    </Box>
  );
}

export default LoginFields;

const styles = StyleSheet.create({
  picker_component:{
    borderRadius: 4,
    width: 150,
    justifyContent:'flex-end'
  },
  picker_container:{
    width: "100%",
    alignItems: "flex-end",
  },
  account_color:{
   color: "#00d5f7" 
  },
  errorMsg:{
    fontSize: 12,
    width: '100%', 
    color: 'red',
    padding: 0,
    margin: 0,
  },
  button:{
    marginTop: 15,
    width: 150,
    height: 40,
    borderRadius: 30,
    backgroundColor:"#00d5f7",
    justifyContent: "center",
    alignItems: "center"
  }
})
