import React from 'react';

import { Text, Center } from 'native-base';


const NewSessionWeb = () => {

  return(
    <Center  alignSelf="center" mt="10" h="10%">
      <Text style={{textAlign : "center", marginHorizontal: "10%", fontWeight: "bold"}}>
        Ouvrez l'application Android pour effectuer la remontée de données.
      </Text>
    </Center>
  );
}

export default NewSessionWeb;
