import React, {useEffect, useState } from 'react';
import { StyleSheet, NativeModules, ToastAndroid, Alert, Platform, TouchableOpacity, Image, View, useWindowDimensions, ActivityIndicator} from 'react-native';
import { Center, NativeBaseProvider, VStack, Heading, FormControl, Input, Box, Text, Button, ScrollView} from 'native-base';
import NetInfo from "@react-native-community/netinfo";
import { PermissionsAndroid } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import translate from '../i18n/translate';
const { CollectData } = NativeModules;

// Images
const spyPassword = require('../images/png/eye.png');


const DeviceConfig = ({navigation, route}) => {

    const {previous_page, previous_params} = route.params;

    const [ssidName, setSsidName] = useState('');
    const [errorSsidName, setErrorSsidName] = useState('');
    const [password, setPassword] = useState(''); 
    const [wifiMysmartmoveExist, setWifiMysmartmoveExist] = useState("");
    const [errorPassword, setErrorPassword] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [showConnectButton, setShowConnectButton] = useState(false);
    const [isToast, setisToast] = useState(true);
    const [isToastConnect, setisToastConnect] = useState(true);
    const [showPassword, setShowPassword] = useState('password');
    const [successConfig, setSuccessConfig] = useState('');
    const [patientInformation, setPatientInformation] = useState("");
    const [launchSubmit, setLaunchSubmit] = useState(false);
    const [successMesure, setSuccessMesure] = useState("");
    const [sendingCredential, setSendingCredential] = useState("");
    const [ssidNetwork, setssidNetwork] = useState('');
    const [badCredentials, setBadCredentials] = useState('');
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [opacityStop, setOpacityStop] = useState(1);
    const [disabledButtonStop, setDisabledButtonStop] = useState(false);

    const [value, setValue] = useState('');
        const textWithoutSpace = (inputText) => {setValue(inputText.replace(/^\s/, '').replace(/\s$/, ''))};

    const [isConnectedToOptimove, setIsConnectedToOptimove] = useState(false);

    const { height, width } = useWindowDimensions();
    const largeBreakpoint   = 992;
    const isTablet = ((width >= largeBreakpoint )||(height >= largeBreakpoint));

    const [locale, setLocale]= useState(LOCALES.FRENCH)
    const setEnglishlanguages = (lang) =>{
        lang == 'Français' ? setLocale(LOCALES.ENGLISH): setLocale(LOCALES.FRENCH)  
    }
    
    const unsubscribeHandler = NetInfo.addEventListener(state => {
        NetInfo.fetch("wifi").then(state => {
            if(state.details.ssid != undefined){
                if(state.details.ssid.substring(0, 11) === "MYSMARTMOVE"){
                    if(isToast){
                      setWifiMysmartmoveExist("MYSMARTMOVE");
                      setssidNetwork(state.details.ssid);
                      setPatientInformation(translate('ssid_connection_message'));
                      setShowConnectButton(true);
                      setisToast(false);
                      setisToastConnect(false);
                      const storeCalibrationState = async (value) => {
                          try {
                            await AsyncStorage.setItem('cfgmsm', value)
                          } catch (error) {
                            console.error(error)
                          }
                        }
                      storeCalibrationState(state.details.ssid.split('-')[1])
                    }
                    
                    setIsConnectedToOptimove(true);


                }else{
                    setShowForm(false);
                    setShowConnectButton(false);
                    setSsidName(state.details.ssid);
                    if(isToast){
                      setssidNetwork(state.details.ssid);
                      setPatientInformation(translate('ssid_connection_second_message'));
                      setisToast(false); 
                    }
                }
    
            }else{
                setShowForm(false);
                setShowConnectButton(false);
                if(isToastConnect){
                  setPatientInformation(translate('not_connection_message'));
                    setisToastConnect(false);
                }
                
            }
            
        });
    })
    
    const requestLocationPermission = async () => {
        try {
          const granted = await PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
            {
              title: "Permission pour accéder au wifi",
              message:
                "Une autorisation de localisation est requise pour se connecter ou rechercher des réseaux Wifi. ",
              buttonNegative: "Non",
              buttonPositive: "Oui"
            }
          );
          if (granted === PermissionsAndroid.RESULTS.GRANTED) {
            NetInfo.fetch("wifi").then(state => {
                if(state.details.ssid != undefined){
                    if(state.details.ssid.substring(0, 11) != "MYSMARTMOVE"){
                      setShowForm(false);
                      setShowConnectButton(false);
                      setssidNetwork(state.details.ssid);
                      setPatientInformation(translate('ssid_connection_second_message')); 
                    }
    
                }else{
                  setPatientInformation(translate('not_connection_message'));
                }
                
            });
          } else {
            setPatientInformation(translate('failed_location'));
          }
        } catch (err) {
          console.warn("Impossible d'avoir la permission pour se connecter au réseau wifi "+err);
        }
      };

    useEffect(() => {
      let mounted = true;
      requestLocationPermission(); 
      setBadCredentials("");
      return() => {
        if(mounted){
          unsubscribeHandler();
          mounted = false;
        }   
      };
      },[]);

      let mounted = true;
      const masterConfiguration = () => {
        CollectData.configuration(ssidName, password).then((response)=>{
          if(response === "OK"){
            const interv = setInterval(() => {
                CollectData.GetAcknowledgeConfig().then((res)=>{
                  if(res === "1"){
                    setLoadingSubmit(false);
                    setShowConnectButton(false);
                    clearInterval(interv);
                    CollectData.WifiBoxConfig()
                    .then(res => {
                      if(res === "Success"){
                        if(mounted){
                          setSuccessConfig(translate('success_config_message'));
                          setSsidName('');
                          setPassword('');
                        }
                        CollectData.CloseAll()
                          .then((response)=>{
                              if(response === "Success"){
                                console.log('Socket has been successfully closed.')
                              }       
                          })
                          .catch((err)=>console.log(err));
                        setTimeout(() => {if(mounted) {
                          navigation.navigate(previous_page, previous_params);
                        //}}, 15000);
                        }}, 7000);
                        return;
                      }else{
                        setSuccessConfig(translate('failed_config_message'));
                        setSuccessMesure(translate('failed_config_reset'));
                      }
                    })
                    .catch(error => console.error(error));
                  }
                }).catch((error)=> {
                  setLaunchSubmit(true);
                  console.log("Sorry:: :: ", error);
                  setBadCredentials(translate('credentials_connection'));
                  const inter = setInterval(() => {
                    setLaunchSubmit(true);
                    clearInterval(inter);
                    clearInterval(interv);
                    setLoadingSubmit(true);
                    CollectData.CloseAll()
                      .then((response)=>{
                          if(response === "Success"){
                            console.log('Socket has been successfully closed.')
                            setBadCredentials(translate('credentials_connection'));
                            if(wifiMysmartmoveExist != ""){
                              setLaunchSubmit(false);
                              setShowForm(false);
                              setTimeout(() => {
                                setLaunchSubmit(false);
                                setLoadingSubmit(false);
                                setDisabledButtonStop(false); 
                                setOpacityStop(1);
                                setShowConnectButton(true); 
                              //}, 32000);
                              }, 7000);
                            }
                          }       
                      })
                      .catch((err)=>console.log(err))
                  }, 3000);
                });
              //},3000);
              },32000);
          }else{
            setSendingCredential(translate('failed_config_reset'));
          }
        }).catch((error) => {
          setShowForm(false);
          setSendingCredential(translate('failed_config_reset'));
          CollectData.CloseAll()
            .then((response)=>{
                if(response === "Success"){
                  console.log('Socket has been successfully closed.')
                  if(wifiMysmartmoveExist != ""){
                    setLaunchSubmit(false);
                    setShowForm(false);
                    setTimeout(() => {
                      setLaunchSubmit(false);
                      setLoadingSubmit(false);
                      setDisabledButtonStop(false); 
                      setOpacityStop(1);
                      setShowConnectButton(true); 
                    //}, 32000);
                    }, 7000);
                  }
                }       
            })
            .catch((err)=>console.log(err))
        });
      }
  
      const onSubmit = () => {
          setLaunchSubmit(true);
          setShowConnectButton(false);
          if(ssidName !==''){
              if(password !== ''){
                const storeSsid = async (value) => {
                  try {
                    await AsyncStorage.setItem('ssid_cfgmsm', value)
                  } catch (error) {
                    console.error(error)
                  }
                }
                storeSsid(ssidName)
                setLoadingSubmit(true);
                masterConfiguration();
                setShowForm(false);
              }else{
                  if(mounted){ 
                    setErrorPassword(translate('fill_current_field'));
                  }
              }
          }else{
              if(mounted){
                setErrorSsidName(translate('fill_current_field'));
              }
          }
      }
    
    const socketConnection = () => {
      setBadCredentials("");
      setShowConnectButton(false);
        if(isConnectedToOptimove){
          CollectData.ConnectionConfig()
            .then((response)=>{
              if(response === 1 || response === 2){
                setShowForm(true);
              }else if(response === 0){
                setBadCredentials(translate('error_config_connection'));
                return;
              } else if("connectionTimeOut") {
                setBadCredentials(translate('error_config_connection'));
              }     
            })
            .catch((err)=>console.log(err))    
        }
    }
    const Resetsocket = () => { 
        CollectData.Reset()
          .then((response)=>{
              if(response === "OK"){
                setSuccessMesure("");
                console.log('reset successfully')
              }       
          })
          .catch((err)=>console.log(err))    
    }

    const displayLoading = ()=>{
      if(loadingSubmit){
        return(
        <View style={{alignItems: "center", marginTop: 50}}>
          <Text style={{fontSize: isTablet? 18 : 15, color: "#32a852"}}>{translate('time_message')}</Text>
          <ActivityIndicator size='large'color="#000000"/>
        </View> 
        )
      }
    }

    const masterAccesspoint = async ()=>{
      const value = await AsyncStorage.getItem('cfgmsm');
      if(value !== null){
        CollectData.Connection()
        .then((data)=>{
          if(data === 1){
            CollectData.EspAccessPoint()
            .then((response)=>{
                if(response === "Success"){ 
                  console.log('success');
                }else if (response === "Failed"){
                  console.log('Failed');
                }       
            })
            .catch((err)=>console.log(err)); 
          }else{
            setSuccessMesure(translate('failed_socket_connection'));
          }    
        })
        .catch((err)=>{
          ToastAndroid.show(
          `Connexion impossible, ${err}`,
          ToastAndroid.LONG
        );}); 
      }
      
    }

  return(
    <NativeBaseProvider flex={1}>
      <ScrollView style={{fontFamily:"sans-serif"}}>
        <Box safeArea flex={1} p="2" w={{ base:"75%", md:"65%", lg:"50%" }} mx="auto" py="8">
          { (successConfig != "") && <Center>
            <Heading mt={8} mb ={4} fontSize={18} color="#32a852"> { successConfig } </Heading>
            <ActivityIndicator size='large'color="#000000"/>
          </Center>}
          { (badCredentials != "") && <Center>
            <Heading mt={8} mb ={4} fontSize={18} color="#f54284"> { badCredentials } </Heading>
          </Center>}
          { (successConfig === "") && (patientInformation != "") && <Text textAlign="center" smt={8} mb ={4} fontSize={18} color="#32a852"> { patientInformation } {ssidNetwork ? ssidNetwork : ""} </Text>}
          {(successConfig === "") && <Box >
            {(successConfig === "") && (badCredentials === "") &&<Center>
            <Heading mt={8} fontSize="xl">{translate('mysmartmove_network_message')}</Heading>
            <Text mt={4}>{translate('device_access_location')}</Text>
            </Center>} 
            {wifiMysmartmoveExist === "" &&<View style={[styles.question_center_calibration, {flexDirection: width>500? 'row': 'column'}]}>
              <Text style={styles.heading_style}>{translate('check_musmartmove_network')}</Text>
              <TouchableOpacity
                style={styles.access_point_button}
                onPress={() => {
                  masterAccesspoint();
                }}>
                <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
              </TouchableOpacity>
            </View>}
            {successMesure != "" && 
              <View style={{borderWidth: 0.5, borderRadius: 5}}>
                <View style={{margin: 10, alignSelf:"center", alignItems:"center"}}>
                  <Text style={{color:"#f54284",fontSize: 16}}>
                    {successMesure} 
                  </Text>
                  <TouchableOpacity
                        style={[styles.access_point_button,{width: 70, backgroundColor: "#00d5f7"}]}
                        onPress={() => {
                          Resetsocket();
                        }}>
                      <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                  </TouchableOpacity>
                </View>
              </View>} 
              {sendingCredential != "" && 
              <View style={{borderWidth: 0.5, borderRadius: 5}}>
                <View style={{margin: 10, alignSelf:"center", alignItems:"center"}}>
                  <Text style={{color:"#f54284",fontSize: 16}}>
                    {sendingCredential} 
                  </Text>
                  <TouchableOpacity
                        style={[styles.access_point_button,{width: 70, backgroundColor: "#00d5f7"}]}
                        onPress={() => {
                          setSendingCredential("")
                        }}>
                      <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                  </TouchableOpacity>
                </View>
              </View>} 
            {(showConnectButton && !showForm && !launchSubmit) && <Center mt={16}>
              <TouchableOpacity
                disabled={disabledButtonStop}
                style = {[styles.button,{opacity: opacityStop}]}
                onPress={() => {
                  setDisabledButtonStop(true); 
                  setOpacityStop(0.4);
                  socketConnection();
                }}
                >
                <Text style={{alignSelf: "center", fontSize: isTablet? 18 : 15}}>{translate('continue')}</Text>
              </TouchableOpacity>
            </Center>}
            {showForm && <VStack space={3} mt="5">
                <FormControl>
                <FormControl.Label
                    _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}>
                    <Text>{translate('network_name')}</Text>
                </FormControl.Label>
                <Input
                  style={{width : Math.min(width*0.70, 500)}}
                  value={ssidName}
                  onChangeText={(text) => setSsidName(text)}
                  //onChangeText={(text) => setSsidName(textWithoutSpace(text))}
                />
                {errorSsidName&&<Text style={styles.errorMsg}>{errorSsidName}</Text>}
                </FormControl>
                <FormControl>
                <FormControl.Label
                    _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}>
                    <Text>{translate('password')}</Text>
                </FormControl.Label>
                <View style={{flexDirection:'row', alignItems: 'center'}}>
                <Input
                  style={{width : Math.min(width*0.70, 600)}}
                      type={showPassword}
                      value={password}
                      onChangeText={(text) => setPassword(text.trim())}
                      //onChangeText={(text) => setPassword(textWithoutSpace(text))}

                />
                  <TouchableOpacity 
                    style={{alignSelf:"center"}}
                    //style={{alignSelf:"auto"}}
                    onPress={() => setShowPassword((showPassword === '') ? 'password' : '')
                    }>
                    <Image 
                      style={{width: 30, height:16, marginLeft: 10}}
                      source={ spyPassword }
                      alt="PasswordSpy"/>
                  </TouchableOpacity>
                </View>
                {errorPassword && <Text style={styles.errorMsg}>{errorPassword}</Text>}
                </FormControl>
                <Button mt="2" colorScheme="indigo" _text={{ color: 'white' }} onPress={() => onSubmit()}>
                <Text style={{fontSize: isTablet? 18 : 15}}>{translate('submit_config')}</Text>
                </Button>
              </VStack>}
            </Box>}
            {displayLoading()}
        </Box>
      </ScrollView>       
    </NativeBaseProvider>
  );
}

export default DeviceConfig;

const styles = StyleSheet.create({
  errorMsg:{
    fontSize: 12,
    width: '100%', 
    color: 'red',
    padding: 0,
    margin: 0,
  },
  picker_component:{
    flex:1,
    borderRadius: 4,
    width: 150,
    marginRight: 30,
    justifyContent:'flex-end'
  },
  picker_container:{
    width: "100%",
    alignItems: "flex-end",
    height: 30,
  },
  button:{
    width:170, 
    height:35, 
    backgroundColor:"#00d5f7", 
    borderRadius:90, 
    justifyContent:"center"
  },
  question_center_calibration: {
    alignItems: 'center',
    padding:0,
    margin:0,
    width: "98%",
  },
  access_point_button:{
    marginTop: 15,
    width: 70,
    height: 40,
    borderRadius: 30,
    backgroundColor:"#00d5f7",
    justifyContent: "center",
    alignItems: "center"
  },
  heading_style:{
    color:"#00d5f7",
    fontSize:14
  },

})
  