// This component is used to render the navigation adapted on balance pages.
// It takes the place of the classical return button native of React Navigation.

import React, {useEffect, useState } from 'react';
import { StyleSheet, View,ToastAndroid, Text, NativeModules, Alert, Platform, TouchableOpacity, useWindowDimensions} from 'react-native';

// intl components
import 'intl';
import { I18nProvider, LOCALES } from '../i18n';
import translate from '../i18n/translate';

// Native Module
const { CollectData } = NativeModules;

const NavigationBalanceConstraint = ({navigation, route}) => {

    const { height, width } = useWindowDimensions();

    // If the back button is pressed while on the SessionResults screen, the following is executed
    const goBackFromSessionResults = () => {
        console.log("Navigation attemptend from Balance Screen...");
        // Since this component is not linked to the screen
        ToastAndroid.showWithGravity(
            "Cliquez sur la flèche (<) retour dans le coin gauche vers le haut !",
            ToastAndroid.LONG,
            ToastAndroid.CENTER
          );
        
    }

    return(
        Platform.OS === 'android' && <TouchableOpacity onPress={()=>goBackFromSessionResults()}>
            <Text style={styles.whiteText}></Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    whiteText:{
        color: "white",
        fontSize: 24,
        paddingRight:24,
        fontWeight:"bold"
    },
});

export default NavigationBalanceConstraint;