import {LOCALES} from '../locales';

export default {
    [LOCALES.FRENCH]: {
        'welcome'                               :'Bienvenue !',
        'connect_button'                        :'Se connecter',
        'connexion_message'                     :'Connectez-vous pour continuer',
        'email'                                 :'Adresse e-mail',
        'password'                              :'Mot de passe',
        'forgot_password'                       :'Mot de passe oublié ?',
        'fist_name'                             :"Prénom",
        'last_name'                             :"Nom",
        'not_account'                           :"Je n'ai pas de compte.",
        'account'                               :"Créer un compte",
        'registry'                              :"Patients enregistrés",
        'last_session'                          :"Dernières sessions",
        'patient_details'                       :"Détails du patient",
        'id'                                    :'ID',
        'age'                                   :'Age',
        'sex'                                   :'Sexe',
        'size'                                  :'Taille',
        'weight'                                :'Poids',
        'diagnostic'                            :'Diagnostic',
        'body_region'                           :'Région corporelle',
        'evolution_functional_level'            :'Évolution du niveau fonctionnel',
        'start_balance'                         :'Démarrer un bilan',
        'start_measurement'                     :'Démarrer',
        'stop_measurement'                      :'Arrêter',
        'next_step'                             :'Suivant',
        'continue'                              :'Continuer',
        'network_name'                          :'Nom du réseau de la box',
        'comment'                               :'Commentaire',
        'submit_button'                         :'Valider',
        'submit_config'                         :'Envoyer',
        'Walk_balance'                          :'Marche',
        'run_balance'                           :'Course',
        'slow_Walk_balance'                     :'Marche lente',
        'quick_Walk_balance'                    :'Marche rapide',
        'slow_run_balance'                      :'Course lente',
        'quick_run_balance'                     :'Course rapide',
        'country_option'                        :'fr-FR',
        'new_balance'                           :'Nouveau bilan',
        'functional_level_evolution_activities' :'Comment évalue-t-il son niveau fonctionnel pendant ses activités quotidiennes ?',
        'course_lenght'                         :'Distance parcourue',
        'estimated_speed'                       :'Vitesse estimée',
        'cadence'                               :'Cadence',
        'damping_symetry'                       :'Symétrie des amortis',
        'impact_symetry'                        :'Symétrie des impacts',
        'step_symetry'                          :'Symétrie des pas',
        'pelvic_symetry'                        :'Symétrie du bassin',
        'hip_symetry'                           :'Symétrie des hanches',
        'knee_symetry'                          :'Symétrie des genoux',
        'steps_label'                           :'pas/min',
        'spatio_temporal'                       :'Spatio-temporels',
        'kinetic'                               :'Cinétiques',
        'pelvis'                                :'Bassin',
        'hips'                                  :'Hanches',
        'knees'                                 :'Genoux',
        'global'                                :'Globale',
        'support_phase'                         :"Phase d'appui",
        'oscillating_phase'                     :'Phase oscillante',
        'tilt'                                  :'Inclinaison',
        'detail'                                :'Détail',
        'version'                               :'Version',
        'right'                                 :'droite',
        'left'                                  :'gauche',
        'at_right'                              :'à droite',
        'at_left'                               :'à gauche',
        'stride_time'                           :'Durée de la foulée',
        'stride_length'                         :'Longueur de la foulée',
        'impact_force'                          :"Force d'impact au sol",
        'loading_rate'                          :' Amorti',
        'new_patient'                           :'Nouveau patient',
        'input_diagrostic'                      :"Diagnostic d'entrée",
        'add'                                   :"Ajouter",
        'go_Back'                               :"Retour",
        'ok'                                    :"OK",
        'cancel'                                :"Annuler",
        'settings'                              :"Paramètres",
        'app_version'                           :"Version 1.44.1",
        'web_site'                              :"Site web",
        'update_patient'                        :"Modifier les données du patient",
        'validate'                              :"Valider",
        'create_acount'                         :"Créer votre compte pour continuer !",
        'confirm_password'                      :"Confirmer le mot de passe",
        'err_email'                             :"Veuillez bien renseigner votre email.",
        'err_password'                          :"Le mot de passe doit avoir au moins 8 caractères, au moins une lettre majuscule et au moins un chiffre.",
        'err_confirm_password'                  :"Veuillez bien renseigner le même mot de passe.",
        'err_first_name'                        :"Veuillez renseigner votre prénom.",
        'err_last_name'                         :"Veuillez renseigner votre nom.",
        'fill_current_field'                    :"veuillez remplir ce champ.",
        'calibration_information'               :"Placez les capteurs sur votre patient.",
        'patient_position'                      :"Placez le debout, jambes tendues.",
        'sucess_text'                           :"Les informations sont validées avec succès.",
        'session_calibration'                   :"Voulez-vous réaliser une calibration ou faire un exercice ? ",
        'config_not_found'                      :"Absence de configuration du Master, voulez-vous configurer un Master ?",
        'expiration_count'                      :"Votre session s'expire dans 10 min, vous pouvez relancer en naviguant d'un écran à l'autre.",
        'xsens_calibration'                     :"Calibration",
        'calibration_name'                      :"Calibrer",
        'session_start'                         :"Démarrer une session",
        'session_start_message'                 :"Démarrez votre session",
        'session_calibration_message'           :"Démarrez votre calibration",
        'start_new_config'                      :"Démarrer une nouvelle configuration",
        'start_new_calibration'                 :"Démarrer une nouvelle calibration",
        'warning_mesure_message'                :"LpasG et StrideLength ne peuvent pas être traités / traitement de VLRD impossible.",
        'ok_message'                            :"D'accord !",
        'error_mesure_message'                  :"Une erreur est survenue lors de la mesure. Veuillez recalibrer s'il vous plaît !",
        'time_message'                          :"Veuillez patienter quelques instants ...",
        'sensors_offline_message'               :"Connexion impossible pour les capteurs, veuillez les vérifier.",
        'error_master_message'                  :"ATTENTION | Une erreur est survenue au niveau du Master.\n\nLog des Erreurs : \n",
        'error_master_notImus'                  :"ATTENTION | Une erreur spontannée est survenue au niveau du Master.",
        'message_measurement'                   :"Une mesure est en cours d'exécution ...",
        'step_measurement_message'              :"Patientez quelques instants pour le transfert des données.",
        'offline_master_error'                  :"ATTENTION | Une erreur est survenue au niveau du Master : déconnexion du master.",
        'failed_measurement'                    :"Échec lors de la mesure, veuillez reprendre l'expérience.",
        'failed_calibration_message'            :"Une erreur est survenue lors de la calibration. Veuillez recalibrer s'il vous plaît !",
        'success_calibration_message'           :"La calibration s'est faite avec succès, vous pouvez faire une mesure.",
        'take_again_measurement'                :"Une erreur est survenue lors de l'exercice. Veuillez refaire l'exercice s'il vous plaît !",
        'take_again_calibration'                :"Une erreur est survenue lors de la mesure. Veuillez recalibrer s'il vous plaît ! ",
        'sensors_connection_message'            :"Connexion en cours...",
        'restart_master_device'                 :"Le Master va redémarrer.",
        'master_battery_level'                  :"Niveau de la batterie du master",
        'iMUs_battery_level'                    :"Niveau de batterie",
        'bad_master_connection'                 :"Connexion impossible, Veuillez réessayer s'il vous plaît !",
        'error_master_connection'               :"Connexion impossible, Veuillez vérifier l'état du master !",
        'error_config_connection'               :"Connexion impossible, Veuillez vérifier la présence du point d'accès du master !",
        'master_configuration_message'          :"Veuillez configurer votre master",
        'network_store_ssid1_message'           :"Votre réseau de configuration est : ",
        'network_store_ssid2_message'           :".\n Veuillez vous reconnecter dessus.",
        'mysmartmove_network_message'           :"Afin de réaliser votre configuration, veuillez vous connecter sur le point d'accès WiFi MYSMARTMOVE.",
        'device_access_location'                :"Pour cette étape, vous devez également activer la position de l'appareil.",
        'check_musmartmove_network'             :"Si le point d'accès MYSMARTMOVE n'existe pas, alors clickez sur OK pour l'initialiser :",
        'ssid_connection_message'               :"Vous êtes maintenant connecté à",
        'ssid_connection_second_message'        :"Vous êtes connecté à",
        'not_connection_message'                :"Pas de connexion, veuillez patienter quelques instants",
        'failed_location'                       :"Autorisation de la localisation refusée",
        'success_config_message'                :"La configuration s'est faite avec succès. \nVous allez être redirigé dans quelques instants...",
        'failed_config_message'                 :"Une erreur est survenue lors de la configuration.",
        'failed_config_reset'                   :"Une erreur est survenue lors de la configuration, veuillez re-initialiser le master.",
        'failed_socket_connection'              :"Impossible de se connecter, veuillez re-initialiser le master",
        'connection_status_message'             :"Etat de connexion",
        'evolution_functional_level_message'    :"\t\t Les précédentes sessions sont affichées sur ce graphique, en fonction de leur niveau fonctionnel (leur qualité).\n\n \t\t Vous pouvez cliquer sur une des sessions pour sélectionner celle-ci comme session de référence. Elle sera alors utilisée comme référence dans l'affichage des résultats, disponible en cliquant sur une des sessions de la liste des 'Dernières Sessions'.",
        'session_message'                       :"Session :",
        'reference_session_message'             :"Session de référence :",
        'functional_level_choice'               :"Veuillez choisir un niveau fonctionnel.",
        'disconnect_box_message'                :"Vous n'êtes plus connecté sur le réseau.",
        'connection_and_battery_status'         :"Etat de connexion :\n Niveau de batterie :",
        'calibration_measure_xsens'             :"Veuillez faire tourner les capteurs dans toutes les directions pendant au moins une minute.",
        'time_left_calibration'                 :"Temps restant : ",
        'manually_master_reset'                 :"Veuillez réinitialiser le Master manuellement.",
        'display_xsens_status'                  :"Affichage de l'état des capteurs.",
        'display_xsens_order_message'           :"Les capteurs sont affichés dans l'ordre, de 0 à 4. Vous pouvez ainsi déterminer l'origine d'un problème de connexion qui concerne un ou plusieurs capteurs.",
        'calibration_button_message'            :"Alignement du patient",
        'session_number'                        :"Séance n°",
        'credentials_login'                     :"Désolé, les identifiants que vous avez renseignés ne sont pas corrects.",
        'credentials_connection'                :"Désolé, impossible pour le master de se connecter avec les identifiants que vous avez renseignés. Veuillez réessayer dans quelques instants, s'il vous plaît !",
        'new_comment'                           :"Nouveau commentaire",
        'edit_comment'                          :"Modifier le commentaire",
        'input_comment'                         :"Entrer votre commentaire",
        'input_pdf_comment'                     :"Souhaitez-vous ajouter un commentaire ?",
        'recommendation_message'                :"Message de recommandation :",
        'recalibration_message'                 :"Souhaitez-vous recalibrer ? ",
        'reactivate_master_communication'       :"Réactiver la communication avec le master",
        'add_pathologie'                        :"Ajouter une pathologie",
        'no_WiFi_connexion'                     :"Vous n'êtes connecté à aucun réseau WiFi",
        'format_DatFile_KO'                     :"Le format des données est incorrect",
        'xsens_charge'                          :"Veuillez recharger vos capteurs",
        'medium_wifi_network_speed'             :"Compte tenu de la qualité de votre wifi, le traitement de données peut être plus long que prévu.",
        'bad_wifi_network_speed'                :"Compte tenu de votre débit wifi, le traitement de données peut être assez long que prévu. Votre débit est de ",
        'wifi_network_frequency'                :"Votre Wifi fonctionne actuellement à 5 GHz. Pour un échange de données avec le master, configurer le à 2,4 GHz. Merci.",
        'remember_me'                           :"Se souvenir de moi.",
        'acc_peak'                              :"Force d'impact",
        'params_behavior'                       :"Evolution des paramètres de :",
        'session_date'                          :"Séance du",
        'spatio_and_kinetic'                    :"Spatio-temporels et cinétiques",
        'choose_icon'                           :"Cliquez sur l'icône →",
        'pelvis_information'                    :"Inclinaison du bassin :\n Amplitudes angulaires maximales d'inclinaison latérale du bassin vers le côté gauche (en négatif) et droit (en positif) en vue de derrière lors du mouvement, dans le plan frontal (plan vertical qui passe par la ligne médiane du corps et le divise en deux parties ventrale et dorsale).\n\nVersion du bassin :\n Amplitudes angulaires maximales de rétroversion (en négatif) et d'antéversion (en positif) du bassin, dans le plan sagittal (plan vertical qui passe par la ligne médiane du corps et le divise en deux parties droite et gauche).",
        'hip_information'                       :"Abd./Add.(abduction/adduction) :\n Amplitudes angulaires maximales d'adduction (en positif) et d'abduction (en négatif) des hanches droite (en bleu) et gauche (en orange) lors du mouvement (plan frontal).\n\n Ext./Flex.(Extension/Flexion) :\n Amplitudes angulaires maximales de flexion (en positif) et d'extension(en négatif) des hanches droite (en bleu) et gauche (en orange) lors du mouvement (plan sagittal).\n\n Rot.ext./int.(Rotation) :\n Amplitudes angulaires maximales de rotation interne (en positif) et externe (en négatif) des hanches droite (en bleu) et gauche (en orange) lors du mouvement (plan transverse).",
        'knee_information'                      :"Ext./Flex.(Extension/Flexion) :\n Amplitudes angulaires maximales de flexion (en positif) et d'extension (en négatif) des genoux droit (en bleu) et gauche (en orange) lors du mouvement (plan frontal).\n\n Rot.ext./int.(Rotation) :\n Amplitudes angulaires maximales de rotation interne (en positif) et externe (en négatif) des genoux droit (en bleu) et gauche (en orange) lors du mouvement (plan transverse).",
        'spatio_temporal_information'           :"  Durée de la foulée : durée d'une enjambée, entre deux poses successives du pied droit au sol (somme des durées des pas droit et gauche).\n\n Longueur de la foulée : longueur d'une enjambée, entre deux poses successives du pied droit au sol (somme des longueurs des pas droit et gauche).\n\n Les valeurs sur les histogrammes représentent les proportions de la durée du pas gauche par rapport à la durée de la foulée et la durée de pas droit par rapport à la durée de la foulée; ainsi que les proportions de la longueur du pas gauche par rapport à la longueur de la foulée et la longueur de pas droit par rapport à la longueur de la foulée.",
        'kinetic_information'                   :"  Force d'impact au sol : estimation, sur la base de l'accélération des tibias, des forces d'impacts maximales au sol au moment des poses du pied à droite et gauche exprimée en Newton (accélération x poids du sujet).\n\n Amorti : estimation, sur la base de l'accélération des tibias, de la capacité d'amorti du sujet (aussi appelé vertical loading rate), à droite et à gauche. Il s'agit de la mesure de la pente de la courbe de force lors de l'impact au sol, par division de la force d'impact maximale par le temps entre le début de l'impact et ce maximum, exprimée en « body weight » par seconde (nombre de fois le poids du sujet appliqué au sol par seconde).",
        'cadence_information'                   :" Cadence : nombre de pas effectués par minute.\n\n",
        'damping_symetry_information'           :"Symétrie des amortis : cette grandeur reflète la symétrie des amortis à droite et à gauche.\n\n",
        'impact_symetry_information'            :"Symétrie des impacts : cette grandeur reflète la symétrie entre les impacts à droite et à gauche.\n\n",
        'step_symetry_information'              :" Symétrie des pas : cette grandeur reflète la symétrie entre les pas à droite et à gauche en terme à la fois de durée et de longueur.\n\n",
        'pelvic_symetry_information'            :"  Symétrie du bassin : cette grandeur reflète la symétrie des mouvements du bassin lors de la course ou la marche.\n\n",
        'hip_symetry_information'               :"Symétrie des hanches : cette grandeur reflète la symétrie des mouvements entre les hanches droite et gauche lors d'une course ou marche.\n\n",
        'knee_symetry_information'              :" Symétrie des genoux : cette grandeur reflète la symétrie des mouvements entre les genou droit et gauche lors d'une course ou marche.\n\n",
        'percentage_arrows_information'         :"Un pourcentage de 100 % indique une symétrie parfaite, alors qu'un pourcentage de 0% indique une asymétrie totale.\n\nLes flèches caractérisent la comparaison de la grandeur d'une séance par rapport à la séance cible précédente choisie comme référence.",
    }
}