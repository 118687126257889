import React, {useDebugValue, useEffect, useState} from 'react';
import { NativeBaseProvider, Box, Text, Button, Icon, Center, VStack, Heading, Image, ScrollView } from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';

// import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from 'react-native-simple-radio-button';
import { RadioButton } from 'react-native-paper';

// utils functions
import { get }       from '../src/fetch.js';
import { sendToDataProcessing } from '../src/fetch.js';
import { parseError, handleErrors } from '../src/masterErrorManagement.js';
import NetInfo from "@react-native-community/netinfo";

// intl components
import 'intl';
import translate from '../i18n/translate';
import { I18nProvider, LOCALES } from '../i18n';
import { View, StyleSheet, TextInput, ActivityIndicator, useWindowDimensions, TouchableOpacity } from 'react-native';
import {Picker} from '@react-native-picker/picker';

// Import the native module
import { Platform, NativeModules, Alert } from 'react-native';
const { CollectData } = NativeModules;
var RNFS = Platform.OS === 'android' || Platform.OS === 'ios' ? require('react-native-fs'): null;

const DeviceMesure = ({ route, navigation }) => {


    const { activity, sessionList, access_token, runner, execCreateFile, xsensStatusSaved, displayDataprocessValue, choiceActivity, label} = route.params;

    // console.groupCollapsed('RetrieveSession.constructor::')
    // console.log(`RetrieveSession.constructor:: got activity ${activity}`);
    // console.groupEnd()

    const [numberOfIMUs, setNumberOfIMUs] = useState(5);
    const [imuInformation, setImuInformation] = useState(false);
    const [displayConnectingState, setDisplayConnectingState] = useState(false);
    const [results, setResults] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [recalibrationActivity, setRecalibrationActivity] = useState(false);
    const [checkForm, setCheckForm] = useState(false);
    const [sendButton, setSendButton] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingStop, setLoadingStop] = useState(false);
    const [checked, setChecked] = useState('');
    const [disabledButton, setDisabledButton] = useState(false);
    const [disabledButtonStop, setDisabledButtonStop] = useState(false);
    const [opacitybuttonImport, setOpacitybuttonImport] = useState(1);
    const [opacityStop, setOpacityStop] = useState(1); 
    const [displayMesureMessage, setDisplayMesureMessage] = useState('');
    const [displayHeader, setDisplayHeader] = useState(false);
    const [xsensStatus, setXsensStatus] = useState(["0000000", "00000000000000"]);
    const [text, onChangeText] = useState("");
    const [startQat, setStartQat] = useState(true);
    const [errorMesureCalibration, setErrorMesureCalibration] = useState("");
    const [errorMesure, setErrorMesure] = useState("");
    const [successMesure, setSuccessMesure] = useState("");
    const [offline, setOffline] = useState('');
    const [sessionsListResponse, setSessionsListResponse] = useState([]);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("Français");
    const options =[{id: 1, language:"Français"}, {id:2, language:"Anglais"}];

    // Used for Master Error Management
    const [isMeasuring, setIsMeasuring] = useState(false);
    const [capitalErrors, setCapitalErrors] = useState("");
    const [pingMasterErrors, setPingMasterErrors] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [count, setCount] = useState(0);
    const [xsensConnectionError, setXsensConnectionError] = useState("");

    const { height, width } = useWindowDimensions();
    const largeBreakpoint = 992;
    const midBreakpoint = 688;
    const isTablet = (width >= largeBreakpoint)||(height >= largeBreakpoint);
    const isWideEnough = width >= midBreakpoint;
    let isInternetReachable = false;

    useEffect(() => {
      const unsubscribeHandler = NetInfo.addEventListener(state => {
        if(state.isInternetReachable === false){
          isInternetReachable = true;
        }
        if(state.details.ssid != undefined){
          setOffline("");
          if(state.details.ssid.substring(0, 11) === "MYSMARTMOVE"){
            CollectData.CloseAll()
            .then((res)=>{
              if(res === "Success"){
                setOffline(translate('disconnect_box_message'));
                console.log("The socket closed successfully");
              }
            })
            .catch((res)=>{
              if(res === "BAD CLOSE"){
                console.log("socket close failed");
              }
            }); 
      
          }else{    
            if(isInternetReachable && state.isInternetReachable === true){
              navigation.navigate('Runner', {access_token, runner });
            } 
          } 
        }else{
          CollectData.CloseAll()
          .then((res)=>{
            if(res === "Success"){
              setOffline(translate('disconnect_box_message'));
              console.log("The socket closed successfully");
            }
          })
          .catch((res)=>{
            if(res === "BAD CLOSE"){
              console.log("socket close failed");
            }
          });
          
        }    
      });
      //unsubscribe Handle 
      unsubscribeHandler();
      if(execCreateFile === "run"){   // Calibration only

        const countDown = setInterval(() => {setCount(count+1)}, 1000);
        if(isMeasuring){
          if(count === 60){
            clearInterval(countDown);
            StopMesure();
          }
        }

        if(!isMeasuring)
        {
            //console.log("DeviceMesure/setInterval/!isMeasuring");
            clearInterval(countDown)
        }
        
        //console.log("DeviceMesure/setInterval/isMeasuring", "count = " + count);

        return () => {
          clearInterval(countDown);
        }
      }  
      
    }, [isMeasuring, count, loadingStop])

  useEffect(() => {
    let mounted = true;
    const intervalGetError = setInterval(asyncGetError, 5000);
    if(label === "Quaternions") {
      if(mounted){
        console.log("commande K:: :: ")
        QuaternionsStartMesure();
        mounted = false;
        }
      }
    if(!isMeasuring) clearInterval(intervalGetError);

    return () => {
      clearInterval(intervalGetError);
      setCapitalErrors("");
    };
  }, [isMeasuring, capitalErrors]);

  useEffect(() => {
    const route = `/api/runners/${runner.public_id}/sessions`;
    get(route, access_token)
    .then(({json}) => setSessionsListResponse(json))
    .catch( err => console.error(err));
    let running = execCreateFile;
    if(running === "run"){
      setDisplayConnectingState(true);
      setDisplayHeader(true);
      setDisplayMesureMessage(translate('time_message'));
      init_file_and_sensors_connected();
    }else{
      setXsensStatus(xsensStatusSaved);
      setDisplayConnectingState(false);
      setLoadingForm(true);
    }
  }, [])

    const init_file_and_sensors_connected = ()=>{
      console.log(`RetrieveSession.render:: calling Initialisation`);
    var counter = 0;
    var numberoftime = 0;
    var numberOfcount = 0;
    var setInter = setInterval(() => {
      numberOfcount++;
    }, 1000);
    var infoSystems  = setInterval(()=>{
      CollectData.DeviceInfo().then(response=>{
        numberoftime ++;
        console.log(`DeviceMesure.render sensors Xsens cal :: ${response} :: n° : ${numberoftime}`);
        setXsensStatus(response);
        if(statusIsConnected(response)){
          clearInterval(infoSystems);
          clearInterval(setInter);
          counter ++;
          CollectData.Initialisation().then(data=>{
            if(data){
              setDisplayMesureMessage('');
              setLoadingForm(true);
              setDisplayConnectingState(false);
            }
            }).catch(err => {
              ToastAndroid.show(
                `Une erreur est survenue lors de la mesure. Veuillez refaire l'exercice s'il vous plaît !`,
                ToastAndroid.LONG
              );
              CollectData.CloseAll()
              .then(res => {
                if(res === "Success"){
                  console.log("Socket has been successfully closed.");
                  navigation.navigate('Runner', {access_token, runner });
                }else{
                  console.log("An error occured while trying to close socket.", res);
                }
              })
              .catch(error => console.error(error));
            });
        }  

      }).catch(err => console.error(err))
      if(counter === 1 || numberoftime === 20 || numberOfcount ===20){
        clearInterval(infoSystems);
        clearInterval(setInter);
        if(numberoftime === 20 || numberOfcount ===20){
          setDisplayConnectingState(false);
          setXsensConnectionError(translate('sensors_offline_message'));
        }
      }
    },2000 ); 
    }

    const asyncGetError = () => {
      console.log("ERROR detection initiated");
      CollectData.ErrorMessage().then(response => {
        if(response != "NO ERROR"){
          const messages = response.split('\n').filter(function(item) {return item.length === 9});
          messages.forEach(msg => {
              console.log(parseError(msg));
            });
          const errs = handleErrors(messages);
          if (errs === ""){
            console.log("No error, Master is fine.");
          }else{
            console.log("Master Error:: ::" + errs);
            CollectData.GetAcknowledgePingMaster().then((pinResponse)=>{
              console.log("The ping response Master is :: :: " + pinResponse);
              if(pinResponse === 1){
                setDisplayMesureMessage('');
                setErrorMessage(errs);
                setCapitalErrors(translate('error_master_message'));
              }else if(pinResponse === 0){
                console.log("The ping response Master is :: :: " + pinResponse);
                setPingMasterErrors(translate('error_master_notImus'));
              }
            }).catch((err)=>console.log(err));
          }
        }else{
          console.log("No error, Master is fine.");
        }     
      }).catch(error => console.error(error));
    }

    const StartMesure = () =>{
      setIsMeasuring(true);
      if(execCreateFile != "run"){
        setLoadingStop(true);
      }
      CollectData.Start()
      .then(data => {          
        if(data === "OK"){
          console.log(`RetrieveSession.render:: start ${data}`);
          if(execCreateFile === "run"){
            console.log(`RetrieveSession.render:: start `);
            setLoadingForm(false);
            setDisplayMesureMessage(translate('message_measurement'));
          }else{
            console.log(`RetrieveSession.render:: start ......`);
            setLoadingStop(true);
            setLoadingForm(false);
            setDisplayMesureMessage(translate('message_measurement'));
          }
        }
      })
      .catch(err => console.error);
    }

    const QuaternionsStartMesure = () =>{
      if(startQat){
        setStartQat(false);
        setIsMeasuring(true);
        // if(execCreateFile != "run"){
        //   setLoadingStop(true);
        // }
        CollectData.StartQuaternions()
        .then(data => {          
          if(data === "OK"){
            console.log(`RetrieveSession.render:: startQuaternions ${data}`);
            setLoadingStop(true);
            setLoadingForm(false);
            setDisplayMesureMessage(translate('message_measurement'));
            
          }
        })
        .catch(err => console.error);
      }
    }

    const StopMesure = ()=>{
      setDisplayMesureMessage('');
      setIsMeasuring(false);
      CollectData.Stop()
        .then(data => { 
          if(data === "OK"){
            setDisplayMesureMessage(translate('step_measurement_message'));
            const inter = setInterval(()=>{
              clearInterval(inter);
              AvailableSessionsMesure();
              setLoadingStop(false);
            }, 2000)
          }else{
            return;
          }
          
        })
        .catch(error => {
          console.error(error);
          return;
        });
    }

    const AvailableSessionsMesure = ()=>{
      CollectData.AvailableSessions()
      .then(data => {
        setResults(data);
        importation(data.toString());    
    })
    .catch(error => console.error(error));
    }

    const displayLoadingIndicator = ()=>{
      if(isLoading){
        return(
         <View style={styles.isLoading_container}>
           <ActivityIndicator size='large'color="#000000"/>
         </View> 
        )
      }
    }

    const importation = (lastDateFile)=>{
      setIsLoading(true)
      CollectData.ImportDatFiles(0, lastDateFile).then((data)=>{ 
        console.log("data en fin:: :: ",data );
        if(data === "1"){
          setDisplayMesureMessage('');
          setIsLoading(false);
          if(execCreateFile === "run"){
            console.log("commande calibration:: :: ")
            setDisplayMesureMessage(translate('time_message'));
            setCheckForm(false);
            const inter = setInterval(()=>{
              clearInterval(inter);
              sendDataProcessing(lastDateFile);
              console.log(`Run dataprocessing`);
            }, 2000)
          }else if(label === "Quaternions"){
            navigation.navigate('UnitySession', {runner, access_token });
          }else{
            setCheckForm(true);
            setSendButton(true);
          }
        }else if(data === "chunksTimeOut"){
          setPingMasterErrors(translate('offline_master_error'));
        }else if(data === "formatDatFileKO"){
          setPingMasterErrors(translate('format_DatFile_KO'));
         console.error(error, ".dat file format is wrong");
        }else{
          setPingMasterErrors(translate('offline_master_error'));
        }
      }).catch(error => {
        setPingMasterErrors(translate('offline_master_error'));
        console.error(error, "error data"); 
      });
    }

    const storeCalibrationState = async (value) => {
      try {
        await AsyncStorage.setItem('displayCalibrationButton', value)
      } catch (error) {
        console.error(error);
      }
    }

    const getDataprocessValueForRecalibration = async (value) => {
      try {
        await AsyncStorage.setItem('dataprocessRecalibration', value)
      } catch (error) {
        console.error(error);
      }
    }
    const getRecalibrationActivity = async () => {
      try {
        const value = await AsyncStorage.getItem('recalibrationActivity')
        if(value !== null) {
          setRecalibrationActivity(value);
        }
      } catch(error) {
        console.error(error)
      }
    }
    getRecalibrationActivity()

    const sendDataProcessing = (lastDateFile)=>{
      setDisplayMesureMessage(translate('time_message'));
      let filteredWalkArray = (sessionsListResponse || []).filter( elt => elt.activity === "walk");
      let filteredRunArray = (sessionsListResponse || []).filter( elt => elt.activity === "run");
      
      const isref = activity === "walk" ? (filteredWalkArray.reduce(
        (previousValue, currentValue) => (previousValue > currentValue.isref) ? previousValue : currentValue.isref,
        0) + 1) : (filteredRunArray.reduce(
          (previousValue, currentValue) => (previousValue > currentValue.isref) ? previousValue : currentValue.isref,
          0) + 1);

          RNFS.readDir(RNFS.DocumentDirectoryPath) // On Android, use "RNFS.DocumentDirectoryPath" (MainBundlePath is not defined)
          .then((result) => {
          // stat the first file
          
          let formData  = new FormData();
          const resultFile = result.find(element => element.name === lastDateFile);
          let calibrationFlag = execCreateFile === "run"? "1" : "0";
          if(resultFile){
            if(resultFile.size === 0){
              setErrorMesure(translate('failed_measurement'));
            }
            console.log("Received Object::: ",resultFile);
            formData.append('file', {
              name: resultFile.name,
              type: 'application/octet-stream',
              uri: resultFile.path.replace('','file://'),
            });
            formData.append('activity', activity);
            formData.append('choiceActivity', choiceActivity === undefined ? recalibrationActivity : choiceActivity);
            formData.append('isref', isref);
            formData.append('weight', runner.weight);
            formData.append('size', runner.size);
            formData.append('comment', text);
            formData.append('feeling', checked);
            formData.append('calibrationFlag', calibrationFlag);
            console.log("Array value:: ", results," Activity:: ", activity," choiceActivity:: ", choiceActivity," Weight:: ", runner.weight, " ckeck value:: ", checked, " last .Dat file :: ", lastDateFile," commentaire :: ", text," isref :: ", isref);
            const route = `/api/runners/${runner.public_id}/sessions`;
            if(checked !== '' || execCreateFile === "run"){
              sendToDataProcessing(route, access_token, formData)
              .then(({json}) => {
                if(json){
                  const response = json;
                  setDisplayMesureMessage('');
                  get(route, access_token)
                    .then(({json}) => {
                      const res = json;
                      var chronologicalSessionList = res.sort(function(a,b){
                        return new Date(Date.parse(b.date)) - new Date(Date.parse(a.date));
                      });
          
                      const session = chronologicalSessionList[0];
                      const refSession = (chronologicalSessionList[1] !== undefined) ? chronologicalSessionList[1] : chronologicalSessionList[0];
                      const feelingSession = session === undefined ? "" : session.feeling;
                      
                      const pathTabs = session === undefined ? "" : session.file_path.split('/');
                      const lastPathName = session === undefined ? "" : pathTabs[pathTabs.length -1].split('_');
                      const lastSessionPath = session === undefined ? "" : lastPathName[0];
                      
                      const jsonmes = JSON.stringify(response)
                      const jsonparse = JSON.parse(jsonmes)

                      if(execCreateFile === "run"){
                        console.log("calib:: ::", jsonparse)
                        storeCalibrationState("1");
                        if(jsonparse.errorMesure){
                          setErrorMesureCalibration(translate('failed_calibration_message'));                   
                        }
                        if(jsonparse.error){
                          setErrorMesureCalibration(translate('failed_calibration_message'));
                        }
                        if(jsonparse.success){
                          console.log("calib2:: ::", jsonparse, "calib2Bis:: ::", displayDataprocessValue)
                          //Do dataprocessing again 
                          if(displayDataprocessValue === "dataprocessingAgain"){
                            setDisplayMesureMessage(translate('time_message'));
                            const dataResultFile = result.find(element => element.name === lastSessionPath);
                            let formDataprocessing  = new FormData();
                            formDataprocessing.append('file', {
                              name: dataResultFile.name,
                              type: 'application/octet-stream',
                              uri: dataResultFile.path.replace('','file://'),
                            });
                            formDataprocessing.append('activity', activity);
                            formDataprocessing.append('choiceActivity', choiceActivity === undefined ? recalibrationActivity : choiceActivity);
                            formDataprocessing.append('isref', isref);
                            formDataprocessing.append('weight', runner.weight);
                            formDataprocessing.append('size', runner.size);
                            formDataprocessing.append('comment', text);
                            formDataprocessing.append('feeling', feelingSession);
                            formDataprocessing.append('calibrationFlag', "0");
                            sendToDataProcessing(route, access_token, formDataprocessing)
                            .then(({json}) =>{
                              getDataprocessValueForRecalibration("");
                              // var pathFile = RNFS.DocumentDirectoryPath + '/' + lastDateFile;
                              //   RNFS.unlink(pathFile) // `unlink` will throw an error, if the item to unlink does not exist
                              //   .then(() => {
                              //     console.log('FILE DELETED');
                              //   })
                              //   .catch((err) => {
                              //     console.log(err.message);
                              //   });
                              const dataResponse = json;
                              const jsondata = JSON.stringify(dataResponse);
                              const datajsonparse = JSON.parse(jsondata);
                              if(datajsonparse.errorMesure){
                                setErrorMesure(translate('take_again_measurement'));
                              }else if(datajsonparse.error){
                                let dataWarningArray = datajsonparse.error;
                                let dataerrorResum = dataWarningArray[1] === "E_GENERICERRORMSG"? dataWarningArray[1] : "";
                                if(dataerrorResum === "E_GENERICERRORMSG"){
                                  setErrorMesure(translate('take_again_measurement'));
                                }else{
                                  setErrorMesureCalibration(translate('take_again_calibration'));  
                                }  
                              }else if(datajsonparse.warning !== " "){
                                let warnings = datajsonparse.warning;
                                navigation.navigate("SessionResults", {runner, session, refSession, warnings, activity, chronologicalSessionList, access_token, recalibrationActivity});
                              }else{
                                navigation.navigate('Runner', {access_token, runner });
                              } 
                            })
                            .catch((err) => {
                              console.log(err.message);
                            })

                          }else{
                            setSuccessMesure(translate('success_calibration_message'));
                          }
                          
                        }
                      }else if(calibrationFlag === "0" && jsonparse.errorMesure){ 
                        console.log("data1:: ::", jsonparse)
                        setLoadingSubmit(false);  
                        setErrorMesure(translate('take_again_measurement'));
                      }else if(calibrationFlag === "0" && jsonparse.error){
                        console.log("data2:: ::", jsonparse)
                        let warningArray = jsonparse.error;
                        let errorResum = warningArray[1] === "E_GENERICERRORMSG"? warningArray[1] : "";
                        setLoadingSubmit(false);
                        if(errorResum === "E_GENERICERRORMSG"){
                          console.log("erreur resume")
                          setErrorMesure(translate('take_again_measurement'));
                        }else{
                          setErrorMesureCalibration(translate('take_again_calibration'));  
                        }        
                      }else if(calibrationFlag === "0" && jsonparse.warning !== ""){
                        setLoadingSubmit(false);
                        let warnings = jsonparse.warning;
                        navigation.navigate("SessionResults", {runner, session, refSession, warnings, activity, chronologicalSessionList, access_token, choiceActivity});
                      }else{
                        console.log("data4:: ::", jsonparse)
                        setLoadingSubmit(false);
                        navigation.navigate('Runner', {access_token, runner });
                      } 

                    })
                    .catch( err => console.error(err));
                }  
              })
              .catch( (err) => {
                return;
              });  
            }else{
              setDisplayMesureMessage(translate('functional_level_choice'));
            }
          }
          else
          {
            console.log("/DeviceMesure / sendDataProcessing / No .dat file received");
          }
        })  
    }

    const getKeysforEvaluation = () => {
      var ans = [];
      for (let i = 1; i <= 10; i++) {
          ans.push(i);
      }
      return ans;
    }

    const statusIsConnected = (status) => {
    try{
      //Checking that we are connected to each sensor and that the battery level is not nil
      for(let i = 0; i<numberOfIMUs; i++)
      {
        if(status[0][i] != 1)
        {
            console.log("DeviceMesure / statusIsConnected ==> status[0][i] !=1 / i = %d" ,i)
            return false;
        }
        //if(status[1].slice(i,i+2) == '00')
        console.log("DeviceMesure / statusIsConnected ==> status[1].slice(i,i+2) = %d", parseInt(status[1].slice(i,i+2)))
        if(parseInt(status[1].slice(i,i+2)) <= 10)
        {
          console.log("DeviceMesure / statusIsConnected ==> status[1].slice(i,i+2) <= 10, %d", parseInt(status[1].slice(i,i+2)))
          return false;
        }
      }
      return true;
      } catch(error) {
        console.log("DeviceMesure / statusIsConnected ==> error = %s", error)
      }
    }

    const displayLoadingConnection = ()=>{
      if(!statusIsConnected(xsensStatus) || loadingSubmit){
        return(
         <View>
           <ActivityIndicator size='large'color="#000000"/>
         </View> 
        )
      }
    }

    const displayLoading = ()=>{
      if(loadingSubmit){
        return(
         <View>
           <ActivityIndicator size='large'color="#000000"/>
         </View> 
        )
      }
    }

    const getKeysForSensors = () => {
      var ans = [];
      try
      {
              for (let i = 0; i < numberOfIMUs; i++)
              {
                  ans.push(i);
                  //console.log("DeviceMesure / getKeysForSensors ==> i: %d", i)
              }
          return ans;
      }
      catch(error)
      {
        console.log("DeviceMesure / getKeysForSensors ==> error = %s", error)
      }
    }

    let imuPercentage = "";
    const getBatteryColor = (umiIndex) => {
      let col = '#00d5f7';
      if (xsensStatus[1].substring(umiIndex*2, (umiIndex+1)*2) <= 10){
        col = '#ff0000';
        imuPercentage = "Veuillez recharger vos capteurs";
      }else if(xsensStatus[1].substring(umiIndex*2, (umiIndex+1)*2) <= 50){
        col = '#ffb636';
      }
      return col;
    }

    const displayConnectionState = () => {
        console.log("displayConnectionState")
      return (
        <View flexDirection="row" style={{justifyContent:isWideEnough? "flex-start" : "center"}}>
          {isWideEnough && <Text style = {{fontSize:isTablet?15:12, paddingLeft: 15, paddingRight: 10, paddingTop: 2, lineHeight: 25}}>{translate('connection_and_battery_status')}</Text>}
          {getKeysForSensors().map(item => {
          try{
            return (
              <View key={item} style = {{flexDirection:'column', justifyContent: 'center', paddingRight: isTablet? 10:4}}>
                <RadioButton value = {String(item)}
                    status = {xsensStatus[0].charAt(item) === '1' ? 'checked' : 'unchecked'}
                    color = {getBatteryColor(item)}
                />
                <Text style = {{fontSize:isTablet?18:12, paddingLeft:7}} color= {getBatteryColor(item)}
                  >{xsensStatus[1].substring(item*2, (item+1)*2)}%</Text>
                {getBatteryColor(item) ==='#ff0000' && <Text style = {{fontSize:isTablet?18:12, paddingLeft:7}} color= {getBatteryColor(item)}
                >Rechargez</Text>}
              </View>)
            }
            catch (error)
            {
                     console.error("DeviceMesure / displayConnectionState  ==> error " + error);
                     //navigation.navigate('SessionCalibration')
            }
            })
          }
        </View>
      )
    }
  
    const informationOrderIMU = () => {
      return (<TouchableOpacity style={{marginLeft: "2%", width: isTablet ? 30 : 20, height: isTablet ? 30 : 20, borderRadius: 20, backgroundColor: "#00d5f7", justifyContent: "center", alignSelf: "center"}}
          onPress={() => setImuInformation(true)}>
          <Heading fontSize={isTablet ? 20 : 16} style={{ color: "#fff", alignSelf: "center" }}>i</Heading>
        </TouchableOpacity>
      );
    }
  
    return(
      <NativeBaseProvider flex={1} style={{fontFamily:"sans-serif"}}>
        <ScrollView>
          <View style = {styles.upper_container}>
            {isWideEnough && <View style={{flexDirection: "row", paddingTop: 10}}>
                {informationOrderIMU()}
                {displayConnectionState()}
            </View>}                
            <View style={styles.picker_container}>
              <Picker style={styles.picker_component}
                  selectedValue={selectedLanguage}
                  onValueChange={(itemValue, itemIndex) =>{
                    setSelectedLanguage(itemValue);
                    route.params.translationFunc(selectedLanguage);
                  }}>
                  {options.map(lang=><Picker.Item key={lang.id} label={lang.language} value={lang.language}></Picker.Item>)}
              </Picker>
            </View>
          </View>
          <Box mt="5">
            <Center>
              {!displayHeader ? <Heading mt="40px" color="#00d5f7" fontSize="4xl">
                {translate('new_balance')}
              </Heading> : <Heading mt="40px" color="#00d5f7" fontSize="4xl">
              {translate('xsens_calibration')}
              </Heading>}
              { checkForm && <Text mt="20px" color="#00d5f7" fontSize="xl" textAlign="center">
              {translate('functional_level_evolution_activities')}           
              </Text>}
              { displayMesureMessage && <Text textAlign="center" mt="20px" mb="15px" color="#32a852" fontSize="xl">
              {displayMesureMessage}           
              </Text>}
              { offline !== "" && <Text textAlign="center" mt="20px" mb="15px" color="#32a852" fontSize="xl">
              {offline}           
              </Text>}
            </Center>
    
            <VStack space={5} alignItems="center">
              {displayConnectingState && <View>
                <Text color="#000000">
                  {translate('sensors_connection_message')}
                </Text>
                {displayLoadingConnection()}
              </View>
              }
              {(capitalErrors === "") && loadingForm && !loadingStop && !sendButton && !isMeasuring && label !== "Quaternions" && imuPercentage === "" && <TouchableOpacity
                  style={styles.button}
                  onPress={() => {StartMesure()} }>
                  <Text style={{fontSize: isTablet? 18 : 15}}>{translate('start_measurement')}</Text>
                </TouchableOpacity>}
                
              {(execCreateFile === "run") && (capitalErrors === "") && isMeasuring && !loadingStop && <View style={{width:"80%"}}>
                <Text style={{textAlign:"center"}}>{translate('calibration_measure_xsens')}</Text>
                <Text style={{fontWeight:"bold"}}>{translate('time_left_calibration')}{ String(60 - count) + " sec"}</Text>
                <View style={{height:20}}/>
                <View style={{position:"absolute", bottom:3, height:10, width: "100%", borderRadius: 10, backgroundColor:"#ddd"}}/>
                <View style={{position:"absolute", bottom:3, height:10, width: `${count*5/3}%`, borderRadius: 10, backgroundColor:"#00d5f7"}}/>
              </View>}

              {(capitalErrors === "") && !loadingForm && loadingStop && 
              <TouchableOpacity
              disabled={disabledButtonStop}
                style={{marginTop: 15,
                  width: 150,
                  height: 40,
                  opacity: opacityStop,
                  borderRadius: 30,
                  backgroundColor:"#00d5f7",
                  justifyContent: "center",
                  alignItems: "center"}}
                onPress={() => {setDisabledButtonStop(true); setOpacityStop(0.4); StopMesure()}}
                >
                <Text style={{fontSize: isTablet? 18 : 15}}>{translate('stop_measurement')}</Text>
              </TouchableOpacity>}
              {displayLoadingIndicator()}
              {checkForm && 
              <Center > 
                <Center flexDirection="row" mb="30px">
                    {getKeysforEvaluation().map(item => {
                      return (
                        <View key={item} style={styles.base_check}>
                        <RadioButton
                          value={item.toString()}  
                          status={ checked === item.toString() ? 'checked' : 'unchecked' }
                          onPress={() => setChecked(item.toString())}
                          /><Text style={styles.size_number_check}>{item}</Text>
                      </View>)
                      })
                    }
                </Center>
                { sendButton && 
                <TouchableOpacity
                  disabled={disabledButton}
                  style={{marginTop: 15,
                    width: 150,
                    height: 40,
                    opacity: opacitybuttonImport,
                    borderRadius: 30,
                    backgroundColor:"#00d5f7",
                    justifyContent: "center",
                    alignItems: "center"}}
                  onPress={() => {
                    if(checked !== '' || execCreateFile === "run"){
                      setDisabledButton(true); 
                      setOpacitybuttonImport(0.4);
                      setLoadingSubmit(true);
                    } 
                    sendDataProcessing(results[results.length - 1]);
                  }}
                >
                  <Text style={{fontSize: isTablet? 18 : 15}}>{translate('submit_button')}</Text>
                </TouchableOpacity>}
              </Center>}
              {displayLoading()}
              {(((capitalErrors != "")&&(capitalErrors != translate('offline_master_error')))||(errorMessage!=""))? <View style={{borderWidth: 0.7, borderRadius:7, borderColor: '#00d5f7'}}>
                <View style={{margin: 10, alignSelf:"center", alignItems:"center"}}>
                  <Text>
                    {capitalErrors} {errorMessage != "" && errorMessage}
                  </Text>
                  <TouchableOpacity
                        style={[styles.button,{width: 70, backgroundColor: "#00d5f7"}]}
                        onPress={() => {
                          CollectData.Reset().then(resolve => {
                            console.log(resolve);
                            if(resolve === "OK"){
                              console.log("Master has been successfully reset.");
                            }else{
                              console.log("An error occured while trying to reset the Master.");
                            }
                            navigation.navigate('Runner', {access_token, runner });
                          }).catch(error => console.error(error));
                        }}>
                      <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                  </TouchableOpacity>
                </View>
              </View>
              :(capitalErrors === translate('offline_master_error')) && <View style={{borderWidth: 0.7, borderRadius:7, borderColor: '#00d5f7'}}>
              <View style={{margin: 10, alignSelf:"center", alignItems:"center"}}>
                <Text>
                  {capitalErrors} {errorMessage != "" && errorMessage}
                </Text>
                <Text>
                  {translate('manually_master_reset')}
                </Text>
                <TouchableOpacity
                      style={[styles.button,{width: 70, backgroundColor: "#00d5f7"}]}
                      onPress={() => {
                        CollectData.Reset().then(resolve => {
                          console.log(resolve);
                          if(resolve === "OK"){
                            console.log("Master has been successfully reset.");
                          }else{
                            console.log("An error occured while trying to reset the Master.");
                          }
                          navigation.navigate('Runner', {access_token, runner });
                        }).catch(error => console.error(error));
                      }}>
                    <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                </TouchableOpacity>
              </View>
            </View>}
            {(pingMasterErrors != "" || imuPercentage !== "") && <View style={{ borderWidth: 0.7,borderRadius:7, borderColor: '#00d5f7'}}>
                <View style={{ margin: 10, alignSelf: "center", alignItems: "center" }}>
                    <Text>
                        {pingMasterErrors && pingMasterErrors} {imuPercentage && imuPercentage}
                    </Text>
                    <TouchableOpacity
                        style={[styles.button, { width: 70, backgroundColor: "#00d5f7" }]}
                        onPress={() => {
                          CollectData.CloseAll()
                          .then(res => {
                            if(res === "Success"){
                              console.log("Socket has been successfully closed.");
                              navigation.navigate('Runner', {access_token, runner});
                            }else{
                              console.log("An error occured while trying to close socket.", res);
                            }
                          })
                          .catch(error => console.error(error));
                        }}>
                        <Text style={{alignSelf: "center", fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                    </TouchableOpacity>
                </View>
            </View>}
            {(xsensConnectionError != "") && <View style={{borderWidth: 0.7, borderRadius:7, borderColor: '#00d5f7'}}>
              <View style={{margin: 10, alignSelf:"center", alignItems:"center"}}>
                <Text>
                  {xsensConnectionError} {errorMessage != "" && errorMessage}
                </Text>
                <TouchableOpacity
                      style={[styles.button,{width: 70, backgroundColor: "#00d5f7"}]}
                      onPress={() => {
                        CollectData.Reset().then(resolve => {
                          console.log(resolve);
                          if(resolve === "OK"){
                            console.log("Master has been successfully reset.");
                          }else{
                            console.log("An error occured while trying to reset the Master.");
                          }
                          navigation.navigate('Runner', {access_token, runner });
                        }).catch(error => console.error(error));
                      }}>
                    <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                </TouchableOpacity>
              </View>
            </View>}
              {errorMesureCalibration != "" && 
              <View style={{borderWidth: 0.7, borderRadius: 5, borderColor: '#00d5f7'}}>
                <View style={{margin: 10, alignSelf:"center", alignItems:"center"}}>
                  <Text style={{color:"#f54284",fontSize: 16}}>
                    {errorMesureCalibration} 
                  </Text>
                  <TouchableOpacity
                        style={[styles.button,{width: 70, backgroundColor: "#00d5f7"}]}
                        onPress={() => {
                          storeCalibrationState("0");
                          setErrorMesureCalibration("");
                          navigation.navigate('SessionCalibration', {activity, sessionList, access_token, runner, choiceActivity});
                        }}>
                      <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                  </TouchableOpacity>
                </View>
              </View>}
              {errorMesure != "" && 
              <View style={{borderWidth: 0.7, borderRadius: 5, borderColor: '#00d5f7'}}>
                <View style={{margin: 10, alignSelf:"center", alignItems:"center"}}>
                  <Text style={{color:"#f54284",fontSize: 16}}>
                    {errorMesure} 
                  </Text>
                  <TouchableOpacity
                        style={[styles.button,{width: 70, backgroundColor: "#00d5f7"}]}
                        onPress={() => {
                          setErrorMesureCalibration("");
                          navigation.navigate('SessionCalibration', {activity, sessionList, access_token, runner, choiceActivity});
                        }}>
                      <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                  </TouchableOpacity>
                </View>
              </View>}
              {successMesure != "" && 
              <View style={{borderWidth: 0.7, borderRadius: 5, borderColor: '#00d5f7'}}>
                <View style={{margin: 10, alignSelf:"center", alignItems:"center"}}>
                  <Text style={{color:"#00cc99",fontSize: 18}}>
                    {successMesure} 
                  </Text>
                  <TouchableOpacity
                        style={[styles.button,{width: 70, backgroundColor: "#00d5f7"}]}
                        onPress={() => {
                          setErrorMesureCalibration("");
                          navigation.navigate('SessionCalibration', {activity, sessionList, access_token, runner, choiceActivity });
                        }}>
                      <Text style={{fontSize: isTablet? 18 : 15}}>{translate('ok')}</Text>
                  </TouchableOpacity>
                </View>
              </View>}     
            </VStack>
          </Box>

          <View style={{paddingTop:20}}>
            {!isWideEnough && <View>
              {displayConnectionState()}
            </View>}
          </View>
        </ScrollView>
        {imuInformation && <View style={{position: "absolute", width:"100%", height:"90%", justifyContent:"center", alignItems:"center"}}>
          <View style={{width:"70%", backgroundColor :"#F2F2F2", borderWidth:2, borderColor:"#ccc"}}>
            <View style={{margin: 5}}>
              <TouchableOpacity style={{backgroundColor: "#888", width: 25, height: 25, borderRadius:90, alignSelf:"flex-end", alignItems:"center"}}
                onPress={()=> setImuInformation(false)}>
                <Text style={{fontWeight:"bold", color:"white", fontSize: isTablet? 18 : 15}}>X</Text>
              </TouchableOpacity>
              <Heading style={{fontSize:20}}>{translate('display_xsens_status')}</Heading>
              <Text style={{textAlign: "justify", paddingHorizontal:"1%", fontSize: isTablet ? 15 : 11}}>
                {translate('display_xsens_order_message')}
              </Text>
            </View>
          </View>
        </View>}
      </NativeBaseProvider>
    );
  }
  
  
  export default DeviceMesure;
  
  const styles = StyleSheet.create({
    picker_component:{
      flex:1,
      borderRadius: 4,
      width: 150,
      marginRight: 30,
      justifyContent:'flex-end'
    },
    picker_container:{
      flex: 1,
      alignItems: "flex-end",
      height: 30,
    },
    base_check:{
      width:"8%",
      alignItems: 'center',
    },
    size_number_check:{
      fontSize: 16,
    },
    input: {
      marginTop: 5,
      borderWidth: 1,
      borderRadius: 4,
      borderColor:"#00d5f7"
    },
    isLoading_container:{
      height:40,
      alignItems: 'center',
      justifyContent:'center'
    },
    upper_container:{
      paddingTop: 10,
      justifyContent : 'flex-end',
      flexDirection : 'row',
    },
    button:{
      marginTop: 15,
      width: 150,
      height: 40,
      borderRadius: 30,
      backgroundColor:"#00d5f7",
      justifyContent: "center",
      alignItems: "center"
    },
  })